import * as React from "react";
import ReactLoading from "react-loading";
import colors from "../utils/colors.json";
import "./Loader.css";

export const Loader: React.FunctionComponent<{
  size?: "small" | "medium" | "large";
  color?: string;
  inline?: boolean;
}> = (props) => {
  return (
    <div
      className={`Loader ${props.size || ""} ${props.inline ? "inline" : ""}`}
    >
      <ReactLoading type="spin" color={props.color || colors["dark-grey"]} />
    </div>
  );
};
