/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./EntitySelector.css";

import { Loader } from "./Loader";
import API from "../utils/api";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Warning from "@mui/icons-material/Warning";
// import Bank from "@mui/icons-material/AccountBalance";
// import Warning from "@mui/icons-material/Warning";
import { NZBNEntitySearch, NZBNEntitySearchResult } from "./NZBNEntitySearch";
import { SessionStore } from "src/utils/session";
import { NzbnEntity } from "utils-common/src/db/dollaUsers/models";
import { dollaUsers } from "utils-common";
import { uniq } from "lodash";
import { getFullAddress } from "src/utils/helpers";
import { Entity } from "rdb/src/models";

type EntitySelectorProps = {
  onChange?: (entity: {
    account_name?: string;
    account_number?: string;
    name?: string;
    _id?: string;
    entity: {
      name?: string;
      trading_name?: string;
      avatar_url?: string;
      phone?: string;
      gst_number?: string;
      email?: string;
      address?: string;
      website?: string;
      entity_type?: Entity["type"];
    };
  }) => void;
  onEntityLoad?: (entity: any | undefined) => void;
  name?: string;
  avatar_url?: string;
  account_name?: string;
  account_number?: string;
  gst_number?: string;
  email?: string;
  address?: string;
  phone?: string;
  website?: string;
  entity_type?: string;
};

/** A selector for an entity */
export const EntitySelector: React.FunctionComponent<EntitySelectorProps> = (
  props
) => {
  const {
    name,
    avatar_url,
    account_name,
    account_number,
    gst_number,
    email,
    address,
    phone,
    website,
    entity_type,
    onChange,
  } = props;

  const [loading, setLoading] = React.useState<string | boolean>(false);
  const [params, setParams] = React.useState<any>();
  const [entity, setEntity] = React.useState<any>();
  const [bAcc, setBAcc] = React.useState<dollaUsers.Models.BankAccount>();

  const clean = (str?: string) => str?.replace(/[^\d]/g, "");
  const cleanNumber = clean(account_number);
  const validNumber = cleanNumber?.length === 15 || cleanNumber?.length === 16;

  const cleanBillerNumber = clean(entity?.biller_account);
  const validBillerNumber =
    cleanBillerNumber?.length === 15 || cleanBillerNumber?.length === 16;
  const isBillerAccount =
    validBillerNumber &&
    validNumber &&
    entity?.biller_account === account_number;

  const onSelect = async (result: NZBNEntitySearchResult | undefined) => {
    if (!result) {
      setParams(undefined);
      setEntity(undefined);
      return;
    }

    if (result._id) {
      const _result = result as any;
      const _params = {
        _id: _result._id,
        name: _result?.name,
        // Manual fields
        account_name,
        account_number,
        // Provided fields
        entity: {
          ..._result,
          nzbn: _result.nzbn ?? "",
          avatar_url: avatar_url ?? _result.avatar_url,
          trading_name: _result.trading_name ?? "",
          gst_number: _result.gst_numbers?.[0] ?? "",
          email: _result.email_addresses?.[0] ?? "",
          phone: _result.phone_numbers?.[0] ?? "",
          website: _result.websites?.[0] ?? "",
          address: _result.addresses?.[0] ?? "",
          entity_type: _result.type,
        },
      };

      setParams(_params);
      setEntity(_params.entity);
      onChange?.(_params);
      return;
    }

    // Get full entity
    const link = result?.links.find((x) => x.rel === "Full Entity");

    if (!link) {
      return;
    }

    let res;
    let full: Partial<dollaUsers.Models.Entity>;

    /** Attempt to search nzbn in rdb first */
    setLoading("ENTITY");
    res = await API.get(`/entities?nzbn=${result.nzbn}`);

    if (res.json?.status && res.json?.status !== 200) {
      SessionStore.apiErr(res);
      setLoading(false);
      return;
    }

    const existing = res.json.item;

    if (existing) {
      full = existing;
    } else {
      res = await API.get(link.href, {
        external: true,
        headers: {
          "Ocp-Apim-Subscription-Key":
            process.env.NZBN_SUBSCRIPTION_KEY_SANDBOX_PRIMARY ??
            "f6a78bdcfb5f447abcb1de50d7a1fe02", // ezra's subscription key
        },
      });
      if (res.json?.status && res.json?.status !== 200) {
        SessionStore.apiErr(res);
        setLoading(false);
        return;
      }
      const nzbn_item = res.json as NzbnEntity;

      full = {
        nzbn: nzbn_item.nzbn,
        name: nzbn_item.entityName,
        trading_name: nzbn_item.tradingNames?.[0]?.name ?? nzbn_item.entityName,
        type: "COMPANY",
        nzbn_status: nzbn_item.entityStatusDescription,
        nzbn_type: nzbn_item.entityTypeDescription,
        nzbn_registered_at: nzbn_item.registrationDate,
        previous_names: nzbn_item.previous_names,
        email_addresses: uniq(
          nzbn_item.emailAddresses.map((x) => x.emailAddress)
        ),
        phone_numbers: uniq(
          nzbn_item.phoneNumbers.map(
            (x) => `+${x.phoneCountryCode} ${x.phoneAreaCode} ${x.phoneNumber}`
          )
        ),
        gst_numbers: uniq(nzbn_item.gstNumbers?.map((x) => x.gstNumber)),
        websites: uniq(nzbn_item.websites?.map((x) => x.url)),
        addresses: uniq(
          nzbn_item.addresses?.addressList?.map((x) => getFullAddress(x))
        ),
      };
    }

    // Check if this entity is a registered biller
    res = await API.get(`/entities/billers?q=${full.name}`);
    if (res.json?.status && res.json?.status !== 200) {
      SessionStore.apiErr(res);
      setLoading(false);
      return;
    }

    const biller = res.json.items?.[0];

    full.registered_biller = !!biller;
    full.biller_account = biller ? biller.BankAccountNumber : undefined;

    setLoading(false);

    const _params = {
      _id: full?._id ?? "",
      name: full?.name,
      // Manual fields
      account_name,
      account_number,
      // Provided fields
      entity: {
        nzbn: full?.nzbn,
        avatar_url: avatar_url ?? full?.avatar_url,
        trading_name: full?.trading_name ?? "",
        gst_number: full?.gst_numbers?.[0] ?? "",
        email: full?.email_addresses?.[0] ?? "",
        phone: full?.phone_numbers?.[0] ?? "",
        website: full?.websites?.[0] ?? "",
        address: full?.addresses?.[0] ?? "",
        entity_type: "COMPANY" as Entity["type"],
        // Other NZBN fields
        nzbn_status: full.nzbn_status,
        nzbn_type: full.nzbn_type,
        nzbn_registered_at: full.nzbn_registered_at,
        previous_names: full.previous_names,
        registered_biller: full.registered_biller,
        biller_account: full.biller_account,
        links: full.links,
      },
    };

    setParams(_params);
    setEntity(_params.entity);
    onChange?.(_params);
  };

  const onInputChange = React.useCallback(
    (key: string, val: any) => {
      const o: { [x: string]: any } = {
        account_name: params?.account_name ?? account_name,
        account_number: params?.account_number ?? account_number,
        _id: params?._id,
        name: params?.name ?? name,
        trading_name: entity?.trading_name ?? "",
        logo: entity?.avatar_url ?? avatar_url,
        gst_number: entity?.gst_number ?? gst_number,
        email: entity?.email ?? email,
        address: entity?.address ?? address,
        phone: entity?.phone ?? phone,
        website: entity?.website ?? website,
        entity_type: entity?.entity_type ?? entity_type,
      };
      o[key] = val;

      const newValue = {
        account_name: o?.account_name,
        account_number: o?.account_number,
        _id: o?._id,
        name: o?.name,
        entity: {
          trading_name: o.trading_name,
          logo: o.logo,
          gst_number: o.gst_number,
          email: o.email,
          address: o.address,
          phone: o.phone,
          website: o.website,
          entity_type: o.entity_type,
        },
      };

      setParams(newValue);
      setEntity(newValue.entity);
      onChange?.(newValue);
    },
    [
      params?.account_name,
      params?.account_number,
      params?._id,
      params?.name,
      account_name,
      account_number,
      name,
      entity?.trading_name,
      entity?.avatar_url,
      entity?.gst_number,
      entity?.email,
      entity?.address,
      entity?.phone,
      entity?.website,
      entity?.entity_type,
      avatar_url,
      gst_number,
      email,
      address,
      phone,
      website,
      entity_type,
      onChange,
    ]
  );

  // Get the bank account when the number changes
  React.useEffect(() => {
    const getBankAccount = async () => {
      if (!validNumber) {
        return;
      }
      setLoading("BANK_ACC");
      const result = await API.get(
        `/bank-accounts/by-number/${account_number}`
      );
      setLoading(false);
      if (!result.json?.success) {
        return;
      }
      if (result.json?.item) {
        const _bAcc = result.json.item;
        setBAcc(_bAcc);
        onChange?.({
          account_name: _bAcc.name,
          account_number: _bAcc.account_number,
          entity: entity,
        });
      }
    };

    if (!bAcc) {
      getBankAccount();
    } else if (!validNumber) {
      setBAcc(undefined);
    }
  }, [
    cleanNumber,
    validNumber,
    bAcc,
    account_number,
    params,
    onChange,
    entity,
  ]);

  // Get the entity when the bank account changes
  const getEntity = React.useCallback(async () => {
    if (!validNumber) {
      return;
    }
    setLoading("ENTITY");
    let result = await API.get(`/entities?account_number=${account_number}`);

    setLoading(false);
    if (!result.json?.success) {
      return;
    }
    if (result.json?.item) {
      const ent = result.json.item;
      setEntity(ent);
      const p = {
        ...params,
        entity: ent,
        name: ent?.name ?? ent?.trading_name ?? "",
        _id: ent?._id,
      };
      onChange?.(p);
    }
  }, [account_number, onChange, params, validNumber]);

  React.useEffect(() => {
    if (!entity) {
      getEntity();
    }
  }, [entity, getEntity]);

  return (
    <div className="EntitySelector">
      <label style={{ flexDirection: "column" }}>
        <input
          type="text"
          className="accountNumber"
          value={account_number}
          onChange={(evt) =>
            onInputChange(
              "account_number",
              evt.target.value.replace(/[^0-9 -]/g, "")
            )
          }
          placeholder="00-0000-0000000-00"
        />
        {loading === "BANK_ACC" ? (
          <div className="inlineLoader" style={{ position: "absolute" }}>
            <Loader size="small" />
          </div>
        ) : bAcc ? (
          <div className="badge" title="This bank account exists">
            <CheckIcon />
          </div>
        ) : (
          <></>
        )}
        {validBillerNumber && validNumber && !isBillerAccount ? (
          <div
            className="badge"
            style={{ background: "var(--red)" }}
            title="This bank account is different from the biller account"
          >
            <Warning />
          </div>
        ) : (
          <></>
        )}
      </label>
      <label>
        <input
          type="text"
          value={account_name}
          onChange={(evt) => onInputChange("account_name", evt.target.value)}
          placeholder="Account Name"
        />
      </label>

      <div style={{ height: "2rem" }} />

      {params?.name ? (
        <div className="descriptionWrapper">
          <div className="description">
            <img
              className="avatar"
              src={
                entity?.avatar_url ?? "https://static.dolla.nz/images/bank.png"
              }
              alt=""
            />{" "}
            {params?.name}{" "}
            <a onClick={() => onSelect(undefined)}>
              <ClearIcon />
            </a>
          </div>

          {params?._id ? (
            <div className="noteWrapper">
              <span className="note">{params._id}</span>
            </div>
          ) : (
            <div className="noteWrapper">
              <span className="note">This entity does not exist on dolla</span>
            </div>
          )}

          {entity?.registered_biller ? (
            <div className="noteWrapper">
              <span className="note">This entity is a registered biller:</span>
              <span className="note">{entity.biller_account}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <label style={{ marginBottom: "2rem", width: "100%" }}>
          <NZBNEntitySearch onSelect={onSelect} />
        </label>
      )}

      {loading === "ENTITY" ? (
        <div className="loader">
          <Loader size="medium" />
        </div>
      ) : (
        <div className="entityFields">
          <label>
            <span className="text">Legal name</span>
            <input
              type="text"
              value={params?.name ?? name}
              onChange={(evt) => onInputChange("name", evt.target.value)}
              placeholder="Entity Name"
            />
          </label>
          <label>
            <span className="text">Trading name</span>
            <input
              type="text"
              value={entity?.trading_name ?? name}
              onChange={(evt) => {
                onInputChange("trading_name", evt.target.value);
              }}
              placeholder="Trading Name"
            />
          </label>
          <label>
            <span className="text">Entity type</span>
            <select
              value={entity?.entity_type ?? "COMPANY"}
              onChange={(evt) => onInputChange("entity_type", evt.target.value)}
            >
              <option value="COMPANY">Company</option>
              <option value="PERSON">Person</option>
            </select>
          </label>
          <label>
            <span className="text">Logo URI</span>
            <input
              type="text"
              value={entity?.avatar_url}
              onChange={(evt) => onInputChange("avatar_url", evt.target.value)}
              placeholder="Logo URI"
            />
          </label>
          <label>
            <span className="text">GST Number</span>
            <input
              type="text"
              value={entity?.gst_number ?? gst_number}
              onChange={(evt) =>
                onInputChange(
                  "gst_number",
                  evt.target.value.replace(/[^0-9 -]/g, "")
                )
              }
              placeholder="GST No."
            />
          </label>
          <label>
            <span className="text">Email</span>
            <input
              type="email"
              value={entity?.email ?? email}
              onChange={(evt) => onInputChange("email", evt.target.value)}
              placeholder="Email"
            />
          </label>
          <label>
            <span className="text">Phone</span>
            <input
              type="phone"
              value={entity?.phone ?? phone}
              onChange={(evt) => onInputChange("phone", evt.target.value)}
              placeholder="Phone"
            />
          </label>
          <label>
            <span className="text">Website</span>
            <input
              type="text"
              value={entity?.website ?? website}
              onChange={(evt) => onInputChange("website", evt.target.value)}
              placeholder="Website"
            />
          </label>
          <label>
            <span className="text">Address</span>
            <input
              type="text"
              value={entity?.address ?? address}
              onChange={(evt) => onInputChange("address", evt.target.value)}
              placeholder="Address"
            />
          </label>
        </div>
      )}
    </div>
  );
};
