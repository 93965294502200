/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Tippy from "@tippyjs/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Payment from "@mui/icons-material/Payment";
import moment from "moment";
import DeleteForever from "@mui/icons-material/DeleteForever";
import * as RDB from "rdb";

import "./BankAccountPage.css";

import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { Helmet } from "react-helmet";
import { InspectorRDB } from "src/components/InspectorRDB";

export const BankAccountPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const account_number = urlParams.account_number;
  const [bankAccount, setBankAccount] =
    React.useState<RDB.Models.BankAccount>();
  const [loadingBankAccount, setLoadingBankAccount] = React.useState(false);

  const getBankAccount = React.useCallback(() => {
    async function getBankAccount() {
      setLoadingBankAccount(true);
      const result = await API.get(
        `/bank-accounts/by-number/${account_number}`
      );
      setLoadingBankAccount(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBankAccount(result.json.item);
    }
    getBankAccount();
  }, [account_number]);
  React.useEffect(() => {
    getBankAccount();
  }, [getBankAccount]);

  const deleteBankAccount = async () => {
    if (!bankAccount) {
      return;
    }
    if (
      !window.confirm(
        "Are you sure you want to delete this bank account?\n\nYou can't undo this."
      )
    ) {
      return;
    }

    setLoadingBankAccount(true);
    let result = await API.delete(`/bank_accounts/${bankAccount._id}`);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    SessionStore.setInfo("Deleted bank account");
    navigate(`/bank_accounts`);
  };

  if (loadingBankAccount || !bankAccount) {
    return <Loader />;
  }

  return (
    <div className="BankAccountPage">
      <Helmet>
        <title>
          {bankAccount?.name ??
            bankAccount?.account_name ??
            bankAccount?.account_number ??
            bankAccount._id}
          | Dolla Management Console
        </title>
      </Helmet>
      <Link to={`/bank_accounts`} className="backlink">
        ← Back to bank accounts
      </Link>
      <h3>
        <img src={bankAccount?.avatar_url ?? ""} alt="" />
        {bankAccount?.account_name ??
          bankAccount.account_number ??
          bankAccount._id}
        <Tippy content={"Inspect this item"}>
          <InspectorRDB _id={bankAccount._id} />
        </Tippy>
        <a className="titleLink" onClick={deleteBankAccount} title="Delete">
          <DeleteForever fontSize="inherit" />
        </a>
      </h3>
      <table className="prettyDetails">
        <tbody>
          <tr>
            <td>Account Name</td>
            <td>{bankAccount.account_name ?? bankAccount.name ?? "-"}</td>
          </tr>
          <tr>
            <td>Account Number</td>
            <td>{bankAccount.account_number ?? "-"}</td>
          </tr>
          <tr>
            <td>Created</td>
            <td title={moment(bankAccount.created_at).format()}>
              {moment(bankAccount.created_at).fromNow()}
            </td>
          </tr>
          <tr>
            <td>Updated at</td>
            <td title={moment(bankAccount.updated_at).format()}>
              {moment(bankAccount.updated_at).fromNow()}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{bankAccount.status}</td>
          </tr>
        </tbody>
      </table>
      <div className="linklist vertical">
        <Link to={`/bank_accounts/${bankAccount.account_number}/payments`}>
          <Payment className="icon" />
          Payments
        </Link>
      </div>
    </div>
  );
};
