/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./EntityEditor.css";
import { set } from "lodash-es";
import { SessionStore } from "../../../utils/session";
import { getFormattedPhoneNumber } from "../../../utils/phonenumbers";
import { TippyExplainer } from "../../../components/TippyExplainer";
import {
  NZBNEntitySearch,
  NZBNEntitySearchResult,
} from "src/components/NZBNEntitySearch";
import API from "../../../utils/api";
import { EntityAccountEditList } from "./EntityAccountEditList";
import { Loader } from "src/components/Loader";
import { EntityDetailEditList } from "./EntityDetailEditList";
import { BankAccount, Entity } from "rdb/src/models";
import { validateBankAccount } from "../../../utils/helpers";

type EntityEditorProps = {
  onSubmit: (payload: {
    entity: Partial<Entity>;
    bank_accounts?: Partial<BankAccount>[];
  }) => Promise<void>;
  entity?: Partial<Entity>;
  bank_accounts?: Partial<BankAccount>[];
  submitTitle?: string;
};

export const EntityEditor: React.FunctionComponent<EntityEditorProps> = (
  props
) => {
  const [entity, setEntity] = React.useState(props.entity);
  const [bank_accounts, setBankAccounts] = React.useState<
    Partial<BankAccount>[]
  >(props.bank_accounts ?? []);

  const [loading, setLoading] = React.useState(false);

  const onChange = (path: string, value: any) => {
    const newEntity = set(entity ?? {}, path, value);
    console.log(newEntity);
    setEntity({ ...newEntity });
  };

  const onInputChange = (e: React.FormEvent, path: string, value?: any) => {
    e.preventDefault();
    onChange(path, value ?? (e.target as HTMLInputElement).value);
  };

  const onSelect = async (entityResult: NZBNEntitySearchResult | undefined) => {
    if (!entityResult) {
      setEntity(undefined);
      return;
    }

    const exists = await API.get(`/entities?nzbn=${entityResult.nzbn}`);

    if (exists.json?.success) {
      SessionStore.setError(
        `Entity already exists for "${entityResult.entityName}" (${exists.json.item?._id})`
      );
      return;
    }

    // Get full entity
    const link = entityResult?.links.find((x) => x.rel === "Full Entity");

    if (!link) {
      return;
    }

    setLoading(true);
    const result = await API.get(`/entities/nzbn/${entityResult.nzbn}`);
    setLoading(false);

    if (!result.json.success) {
      SessionStore.setError(`Failed to query NZBN ${entityResult.nzbn}`);
      return;
    }

    const newEntity: Partial<Entity> = result.json.item;

    setEntity(newEntity);
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Validate
    SessionStore.setState({ error: undefined });
    if (!entity?.name?.length) {
      SessionStore.setError("Please give this entity a name");
      return;
    }
    if (entity?.phone_numbers?.[0]) {
      const phoneValidation = getFormattedPhoneNumber(
        entity?.phone_numbers?.[0]
      );
      if (!phoneValidation.success) {
        SessionStore.setError(phoneValidation.message);
        return;
      }
      entity.phone_numbers[0] = phoneValidation.result.international;
    }

    const invalid_account = bank_accounts.find(
      (x) => !validateBankAccount(x?.account_number ?? "")
    );
    if (bank_accounts.length && invalid_account) {
      SessionStore.setError(
        `Invalid account number - ${invalid_account?.account_name} ${invalid_account?.account_number}  `
      );
      return;
    }

    // Clean up any empty items in array attrs
    entity.email_addresses =
      entity?.email_addresses
        ?.join(",")
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x) ?? [];
    entity.addresses =
      entity?.addresses
        ?.join(",")
        ?.split(",")
        .map((x) => x.trim())
        .filter((x) => x) ?? [];
    entity.gst_numbers =
      entity?.gst_numbers
        ?.join(",")
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x) ?? [];

    // Submit
    props.onSubmit({ entity, bank_accounts: bank_accounts });
  };

  return (
    <form className="EntityEditor" onSubmit={onSubmit}>
      <label>
        Type
        <select onChange={(e) => onInputChange(e, "type")} name="type">
          <option value="PERSON">Individual</option>
          <option value="SOLE_TRADER">Sole Trader</option>
          <option value="COMPANY">Company</option>
          <option value="OVERSEAS">Overseas Company</option>
        </select>
      </label>

      {entity?.type === "COMPANY" || entity?.type === "SOLE_TRADER" ? (
        <>
          <label style={{ marginTop: "2rem", marginBottom: "4rem" }}>
            Search
            <NZBNEntitySearch onSelect={onSelect} />
          </label>
          <label>
            Name
            <input
              type="text"
              placeholder="Name"
              value={entity?.name ?? ""}
              onChange={(evt) => onChange("name", evt.target.value)}
              required={entity?.type === "COMPANY"}
            />
            {loading ? (
              <div className="inlineLoader">
                <Loader size="small" />
              </div>
            ) : (
              <></>
            )}
          </label>
          <label>
            Trading Name
            <input
              type="text"
              placeholder="Trading name"
              value={entity?.trading_name ?? ""}
              onChange={(evt) => onChange("trading_name", evt.target.value)}
              required
            />
            {loading ? (
              <div className="inlineLoader">
                <Loader size="small" />
              </div>
            ) : (
              <></>
            )}
          </label>
          <label>
            NZBN
            <TippyExplainer>
              The entity NZBN. This is optional for sole traders.
            </TippyExplainer>
            <input
              type="text"
              placeholder=""
              value={entity?.nzbn ?? ""}
              onChange={(evt) => onChange("nzbn", evt.target.value)}
              required={entity?.type === "COMPANY"}
            />
            {loading ? (
              <div className="inlineLoader">
                <Loader size="small" />
              </div>
            ) : (
              <></>
            )}
          </label>
          <label>
            Akahu Merchant ID (Optional)
            <TippyExplainer>
              Akahu Merchant ID from Akahu enriched transactions
            </TippyExplainer>
            <input
              type="text"
              placeholder="merchant_123"
              value={entity?._merchant ?? ""}
              onChange={(evt) => onChange("_merchant", evt.target.value)}
            />
            {loading ? (
              <div className="inlineLoader">
                <Loader size="small" />
              </div>
            ) : (
              <></>
            )}
          </label>
        </>
      ) : (
        <>
          <label>
            Name
            <input
              type="text"
              placeholder=""
              value={entity?.name ?? ""}
              onChange={(evt) => onChange("name", evt.target.value)}
              required
            />
          </label>
          <label>
            <div className="Verify">
              <div>
                <span style={{ paddingBottom: "1.2rem" }}>Is Verified?</span>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    onInputChange(e, "verified_at", new Date().toISOString())
                  }
                  defaultChecked={false}
                />
              </div>
              <select
                disabled={!entity?.verified_at}
                onChange={(e) => onInputChange(e, "verified_by")}
                name="type"
              >
                <option value="CALL_BANK">Called Bank branch</option>
                <option value="BANK_STATEMENT">Bank Statement</option>
              </select>
            </div>
          </label>
        </>
      )}
      <label>
        Logo URL
        <input
          type="text"
          placeholder="https://..."
          value={entity?.avatar_url ?? ""}
          onChange={(evt) => onChange("avatar_url", evt.target.value)}
        />
        {loading ? (
          <div className="inlineLoader">
            <Loader size="small" />
          </div>
        ) : (
          <></>
        )}
      </label>
      <label>
        Slugs (comma separated){" "}
        <TippyExplainer>
          These are additional search terms that will bring up this entity.
          <br />
          For example, old trading names or extra social handles can go in here.
        </TippyExplainer>
        <input
          type="text"
          placeholder="Gandalf's Gurnard, Faramir's Flounder, ..."
          value={entity?.previous_names?.join(", ") ?? ""}
          onChange={(evt) =>
            onChange("previous_names", evt.target.value.split(", "))
          }
        />
        {loading ? (
          <div className="inlineLoader">
            <Loader size="small" />
          </div>
        ) : (
          <></>
        )}
      </label>
      <EntityAccountEditList
        entity={entity}
        accounts={bank_accounts}
        onChange={(bAccs) => setBankAccounts(bAccs)}
      />

      <EntityDetailEditList entity={entity} onChange={onChange} />

      <input type="submit" value={props.submitTitle || "Submit"} />
    </form>
  );
};
