/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./QR.css";

import qr from "qr.js";

type QRProps = {
  value?: string;
  showInvert?: boolean;
  showDownload?: boolean;
};

export const QR: React.FunctionComponent<QRProps> = ({
  value,
  showInvert,
  showDownload,
}) => {
  const [invert, setInvert] = React.useState(false);
  const qrRef = React.useRef<HTMLDivElement>(null);

  /** A 2d array of bits that should be on or off (true or false) */
  const qrCodeRows = qr(value ?? "").modules;
  /** How many dots wide this QR code is */
  const qrWidthInDots = qrCodeRows[0].length;
  /** The rows of the QR code that are _not_ corners */
  const dataRows = qrCodeRows.map((row, y) =>
    row.map((cell, x) => {
      // Top-right corner
      if (y < 7 && x >= qrWidthInDots - 7) {
        return false;
      }
      // Top-left corner
      if (y < 7 && x < 7) {
        return false;
      }
      // Bottom-left corner
      if (y >= qrWidthInDots - 7 && x < 7) {
        return false;
      }

      return cell;
    })
  );
  /** The position of the centers of the "eyes" in the corner of the qr code */
  const cornerPositions = [
    { x: qrWidthInDots - 4, y: 3 },
    { x: 3, y: 3 },
    { x: 3, y: qrWidthInDots - 4 },
  ];

  const onDownload = () => {
    if (qrRef.current) {
      const base64doc = btoa(
        unescape(encodeURIComponent(qrRef.current.innerHTML))
      );
      const a = document.createElement("a");
      const e = new MouseEvent("click");
      a.download =
        "a_beautiful_dolla_qr_code_created_by_the_magnificent_olly.svg";
      a.href = "data:image/svg+xml;base64," + base64doc;
      a.dispatchEvent(e);
    }
  };

  return (
    <div className="QR">
      {showInvert ? (
        <label>
          <input
            type="checkbox"
            checked={invert}
            onChange={() => setInvert(!invert)}
          />
          Invert colours
        </label>
      ) : (
        <></>
      )}
      <div className={`qrSpot ${invert ? "invert" : ""}`} ref={qrRef}>
        <svg viewBox={`0 0 ${qrWidthInDots} ${qrWidthInDots}`}>
          {/* The dots */}
          {dataRows.map((row, y) =>
            row.map((cell, x) =>
              cell ? (
                <circle
                  key={`${x}_${y}`}
                  cy={y + 0.5}
                  cx={x + 0.5}
                  r={0.5}
                  fill={invert ? "var(--blue)" : "white"}
                />
              ) : undefined
            )
          )}
          {/* The corners */}
          {cornerPositions.map(({ x, y }, i) => {
            return (
              <g key={`corner_${i}`}>
                {/* The outer circle */}
                <circle
                  cy={y + 0.5}
                  cx={x + 0.5}
                  r={3}
                  stroke={invert ? "var(--blue)" : "white"}
                  fill={"transparent"}
                  strokeWidth={1}
                />
                {/* The inner circle */}
                <circle
                  cy={y + 0.5}
                  cx={x + 0.5}
                  r={1.5}
                  fill={invert ? "var(--blue)" : "white"}
                />
              </g>
            );
          })}
        </svg>
      </div>
      {showDownload ? <button onClick={onDownload}>Download</button> : <></>}
    </div>
  );
};
