import * as React from "react";
import { Link, useParams } from "react-router-dom";
import "./UserPayments.css";
import { groupBy, orderBy, uniqBy } from "lodash-es";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { PaymentRow } from "./components/PaymentRow";
import { PaymentRequiringVerificationRow } from "./components/PaymentRequiringVerificationRow";
import { PaymentScheduledRow } from "./components/PaymentScheduledRow";
import moment from "moment";
import { Helmet } from "react-helmet";

// Used for type discrimination
const isPendingPayment = (
  pmt: dollaUsers.Models.Payment | dollaUsers.Models.PendingPayment
): pmt is dollaUsers.Models.PendingPayment =>
  pmt._id.startsWith("pending_payment");

export const UserPayments: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [payments, setPayments] = React.useState<
    (dollaUsers.Models.Payment | dollaUsers.Models.PendingPayment)[]
  >([]);
  const [participants, setParticipants] = React.useState<
    (
      | dollaUsers.Models.User
      | dollaUsers.Models.Business
      | dollaUsers.Models.BankAccount
    )[]
  >([]);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingPayments, setLoadingPayments] = React.useState(false);

  React.useEffect(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    getUser();
  }, [_user]);
  React.useEffect(() => {
    async function getPayments() {
      setLoadingPayments(true);
      const [paymentResult, requireVerifyResult, scheduledResult] =
        await Promise.all([
          API.get(`/users/${_user}/payments`),
          API.get(
            `/users/${_user}/payments/pending?status=REQUIRES_VERIFICATION`
          ),
          API.get(`/users/${_user}/payments/pending?status=SCHEDULED`),
        ]);
      setLoadingPayments(false);
      if (!paymentResult.json?.success) {
        SessionStore.apiErr(paymentResult);
        return;
      }
      if (!requireVerifyResult.json?.success) {
        SessionStore.apiErr(requireVerifyResult);
        return;
      }
      if (!scheduledResult.json?.success) {
        SessionStore.apiErr(scheduledResult);
        return;
      }
      setPayments([
        ...paymentResult.json.item.payments,
        ...requireVerifyResult.json.item.payments,
        ...scheduledResult.json.item.payments,
      ]);
      setParticipants(
        uniqBy(
          [
            ...paymentResult.json.item.participants,
            ...paymentResult.json.item.creators, // Edge-case: Users might not be a `participant` in other payments.
            ...requireVerifyResult.json.item.participants,
            ...scheduledResult.json.item.participants,
          ],
          "_id"
        )
      );
    }
    getPayments();
  }, [_user]);

  const refreshPayment = async (_user: string, _pmt: string) => {
    const result = await API.get(`/sandbox/${_pmt}?_user=${_user}`);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    let newPmt = result.json.item;
    // If this is a pending payment that has just become a payment, get the new payment
    if (newPmt._id.startsWith("pending_payment_") && newPmt._payment) {
      const result2 = await API.get(
        `/sandbox/${newPmt._payment}?_user=${_user}`
      );
      if (!result2.json?.success) {
        SessionStore.apiErr(result2);
        return;
      }
      newPmt = result2.json.item;
    }
    setPayments(
      payments.map((p) => {
        if (p._id === _pmt) {
          return result.json.item;
        }
        return p;
      })
    );
  };

  if (loadingUser || loadingPayments || !user) {
    return <Loader />;
  }

  /** Given a payment, figure out what sort key to use.
   *
   * We use special sort keys for payments that we want to put in special spots.
   */
  const getSortDate = (
    pmt: dollaUsers.Models.Payment | dollaUsers.Models.PendingPayment
  ) =>
    pmt.status === "REQUIRES_VERIFICATION"
      ? `ZZZZ-${pmt.created_at}`
      : pmt.status === "SCHEDULED"
      ? pmt.pay_at
      : pmt.created_at;
  const getDateTitle = (
    pmt: dollaUsers.Models.Payment | dollaUsers.Models.PendingPayment
  ) => {
    if (pmt.status === "REQUIRES_VERIFICATION") {
      return "Requires Verification";
    }
    let date = pmt.created_at;
    if (pmt.status === "SCHEDULED" && pmt.pay_at) {
      date = pmt.pay_at;
    }
    return moment(date).format("Do MMM YYYY");
  };

  let groupedByDate: [
    string,
    (dollaUsers.Models.Payment | dollaUsers.Models.PendingPayment)[]
  ][] = Object.entries(
    groupBy(orderBy(payments, getSortDate, "desc"), getDateTitle)
  );
  const yesterdayTitle = moment().subtract(1, "day").format("Do MMM YYYY");
  const todayTitle = moment().format("Do MMM YYYY");
  const tomorrowTitle = moment().add(1, "day").format("Do MMM YYYY");
  // Insert an entry for today if one doesn't exist
  if (!groupedByDate.find(([date]) => date === todayTitle)) {
    groupedByDate.push([todayTitle, []]);
  }
  groupedByDate = orderBy(
    groupedByDate,
    ([title, [pmt]]) =>
      pmt ? getSortDate(pmt) : moment(title, "Do MMM YYYY").toISOString(),
    "desc"
  );

  return (
    <div className="UserPayments">
      <Helmet>
        <title>{user.name}'s Payments | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name}'s Payments</h3>
      <div className="linkRow">
        <a href="#today">Jump to today</a>
      </div>
      {!payments.length ? (
        <p>No payments</p>
      ) : (
        <div className="payments">
          {groupedByDate.map(([date, pmts]) => (
            <>
              <div className="date">
                {date === yesterdayTitle ? (
                  <>
                    {date}
                    <span className="subtitle">Yesterday</span>
                  </>
                ) : date === todayTitle ? (
                  <>
                    {date}
                    <span className="subtitle" id="today">
                      Today
                    </span>
                  </>
                ) : date === tomorrowTitle ? (
                  <>
                    {date}
                    <span className="subtitle">Tomorrow</span>
                  </>
                ) : (
                  date
                )}
              </div>
              {pmts.map((pmt) => {
                if (isPendingPayment(pmt)) {
                  if (pmt.status === "REQUIRES_VERIFICATION") {
                    return (
                      <PaymentRequiringVerificationRow
                        payment={pmt}
                        participants={[user, ...participants]}
                        refreshPayment={refreshPayment}
                      />
                    );
                  } else if (pmt.status === "SCHEDULED") {
                    return (
                      <PaymentScheduledRow
                        payment={pmt}
                        participants={[user, ...participants]}
                        refreshPayment={refreshPayment}
                      />
                    );
                  }
                  // Should never see pending payments that have turned into payments
                  return <></>;
                }
                return (
                  <PaymentRow
                    payment={pmt}
                    participants={[user, ...participants]}
                  />
                );
              })}
            </>
          ))}
        </div>
      )}
    </div>
  );
};
