/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./EntityAccountEditList.css";
import { set } from "lodash-es";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import AddCircle from "@mui/icons-material/AddCircle";
import Warning from "@mui/icons-material/Warning";
import { TippyExplainer } from "../../../components/TippyExplainer";
import API from "../../../utils/api";

import { Loader } from "src/components/Loader";
import { BankAccount, Entity } from "rdb/src/models";
import { EditableBankAccount } from "../EntityPage";

type AccountRowProps = {
  bAcc: EditableBankAccount;
  i: number;
  onChange: (index: number, path: string, value: any) => void;
  loading: boolean;
  onRemove: (index: number) => void;
};

const AccountRow: React.FunctionComponent<AccountRowProps> = (props) => {
  return (
    <div className="Account" key={props.i}>
      <table>
        <tbody>
          <tr>
            <td>
              Name{" "}
              <TippyExplainer>
                An optional name to show users when they pay this account.
                <br />
                For example, Vodafone might have a "Vodafone Broadband" account
                and a "Vodafone Mobile" account.
              </TippyExplainer>
            </td>
            <td>
              <input
                type="text"
                placeholder="(Optional)"
                value={props.bAcc.account_name ?? ""}
                onChange={(evt) =>
                  props?.onChange(props.i, "account_name", evt.target.value)
                }
              />
            </td>
            <td>
              <div className="inner">
                Account Number
                {props.bAcc?.warn ? (
                  <Warning color="error" fontSize="inherit" />
                ) : (
                  <></>
                )}
                {props.loading ? (
                  <Loader size="small" />
                ) : (
                  <input
                    type="text"
                    placeholder="(Required)"
                    value={props.bAcc.account_number ?? ""}
                    onChange={(evt) =>
                      props?.onChange(
                        props.i,
                        "account_number",
                        evt.target.value
                      )
                    }
                    required
                  />
                )}
              </div>
            </td>
            <td>
              <div className="inner">
                <a onClick={() => props?.onRemove(props.i)}>
                  <RemoveCircle fontSize="large" />
                </a>
                {props.bAcc?.warn ? (
                  <span>This account number belongs to another entity</span>
                ) : (
                  <></>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              Description{" "}
              <TippyExplainer>
                An optional description to show users when they pay this
                account.
              </TippyExplainer>
            </td>
            <td colSpan={2}>
              <input
                type="text"
                placeholder="(Optional)"
                value={props?.bAcc.description ?? ""}
                onChange={(evt) =>
                  props?.onChange(props.i, "description", evt.target.value)
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              Minimum{" "}
              <TippyExplainer>
                An optional minimum amount when paying this account
                <br />
                For example, Digicell has a minimum topup of $10
              </TippyExplainer>
            </td>
            <td>
              <input
                type="number"
                placeholder="(Optional)"
                value={props?.bAcc?.minimum_amount ?? ""}
                onChange={(evt) =>
                  props?.onChange(props?.i, "minimum_amount", evt.target.value)
                }
              />
            </td>
            <td>
              <div className="inner">
                Maximum{" "}
                <TippyExplainer>
                  An optional maximum amount when paying this account
                  <br />
                  For example, Digicell has a maximum topup of $30
                </TippyExplainer>
                <input
                  type="number"
                  placeholder="(Optional)"
                  value={props?.bAcc?.maximum_amount ?? ""}
                  onChange={(evt) =>
                    props?.onChange(
                      props?.i,
                      "maximum_amount",
                      evt.target.value
                    )
                  }
                />
              </div>
            </td>
          </tr>
          <tr className="borderTop">
            <td>Code</td>
            <td>
              <div className="inner">
                Label
                <input
                  type="text"
                  placeholder="What do you put in this field?"
                  value={props?.bAcc?.code?.label ?? ""}
                  onChange={(evt) =>
                    props?.onChange(props?.i, "code.label", evt.target.value)
                  }
                />
              </div>
            </td>
            <td>
              <div className="inner">
                Hint
                <input
                  type="text"
                  placeholder="Where do you find this information?"
                  value={props?.bAcc?.code?.hint ?? ""}
                  onChange={(evt) =>
                    props?.onChange(props?.i, "code.hint", evt.target.value)
                  }
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>
              <div className="inner">
                Validation{" "}
                <TippyExplainer>
                  This uses Regular Expressions. If you need a hand with it,
                  feel free to ask Olly!
                </TippyExplainer>
                <input
                  type="text"
                  placeholder="^[0-9]{6}$"
                  value={props?.bAcc?.code?.regex ?? ""}
                  onChange={(evt) =>
                    props?.onChange(props?.i, "code.regex", evt.target.value)
                  }
                />
              </div>
            </td>
            <td>
              <div className="inner">
                Keyboard Type{" "}
                <TippyExplainer>
                  What sort of keyboard we should show the user when they go to
                  enter this field:
                  <br />
                  Default - Normal, alphanumeric keyboard.
                  <br />
                  Numeric - Digits 0-9 only.
                  <br />
                  Decimal - Digits 0-9 and ".".
                </TippyExplainer>
                <select
                  value={props?.bAcc?.code?.keyboard ?? "default"}
                  onChange={(evt) =>
                    props?.onChange(props?.i, "code.keyboard", evt.target.value)
                  }
                >
                  <option value="default">Default</option>
                  <option value="numeric">Numeric</option>
                  <option value="decimal">Decimal</option>
                  <option value="email-address">Email</option>
                  <option value="phone-pad">Phone</option>
                  <option value="url">URL</option>
                </select>
              </div>
            </td>
          </tr>
          <tr className="borderTop">
            <td>Reference</td>
            <td>
              <div className="inner">
                Label
                <input
                  type="text"
                  placeholder="What do you put in this field?"
                  value={props?.bAcc?.reference?.label ?? ""}
                  onChange={(evt) =>
                    props?.onChange(
                      props?.i,
                      "reference.label",
                      evt.target.value
                    )
                  }
                />
              </div>
            </td>
            <td>
              <div className="inner">
                Hint
                <input
                  type="text"
                  placeholder="Where do you find this information?"
                  value={props?.bAcc?.reference?.hint ?? ""}
                  onChange={(evt) =>
                    props?.onChange(
                      props?.i,
                      "reference.hint",
                      evt.target.value
                    )
                  }
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>
              <div className="inner">
                Validation{" "}
                <TippyExplainer>
                  This uses Regular Expressions. If you need a hand with it,
                  feel free to ask Olly!
                </TippyExplainer>
                <input
                  type="text"
                  placeholder="^[0-9]{6}$"
                  value={props?.bAcc?.reference?.regex ?? ""}
                  onChange={(evt) =>
                    props?.onChange(
                      props?.i,
                      "reference.regex",
                      evt.target.value
                    )
                  }
                />
              </div>
            </td>
            <td>
              <div className="inner">
                Keyboard Type{" "}
                <TippyExplainer>
                  What sort of keyboard we should show the user when they go to
                  enter this field:
                  <br />
                  Default - Normal, alphanumeric keyboard.
                  <br />
                  Numeric - Digits 0-9 only.
                  <br />
                  Decimal - Digits 0-9 and ".".
                </TippyExplainer>
                <select
                  value={props?.bAcc?.reference?.keyboard ?? "default"}
                  onChange={(evt) =>
                    props?.onChange(
                      props?.i,
                      "reference.keyboard",
                      evt.target.value
                    )
                  }
                >
                  <option value="default">Default</option>
                  <option value="numeric">Numeric</option>
                  <option value="decimal">Decimal</option>
                  <option value="email-address">Email</option>
                  <option value="phone-pad">Phone</option>
                  <option value="url">URL</option>
                </select>
              </div>
            </td>
          </tr>
          <tr className="borderTop">
            <td>Success</td>
            <td>
              <div className="inner">
                Type{" "}
                <select
                  value={props?.bAcc?.success?.[0]?.type}
                  onChange={(evt) =>
                    props?.onChange(
                      props?.i,
                      "success[0].type",
                      evt.target.value
                    )
                  }
                >
                  <option value="EMAIL">Email</option>
                  <option value="URI">URI</option>
                </select>
              </div>
            </td>
            <td>
              <div className="inner">
                Value
                <input
                  type="text"
                  placeholder="Success value"
                  value={props?.bAcc?.success?.[0]?.value ?? ""}
                  onChange={(evt) =>
                    props?.onChange(
                      props?.i,
                      "success[0].value",
                      evt.target.value
                    )
                  }
                />
              </div>
            </td>
            <td>
              <div className="inner">
                Secret
                <input
                  type="text"
                  placeholder="Optional key/secret included as a header in POST request"
                  value={props?.bAcc?.success?.[0]?.secret ?? ""}
                  onChange={(evt) =>
                    props?.onChange(
                      props?.i,
                      "success[0].secret",
                      evt.target.value
                    )
                  }
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>
              <div className="inner">
                Template{" "}
                <input
                  type="text"
                  placeholder="PaymentReceipt"
                  value={props?.bAcc?.success?.[0]?.template ?? ""}
                  onChange={(evt) =>
                    props?.onChange(
                      props?.i,
                      "success[0].template",
                      evt.target.value
                    )
                  }
                />
              </div>
            </td>
            <td>
              <div className="inner">
                Status{" "}
                <select
                  value={props?.bAcc?.success?.[0]?.type}
                  onChange={(evt) =>
                    props?.onChange(
                      props?.i,
                      "success[0].status",
                      evt.target.value
                    )
                  }
                >
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                </select>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

type EntityAccountEditListProps = {
  entity?: Partial<Entity>;
  accounts: EditableBankAccount[];
  onChange: (accounts: EditableBankAccount[]) => void;
};

export const EntityAccountEditList = React.forwardRef(
  (props: EntityAccountEditListProps, ref: any) => {
    const { entity, accounts } = props;

    const [loading, setLoading] = React.useState(-1);
    const [bankAccounts, setBankAccounts] = React.useState<
      EditableBankAccount[]
    >(accounts.length ? accounts : []);

    const getEntityFromBankNo = async (number: string, index: number) => {
      const clean = (str?: string) => str?.replace(/[^\d]/g, "");
      const cleanNumber = clean(number);
      const validNumber =
        cleanNumber?.length === 15 || cleanNumber?.length === 16;

      if (!validNumber) {
        return false;
      }

      setLoading(index);
      const result = await API.get(`/entities?account_number=${number}`);
      console.log("result", result.json);
      setLoading(-1);

      if (!result.json?.success) {
        return false;
      }

      return result.json.item as BankAccount;
    };

    const onChange = async (index: number, path: string, value: any) => {
      const newAccounts = [...bankAccounts];
      const account = newAccounts[index];

      if (account) {
        if (path === "account_number") {
          const clean = (str?: string) => str?.replace(/[^\d]/g, "");
          const cleanNumber = clean(value);
          const validNumber =
            cleanNumber?.length === 15 || cleanNumber?.length === 16;

          if (validNumber) {
            if (accounts.find((x) => x.account_number === cleanNumber)) {
              newAccounts[index] = set(account, "warn", true);
            }

            const exists = await getEntityFromBankNo(value, index);
            if (exists && exists._id !== entity?._id) {
              newAccounts[index] = set(account, "warn", true);
            }
          } else {
            newAccounts[index] = set(account, "warn", false);
          }
        }
      }

      newAccounts[index] = set(account, path, value);
      setBankAccounts(newAccounts);
      props?.onChange(newAccounts);
    };

    const addAccount = () => {
      const temp: EditableBankAccount = {
        account_name: "",
        account_number: "",
      };
      const newAccounts = [...bankAccounts, temp];
      setBankAccounts(newAccounts);
      props?.onChange(newAccounts);
    };

    const removeAccount = async (index: number) => {
      const newAccounts = [...bankAccounts];
      const temp = newAccounts[index];
      if (temp._id?.startsWith("bank_acc")) {
        ref?.current?.open(
          `Unlink Bank account from Entity`,
          [
            <p>
              This will unlink <b>{temp.account_number}</b> from{" "}
              <b>{entity?.name}</b>.
            </p>,
          ],
          async () => {
            setLoading(index);
            await API.put(`/entities/${entity?._id ?? ""}/bank-accounts`, {
              bank_accounts: [
                {
                  ...newAccounts[index],
                  remove: true,
                },
              ],
            });
            setLoading(-1);
            window.location.reload();
          }
        );
        return;
      } else {
        newAccounts.splice(index, 1);
      }
      setBankAccounts(newAccounts);
      props?.onChange(newAccounts);
    };

    return (
      <div className="EntityAccountEditList">
        {bankAccounts
          .filter((x) => !x.remove)
          .map((acc, i) => {
            return (
              <AccountRow
                bAcc={acc}
                i={i}
                onChange={onChange}
                onRemove={removeAccount}
                loading={loading === i}
              />
            );
          })}
        <a onClick={addAccount}>
          <AddCircle /> Add Account
        </a>
      </div>
    );
  }
);
