/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Editor from "@monaco-editor/react";
import "./EmailTemplates.css";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Handlebars from "handlebars/dist/cjs/handlebars";

import { Loader } from "../../components/Loader";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { EmailTemplate } from "../../../../../lambdas/dolla-send-email/src";
import { Helmet } from "react-helmet";

type EditableEmailTemplate = {
  /** The subject. Can contain handlebar template substitutions */
  subject: string;
  /** The html. Can contain handlebar template substitutions */
  html: string;
  /** The text. Can contain handlebar template substitutions */
  text: string;
  /** Whether this template is rendered as a child of the WrapperTemplate */
  wrapped: boolean;
  /** Default data to put into the template if none is supplied when rendering */
  defaults: string;
};

export const EmailTemplates: React.FunctionComponent = () => {
  const [loadingList, setLoadingList] = React.useState(false);
  const [loadingTemplate, setLoadingTemplate] = React.useState(false);
  const [loadingWrapper, setLoadingWrapper] = React.useState(false);
  const [templateNames, setTemplateNames] = React.useState<string[]>([]);
  const [template, setTemplate] = React.useState<
    EditableEmailTemplate | undefined
  >();
  const [wrapper, setWrapper] = React.useState<EmailTemplate | undefined>();
  const [templateName, setTemplateName] = React.useState("New Email");
  const [contextCollapsed, setContextCollapsed] = React.useState(true);
  const [textCollapsed, setTextCollapsed] = React.useState(true);
  const [htmlCollapsed, setHtmlCollapsed] = React.useState(false);
  const [renderCollapsed, setRenderCollapsed] = React.useState(false);
  const iframeRef = React.useRef<HTMLIFrameElement>();

  // Get the list of all templates
  const loadTemplateNames = React.useCallback(() => {
    const getFromApi = async () => {
      setLoadingList(true);
      const result = await API.get("/emails/templates");
      setLoadingList(false);
      if (!result.json || !result.json.success) {
        SessionStore.apiErr(result);
      } else {
        setTemplateNames(result.json.items);
      }
    };

    getFromApi();
  }, []);

  // Load a template by name
  const loadTemplate = React.useCallback((templateName: string) => {
    const getFromApi = async (templateName: string) => {
      setLoadingTemplate(true);
      const result = await API.get(`/emails/templates/${templateName}`);
      setLoadingTemplate(false);
      if (!result.json || !result.json.success) {
        SessionStore.apiErr(result);
      } else {
        result.json.item.template.defaults = JSON.stringify(
          result.json.item.template.defaults,
          null,
          2
        );
        setTemplate(result.json.item.template);
        setTemplateName(templateName);
      }
    };

    getFromApi(templateName);
  }, []);
  // Load the wrapper
  const loadWrapper = React.useCallback(() => {
    const getFromApi = async () => {
      setLoadingWrapper(true);
      const result = await API.get(`/emails/templates/Wrapper`);
      setLoadingWrapper(false);
      if (!result.json || !result.json.success) {
        SessionStore.apiErr(result);
      } else {
        setWrapper(result.json.item.template);
      }
    };

    getFromApi();
  }, []);
  // Load a blank template
  const loadBlankTemplate = React.useCallback(() => {
    setTemplate({
      subject: "",
      text: "",
      html: "",
      defaults: "{\n    \n}",
      wrapped: true,
    });
    setTemplateName("New Email");
  }, []);

  // Get all the templates on load
  React.useEffect(() => {
    loadTemplateNames();
    loadBlankTemplate();
    loadWrapper();
  }, [loadTemplateNames, loadBlankTemplate, loadWrapper]);

  // Save this template
  const putTemplate = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!template) {
      return;
    }
    let parsedTemplate: EmailTemplate | undefined;
    try {
      parsedTemplate = {
        ...template,
        ...{
          defaults: template.defaults ? JSON.parse(template?.defaults) : {},
        },
      };
    } catch (e) {
      SessionStore.setError("Invalid JSON in context");
      return;
    }
    setLoadingTemplate(true);
    const result = await API.put(
      `/emails/templates/${templateName}`,
      parsedTemplate
    );
    setLoadingTemplate(false);
    if (!result.json || !result.json.success) {
      SessionStore.apiErr(result);
    } else {
      SessionStore.setInfo(`Saved template: ${templateName}`);
      loadTemplateNames();
      if (templateName === "Wrapper") {
        loadWrapper();
      }
    }
  };
  // Delete this template
  const deleteTemplate = async () => {
    if (templateName === "Wrapper") {
      SessionStore.setInfo("You can't delete the wrapper!");
      return;
    }
    setLoadingTemplate(true);
    const result = await API.delete(`/emails/templates/${templateName}`);
    setLoadingTemplate(false);
    if (!result.json || !result.json.success) {
      SessionStore.apiErr(result);
    } else {
      SessionStore.setInfo(`Deleted template: ${templateName}`);
      loadTemplateNames();
    }
  };

  const updateTemplate = (field: keyof EmailTemplate, value: any) => {
    if (!template) {
      return;
    }
    const newTemplate = { ...template, ...{ [field]: value } };
    setTemplate(newTemplate);
  };

  const resizeIframe = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.style.height =
        iframeRef.current.contentWindow.document.body.scrollHeight + 20 + "px";
    }
  };

  // Render the preview
  let renderedEmail = "";
  if (template) {
    renderedEmail = template.html;
    if (template.wrapped && wrapper && wrapper.html) {
      console.log("About to use the dreaded replace!", {
        "template.wrapped": template.wrapped,
        wrapper: wrapper,
        "typeof wrapper": typeof wrapper,
        "wrapper.html": wrapper?.html,
        "typeof wrapper.html": typeof wrapper?.html,
        "wrapper.html.replace": wrapper?.html?.replace,
        "typeof wrapper.html.replace": typeof wrapper?.html?.replace,
      });
      renderedEmail = wrapper.html.replace("{{ html }}", template.html);
    }
    let data = {};
    try {
      data = template.wrapped
        ? { ...wrapper?.defaults, ...JSON.parse(template.defaults) }
        : JSON.parse(template.defaults);
      Handlebars.registerHelper(
        "ifEquals",
        function (this: any, arg1, arg2, options) {
          // eslint-disable-next-line eqeqeq
          return arg1 == arg2 ? options.fn(this) : options.inverse(this);
        }
      );
      const hb = Handlebars.compile(renderedEmail);
      renderedEmail = hb(data);
    } catch (e) {
      console.log("Render error", e);
    }
  }

  return (
    <div className="EmailTemplates">
      <Helmet>
        <title>Email Templates | Dolla Management Console</title>
      </Helmet>
      <div className="sideBar">
        <h3>Email Templates</h3>
        {loadingList ? (
          <Loader size="medium" />
        ) : (
          <>
            <a
              onClick={() => {
                loadBlankTemplate();
              }}
              className={templateName === "New Email" ? "selected" : ""}
            >
              New Email Template
            </a>
            {templateNames.map((name) => (
              <a
                onClick={() => {
                  loadTemplate(name);
                }}
                className={templateName === name ? "selected" : ""}
                key={name}
              >
                {name === "Wrapper" ? "⭐️ " : ""}
                {name}
              </a>
            ))}
          </>
        )}
      </div>
      {loadingTemplate || !template || (template.wrapped && loadingWrapper) ? (
        <Loader />
      ) : (
        <form onSubmit={putTemplate}>
          <table className="formTable">
            <tbody>
              <tr>
                <td>Name</td>
                <td>
                  <input
                    type="text"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Subject</td>
                <td>
                  <input
                    type="text"
                    value={template?.subject}
                    onChange={(e) => updateTemplate("subject", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Wrapper</td>
                <td>
                  <input
                    type="checkbox"
                    checked={template?.wrapped}
                    onChange={(e) =>
                      updateTemplate("wrapped", e.target.checked)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {/* The Context editor */}
          <a onClick={() => setContextCollapsed(!contextCollapsed)}>
            <span
              className={
                contextCollapsed
                  ? "expandIcon collapsed"
                  : "expandIcon expanded"
              }
            >
              <ExpandCircleDownIcon />
            </span>{" "}
            Context
          </a>
          <div
            className={`editor ${contextCollapsed ? "collapsed" : "expanded"}`}
          >
            <Editor
              height={"300px"}
              defaultLanguage="json"
              value={template.defaults}
              onChange={(value) => updateTemplate("defaults", value)}
              theme="light"
              loading={<Loader />}
            />
          </div>
          {/* The text editor */}
          <a onClick={() => setTextCollapsed(!textCollapsed)}>
            <span
              className={
                textCollapsed ? "expandIcon collapsed" : "expandIcon expanded"
              }
            >
              <ExpandCircleDownIcon />
            </span>{" "}
            Text
          </a>
          <div className={`editor ${textCollapsed ? "collapsed" : "expanded"}`}>
            <Editor
              height={"300px"}
              defaultLanguage="plain"
              value={template.text}
              onChange={(value) => updateTemplate("text", value)}
              theme="light"
              loading={<Loader />}
            />
          </div>
          {/* The HTML editor */}
          <a onClick={() => setHtmlCollapsed(!htmlCollapsed)}>
            <span
              className={
                htmlCollapsed ? "expandIcon collapsed" : "expandIcon expanded"
              }
            >
              <ExpandCircleDownIcon />
            </span>{" "}
            HTML
          </a>
          <div
            className={`editor large ${
              htmlCollapsed ? "collapsed" : "expanded"
            }`}
          >
            <Editor
              height={"600px"}
              defaultLanguage="html"
              value={template.html}
              onChange={(value) => updateTemplate("html", value)}
              theme="light"
              loading={<Loader />}
            />
          </div>
          <div className="buttonRow">
            <input type="submit" value="Save" />
            <button className="delete" onClick={deleteTemplate}>
              Delete
            </button>
          </div>
          {/* The rendered view */}
          <a onClick={() => setRenderCollapsed(!renderCollapsed)}>
            <span
              className={
                renderCollapsed ? "expandIcon collapsed" : "expandIcon expanded"
              }
            >
              <ExpandCircleDownIcon />
            </span>{" "}
            Rendered
          </a>
          {/* Can't do fancy animation because there's no set height */}
          <div className={`editor ${renderCollapsed ? "collapsed" : ""}`}>
            <iframe
              ref={(ref) => (iframeRef.current = ref ?? undefined)}
              srcDoc={renderedEmail}
              onLoad={() => resizeIframe()}
              scrolling="no"
              title="Preview"
            ></iframe>
          </div>
        </form>
      )}
    </div>
  );
};
