/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./SmallBusinessSearch.css";

import { dollaUsers } from "../../../../lambdas/utils-common";
import { SmallSearch, SmallSearchProps } from "./SmallSearch";
import API from "../utils/api";
import { SessionStore } from "../utils/session";

type BusinessSearchResult = {
  _id: string;
  name: string;
  avatar_url: string;
  tag: string;
};

type SmallBusinessSearchProps = Omit<
  SmallSearchProps<dollaUsers.Models.Business>,
  "query" | "renderItem"
>;

/** A small business search bar */
export const SmallBusinessSearch: React.FunctionComponent<SmallBusinessSearchProps> =
  (props) => {
    const queryBusinesses = async (query: string) => {
      const result = await API.get(`/businesses/v2/search?q=${query}`);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return [];
      }
      return result.json.items as BusinessSearchResult[];
    };

    const renderBusiness: SmallSearchProps["renderItem"] = (biz) => {
      return (
        <>
          <img className="avatar" src={biz.avatar_url} alt="" />
          {biz.name}
        </>
      );
    };
    return (
      <div className="SmallBusinessSearch">
        <SmallSearch
          {...props}
          renderItem={renderBusiness}
          query={queryBusinesses}
          placeholder={props.placeholder || "Search for a business"}
          clearOnSelect={props.clearOnSelect || true}
        />
      </div>
    );
  };
