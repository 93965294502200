/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import "./UserDevices.css";
import moment from "moment";
import Tippy from "@tippyjs/react";
import DeleteForever from "@mui/icons-material/DeleteForever";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";

export const UserDevices: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [loading, setLoading] = React.useState(false);
  const [loadingDevices, setLoadingDevices] = React.useState<string[]>([]);

  const getUser = React.useCallback(() => {
    async function _getUser() {
      setLoading(true);
      const result = await API.get(`/users/${_user}`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    _getUser();
  }, [_user]);

  React.useEffect(() => {
    getUser();
  }, [getUser]);

  const deleteDevice = async (_device: string) => {
    setLoadingDevices([...loadingDevices, _device]);
    const result = await API.delete(`/users/${_user}/devices/${_device}`);
    setLoadingDevices(loadingDevices.filter((x) => x !== _device));
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    getUser();
  };

  const verifyDevice = async (device: dollaUsers.Models.Device) => {
    await API.put(`/users/${_user}/device`, {
      verify: !device?.verified_at,
      _device: device._id,
    });
    getUser();
  };

  if (loading || !user) {
    return <Loader />;
  }

  return (
    <div className="UserDevices">
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name}'s Devices</h3>
      {user.devices.length === 0 ? (
        <p>No devices</p>
      ) : (
        <List
          items={user.devices.map((device) => ({
            title: (
              <>
                {device.brand || ""} {device.model || ""} [{" "}
                {device.os?.toLowerCase() || ""} ]{" "}
                <Inspector _user={user._id} _id={user._id} />
              </>
            ),
            subtitle: `Registered: ${moment(
              device.installed_at
            ).fromNow()}, last used ${moment(device.last_seen_at).fromNow()}.`,
            logo: (
              <>
                {device.push_token.startsWith("ExponentPushToken") ? (
                  <div className="deviceIcon">
                    <NotificationsActiveIcon />
                  </div>
                ) : (
                  <div className="deviceIcon disabled">
                    <NotificationsOffIcon />
                  </div>
                )}
              </>
            ),
            rightElement: loadingDevices.includes(device._id) ? (
              <Loader size="small" inline />
            ) : (
              <>
                <a
                  onClick={() => {
                    verifyDevice(device);
                  }}
                >
                  {device?.verified_at ? (
                    <Tippy content={"Un-verify user"}>
                      <CheckBoxIcon fontSize="large" />
                    </Tippy>
                  ) : (
                    <Tippy content={"Verify user"}>
                      <CheckBoxOutlineBlankIcon fontSize="large" />
                    </Tippy>
                  )}
                </a>
                <a
                  onClick={() => {
                    deleteDevice(device._id);
                  }}
                >
                  <Tippy content={"Delete device"}>
                    <DeleteForever fontSize="large" />
                  </Tippy>
                </a>
              </>
            ),
            key: device._id,
          }))}
        />
      )}
    </div>
  );
};
