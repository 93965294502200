/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./UserEmail.css";
import * as RDB from "rdb";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
// import { Inspector } from "../../components/Inspector";
import { EmailHtml } from "./components/email/EmailHtml";
import { EmailAttachment } from "./components/email/EmailAttachment";
// import { isNil } from "lodash-es";

import { Helmet } from "react-helmet";
import { EmailFormV2 } from "./components/email/EmailFormV2";
import { InspectorRDB } from "src/components/InspectorRDB";
import { EmailAttachmentSelector } from "./components/email/EmailAttachmentSelector";
import { BankAccountSuggestionTo } from "utils-common/src/db/dollaUsers/models";
import moment from "moment";
import { DangerModal } from "src/components/DangerModal";
import { PaymentHistoryTable } from "./components/email/PaymentHistoryTable";

const UNASSIGNED_USER = {
  _id: "UNASSIGNED",
  name: "Unassigned",
  avatar_url: "https://static.dolla.nz/images/single.png",
} as dollaUsers.Models.User;

export const UserEmailV2: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _user = urlParams.id;
  const _email = urlParams.email;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [email, setEmail] = React.useState<RDB.Models.Email>();
  const [recentPayments, setRecentPayments] =
    React.useState<RDB.Models.Payment[]>();
  const [similarPayments, setSimilarPayments] =
    React.useState<RDB.Models.Payment[]>();
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingEmail, setLoadingEmail] = React.useState(false);
  const [processingResult, setProcessingResult] =
    React.useState<NonNullable<RDB.Models.Email["processing_result"]>>(
      "NO_INVOICE"
    );
  const [processingNote, setProcessingNote] = React.useState<string>("");

  const nav = useNavigate();
  const dangerModal = React.useRef<any>();

  const loadData = React.useCallback(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    async function getEmail() {
      setLoadingEmail(true);
      const result = await API.get(`/users/${_user}/emails/${_email}`);
      setLoadingEmail(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setEmail(result.json.item);
    }

    if (_user === "UNASSIGNED") {
      setUser(UNASSIGNED_USER);
    } else {
      getUser();
    }
    getEmail();
  }, [_user, _email]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  console.log(recentPayments);
  const getPayments = React.useCallback(async () => {
    if (!email || user?._id === "UNASSIGNED") {
      return;
    }

    const [suggestion] = email.suggestions;

    const suggestionTo = suggestion?.to as BankAccountSuggestionTo;

    setLoadingEmail(true);

    let query = "limit=10";

    if (suggestionTo?.account_number) {
      query += `&recipient=${suggestionTo.account_number}`;
    }

    const result = await API.get(`/users/${_user}/v3/payments?${query}`);
    setLoadingEmail(false);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }

    setRecentPayments(result.json.items);

    const similar = (result.json.items as RDB.Models.Payment[]).filter(
      (pmt) => {
        // if (!e._payments.length) {
        //   return false;
        // }

        if (moment(pmt.created_at).diff(moment(), "days") > 2) {
          return false;
        }

        console.log("sug", !suggestionTo?.account_number);

        if (!suggestionTo?.account_number) {
          return false;
        }

        // Same recipient account
        if (pmt.recipient_account_number === suggestionTo.account_number) {
          if (pmt.amount !== suggestion.amount) {
            return false;
          }

          // if (
          //   isNil(pmt?.code) &&
          //   isNil(suggestionTo?.meta?.code) &&
          //   pmt.code !== suggestionTo.meta.code
          // ) {
          //   return false;
          // }

          // if (
          //   isNil(pmt?.reference) &&
          //   isNil(suggestionTo?.meta?.reference) &&
          //   pmt.reference !== suggestionTo.meta.reference
          // ) {
          //   return false;
          // }

          return true;
        }

        return false;
      }
    );

    setSimilarPayments(similar);
  }, [_user, email, user?._id]);

  React.useEffect(() => {
    getPayments();
  }, [email, getPayments]);

  const processingResultChange = async (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const val = evt.target.value as typeof processingResult;
    setProcessingResult(val);
  };

  const markEmailProcessed = async () => {
    if (!email) {
      return;
    }
    setLoadingEmail(true);
    const result = await API.put(
      `/users/${email._user}/emails/${email._id}/process`,
      {
        processing_result: processingResult,
        note:
          processingResult === "OTHER"
            ? processingNote.trim() || undefined
            : undefined,
      }
    );
    setLoadingEmail(false);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    setEmail({
      ...email,
      dolla_processed_at: new Date().toISOString(),
    });
    nav(-1); // Go back a page
  };

  const regenerate = async () => {
    if (!email) {
      return;
    }
    setLoadingEmail(true);
    const result = await API.put(
      `/users/${_user}/emails/${email._id}/regenerate`
    );
    setLoadingEmail(false);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    loadData();
  };

  if (loadingUser || loadingEmail || !user || !email) {
    return <Loader />;
  }

  return (
    <div className="UserEmail">
      <Helmet>
        <title>{email.subject} | Emails | Dolla Management Console</title>
      </Helmet>
      <div className="sidebar">
        <EmailFormV2
          email={email}
          similarPayments={similarPayments}
          user={user}
          reload={loadData}
          checkRef={dangerModal}
        />
      </div>
      <div className="mainCol">
        <h3>
          {email.subject}
          <InspectorRDB _id={_email as string} />
        </h3>
        <div className="metadata">
          <div>
            To:
            <Link to={`/users/${user._id}`} className="userLink">
              <img src={user.avatar_url} alt="" />
              {user.name}
            </Link>
            <span>
              &lt;
              <a href={`mailto:${email.to}`}>{email.to}</a>
              &gt;
            </span>
          </div>
          <div>
            From:
            <a className="userLink" href={`mailto:${email.from}`}>
              {email.from}
            </a>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <span style={{ flexShrink: 0, marginRight: "10px" }}>
              Status:{" "}
              <b>{email.dolla_processed_at ? "Processed" : "Unprocessed"}</b>
              {email.processing_result
                ? ` (${email.processing_result})`
                : ""}{" "}
            </span>
            {!email.dolla_processed_at && (
              <>
                <select
                  className="statusSelector"
                  value={processingResult}
                  onChange={processingResultChange}
                  style={{
                    flexShrink: 0,
                    marginRight: "10px",
                    width: "auto",
                    marginBottom: 0,
                  }}
                >
                  <option value="NO_INVOICE">No invoice</option>
                  <option value="ALREADY_PAID">Already paid</option>
                  <option value="UNSUPPORTED_PAYMENT_TYPE">
                    Unsupported payment type
                  </option>
                  <option value="NO_PAYMENT_INFO">No payment info</option>
                  <option value="OTHER">Other (add note)</option>
                </select>
                {processingResult === "OTHER" ? (
                  <textarea
                    onChange={(e) => setProcessingNote(e.target.value)}
                  />
                ) : null}
                <button
                  onClick={() => markEmailProcessed()}
                  style={{ flexShrink: 0, marginBottom: 0 }}
                >
                  Mark as processed
                </button>
              </>
            )}
          </div>

          {email._payments?.length ? (
            <div>
              Payment{email._payments?.length === 1 ? "" : "s"}:
              {email._payments.map((_pmt) => (
                <Link
                  key={_pmt}
                  className="attachment"
                  to={`/universal/${_pmt}?_user=${email._user}`}
                  target="_blank"
                >
                  {_pmt}
                </Link>
              ))}
            </div>
          ) : (
            <></>
          )}

          {similarPayments?.length ? (
            <div>
              Similar payments:
              {similarPayments.map((pmt) => (
                <>
                  <Link
                    key={pmt._id}
                    className="attachment"
                    to={`/universal/rdb/${pmt._id}`}
                    target="_blank"
                  >
                    {pmt._id}
                  </Link>
                </>
              ))}
            </div>
          ) : (
            <></>
          )}

          <div>
            <button onClick={() => regenerate()}>
              Regenerate GPT suggestion
            </button>
          </div>

          <div>
            <EmailAttachmentSelector
              email={email}
              onChange={(e) => setEmail(e)}
            />
          </div>

          <div>
            <PaymentHistoryTable
              payments={similarPayments}
              onChange={(e) => setEmail(e)}
            />
          </div>

          <div></div>
        </div>

        <div className="docs">
          <EmailHtml email={email as any} />
          {email.attachments.map((attch) => (
            <EmailAttachment item={attch as any} key={attch.name} />
          ))}
        </div>
      </div>
      <DangerModal openRef={dangerModal} />
    </div>
  );
};
