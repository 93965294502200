import { SessionStore } from "./session";
import * as React from "react";
import { sleep, stage } from "./helpers";

export type CustomResponse = Response & {
  textBody?: string;
  json?: any;
};

export const MBIE_HEADERS = {
  external: true,
  headers: {
    "Ocp-Apim-Subscription-Key":
      process.env.NZBN_SUBSCRIPTION_KEY_SANDBOX_PRIMARY ??
      "f6a78bdcfb5f447abcb1de50d7a1fe02", // ezra's subscription key
  },
};

type RequestOptions = {
  ignore401?: boolean;
  external?: boolean;
  headers?: { [x: string]: string };
};

class API extends React.Component {
  logout?: () => Promise<void>;

  setLogoutFunction(fn: () => Promise<void>) {
    this.logout = fn;
  }

  async _fetch(
    method: "GET" | "POST" | "PUT" | "DELETE",
    path: string,
    body?: any,
    opts?: RequestOptions
  ) {
    let session = SessionStore.getState();
    if (!session) {
      console.log("Session is not yet instantiated");
      await sleep(50);
      const sessionImport = await import("./session");
      session = sessionImport.SessionStore.getState();
      if (!session) {
        throw Error("SessionNotInstantiatedError");
      }
    }

    const baseUrl = {
      prod: "https://api.management.dolla.nz/v1",
      staging: "https://api.management.staging.dolla.nz/v1",
    }[stage];

    const url = `${opts?.external ? "" : baseUrl}${path}`;
    console.log(`API Request [${method}] ${url}`);
    const headers = opts?.external
      ? { ...opts?.headers }
      : {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${session._token}`,
          "X-Dolla-User": session.user?._id || "",
          ...opts?.headers,
        };
    const strBody = body ? JSON.stringify(body) : undefined;
    const result: CustomResponse = await fetch(url, {
      method,
      headers,
      body: strBody,
    });
    try {
      result.textBody = await result.text();
      result.json = JSON.parse(result.textBody);
    } catch (error: any) {
      if (error?.status === 401 && !opts?.ignore401 && this.logout) {
        this.logout();
      }
    }
    console.log(`API Response [${method}] ${url} - ${result.status}`);
    return result;
  }

  get(path: string, options?: RequestOptions) {
    return this._fetch("GET", path, null, options);
  }
  post(path: string, payload?: any, options?: RequestOptions) {
    return this._fetch("POST", path, payload, options);
  }
  put(path: string, payload?: any, options?: RequestOptions) {
    return this._fetch("PUT", path, payload, options);
  }
  delete(path: string, payload?: any, options?: RequestOptions) {
    return this._fetch("DELETE", path, payload, options);
  }
}

const api = new API({});

export default api;
