/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./BrowseScripts.css";
import { groupBy, orderBy } from "lodash-es";
import IntegrationInstructions from "@mui/icons-material/IntegrationInstructions";

import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { ScriptViewModal } from "./components/ScriptViewModal";
import { managementDB } from "../../../../../lambdas/utils-common/src";
import { Helmet } from "react-helmet";

export const BrowseScripts: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState<managementDB.Models.User[]>([]);
  const [scripts, setScripts] =
    React.useState<{ [_user: string]: managementDB.Models.Script[] }>();

  const selectModal = React.useRef<any>();

  React.useEffect(() => {
    const getScripts = async () => {
      const result = await API.get("/scripts/all");
      if (!result.json || !result.json.success) {
        SessionStore.apiErr(result);
      } else {
        setLoading(false);
        setUsers(result.json.item.users as managementDB.Models.User[]);
        setScripts(
          groupBy(
            result.json.item.scripts as managementDB.Models.Script[],
            "_user"
          )
        );
      }
    };
    getScripts();
  }, []);

  const onScriptSelected = (
    script: managementDB.Models.Script,
    user: managementDB.Models.User
  ) => {
    if (selectModal.current) {
      selectModal.current.open(script, user);
    }
  };

  if (loading || !scripts || !users) {
    return <Loader />;
  }

  return (
    <div className="BrowseScripts">
      <Helmet>
        <title>Browse Scripts | Dolla Management Console</title>
      </Helmet>
      <h3>Browse Scripts</h3>
      {users.map((user) => (
        <React.Fragment key={user._id}>
          <b>{user.username}:</b>
          {orderBy(scripts[user._id], "updated_at", "desc").map((script) => (
            <a
              onClick={onScriptSelected.bind(null, script, user)}
              key={script._id}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "1rem",
              }}
            >
              <IntegrationInstructions />
              &nbsp;{script.name}
            </a>
          ))}
        </React.Fragment>
      ))}
      <ScriptViewModal openRef={selectModal} />
    </div>
  );
};
