/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Tippy from "@tippyjs/react";

import "./EntityPage.css";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { Helmet } from "react-helmet";
import moment from "moment";
import DeleteForever from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import { set, cloneDeep, uniq } from "lodash";
import { getFullAddress } from "src/utils/helpers";
import { BankAccount, Entity } from "rdb/src/models";
import { EntityDetailEditList } from "./components/EntityDetailEditList";
import { EntityAccountEditList } from "./components/EntityAccountEditList";
import { DangerModal } from "src/components/DangerModal";
import { InspectorRDB } from "src/components/InspectorRDB";

export type EditableBankAccount = Partial<
  BankAccount & { warn?: boolean; otherName?: string; remove?: boolean }
>;

export const EntityPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _entity = urlParams.id;
  const [bankAccounts, setBankAccounts] =
    React.useState<EditableBankAccount[]>();
  const [editedBankAccounts, setEditedBankAccounts] =
    React.useState<EditableBankAccount[]>();
  const [entity, setEntity] = React.useState<Entity>();
  const [editedEntity, setEditedEntity] = React.useState<Entity>();
  const [loadingEntity, setLoadingEntity] = React.useState(false);
  const [loadingBankAccounts, setLoadingBankAccounts] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  const dangerModal = React.useRef<any>();

  const getBankAccounts = React.useCallback(() => {
    async function getBankAccounts() {
      setLoadingBankAccounts(true);
      const result = await API.get(`/bank-accounts/entities/${_entity}`);
      setLoadingBankAccounts(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBankAccounts(result.json.item);
    }
    getBankAccounts();
  }, [_entity]);
  React.useEffect(() => {
    getBankAccounts();
  }, [getBankAccounts]);

  const getEntity = React.useCallback(() => {
    async function getEntity() {
      setLoadingEntity(true);
      const result = await API.get(`/entities?_id=${_entity}`);
      setLoadingEntity(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setEntity(result.json.item);
    }
    getEntity();
  }, [_entity]);
  React.useEffect(() => {
    getEntity();
  }, [getEntity]);

  const deleteEntity = async () => {
    if (!entity) {
      return;
    }
    if (
      !window.confirm(
        "Are you sure you want to delete this entity?\n\nYou can't undo this."
      )
    ) {
      return;
    }

    setLoadingEntity(true);
    let result = await API.delete(`/entities/${entity._id}`);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    SessionStore.setInfo("Deleted entity");
    navigate(`/entities`);
  };

  const toggleEdit = () => {
    if (editing) {
      setEditing(false);
      if (entity) {
        setEditedEntity(cloneDeep(entity));
      }
    } else {
      setEditing(true);
      if (!editedEntity && entity) {
        setEditedEntity(cloneDeep(entity));
      }
    }
  };

  /** Pull the latest data from MBIE */
  const refresh = async () => {
    if (!entity?.nzbn) {
      SessionStore.setInfo("Please enter a valid NZBN before refreshing");
      return;
    }

    setLoadingEntity(true);
    let result = await API.get(`/entities/nzbn/${entity.nzbn}`);

    if (result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }

    SessionStore.setInfo("");

    const updatedEntity = result.json as dollaUsers.Models.NzbnEntity;

    console.log(updatedEntity);

    const final = {
      ...entity,
      nzbn: updatedEntity.nzbn,
      name: updatedEntity.entityName,
      trading_name: updatedEntity.tradingNames?.[0]?.name,
      nzbn_status: updatedEntity.entityStatusDescription,
      nzbn_type: updatedEntity.entityTypeDescription,
      nzbn_registered_at: updatedEntity.registrationDate,
      email_addresses: uniq([
        ...updatedEntity.emailAddresses.map((x) => x.emailAddress),
        ...(entity?.email_addresses ? entity.email_addresses : []),
      ]),
      phone_numbers: uniq([
        ...updatedEntity.phoneNumbers.map(
          (x) => `+${x.phoneCountryCode} ${x.phoneAreaCode} ${x.phoneNumber}`
        ),
        ...(entity?.phone_numbers ? entity.phone_numbers : []),
      ]),
      gst_numbers: uniq([
        ...updatedEntity.gstNumbers?.map((x) => x.gstNumber),
        ...(entity?.gst_numbers ? entity.gst_numbers : []),
      ]),
      websites: uniq([
        ...updatedEntity.websites?.map((x) => x.url),
        ...(entity?.websites ? entity.websites : []),
      ]),
      addresses: uniq([
        ...updatedEntity.addresses?.addressList?.map((x) => getFullAddress(x)),
        ...(entity?.addresses ? entity.addresses : []),
      ]),
      previous_names: uniq([
        ...updatedEntity.tradingNames?.map((x) => x.name), // Store other trading names here as well
        ...(entity?.previous_names ? entity.previous_names : []),
      ]),
    } as Entity;
    setLoadingEntity(false);

    result = await API.put(`/entities/${_entity}`, {
      ...final,
    });
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    SessionStore.setInfo("Refreshed entity");
    getEntity();
  };

  /** Handle updates to the entity fields */
  const onEntityEdit = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (!editedEntity) {
      return;
    }

    const field = event.target.name;
    let value: string | boolean | null = event.target.value;

    if (value === "TRUE" || value === "FALSE") {
      value = Boolean(event.target.value);
    }

    if (value === "null") {
      value = null;
    }

    const final = set(editedEntity, field, value);
    setEditedEntity(cloneDeep(final));
    SessionStore.setError("");
  };

  const handleBankAccounts = (bAccs: EditableBankAccount[]) => {
    setEditedBankAccounts(bAccs);
  };

  /** Save the updated entity & bank_accounts */
  const onSubmit = async () => {
    if (!editedEntity) {
      return;
    }

    // Store the sucker
    setLoadingEntity(true);
    const result = await API.put(`/entities/${_entity}`, {
      ...editedEntity,
    });
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    SessionStore.setInfo("Updated entity");

    if (editedBankAccounts?.length) {
      const res = await API.put(`/entities/${_entity}/bank-accounts`, {
        bank_accounts: editedBankAccounts,
      });

      if (!res.json.success) {
        SessionStore.setError(res.json.message);
        return;
      }
    }
    setLoadingEntity(false);
    toggleEdit();
    setEntity(result.json.item);
  };

  if (loadingEntity || !entity) {
    return <Loader />;
  }

  return (
    <div className="EntityPage">
      <Helmet>
        <title>{entity.name}| Dolla Management Console</title>
      </Helmet>
      <Link to={`/entities`} className="backlink">
        ← Back to entities
      </Link>
      <h3>
        <img src={entity?.avatar_url ?? ""} alt="" />
        {entity.name}
        <Tippy content={"Inspect this item"}>
          <InspectorRDB _id={entity._id} />
        </Tippy>
        <a className="titleLink" onClick={toggleEdit} title="Edit">
          <EditIcon fontSize="inherit" />
        </a>
        <a className="titleLink" onClick={refresh} title="Refresh">
          <RefreshIcon fontSize="inherit" />
        </a>
        <a
          className="titleLink"
          href={`https://www.nzbn.govt.nz/mynzbn/nzbndetails/${entity.nzbn}/`}
          target="_blank"
          rel="noopener noreferrer"
          title="Refresh"
        >
          <SearchIcon fontSize="inherit" />
        </a>
        <a className="titleLink" onClick={deleteEntity} title="Delete">
          <DeleteForever fontSize="inherit" />
        </a>
      </h3>
      <table className="prettyDetails">
        <tbody>
          <tr>
            <td>Trading name</td>
            <td>
              {!editing ? (
                entity.trading_name ?? "-"
              ) : (
                <input
                  type="text"
                  value={
                    editedEntity?.trading_name ? editedEntity.trading_name : ""
                  }
                  onChange={onEntityEdit}
                  name="trading_name"
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Created</td>
            <td title={moment(entity.created_at).format()}>
              {moment(entity.created_at).fromNow()}
            </td>
          </tr>
          <tr>
            <td>Updated at</td>
            <td title={moment(entity.updated_at).format()}>
              {moment(entity.updated_at).fromNow()}
            </td>
          </tr>
          <tr>
            <td>NZBN</td>
            <td>
              {!editing ? (
                entity.nzbn ?? "-"
              ) : (
                <input
                  type="text"
                  value={editedEntity?.nzbn ? editedEntity.nzbn : ""}
                  placeholder={entity?.nzbn ?? ""}
                  onChange={onEntityEdit}
                  name="nzbn"
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              {!editing ? (
                entity.nzbn_status ?? "-"
              ) : (
                <input
                  type="text"
                  value={
                    editedEntity?.nzbn_status ? editedEntity.nzbn_status : ""
                  }
                  placeholder={entity?.nzbn_status ?? ""}
                  onChange={onEntityEdit}
                  name="nzbn_status"
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Entity type</td>
            <td>
              {!editing ? (
                entity.type
              ) : (
                <select
                  value={String(editedEntity?.type ?? false)}
                  onChange={onEntityEdit}
                  name="type"
                >
                  <option value="SOLE_TRADER">Sole Trader</option>
                  <option value="COMPANY">Company</option>
                  <option value="PERSON">Person</option>
                </select>
              )}
            </td>
          </tr>
          <tr>
            <td>Verified at</td>
            {entity.verified_at ? (
              <td title={moment(entity.verified_at).format()}>
                {moment(entity.verified_at).fromNow()}
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
          <tr>
            <td>Verified by</td>
            <td>
              {!editing ? (
                entity.verified_by ?? "-"
              ) : (
                <select
                  value={String(
                    editedEntity?.verified_by ?? false
                  ).toUpperCase()}
                  onChange={onEntityEdit}
                  name="verified_by"
                >
                  <option value="CALL_BANK">CALL_BANK</option>
                  <option value="BANK_STATEMENT">BANK_STATEMENT</option>
                  <option value="DEPOSIT">DEPOSIT</option>
                  <option value="FALSE">None</option>
                </select>
              )}
            </td>
          </tr>
          <tr>
            <td>Registered biller</td>
            <td>
              {!editing ? (
                entity?.registered_biller ? (
                  "Yes"
                ) : (
                  "No"
                )
              ) : (
                <select
                  value={String(
                    editedEntity?.registered_biller ?? false
                  ).toUpperCase()}
                  onChange={onEntityEdit}
                  name="registered_biller"
                >
                  <option value="TRUE">Yes</option>
                  <option value="FALSE">No</option>
                </select>
              )}
            </td>
          </tr>
          <tr>
            <td>Biller account</td>
            <td>
              {!editing ? (
                entity.biller_account ?? "-"
              ) : (
                <input
                  type="text"
                  value={
                    editedEntity?.biller_account
                      ? editedEntity.biller_account
                      : ""
                  }
                  onChange={onEntityEdit}
                  name="biller_account"
                />
              )}
            </td>
          </tr>
          <tr>
            <td>Logo</td>
            <td>
              {!editing ? (
                entity.avatar_url ?? "-"
              ) : (
                <input
                  type="text"
                  value={
                    editedEntity?.avatar_url ? editedEntity.avatar_url : ""
                  }
                  onChange={onEntityEdit}
                  name="avatar_url"
                />
              )}
            </td>
          </tr>
          {!editing ? (
            <>
              <tr>
                <td>Previous Names</td>
                <td>
                  <ul>
                    {entity?.previous_names?.length ? (
                      entity.previous_names.map((name) => <li>{name}</li>)
                    ) : (
                      <>-</>
                    )}
                  </ul>
                </td>
              </tr>
              <tr>
                <td>GST Numbers</td>
                <td>
                  <ul>
                    {entity?.gst_numbers?.length ? (
                      entity.gst_numbers.map((gst) => <li>{gst}</li>)
                    ) : (
                      <>-</>
                    )}
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Addresses</td>
                <td>
                  <ul>
                    {entity?.addresses ? (
                      entity.addresses.map((address) => <li>{address}</li>)
                    ) : (
                      <>-</>
                    )}
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Email addresses</td>
                <td>
                  <ul>
                    {entity?.email_addresses?.length ? (
                      entity.email_addresses.map((email) => <li>{email}</li>)
                    ) : (
                      <>-</>
                    )}
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Phone numbers</td>
                <td>
                  <ul>
                    {entity?.phone_numbers?.length ? (
                      entity.phone_numbers.map((phone) => <li>{phone}</li>)
                    ) : (
                      <>-</>
                    )}
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Websites</td>
                <td>
                  <ul>
                    {entity?.websites?.length ? (
                      entity.websites.map((url) => (
                        <li>
                          <a href={url} target="_blank" rel="noreferrer">
                            {url}
                          </a>
                        </li>
                      ))
                    ) : (
                      <>-</>
                    )}
                  </ul>
                </td>
              </tr>
            </>
          ) : (
            <tr>
              <td></td>
              <td>
                <EntityDetailEditList
                  entity={editedEntity}
                  onChange={(p, v) => {
                    console.log("pv", p, v);
                    onEntityEdit({
                      target: {
                        name: p,
                        value: v,
                      },
                    } as React.ChangeEvent<HTMLInputElement>);
                  }}
                />
              </td>
            </tr>
          )}
          <tr>
            <td>Bank Accounts</td>
            <td>
              {!editing ? (
                <>
                  {!loadingBankAccounts ? (
                    <ul>
                      {bankAccounts?.length ? (
                        bankAccounts.map((bAcc) => (
                          <li>
                            <Link to={`/universal/rdb/${bAcc._id}`}>
                              {bAcc.account_number ?? bAcc._id}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li>
                          <i>No bank accounts associated with this entity</i>
                        </li>
                      )}
                    </ul>
                  ) : (
                    <Loader />
                  )}
                </>
              ) : (
                <EntityAccountEditList
                  entity={entity}
                  accounts={bankAccounts?.length ? bankAccounts : []}
                  onChange={handleBankAccounts}
                  ref={dangerModal}
                />
              )}
            </td>
          </tr>
          {editing ? (
            <tr>
              <td colSpan={2}>
                <div className="buttonRow">
                  <button className="button-outline" onClick={toggleEdit}>
                    Cancel
                  </button>
                  <button onClick={onSubmit}>Save</button>
                </div>
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>

      <DangerModal openRef={dangerModal} />
    </div>
  );
};
