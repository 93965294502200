/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Editor from "@monaco-editor/react";
import { useParams } from "react-router-dom";
import "./EditConstants.css";
import moment from "moment";

import { Loader } from "../../components/Loader";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Helmet } from "react-helmet";

type FileVersion = {
  uri: string;
  date: string;
  latest: boolean;
};

export const EditConstants: React.FunctionComponent = () => {
  const urlParams = useParams();
  const filename = urlParams.filename;
  const [loading, setLoading] = React.useState(false);
  const [versions, setVersions] = React.useState<FileVersion[]>([]);
  const [selectedVersion, setSelectedVersion] = React.useState(0);
  const [value, setValue] = React.useState("");

  // Load the versions of a file when the page loads
  React.useEffect(() => {
    const getVersions = async () => {
      setLoading(true);
      const result = await API.get(`/constants/${filename}`);
      setLoading(false);
      if (!result.json || !result.json.success) {
        SessionStore.apiErr(result);
      } else {
        setVersions(result.json.item.versions);
      }
    };

    getVersions();
  }, [filename]);

  // Load the selected version when it changes
  React.useEffect(() => {
    const getVersion = async () => {
      const version = versions[selectedVersion];
      if (version) {
        setLoading(true);
        const result = await fetch(version.uri);
        const value = await result.json();
        setLoading(false);
        setValue(JSON.stringify(value, null, 2));
      }
    };

    getVersion();
  }, [selectedVersion, versions]);

  // Save this file
  const save = async () => {
    let json = "";
    try {
      json = JSON.parse(value);
    } catch (err) {
      SessionStore.setError("Cannot save invalid JSON");
      return;
    }
    setLoading(true);
    const result = await API.put(`/constants/${filename}`, json);
    setLoading(false);
    if (!result.json || !result.json.success) {
      SessionStore.apiErr(result);
    } else {
      SessionStore.setInfo(`Updated ${filename}`);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="EditConstants">
      <Helmet>
        <title>Edit {filename} | Dolla Management Console</title>
      </Helmet>
      <h2>Edit {filename}</h2>
      <div className="wrapper">
        <div className="editor">
          <Editor
            height={"600px"}
            defaultLanguage="json"
            value={value}
            onChange={(v) => (v ? setValue(v) : null)}
            theme="light"
            loading={<Loader />}
          />
        </div>
        <div className="versions">
          <h3>Past Versions</h3>
          {versions.map((version, i) => (
            <a
              className={`version ${i === selectedVersion ? "selected" : ""}`}
              key={version.uri}
              onClick={() => setSelectedVersion(i)}
            >
              <span className="title">
                {moment(version.date).format("h:mma ddd Do MMM YYYY")}
              </span>
              <span className="subtitle">{moment(version.date).fromNow()}</span>
            </a>
          ))}
        </div>
      </div>
      <button onClick={save}>Save</button>
    </div>
  );
};
