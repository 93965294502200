/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./EmailForm.css";

import { dollaUsers } from "utils-common";
import API from "../../../../utils/api";
import { SessionStore } from "../../../../utils/session";
import { Loader } from "../../../../components/Loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import numeral from "numeral";
import { BusinessRecipientForm } from "./BusinessRecipientForm";
import { PersonalRecipientForm } from "./PersonalRecipientForm";
import { BankRecipientForm } from "./BankRecipientForm";
import { EmailFormV2Props } from "./EmailFormV2";
import { EntityRecipientForm } from "./EntityRecipientForm";
import * as RDB from "rdb";
import { Link } from "react-router-dom";

export type RecipientFormV2Props<T> = {
  prefill?: RDB.Models.Email["suggestions"][number];
  user: dollaUsers.Models.User;
  to?: Partial<T>;
  onChange: (to: Partial<T>) => void;
  validationRef: React.MutableRefObject<(() => string | null) | undefined>;
};

const MIN_DATE = "2021-01-01";

/** A payment form. It may have a `prefill` prop with initial state (from a
 * suggestion). */
export const PaymentFormV2: React.FunctionComponent<
  EmailFormV2Props & {
    prefill?: dollaUsers.Models.Email["suggestions"][number];
  }
> = (props) => {
  const { prefill, email, user, reload } = props;

  console.log("prefill", prefill);

  const [loading, setLoading] = React.useState(false);
  const [accounts, setAccounts] = React.useState<RDB.Models.Account[]>([]);
  const [_from, setFrom] = React.useState<string>(
    user.defaults?.pay_from?._id ?? ""
  );
  const [to, setTo] = React.useState<
    Partial<RDB.Models.Email["suggestions"][number]["to"]>
  >(
    prefill?.to ?? {
      type: "BANK_ACCOUNT",
    }
  );

  const [taxType, setTaxType] = React.useState<string | undefined>("INPUT2");
  const [invoiceDate, setInvoiceDate] = React.useState<string | undefined>();
  const [dueDate, setDueDate] = React.useState<string | undefined>();
  const [paymentDate, setPaymentDate] = React.useState<string | undefined>();
  const [amount, setAmount] = React.useState<string | undefined>(
    prefill?.amount?.toString()
  );
  const [amountDue, setAmountDue] = React.useState<string | undefined>(
    prefill?.total_amount_due?.toString() ?? prefill?.amount?.toString()
  );
  const [currencyCode, setCurrencyCode] = React.useState(
    prefill?.currency?.toString()
  );
  const [customCurrencyCode, setCustomCurrencyCode] = React.useState(
    prefill?.currency?.toString()
  );
  const [invoiceNo, setInvoiceNo] = React.useState(
    prefill?.invoice_number?.toString() ?? prefill?.customer_id?.toString()
  );
  const [toName, setToName] = React.useState(prefill?.to_name);
  const [note, setNote] = React.useState(prefill?.note);
  const [lineItemDesc, setLineItemDesc] = React.useState(
    prefill?.line_item_description
  );
  const [payViaDolla, setPayViaDolla] = React.useState(
    !!prefill?.total_amount_due && prefill.total_amount_due > 0
  );

  const nav = useNavigate();
  const validateTo = React.useRef<() => string | null>();

  React.useEffect(() => {
    if (!to) {
      setTo(
        prefill?.to ?? {
          type: "BANK_ACCOUNT",
        }
      );
    }
    if (!taxType) setTaxType(prefill?.tax_type ?? "INPUT2");
    if (!invoiceDate)
      setInvoiceDate(
        moment(prefill?.invoice_date, "DD-MM-YYYY").format("YYYY-MM-DD")
      );

    if (!dueDate) {
      const due_date = prefill?.due_date
        ? moment(prefill?.due_date, "DD-MM-YYYY").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");

      setDueDate(due_date);
    }
    if (!currencyCode) setCurrencyCode(prefill?.currency);
    if (!invoiceNo) setInvoiceNo(prefill?.invoice_number);
    if (!toName) setToName(prefill?.to_name);
    if (!lineItemDesc) setLineItemDesc(prefill?.line_item_description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefill?.parser]);

  const onChangeTo = React.useCallback(
    (
      to: Partial<dollaUsers.Models.PendingPayment["payment_parameters"]["to"]>
    ) => {
      setTo(to);
    },
    [setTo]
  );

  // Change the `to` type, clearing any data that's already there
  const onChangeToType = (
    type: dollaUsers.Models.PendingPayment["payment_parameters"]["to"]["type"]
  ) => {
    if (to?.type !== type) {
      setTo({
        type,
      });
    }
  };

  // Load the user's accounts on mount
  React.useEffect(() => {
    const getAccounts = async () => {
      setLoading(true);
      const result = await API.get(`/users/${user._id}/accounts`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setAccounts(result.json.items);
    };

    getAccounts();
  }, [user._id]);

  // Check a payment before submitting
  const check = async () => {
    if (props.similarPayments?.length) {
      props?.checkRef?.current?.open?.(
        `Duplicate Payment`,
        [
          <>
            <b>
              This invoice is similar to a previous payment sent from this user.
            </b>{" "}
            ({user._id})
          </>,
          <>
            <table style={{ marginTop: "1rem" }}>
              <thead>
                <tr>
                  <td>
                    <b>_id</b>
                  </td>
                  <td>
                    <b>status</b>
                  </td>
                  <td>
                    <b>account name</b>
                  </td>
                  <td>
                    <b>account no.</b>
                  </td>
                  <td>
                    <b>code</b>
                  </td>
                  <td>
                    <b>reference</b>
                  </td>
                  <td>
                    <b>paid_at</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                {props.similarPayments.map((pmt) => {
                  return (
                    <tr>
                      <td>
                        <Link
                          key={pmt._id}
                          className="attachment"
                          to={`/universal/rdb/${pmt._id}`}
                          target="_blank"
                        >
                          {pmt._id}
                        </Link>
                      </td>
                      <td>
                        {pmt.status} ({moment(pmt.created_at).fromNow()})
                      </td>
                      <td>{pmt?.recipient_account_number ?? "-"}</td>
                      <td>
                        <Link
                          key={pmt._id}
                          className="attachment"
                          to={`/bank_accounts/${pmt.recipient_account_number}`}
                          target="_blank"
                        >
                          {pmt.recipient_account_number}
                        </Link>
                      </td>
                      <td>{pmt?.code?.length ? pmt.code : "-"}</td>
                      <td>{pmt?.reference?.length ? pmt.reference : "-"}</td>
                      <td>{moment(pmt?.paid_at ?? pmt?.pay_at).fromNow()}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>,
        ],
        submit
      );
      return;
    }
    submit();
  };

  // Submit a payment
  const submit = async () => {
    // Validate
    SessionStore.setError("");

    if (user.status !== "ACTIVE") {
      SessionStore.setError(`User is ${user.status}`);
      return;
    }

    const amountValid = numeral(amount).value();
    if (amountValid === null) {
      SessionStore.setError("Invalid Amount");
      return;
    }

    const amountDueValid = numeral(amountDue).value();
    if (amountDueValid === null) {
      SessionStore.setError("Invalid Amount Due");
      return;
    }

    const payAtDateISO = moment(dueDate, "YYYY-MM-DD")
      .startOf("day")
      .toISOString(); // Should always be valid from date input
    const invoicedAtDateISO = moment(invoiceDate, "YYYY-MM-DD")
      .startOf("day")
      .toISOString(); // Should always be valid from date input
    const paymentDateISO = moment(paymentDate ?? dueDate, "YYYY-MM-DD")
      .startOf("day")
      .toISOString(); // Should always be valid from date input

    if (email._user === "UNASSIGNED" || !user) {
      SessionStore.setError("Can't create a payment for an unassigned user");
      return;
    }
    if (!validateTo.current || !to) {
      SessionStore.setError("Please fill in who this payment is to");
      return;
    }
    const toValidationError = validateTo.current();
    if (toValidationError) {
      SessionStore.setError(toValidationError);
      return;
    }

    let currency = currencyCode;
    if (currencyCode === "OTHER") {
      currency = customCurrencyCode;
    }

    // Submit
    setLoading(true);
    const payload: dollaUsers.Models.PendingPayment["payment_parameters"] = {
      from: _from,
      amount: amountValid,
      currency: currency ?? "NZD",
      invoice_date: invoicedAtDateISO,
      payment_date: paymentDateISO,
      invoice_number: invoiceNo,
      note: note?.trim() || undefined,
      line_item_description: lineItemDesc?.trim() || undefined,
      tax_type: taxType ?? "INPUT2",
      to: to as dollaUsers.Models.PendingPayment["payment_parameters"]["to"], // Assume this is a valid `to` if it passed validation
    };
    const result = await API.put(
      `/users/${user._id}/emails/${email._id}/suggestion`,
      {
        email_last_update_at: email.updated_at, // This lets the backend tell us if the email has been edited since we loaded the page
        pay_at: payAtDateISO,
        due_date: payAtDateISO,
        to_name: toName?.trim(),
        pay_via_dolla: payViaDolla,
        total_amount: amountValid,
        total_amount_due: amountDueValid,
        ...payload,
      }
    );
    setLoading(false);

    if (!result.json?.success) {
      if (result.json?.message === "payment_updated") {
        alert(
          "This email has been updated since you loaded it.\n\nThat might mean that someone else has made a payment, in which case you should have another look before making this payment.\n\n This email will be reloaded when you click OK."
        );
        reload();
        return;
      }
      setLoading(false);
      SessionStore.apiErr(result);
      return;
    }
    setLoading(false);
    nav(-1); // Go back a page
  };

  if (loading) {
    return <Loader size="medium" />;
  }

  return (
    <>
      <label>
        <span className="text">Amount</span>
        <input
          type="text"
          value={amount ?? ""}
          placeholder="0.00"
          onChange={(evt) =>
            setAmount(evt.target.value.replace(/[^\d,.$ ]/, ""))
          }
        />
      </label>
      <label>
        <span className="text">Amount due</span>
        <input
          type="text"
          value={amountDue ? amountDue : amount}
          placeholder="0.00"
          onChange={(evt) =>
            setAmountDue(evt.target.value.replace(/[^\d,.$ ]/, ""))
          }
        />
      </label>
      <label
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <span>Pay via Dolla</span>
        <input
          type="checkbox"
          checked={payViaDolla}
          onChange={(evt) => setPayViaDolla(evt.target.checked)}
        />
      </label>
      <label>
        <span className="text">Currency</span>
        <select
          defaultValue={currencyCode}
          onChange={(evt) => setCurrencyCode(evt.target.value)}
        >
          <option value="NZD">NZD</option>
          <option value="AUD">AUD</option>
          <option value="USD">USD</option>
          <option value="GBP">GBP</option>
          <option value="EUR">EUR</option>
          <option value="OTHER">Other</option>
        </select>
        {currencyCode === "OTHER" ? (
          <input
            value={customCurrencyCode ?? ""}
            placeholder="NZD"
            onChange={(evt) => setCustomCurrencyCode(evt.target.value)}
          />
        ) : (
          <></>
        )}
      </label>
      <label>
        <span className="text">Tax Type</span>
        <select onChange={(evt) => setTaxType(evt.target.value)}>
          <option value="INPUT2">GST on Expenses</option>
          <option value="NONE">No GST</option>
          <option value="ZERORATED">Zero Rated</option>
        </select>
      </label>

      <label>
        <span className="text">Notes</span>
        <textarea
          value={note ?? ""}
          placeholder="Shown to user"
          onChange={(evt) => setNote(evt.target.value)}
        />
      </label>

      <label>
        <span className="text">Line Item Description</span>
        <textarea
          value={lineItemDesc ?? ""}
          placeholder="The line item description - shown to user"
          onChange={(evt) => setLineItemDesc(evt.target.value)}
        />
      </label>

      <label>
        <span className="text">To</span>
        <textarea
          value={toName ?? ""}
          placeholder="The name of the company, person or entity whom this invoice is addressed to."
          onChange={(evt) => setToName(evt.target.value)}
          rows={1}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            minHeight: "1rem",
          }}
        />
      </label>
      <label>
        <span className="text">Invoice Date</span>
        <input
          type="date"
          value={invoiceDate ?? ""}
          defaultValue={moment().format("YYYY-MM-DD")}
          placeholder="0.00"
          min={MIN_DATE}
          onChange={(evt) => setInvoiceDate(evt.target.value)}
        />
      </label>
      <label>
        <span className="text">Due Date</span>
        <input
          type="date"
          value={dueDate ?? ""}
          defaultValue={moment().format("YYYY-MM-DD")}
          placeholder="0.00"
          min={MIN_DATE}
          onChange={(evt) => setDueDate(evt.target.value)}
        />
      </label>
      <label>
        <span className="text">Payment Date</span>
        <input
          type="date"
          value={paymentDate ? paymentDate : dueDate}
          defaultValue={moment().format("YYYY-MM-DD")}
          placeholder="0.00"
          min={MIN_DATE}
          onChange={(evt) => setPaymentDate(evt.target.value)}
        />
      </label>
      <label>
        <span className="text">Invoice No.</span>
        <input
          type="text"
          value={invoiceNo ?? ""}
          placeholder="ABC-123"
          onChange={(evt) =>
            setInvoiceNo(evt.target.value.replace(/[^[a-z0-9-]$ ]/, ""))
          }
        />
      </label>
      <label>
        <span className="text">From</span>
        <select
          value={_from ?? ""}
          onChange={(evt) => {
            console.log("onChange.from", evt.target.value);
            setFrom(evt.target.value);
          }}
        >
          {accounts.map((acc) => (
            <option key={acc._id} value={acc._id}>
              {acc._id === user.defaults?.pay_from?._id ? "⭐️ " : ""}
              {acc.name} {numeral(acc.balance?.available).format("$0,00[.]00")}{" "}
              ({acc?.connection?.name ?? "Manual"}: {acc.formatted_account})
            </option>
          ))}
        </select>
      </label>

      {email?.note && (
        <label>
          <span className="text">Note</span>
          {email.note}
        </label>
      )}

      <label className="payToSelect">
        <span className="text">
          <span style={{ marginRight: "1rem" }}>Pay To</span>
          {/* <a
            onClick={() => onChangeToType("BUSINESS")}
            className={to?.type === "BUSINESS" ? "selected" : ""}
          >
            Biz
          </a> */}
          {/* <a
            onClick={() => onChangeToType("PERSONAL")}
            className={to?.type === "PERSONAL" ? "selected" : ""}
          >
            User
          </a> */}
          <a
            onClick={() => onChangeToType("BANK_ACCOUNT")}
            className={to?.type === "BANK_ACCOUNT" ? "selected" : ""}
          >
            Bank
          </a>
          {/* <a
            onClick={() => onChangeToType("ENTITY")}
            className={to?.type === "ENTITY" ? "selected" : ""}
          >
            Entity
          </a> */}
        </span>
      </label>
      {to?.type === "BUSINESS" ? (
        <BusinessRecipientForm
          user={user}
          to={to}
          onChange={onChangeTo}
          prefill={prefill}
          validationRef={validateTo}
        />
      ) : to?.type === "PERSONAL" ? (
        <PersonalRecipientForm
          user={user}
          to={to}
          onChange={onChangeTo}
          prefill={prefill}
          validationRef={validateTo}
        />
      ) : to?.type === "BANK_ACCOUNT" ? (
        <BankRecipientForm
          user={user}
          to={to}
          onChange={onChangeTo}
          prefill={prefill}
          validationRef={validateTo}
        />
      ) : to?.type === "ENTITY" ? (
        <EntityRecipientForm
          user={user}
          to={to}
          onChange={onChangeTo}
          prefill={prefill}
          validationRef={validateTo}
        />
      ) : (
        <></>
      )}

      <button onClick={check}>Submit</button>
    </>
  );
};
