/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "../users/UserChats.css";
import ArrowForward from "@mui/icons-material/ArrowForward";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArchiveIcon from "@mui/icons-material/Archive";
import { orderBy } from "lodash-es";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { DangerModal } from "../../components/DangerModal";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";
import { ChatAvatar } from "../../components/ChatAvatar";
import { Helmet } from "react-helmet";

export const BusinessChats: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _biz = urlParams.id;
  const [biz, setBiz] = React.useState<dollaUsers.Models.Business>();
  const [chats, setChats] = React.useState<dollaUsers.Models.Chat[]>([]);
  const [loadingBiz, setLoadingBiz] = React.useState(false);
  const [loadingChats, setLoadingChats] = React.useState(false);

  const dangerModal = React.useRef<any>();

  React.useEffect(() => {
    async function getBiz() {
      setLoadingBiz(true);
      const result = await API.get(`/businesses/${_biz}`);
      setLoadingBiz(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBiz(result.json.item);
    }
    getBiz();
  }, [_biz]);

  const getChats = React.useCallback(() => {
    async function getChats() {
      setLoadingChats(true);
      const result = await API.get(`/users/${_biz}/chats?status=ALL`);
      setLoadingChats(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setChats(result.json.items);
    }
    getChats();
  }, [_biz]);
  React.useEffect(() => {
    getChats();
  }, [getChats]);

  const handleArchive = React.useCallback(
    async (chat: dollaUsers.Models.Chat) => {
      if (!biz) {
        return;
      }
      if (!chat) {
        return;
      }

      const onSubmit = async () => {
        const result = await API.put(
          `/users/${_biz}/chats/${chat._id}/archive`
        );
        if (!result.json.success) {
          SessionStore.apiErr(result);
        }
        getChats();
      };

      dangerModal.current.open(
        `Archive chat`,
        [
          <>
            This will archive <b>{biz.name}'s</b> chat mirror{" "}
            <b>{chat?.title}</b>.
          </>,
        ],
        onSubmit
      );
    },
    [biz, _biz, getChats]
  );
  const handleDelete = React.useCallback(
    async (chat: dollaUsers.Models.Chat) => {
      if (!chat) {
        return;
      }

      const onSubmit = async () => {
        const result = await API.delete(
          `/users/${_biz}/chats/${chat._id}/delete`
        );
        if (!result.json.success) {
          SessionStore.apiErr(result);
        }
        getChats();
      };

      dangerModal.current.open(
        `Delete chat`,
        [
          <>
            This will delete chat <b>{chat.title}</b>.
          </>,
          <>All chat mirrors and messages will be deleted.</>,
        ],
        onSubmit
      );
    },
    [_biz, getChats]
  );

  if (loadingBiz || loadingChats || !biz) {
    return <Loader />;
  }

  return (
    <div className="UserChats">
      <Helmet>
        <title>{biz.name}'s Chats | Dolla Management Console</title>
      </Helmet>
      <Link to={`/businesses/${biz._id}`} className="backlink">
        ← Back to {biz.name}
      </Link>
      <h3>{biz.name} Chats</h3>
      {!chats.length ? (
        <p>No chats</p>
      ) : (
        <List
          items={orderBy(chats, "last_message_at", "desc").map((chat) => ({
            title: (
              <span>
                ${biz.name} ➞ ${chat.title}{" "}
                <Inspector _id={chat._id} _user={_biz} />
              </span>
            ),
            subtitle:
              chat.status === "ACTIVE"
                ? `${chat.message_count} message${
                    chat.message_count === 1 ? "" : "s"
                  } (${chat.unread_count} unread)`
                : chat.status,
            logo: <ChatAvatar chat={chat} />,
            onClick: () => navigate(`/chats/${chat._id}`),
            key: chat._id,
            rightElementClickable: true,
            rightElement: (
              <div className="RowIcons">
                <a onClick={() => handleArchive(chat)} title="Archive">
                  <ArchiveIcon fontSize="inherit" />
                </a>
                <a onClick={() => handleDelete(chat)} title="Delete">
                  <DeleteForeverIcon fontSize="inherit" />
                </a>
                <a
                  onClick={() => navigate(`/chats/${chat._id}`)}
                  title="Open chat"
                >
                  <ArrowForward fontSize="inherit" />
                </a>
              </div>
            ),
          }))}
        />
      )}
      <DangerModal openRef={dangerModal} />
    </div>
  );
};
