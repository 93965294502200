import { omit } from "lodash";
import { useEffect, useState } from "react";

export function useLocalStorage(key: string, initialValue: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<string>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? item : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

type LocalStorageObject = any;
export function useLocalStorageObject(obj: any) {
  const [storedObject, setStoredObject] = useState<LocalStorageObject>(obj);

  useEffect(() => {
    return storedObject;
  }, [storedObject]);

  const getValue = (key: string) => (obj: Record<string, any>) => obj[key];
  const setObject = (obj: any) => {
    try {
      setStoredObject({ ...storedObject, ...obj });
      Object.entries(obj).forEach(([key, value]) => {
        const currentValue = getValue(key)(storedObject);
        const valueToStore =
          value instanceof Function ? value(currentValue) : value;
        localStorage.setItem(
          key,
          typeof valueToStore === "string"
            ? valueToStore
            : JSON.stringify(valueToStore)
        );
        console.log(`[Item stored] ${key}:`, localStorage.getItem(key));
      });
    } catch (error) {
      console.log(error);
    }
  };
  const removeKeys = (keys: string[]) => {
    try {
      const updatedObject = omit(storedObject, keys);
      setStoredObject(updatedObject);
      keys.forEach((key) => {
        localStorage.removeItem(key);
        console.log(`removed: ${key}`);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return [storedObject, setObject, removeKeys];
}
