/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./BusinessSubscriptionPlans.css";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { orderBy } from "lodash-es";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";
import { Helmet } from "react-helmet";
import numeral from "numeral";

export const BusinessSubscriptionPlans: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _biz = urlParams.id;
  const [biz, setBiz] = React.useState<dollaUsers.Models.Business>();
  const [plans, setPlans] = React.useState<dollaUsers.Models.SubscriptionPlan[]>([]);
  const [loadingBiz, setLoadingBiz] = React.useState(false);
  const [loadingPlans, setLoadingPlans] = React.useState(false);

  React.useEffect(() => {
    async function getBiz() {
      setLoadingBiz(true);
      const result = await API.get(`/businesses/${_biz}`);
      setLoadingBiz(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBiz(result.json.item);
    }
    getBiz();
  }, [_biz]);

  const getPlans = React.useCallback(() => {
    async function getLinks() {
      setLoadingPlans(true);
      const result = await API.get(`/users/${_biz}/subscription-plans`);
      setLoadingPlans(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setPlans(result.json.items);
    }
    getLinks();
  }, [_biz]);
  React.useEffect(() => {
    getPlans();
  }, [getPlans]);

  if (loadingBiz || loadingPlans || !biz) {
    return <Loader />;
  }

  return (
    <div className="BusinessSubscriptionPlans">
      <Helmet>
        <title>{biz.name}'s Subscription Plans | Dolla Management Console</title>
      </Helmet>
      <Link to={`/businesses/${biz._id}`} className="backlink">
        ← Back to {biz.name}
      </Link>
      <h3>{biz.name} Subscription Plans</h3>
      {!plans.length ? (
        <p>No Plans</p>
      ) : (
        <List
          items={orderBy(plans, "created_at", "desc").map((plan) => ({
            title: (
              <span>
                Subscription Plan: {plan.name}
                <Inspector _user={biz._id} _id={plan._id} />
              </span>
            ),
            subtitle:
            `${numeral(plan.amount).format("$0,000.00")} every ${
              plan?.period?.frequency === 1
                ? plan?.period?.interval?.slice(0, -1)
                : `${plan?.period?.frequency} ${plan?.period?.interval}`}`,
            onClick: () =>
              navigate(`/businesses/${biz._id}/subscription-plan/${plan._id}/subscriptions`),
            key: plan._id,
            rightElement: <ArrowForward fontSize="inherit" />,
          }))}
        />
      )}
    </div>
  );
};
