import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { NavWrapper } from "./navigation/Wrapper";

// Pages
import { IndexPage } from "./navigation/IndexPage";
import { PageNotFound } from "./navigation/404";
import { SearchUsersPage } from "./users/SearchUsersPage";
import { UserPage } from "./users/UserPage";
import { UserAccounts } from "./users/UserAccounts";
import { UserContacts } from "./users/UserContacts";
import { UserChats } from "./users/UserChats";
import { UserDevices } from "./users/UserDevices";
import { UserPayments } from "./users/UserPayments";
import { UserPaymentsV3 } from "./users/UserPaymentsV3";
import { UserEmailList } from "./users/UserEmailList";
import { EmailListV2 } from "./emails/EmailListV2";
import { EmailTemplates } from "./emails/EmailTemplates";
import { DollaChat } from "./chat/DollaChat";
import { SearchBusinessesPage } from "./businesses/SearchBusinesses";
import { NewBusiness } from "./businesses/NewBusiness";
import { EditBusiness } from "./businesses/EditBusiness";
import { BusinessPage } from "./businesses/BusinessPage";
import { BusinessAccounts } from "./businesses/BusinessAccounts";
import { BusinessChats } from "./businesses/BusinessChats";
import { BusinessPayments } from "./businesses/BusinessPayments";
import { BusinessPaymentLinks } from "./businesses/BusinessPaymentLinks";
import { BusinessPaymentLink } from "./businesses/BusinessPaymentLink";
import { PaymentsPage } from "./payments/PaymentsPage";
import { PaymentsV3Page } from "./paymentsv3/PaymentsV3Page";
import { ListMgmtUsers } from "./mgmt/ListMgmtUsers";
import { MgmtUser } from "./mgmt/MgmtUser";
import { NewMgmtUser } from "./mgmt/NewMgmtUser";
import { SandboxPage } from "./sandbox/Sandbox";
import { BrowseScripts } from "./sandbox/BrowseScripts";
import { UniversalPage } from "./universal/index";
import { EditConstants } from "./constants/EditConstants";
import { ConstantsPage } from "./constants/ConstantsPage";
import { UserLogs } from "./users/UserLogs";
import { QrPage } from "./sandbox/QR";
import { UserPaymentLinks } from "./users/UserPaymentLinks";
import { UserPaymentLink } from "./users/UserPaymentLink";
import { UserSubscriptions } from "./users/UserSubscriptions";
import { BusinessSubscriptionPlans } from "./businesses/BusinessSubscriptionPlans";
import { BusinessSubscriptionPlan } from "./businesses/BusinessSubscriptionPlan";
import { UserTransactions } from "./users/UserTransactions";
import { UserTriggers } from "./users/UserTriggers";
import { UserIdentities } from "./users/UserIdentities";
import { BusinessIdentities } from "./businesses/BusinessIdentities";
import { SearchEntitiesPage } from "./entities/SearchEntities";
import { EntityPage } from "./entities/EntityPage";
import { NewEntity } from "./entities/NewEntity";
import { UserEmailV2 } from "./users/UserEmailV2";
import { UniversalRDBPage } from "./universalRDB";
import { UserParties } from "./users/UserParties";
import { SearchBankAccountsPage } from "./bank_accounts/SearchBankAccounts";
import { BankAccountPage } from "./bank_accounts/BankAccountPage";
import { BankAccountPayments } from "./bank_accounts/BankAccountPayments";
import { XeroTenantPage } from "./xero/XeroTenantPage";
import { XeroContactsPage } from "./xero/XeroContactsPage";
import { XeroAccountsPage } from "./xero/XeroAccountsPage";
import { UserXero } from "./users/UserXero";

// Mapping of path => Page
// First item is the route, or a list of routes. Second item is the page component
export const routes: [string, React.FunctionComponent][] = [
  ["/", IndexPage],
  ["/users", SearchUsersPage],
  ["/users/:id", UserPage],
  ["/users/:id/accounts", UserAccounts],
  ["/users/:id/parties", UserParties],
  ["/users/:id/contacts", UserContacts],
  ["/users/:id/chats", UserChats],
  ["/users/:id/devices", UserDevices],
  ["/users/:id/emails", UserEmailList],
  ["/users/:id/emails/:email", UserEmailV2],
  ["/users/:id/emails/v2/:email", UserEmailV2],
  ["/users/:id/logs", UserLogs],
  ["/users/:id/payments", UserPayments],
  ["/users/:id/payments-v3", UserPaymentsV3],
  ["/users/:id/payment-links", UserPaymentLinks],
  ["/users/:id/payment-links/:link", UserPaymentLink],
  ["/users/:id/subscriptions", UserSubscriptions],
  ["/users/:id/transactions", UserTransactions],
  ["/users/:id/triggers", UserTriggers],
  ["/users/:id/identities", UserIdentities],
  ["/users/:id/xero", UserXero],
  ["/emails", EmailListV2],
  ["/emails/v2", EmailListV2],
  ["/emails/templates", EmailTemplates],
  ["/chats/:id", DollaChat],
  ["/businesses", SearchBusinessesPage],
  ["/businesses/new", NewBusiness],
  ["/businesses/:id", BusinessPage],
  ["/businesses/:id/edit", EditBusiness],
  ["/businesses/:id/accounts", BusinessAccounts],
  ["/businesses/:id/chats", BusinessChats],
  ["/businesses/:id/identities", BusinessIdentities],
  ["/businesses/:id/payments", BusinessPayments],
  ["/businesses/:id/payment-links", BusinessPaymentLinks],
  ["/businesses/:id/payment-links/:link", BusinessPaymentLink],
  ["/businesses/:id/subscription-plans", BusinessSubscriptionPlans],
  [
    "/businesses/:id/subscription-plan/:plan/subscriptions",
    BusinessSubscriptionPlan,
  ],
  ["/entities", SearchEntitiesPage],
  ["/entities/new", NewEntity],
  ["/entities/:id", EntityPage],
  ["/bank_accounts", SearchBankAccountsPage],
  ["/bank_accounts/:account_number", BankAccountPage],
  ["/bank_accounts/:account_number/payments", BankAccountPayments],
  ["/payments", PaymentsPage],
  ["/paymentsv3", PaymentsV3Page],
  ["/mgmt/users", ListMgmtUsers],
  ["/mgmt/users/:id", MgmtUser],
  ["/mgmt/users/new", NewMgmtUser],
  ["/constants", ConstantsPage],
  ["/constants/:filename", EditConstants],
  ["/sandbox", SandboxPage],
  ["/sandbox/browse", BrowseScripts],
  ["/universal/rdb/:id", UniversalRDBPage],
  ["/universal/:id", UniversalPage],
  ["/qr", QrPage],
  ["/xero", () => <div>Xero</div>],
  ["/xero/:id", XeroTenantPage],
  ["/xero/:id/contacts", XeroContactsPage],
  ["/xero/:id/accounts", XeroAccountsPage],
];

export function AppRouter() {
  return (
    <Router>
      <NavWrapper>
        <Routes>
          {routes.map(([path, Component]) => (
            <Route path={path} key={path} element={<Component />} />
          ))}
          {/* 404 not found */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </NavWrapper>
    </Router>
  );
}
