import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import StorageIcon from "@mui/icons-material/Storage";
import "./index.css";

import { JSONObject } from "../../components/JSONObject";
import { Helmet } from "react-helmet";

export const UniversalPage: React.FunctionComponent = () => {
  const params = useParams();
  const { id } = params;
  const [search] = useSearchParams();
  const [raw, setRaw] = React.useState(false);
  let _user = search.get("_user") || search.get("user") || undefined;
  if (_user === "undefined") {
    _user = id;
  }
  const table = "dolla-users";
  const itemType = () => {
    return id?.substring(0, id?.lastIndexOf("_")).toLocaleUpperCase();
  };
  const key = _user + "_" + itemType();
  const url = `https://ap-southeast-2.console.aws.amazon.com/dynamodbv2/home?region=ap-southeast-2#edit-item?table=${table}&itemMode=2&pk=${key}&sk=${id}&ref=%23item-explorer%3Ftable%3Ddolla-users&route=ROUTE_ITEM_EXPLORER`;

  const handleRaw = () => {
    setRaw(!raw);
  };

  if (!id) {
    return <p>Missing ID</p>;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{id} | Sandbox | Dolla Management Console</title>
      </Helmet>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <h3>
          Inspect {id}{" "}
          <span
            style={{
              verticalAlign: "bottom",
              lineHeight: "initial",
            }}
          >
            <a
              className="titleLink"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StorageIcon fontSize="inherit" />
            </a>
          </span>
        </h3>
      </div>
      <div className="buttonRow">
        <button onClick={handleRaw}>{raw ? "Nested" : "Raw JSON"}</button>
      </div>
      <div className="UniversalPage">
        <JSONObject
          _id={id}
          _user={_user}
          load={true}
          probablyId={false}
          raw={raw}
        />
      </div>
    </React.Fragment>
  );
};
