import * as React from "react";
import { useParams, Link } from "react-router-dom";
import "./DollaChat.css";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import socket from "../../utils/websocket";
import { Loader } from "../../components/Loader";
import { ChatMessages } from "./components/ChatMessages";
import { Helmet } from "react-helmet";

const ACTORS = [
  {
    name: "Dolla",
    logo: "https://static.dolla.nz/images/icon.png",
    _id: "business_cl08jr6cd000009mu35sebxc7",
    _api_token: "api_token_cl08jr6cd000009mu35sebxc7",
  },
  {
    name: "DollaStore",
    logo: "https://static.dolla.nz/images/avatars/avatar_cl6egeu35000209l6a8oc8o7e.jpg",
    _id: "business_cl6egeu35000109l691uh1szy",
    _api_token: "api_token_cl6egeu35000109l691uh1szy",
  },
];

export const DollaChat: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _chat = urlParams.id;
  const [otherUser, setOtherUser] = React.useState<dollaUsers.Models.User>();
  const [chat, setChat] = React.useState<dollaUsers.Models.Chat>();
  const [messages, setMessages] = React.useState<dollaUsers.Models.Message[]>(
    []
  );
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingChat, setLoadingChat] = React.useState(false);
  const [loadingMessages, setLoadingMessages] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [sending, setSending] = React.useState(false);
  const [actor, setActor] = React.useState(ACTORS[0]);

  // Get the chat
  // If it doesn't exist for the current actor, go through them to find one that does.
  React.useEffect(() => {
    async function getChat() {
      setLoadingChat(true);
      const result = await API.get(`/users/${actor._id}/chats/${_chat}`);
      setLoadingChat(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      // Try the next actor
      if (!result.json.item) {
        const currentIndex = ACTORS.indexOf(actor);
        if (currentIndex !== -1 && ACTORS[currentIndex + 1]) {
          setActor(ACTORS[currentIndex + 1]);
          return;
        } else {
          SessionStore.setError("Unknown chat");
          return;
        }
      }
      setChat(result.json.item);
    }
    getChat();
  }, [_chat, actor]);

  // Get the other user once we have the chat
  React.useEffect(() => {
    async function getOtherUser(_user: string) {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setOtherUser(result.json.item);
    }
    if (chat) {
      const _otherUser = chat.participants.find(
        (x) => x._id !== actor._id
      )?._id;
      if (_otherUser) {
        getOtherUser(_otherUser);
      }
    }
  }, [chat, actor]);

  // Get the messages once we have the chat
  React.useEffect(() => {
    async function getMessages(_chat: string) {
      setLoadingMessages(true);
      const result = await API.get(
        `/users/${actor._id}/chats/${_chat}/messages`
      );
      setLoadingMessages(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setMessages(result.json.items);
    }
    if (chat) {
      getMessages(chat._id);
    }
  }, [chat, actor]);

  const onWSMessage = React.useCallback(
    (message: any) => {
      if (
        message.item?._id?.startsWith("message_") &&
        message.item._chat === _chat
      ) {
        if (messages.find((x) => x._id === message.item._id)) {
          // Update
          setMessages(
            messages.map((m) => (m._id === message.item._id ? message.item : m))
          );
        } else {
          // Insert
          setMessages([...messages, message.item]);
        }
      }
    },
    [_chat, messages]
  );

  // Set up the socket
  // 1. Open on chat load
  React.useEffect(() => {
    if (chat) {
      if (socket?.isOpen()) {
        socket?.close();
      }
      socket?.open(actor._id, actor._api_token);

      return () => {
        socket?.close();
      };
    }
    return () => {};
  }, [chat, actor]);
  // 2. Listen for messages
  React.useEffect(() => {
    console.log("Setting up listener");
    socket?.on("message", onWSMessage);

    return () => {
      socket?.off("message", onWSMessage);
    };
  }, [onWSMessage]);

  /** Send a basic message to the user */
  const sendMessage = async (event: React.FormEvent) => {
    event.preventDefault();

    setSending(true);
    const result = await API.post(
      `/users/${actor._id}/chats/${_chat}/messages`,
      {
        text: message,
        _actor: actor._id,
      }
    );
    setSending(false);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
    } else {
      setMessage("");
    }
  };

  if (loadingUser || loadingChat || loadingMessages || !otherUser) {
    return <Loader />;
  }

  return (
    <div className="DollaChat">
      <Helmet>
        <title>{chat?.title ?? "Chat"} | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${otherUser._id}/chats`} className="backlink">
        ← Back to {otherUser.name}'s chats
      </Link>
      <h3>Chat with {otherUser.name}</h3>
      <div className="ChatWidget">
        {chat ? (
          <>
            <ChatMessages
              messages={messages}
              otherUser={otherUser}
              _actor={actor._id}
            />
            <form className="MessageInput" onSubmit={sendMessage}>
              <textarea
                onChange={(evt) => setMessage(evt.target.value)}
                placeholder={`Chatting as ${actor.name}`}
                value={message}
                disabled={sending}
              ></textarea>
              <button disabled={sending} onClick={sendMessage}>
                <img src={actor.logo} alt="" />
                Send
              </button>
            </form>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
