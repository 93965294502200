import * as React from "react";
import "./NavLink.css";

import {
  NavLink as OriginalNavLink,
  NavLinkProps as OriginalNavLinkProps,
} from "react-router-dom";

interface NavLinkProps extends OriginalNavLinkProps {
  button?: boolean;
}

export const NavLink: React.FunctionComponent<NavLinkProps> = (
  props: NavLinkProps
) => {
  const manualStyle = props.style || {};
  const buttonStyle = props.button
    ? {
        padding: "0.4rem 0.8rem",
        border: "1px rgba(255, 255, 255, 0.8) solid",
        borderRadius: "3px",
      }
    : {};

  const passThroughProps: any = Object.fromEntries(
    Object.entries(props).filter(([key]) => {
      const toFilterOut = ["button", "style"];
      return !toFilterOut.includes(key);
    })
  );

  return (
    <OriginalNavLink
      className={"NavLink"}
      {...passThroughProps}
      style={{
        ...buttonStyle,
        ...manualStyle,
      }}
    ></OriginalNavLink>
  );
};
