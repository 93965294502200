/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./BusinessIdentities.css";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { orderBy } from "lodash-es";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";
import { Helmet } from "react-helmet";
import moment from "moment";

export const BusinessIdentities: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _biz = urlParams.id;
  const [business, setBusiness] = React.useState<dollaUsers.Models.Business>();
  const [identities, setIdentities] = React.useState<
    dollaUsers.Models.Identity[]
  >([]);
  const [loadingBusiness, setLoadingBusiness] = React.useState(false);
  const [loadingIdentities, setLoadingIdentities] = React.useState(false);

  React.useEffect(() => {
    async function getBusiness() {
      setLoadingBusiness(true);
      const result = await API.get(`/businesses/${_biz}`);
      setLoadingBusiness(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBusiness(result.json.item);
    }
    getBusiness();
  }, [_biz]);

  const getIdentities = React.useCallback(() => {
    async function getIdentities() {
      setLoadingIdentities(true);
      const result = await API.get(`/users/${_biz}/identities`);
      setLoadingIdentities(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setIdentities(result.json.items);
    }
    getIdentities();
  }, [_biz]);
  React.useEffect(() => {
    getIdentities();
  }, [getIdentities]);

  if (loadingBusiness || loadingIdentities || !business) {
    return <Loader />;
  }

  return (
    <div className="BusinessIdentities">
      <Helmet>
        <title>{business.name}'s Identities | Dolla Management Console</title>
      </Helmet>
      <Link to={`/businesses/${business._id}`} className="backlink">
        ← Back to {business.name}
      </Link>
      <h3>{business.name} Identities</h3>
      {!identities.length ? (
        <p>No identities</p>
      ) : (
        <List
          items={orderBy(identities, "created_at", "desc").map((identity) => {
            return {
              logo: business.avatar_url,
              title: (
                <span>
                  {business.name ?? identity._user} identity
                  <Inspector _user={identity._user} _id={identity._id} />
                </span>
              ),
              subtitle: `Created ${moment(identity.created_at).fromNow(
                true
              )} ago.`,
              onClick: () =>
                navigate(`/universal/${identity._id}?_user=${identity._user}`),
              key: identity._id,
              rightElement: <ArrowForward fontSize="inherit" />,
            };
          })}
        />
      )}
    </div>
  );
};
