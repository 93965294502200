/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import moment from "moment";
import numeral from "numeral";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";

import "./PaymentRow.css";

import { dollaUsers } from "../../../../../../lambdas/utils-common";
import { Arrow } from "./Arrow";
import { Inspector } from "../../../components/Inspector";

const GREEN_STATUSES = ["SENT"];
const RED_STATUSES = ["ERROR", "DECLINED", undefined];

const isUserOrBusiness = (
  item: any
): item is dollaUsers.Models.User | dollaUsers.Models.Business =>
  item._id?.startsWith("user_") || item._id?.startsWith("business_");

type payee = {
  avatar_url: string;
  name?: string;
  amount: number;
  status: string;
  _id: string;
  received_at?: string | boolean;
  eta?: string;
  time?: string;
};

const PayeeRow: React.FunctionComponent<{
  payee: payee;
  request: boolean;
  _payment: string;
}> = (props) => {
  const { payee, request, _payment } = props;
  const showPayeeInspector =
    payee._id.startsWith("user_") || payee._id.startsWith("business_");
  return (
    <div className="payee">
      <span className="arrow">
        <Arrow reverse={request}>
          <span style={{ fontSize: "80%" }}>
            {numeral(payee.amount).format("$0,00.00")}
          </span>
        </Arrow>
      </span>
      {showPayeeInspector && <Inspector _id={_payment} _user={payee._id} />}
      <a
        href={`/${
          payee._id.startsWith("user")
            ? "users"
            : payee._id.startsWith("business")
            ? "businesses"
            : "universal"
        }/${payee._id}${
          payee._id.startsWith("bank_acc") ? "?_user=UNASSIGNED" : ""
        }`}
      >
        <img
          className="avatar"
          src={payee.avatar_url}
          alt={`${payee.name}'s avatar`}
        />
      </a>
      {payee.name || "???"}
      <span
        className={`status ${
          GREEN_STATUSES.includes(payee.status) ? "green" : ""
        } ${RED_STATUSES.includes(payee.status) ? "red" : ""}`}
      >
        {payee.status || "???"}
      </span>
      <span
        className={`${GREEN_STATUSES.includes(payee.status) ? "green" : ""} ${
          RED_STATUSES.includes(payee.status) ? "red" : ""
        }`}
        title={!!payee.received_at ? "RECEIVED" : payee.status}
      >
        {!!payee.received_at ||
        moment().isAfter(moment(payee?.eta ?? payee?.time).add(2, "hours")) ? (
          <CheckCircle />
        ) : RED_STATUSES.includes(payee.status) ? (
          <ErrorOutlinedIcon />
        ) : (
          <PendingOutlinedIcon />
        )}
      </span>
    </div>
  );
};

type PaymentRowProps = {
  payment: dollaUsers.Models.Payment;
  /** A bunch of users, including those involved in this payment */
  participants: (
    | dollaUsers.Models.User
    | dollaUsers.Models.Business
    | dollaUsers.Models.BankAccount
  )[];
};

export const PaymentRow: React.FunctionComponent<PaymentRowProps> = (props) => {
  const { payment, participants } = props;
  console.log("participants", payment._id, participants);
  const payer = participants
    .filter(isUserOrBusiness)
    .find((part) => part._id === payment.created_by) ?? {
    avatar_url: "https://static.dolla.nz/images/single.png",
    name: "???",
  };
  const payees: payee[] = payment.participants.map((pmtParticipant) => {
    if (pmtParticipant._id.startsWith("user_")) {
      const asUserParticipant =
        pmtParticipant as dollaUsers.Models.DollaPaymentParticipant;
      const user = participants
        .filter(isUserOrBusiness)
        .find((part) => asUserParticipant._id === part._id);
      if (user) {
        return {
          _id: asUserParticipant._id,
          avatar_url: user.avatar_url,
          name: user.name,
          amount: asUserParticipant.amount,
          status: asUserParticipant.status,
          received_at: asUserParticipant?.received_at ?? false,
        };
      }
    }
    if (pmtParticipant._id.startsWith("business")) {
      const asUserParticipant =
        pmtParticipant as dollaUsers.Models.DollaPaymentParticipant;
      const biz = participants
        .filter(isUserOrBusiness)
        .find((part) => asUserParticipant._id === part._id);
      if (biz) {
        return {
          _id: asUserParticipant._id,
          avatar_url: biz.avatar_url,
          name: biz.name,
          amount: asUserParticipant.amount,
          status: asUserParticipant.status,
          received_at: asUserParticipant?.received_at ?? false,
        };
      }
    }
    if (pmtParticipant._id.startsWith("bank_acc_")) {
      const asBankParticipant =
        pmtParticipant as dollaUsers.Models.ExternalPaymentParticipant;
      return {
        avatar_url: "https://static.dolla.nz/images/bank.png",
        name: `${asBankParticipant.account_name} (${asBankParticipant.account_number})`,
        amount: asBankParticipant.amount,
        status: asBankParticipant.status,
        _id: asBankParticipant._id,
      };
    }
    if (pmtParticipant._id.startsWith("acc_")) {
      const asBankParticipant =
        pmtParticipant as dollaUsers.Models.TransferParticipant;
      return {
        avatar_url: payer.avatar_url,
        name: `${payer.name} (Transfer)`,
        amount: asBankParticipant.amount,
        status: asBankParticipant.status,
        _id: asBankParticipant._id,
        time: asBankParticipant.time,
        eta: asBankParticipant.eta,
      };
    }

    // If we don't know this payee, return a blank with lots of ???s
    return {
      _id: Math.random().toString(),
      avatar_url: "https://static.dolla.nz/images/single.png",
      name: "???",
      amount: pmtParticipant.amount,
      status: pmtParticipant.status,
    };
  });
  return (
    <div className="PaymentRow">
      <div className="time" title={`${moment(payment.created_at).fromNow()}`}>
        {moment(payment.created_at).format("h:mma")}
      </div>
      <div className="payer">
        <div className="links">
          <Inspector _id={payment._id} _user={payment._user} />
          {payment._payment_link && (
            <Inspector
              _id={payment._payment_link}
              _user={payment._user}
              icon="link"
            />
          )}
          {payment._pending_payment && (
            <Inspector
              _id={payment._pending_payment}
              _user={payment._user}
              icon="pending"
            />
          )}
          {payment._subscription && (
            <Inspector
              _id={payment._subscription}
              _user={payment._user}
              icon="subscription"
            />
          )}
          {payment._trigger && (
            <Inspector
              _id={payment._trigger}
              _user={payment._user}
              icon="trigger"
            />
          )}
        </div>
        <a
          href={`${payment._user.startsWith("user") ? "users" : "businesses"}/${
            payment._user
          }`}
        >
          <img
            className="avatar"
            src={payer.avatar_url}
            alt={`${payer.name}'s avatar`}
          />
        </a>
        {payer.name}
      </div>
      <div className="payees">
        {payees.map((payee) => {
          return (
            <PayeeRow
              key={payee._id}
              payee={payee}
              request={payment.type === "REQUEST"}
              _payment={payment._id}
            />
          );
        })}
        {!payees.length ? <div className="payee">???</div> : <></>}
      </div>
    </div>
  );
};
