/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./NZBNEntitySearch.css";

import CheckIcon from "@mui/icons-material/Check";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { SmallSearch, SmallSearchProps } from "./SmallSearch";
import API, { MBIE_HEADERS } from "../utils/api";
import { SessionStore } from "../utils/session";

export type NZBNEntitySearchResult = {
  _id?: string;
  entityStatusCode: string;
  entityName: string;
  nzbn: string;
  entityTypeCode: string;
  entityTypeDescription: string;
  entityStatusDescription: string;
  tradingNames: string[];
  classifications: {
    uniqueIdentifier: string | null;
    classificationCode: string;
    classificationDescription: string;
  }[];
  previousEntityNames: string[];
  registrationDate: string;
  sourceRegisterUniqueId: string;
  links: {
    rel: string;
    href: string;
    methods: ("GET" | "POST" | "PUT")[];
  }[];
};

type NZBNEntitySearchProps = Omit<
  SmallSearchProps<NZBNEntitySearchResult>,
  "query" | "renderItem"
> & {
  existing_only?: boolean;
};

/** An RDB + NZBN entity search bar */
export const NZBNEntitySearch: React.FunctionComponent<NZBNEntitySearchProps> =
  (props) => {
    const queryEntities = async (query: string) => {
      if (!query) {
        return [];
      }

      const isNZBN = /^[\d]*$/.test(query);

      let existing;
      let existing_entities: any[];

      if (isNZBN) {
        existing = await API.get(`/entities?nzbn=${query}`);
        existing_entities = existing.json.success ? [existing.json.item] : [];
      } else {
        existing = await API.get(`/entities/search?q=${query}`);
        existing_entities = existing.json.success ? existing.json.items : [];
      }

      if (props.existing_only) {
        return existing_entities;
      }

      /** Search result from MBIE - Filter out REMOVED entities */
      const mb_result = await API.get(
        `https://api.business.govt.nz/gateway/nzbn/v5/entities?search-term=${query}&entity-status=Registered`,
        MBIE_HEADERS
      );

      if (mb_result.json?.status && mb_result.json?.status !== 200) {
        SessionStore.apiErr(mb_result);
        return [];
      }

      const nzbn_items = mb_result.json.items as NZBNEntitySearchResult[];

      const nzbn_formatted = nzbn_items
        .filter(
          (x) =>
            !existing_entities.find((y: { nzbn: string }) => x.nzbn === y.nzbn)
        )
        .map((x) => ({
          ...x,
          name: x.entityName,
          trading_names: x.tradingNames,
        }));

      // eslint-disable-next-line no-sparse-arrays
      return [...existing_entities, ...nzbn_formatted];
    };

    const renderEntity: SmallSearchProps["renderItem"] = (entity) => {
      const hasTradingNames =
        entity?.trading_names?.length &&
        entity?.trading_names?.[0]?.name?.toLowerCase() !==
          entity.name.toLowerCase();

      return (
        <div className="result" style={{ width: "100%", height: "100%" }}>
          <div className="result">
            {entity?.avatar_url && (
              <img
                className="avatar"
                src={
                  entity?.avatar_url ??
                  "https://static.dolla.nz/images/bank.png"
                }
                alt=""
              />
            )}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{entity.name}</span>
              {hasTradingNames ? (
                <>
                  {entity?.trading_names?.map((x: { name: string }) => (
                    <span className="subtitle">{x.name}</span>
                  ))}
                </>
              ) : entity?.trading_name ? (
                <span className="subtitle">{entity.trading_name}</span>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="result">
            {entity?._id && (
              <div className="badge" title="This entity exists">
                <CheckIcon fontSize="small" />
              </div>
            )}
            {entity?.registered_biller && (
              <div className="badge" title="This entity is a registered biller">
                <ReceiptIcon fontSize="small" />
              </div>
            )}
          </div>
        </div>
      );
    };
    return (
      <div className="NZBNEntitySearch">
        <SmallSearch
          {...props}
          renderItem={renderEntity}
          query={queryEntities}
          placeholder={props.placeholder || "Search for an entity"}
          clearOnSelect={props.clearOnSelect || true}
        />
      </div>
    );
  };
