/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import moment from "moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import MoreVert from "@mui/icons-material/MoreVert";
import { dollaUsers } from "../../../../../../lambdas/utils-common";
import { Loader } from "../../../components/Loader";
import API from "../../../utils/api";
import { SessionStore } from "../../../utils/session";
import { hideAll as closeTippy } from "tippy.js";
import { List } from "../../../components/List";
import * as RDB from "../../../../../../lambdas/rdb";
import { InspectorRDB } from "src/components/InspectorRDB";

export const AccountList: React.FunctionComponent<{
  accounts: RDB.Models.Account[];
  user: dollaUsers.Models.User;
  accountModal: any;
}> = (props) => {
  const { user: suppliedUser, accounts, accountModal } = props;

  const [user, setUser] = React.useState<dollaUsers.Models.User>(suppliedUser);
  const [loadingUser, setLoadingUser] = React.useState(false);

  const getUser = React.useCallback(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${user._id}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    getUser();
  }, [user._id]);
  React.useEffect(() => {
    getUser();
  }, [getUser]);

  const setDefaultToAccount = async (_acc: string) => {
    closeTippy();
    setLoadingUser(true);
    const result = await API.put(`/users/${user._id}/accounts/defaults`, {
      pay_to: _acc,
    });
    if (!result.json?.success) {
      setLoadingUser(false);
      SessionStore.apiErr(result);
      return;
    }
    getUser();
  };
  const setDefaultFromAccount = async (_acc: string) => {
    closeTippy();
    setLoadingUser(true);
    const result = await API.put(`/users/${user._id}/accounts/defaults`, {
      pay_from: _acc,
    });
    if (!result.json?.success) {
      setLoadingUser(false);
      SessionStore.apiErr(result);
      return;
    }
    getUser();
  };
  const setAsDefaults = async (_acc: string) => {
    closeTippy();
    setLoadingUser(true);
    const result = await API.put(`/users/${user._id}/accounts/defaults`, {
      pay_from: _acc,
      pay_to: _acc,
    });
    if (!result.json?.success) {
      setLoadingUser(false);
      SessionStore.apiErr(result);
      return;
    }
    getUser();
  };

  if (loadingUser || !user) {
    return <Loader />;
  }
  return (
    <List
      items={accounts.map((acc) => {
        const isDefaultPayTo = user?.defaults?.pay_to?._id === acc?._id;
        const isDefaultPayFrom = user?.defaults?.pay_from?._id === acc?._id;

        const subtitle: string[] = [];
        if (acc?.refreshed?.balance) {
          subtitle.push(`Updated ${moment(acc?.refreshed?.balance).fromNow()}`);
        } else {
          subtitle.push("Never refreshed");
        }
        if (isDefaultPayFrom) {
          subtitle.push("Default pay from");
        }
        if (isDefaultPayTo) {
          subtitle.push("Default pay to");
        }

        return {
          title: (
            <>
              <div className="contactTitle">
                {acc.name}
                {acc.status === "INACTIVE" ? " (inactive)" : ""}
                <InspectorRDB _id={acc._id} />
              </div>
            </>
          ),
          subtitle: subtitle.join(". "),
          // a ?? generic bank logo would be nice
          logo:
            acc?.connection?.logo ??
            "https://static.dolla.nz/images/single.png",
          key: acc._id,
          onClick: () => accountModal.current?.open(acc),
          rightElement: (
            <a onClick={(e) => e.stopPropagation()} className="menuIcon">
              <Tippy
                interactive={true}
                trigger="click"
                className="AccountItemTippy"
                placement="bottom"
                content={
                  <div className="moreLinks">
                    {!isDefaultPayFrom && !isDefaultPayTo ? (
                      <a
                        onClick={() => {
                          setAsDefaults(acc._id);
                        }}
                      >
                        Set as both default accounts
                      </a>
                    ) : (
                      <></>
                    )}
                    {!isDefaultPayFrom ? (
                      <a
                        onClick={() => {
                          setDefaultFromAccount(acc._id);
                        }}
                      >
                        Set as default pay from
                      </a>
                    ) : (
                      <></>
                    )}
                    {!isDefaultPayTo ? (
                      <a
                        onClick={() => {
                          setDefaultToAccount(acc._id);
                        }}
                      >
                        Set as default pay to
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                }
              >
                <MoreVert fontSize="inherit" />
              </Tippy>
            </a>
          ),
        };
      })}
    />
  );
};
