/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./QR.css";

import { Helmet } from "react-helmet";
import { QR } from "./components/QR";

export const QrPage: React.FunctionComponent = () => {
  const [input, setInput] = React.useState("");

  return (
    <div className="QRPage">
      <Helmet>
        <title>Generate QR Code | Dolla Management Console</title>
      </Helmet>
      <h3>Generate QR Code</h3>
      <input
        type="text"
        placeholder="QR Content"
        value={input}
        onInput={(e) => setInput((e.target as HTMLInputElement).value)}
      />
      <QR value={input} showInvert showDownload />
    </div>
  );
};
