/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinkIcon from "@mui/icons-material/Link";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import BalanceIcon from "@mui/icons-material/Balance";
import "./Inspector.css";

import { OnlyDev } from "./OnlyDev";

type InspectorProps = {
  _id: string;
  icon?: "link" | "pending" | "subscription" | "trigger";
};

export const InspectorRDB: React.FunctionComponent<InspectorProps> = (
  props
) => {
  return (
    <OnlyDev>
      <span className="Inspector">
        <a
          href={`/universal/rdb/${props._id}`}
          target="_blank"
          rel="noreferrer"
          title="Inspect this item"
          onClick={(event) => event?.stopPropagation()}
        >
          {props.icon === "link" ? (
            <LinkIcon fontSize="inherit" />
          ) : props.icon === "pending" ? (
            <ScheduleIcon fontSize="inherit" />
          ) : props.icon === "subscription" ? (
            <AutorenewIcon fontSize="inherit" />
          ) : props.icon === "trigger" ? (
            <BalanceIcon fontSize="inherit" />
          ) : (
            <VisibilityIcon fontSize="inherit" />
          )}
        </a>
      </span>
    </OnlyDev>
  );
};
