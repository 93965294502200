/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "./TippyExplainer.css";

import Help from "@mui/icons-material/Help";

export const TippyExplainer: React.FunctionComponent = (props) => {
  const { children } = props;
  return (
    <Tippy content={<>{children}</>} className="TippyExplainer">
      <a className="TippyExplainerTrigger">
        <Help fontSize="inherit" />
      </a>
    </Tippy>
  );
};
