const PNF = require("google-libphonenumber").PhoneNumberFormat;
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

type MobileResult =
  | { success: false; message: string }
  | {
      success: true;
      result: {
        national: string;
        international: string;
      };
    };

export function getFormattedPhoneNumber(
  number: string,
  country?: string
): MobileResult {
  try {
    const parsed = phoneUtil.parse(number, country ?? "NZ");
    const isPossible = phoneUtil.isPossibleNumber(parsed);
    const isValid = phoneUtil.isValidNumber(parsed);
    if (!isPossible) {
      return { success: false, message: "Invalid phone number" };
    }
    if (!isValid) {
      return { success: false, message: "Invalid phone number" };
    }
    const international = phoneUtil.format(parsed, PNF.E164);
    const national = phoneUtil.format(parsed, PNF.NATIONAL);
    return {
      success: true,
      result: {
        international,
        national,
      },
    };
  } catch (error) {
    console.error(error);
    return { success: false, message: "Error validating number" };
  }
}
