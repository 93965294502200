/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { SmallUserSearch } from "../../../../components/SmallUserSearch";
import API from "../../../../utils/api";
import { SessionStore } from "../../../../utils/session";
import { Loader } from "../../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { EmailFormV2Props } from "./EmailFormV2";

/** A simple form to assign an email to a user */
export const AssignFormV2: React.FunctionComponent<EmailFormV2Props> = (
  props
) => {
  const [loading, setLoading] = React.useState(false);
  const nav = useNavigate();
  const { email } = props;

  const assignToUser = async (user: { _id: string }) => {
    setLoading(true);
    const result = await API.put(
      `/users/${email._user === null ? "UNASSIGNED" : email._user}/emails/${
        email._id
      }/assign`,
      {
        _new_owner: user._id,
      }
    );
    if (!result.json?.success) {
      setLoading(false);
      SessionStore.apiErr(result);
      return;
    }
    setLoading(false);
    // Go to the email now that it belongs to a different user
    const { _id, _user } = result.json.item;
    nav(`/users/${_user}/emails/${_id}`);
  };

  return (
    <div className="EmailForm">
      <h4>Assign to user:</h4>
      {loading ? (
        <Loader size="small" color="#000000" />
      ) : (
        <SmallUserSearch onSelect={assignToUser} clearOnSelect />
      )}
    </div>
  );
};
