import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import "./index.css";

import { Helmet } from "react-helmet";
import { JSONObjectRDB } from "src/components/JSONObjectRDB";

export const UniversalRDBPage: React.FunctionComponent = () => {
  const params = useParams();
  const [queryParams] = useSearchParams();
  const { id } = params;
  const table = queryParams.get("table") ?? undefined;
  const _tenant = queryParams.get("_tenant") ?? undefined;
  const [raw, setRaw] = React.useState(false);
  const handleRaw = () => {
    setRaw(!raw);
  };

  if (!id) {
    return <p>Missing ID</p>;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{id} | Inspector | Dolla Management Console</title>
      </Helmet>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <h3>Inspect {id} </h3>
      </div>
      <div className="buttonRow">
        <button onClick={handleRaw}>{raw ? "Nested" : "Raw JSON"}</button>
      </div>
      <div className="UniversalPage">
        <JSONObjectRDB
          _id={id}
          table={table}
          _tenant={_tenant}
          load={true}
          probablyId={false}
          raw={raw}
        />
      </div>
    </React.Fragment>
  );
};
