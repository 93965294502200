/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./TransactionSupportModal.css";
import ReactModal from "react-modal";

import API from "../../../utils/api";
import { Loader } from "../../../components/Loader";
import { dollaUsers } from "utils-common";
import type { Transaction } from "akahu";
import { JSONObject } from "src/components/JSONObject";

type TransactionModalProps = {
  /** A way to expose the `open` method to parents */
  openRef: React.MutableRefObject<any>;
  /** The Dolla User */
  user: dollaUsers.Models.User;
};

export const TransactionSupportModal: React.FunctionComponent<TransactionModalProps> =
  (props) => {
    const [open, setOpen] = React.useState(false);
    const [fields, setFields] = React.useState<string[]>([]);
    const [comment, setComment] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string>();
    const [showJson, setShowJson] = React.useState(false);
    const [transaction, setTransaction] = React.useState<
      Transaction | undefined
    >(undefined);

    const toggleField = (field: string) => {
      if (fields.includes(field)) {
        setFields(fields.filter((f) => f !== field));
      } else {
        setFields([...fields, field]);
      }
    };

    const onSubmit = async () => {
      if (!transaction) {
        return;
      }
      setLoading(true);
      const result = await API.post(
        `/users/${props.user._id}/transactions/support`,
        {
          _id: transaction._id,
          comment,
          fields: fields.length ? fields : undefined,
        }
      );
      if (!result.json?.success) {
        console.log("API error", result);
        setError(result.json?.message ?? `API error (${result.status})`);
        return;
      }

      closeModal();
    };

    const openModal = (transaction: Transaction) => {
      setTransaction(transaction);
      setOpen(true);
    };
    const closeModal = () => {
      setComment("");
      setFields([]);
      setTransaction(undefined);
      setOpen(false);
      setLoading(false);
    };

    // Pass a reference to the `openModal` to parents
    if (props.openRef) {
      props.openRef.current = { open: openModal };
    }

    const content =
      !transaction || loading ? (
        <Loader />
      ) : (
        <>
          <div className="buttonRow">
            <button className="blue" onClick={() => setShowJson(!showJson)}>
              {showJson ? "Hide" : "Show"} JSON
            </button>
          </div>
          {showJson && (
            <JSONObject
              input={transaction}
              _id={transaction._id}
              probablyId={false}
              raw={true}
            />
          )}
          {"merchant" in transaction && (
            <table>
              <p>Incorrect fields</p>
              <tbody>
                <>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onClick={() => toggleField("merchant.name")}
                      />
                    </td>
                    <td>
                      <p>Merchant name</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        onClick={() => toggleField("merchant.category")}
                      />
                    </td>
                    <td>
                      <p>Category</p>
                    </td>
                  </tr>
                </>
              </tbody>
            </table>
          )}
          <p>Comment (required)</p>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />

          <div className="buttonRow">
            <button
              className="wide blue"
              onClick={() => onSubmit()}
              disabled={loading || !comment}
            >
              Submit
            </button>
          </div>
        </>
      );

    return (
      <ReactModal
        isOpen={open}
        onRequestClose={closeModal}
        overlayClassName="TransactionSupportModal modal_overlay"
        className="TransactionSupportModal modal wide"
        appElement={document.getElementById("root") ?? undefined}
      >
        <>
          <h3>{transaction?.description ?? "Transaction"}</h3>
          {error ? <p className="red">{error}</p> : <></>}
          {content}
        </>
      </ReactModal>
    );
  };
