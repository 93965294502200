import * as React from "react";

interface StoreInterface {
  getState: () => any;
  addChangeListener: (fn: () => void) => void;
  removeChangeListener: (fn: () => void) => void;
}

export default function storeWrapper<StoreProps>(
  Store: StoreInterface,
  name: string
): (
  WrappedComponent: React.FunctionComponent<any>
) => React.FunctionComponent<StoreProps & any> {
  return (WrappedComponent) => {
    class WrappedStore extends React.Component<{
      forwardedRef: React.ForwardedRef<unknown>;
    }> {
      static displayName = `${name}(${
        WrappedComponent.displayName || WrappedComponent.name || "Component"
      })`;

      constructor(props: any) {
        super(props);

        this.state = Store.getState();

        this._onStoreChange = this._onStoreChange.bind(this);
      }

      componentDidMount() {
        Store.addChangeListener(this._onStoreChange);
      }

      componentWillUnmount() {
        Store.removeChangeListener(this._onStoreChange);
      }

      _onStoreChange() {
        const oldState = this.state;
        const newState = Store.getState();
        Object.keys(oldState)
          .filter((k) => !newState[k])
          .forEach((k) => {
            newState[k] = undefined;
          });

        this.setState(newState);
      }

      render() {
        const storeProps = {
          [name]: this.state,
        };
        const { forwardedRef, ...rest } = this.props;
        return (
          <WrappedComponent ref={forwardedRef} {...rest} {...storeProps} />
        );
      }
    }
    // Name the class properly
    Object.defineProperty(WrappedStore, "name", { value: name });
    return React.forwardRef((props, ref) => (
      <WrappedStore forwardedRef={ref} {...props} />
    ));
  };
}
