import * as React from "react";
import { useNavigate, useParams } from "react-router";
import { Loader } from "src/components/Loader";
import "./EditBusiness.css";

import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { getFormattedPhoneNumber } from "../../utils/phonenumbers";
import { dollaUsers } from "../../../../../lambdas/utils-common";
import { BusinessEditor } from "./components/BusinessEditor";
import { Helmet } from "react-helmet";

export const EditBusiness: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _biz = urlParams.id;
  const [loading, setLoading] = React.useState(false);
  const [business, setBusiness] = React.useState<dollaUsers.Models.Business>();

  React.useEffect(() => {
    const loadBusiness = async () => {
      setLoading(true);
      const result = await API.get(`/businesses/${_biz}`);
      if (!result.json.success) {
        SessionStore.apiErr(result);
      } else {
        if (result.json.item?.contact?.phone) {
          const phoneValidation = getFormattedPhoneNumber(
            result.json.item.contact.phone
          );
          if (phoneValidation.success) {
            result.json.item.contact.phone = phoneValidation.result.national;
          }
        }
        setBusiness(result.json.item);
      }
      setLoading(false);
    };

    loadBusiness();
  }, [_biz]);

  const onSubmit = async (business: Partial<dollaUsers.Models.Business>) => {
    setLoading(true);
    const result = await API.put(`/businesses/${_biz}`, business);
    if (!result.json.success) {
      setLoading(false);
      SessionStore.apiErr(result);
    } else {
      // Go back to the business
      navigate(`/businesses/${_biz}`);
    }
  };

  if (loading || !business) {
    return <Loader />;
  }
  return (
    <div className="EditBusiness">
      <Helmet>
        <title>Edit {business.name} | Dolla Management Console</title>
      </Helmet>
      <div className="titleRow">
        <h3>Edit Business</h3>
      </div>
      <BusinessEditor
        business={business}
        onSubmit={onSubmit}
        submitTitle="Update"
      />
    </div>
  );
};
