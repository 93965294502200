/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import moment from "moment";
import numeral from "numeral";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import DeleteForever from "@mui/icons-material/DeleteForever";
import * as RDB from "rdb";

import "./PaymentV3Row.css";

import API from "../../../utils/api";
import { Arrow } from "./Arrow";
import { Inspector } from "../../../components/Inspector";
import Check from "@mui/icons-material/Check";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { InspectorRDB } from "src/components/InspectorRDB";
import { SessionStore } from "src/utils/session";

const GREEN_STATUSES = ["SENT"];
const RED_STATUSES = ["ERROR", "DECLINED", "DELETED", undefined];
const CAN_DELETE_STATUSES = [
  "PENDING",
  "SCHEDULED",
  "REJECTED",
  "CANCELLED",
  "VERIFY",
];

type payee = {
  avatar_url: string;
  name?: string;
  amount: number;
  status: string;
  _id: string;
  received_at?: string | boolean;
  eta?: string;
  time?: string;
};

const PayeeRow: React.FunctionComponent<{
  payee: payee;
  request: boolean;
  _payment: string;
}> = (props) => {
  const { payee, request, _payment } = props;
  const showPayeeInspector =
    payee._id.startsWith("user_") || payee._id.startsWith("business_");
  return (
    <div className="payee">
      <span className="arrow">
        <Arrow reverse={request}>
          <span style={{ fontSize: "80%" }}>
            {numeral(payee.amount).format("$0,00.00")}
          </span>
        </Arrow>
      </span>
      {showPayeeInspector && <Inspector _id={_payment} _user={payee._id} />}
      <a
        href={`/${
          payee._id.startsWith("user")
            ? "users"
            : payee._id.startsWith("business")
            ? "businesses"
            : payee._id.startsWith("entity")
            ? "entities"
            : "universal"
        }/${payee._id}${
          payee._id.startsWith("bank_acc") ? "?_user=UNASSIGNED" : ""
        }`}
      >
        <img
          className="avatar"
          src={payee.avatar_url}
          alt={`${payee.name}'s avatar`}
        />
      </a>
      {payee.name || "???"}
      <span
        className={`status ${
          GREEN_STATUSES.includes(payee.status) ? "green" : ""
        } ${RED_STATUSES.includes(payee.status) ? "red" : ""}`}
      >
        {payee.status || "???"}
      </span>
      <span
        className={`${GREEN_STATUSES.includes(payee.status) ? "green" : ""} ${
          RED_STATUSES.includes(payee.status) ? "red" : ""
        }`}
        title={!!payee.received_at ? "RECEIVED" : payee.status}
      >
        {!!payee.received_at ||
        moment().isAfter(moment(payee?.eta ?? payee?.time).add(2, "hours")) ? (
          <CheckCircle />
        ) : RED_STATUSES.includes(payee.status) ? (
          <ErrorOutlinedIcon />
        ) : (
          <PendingOutlinedIcon />
        )}
      </span>
    </div>
  );
};

type PaymentRowProps = {
  payment: RDB.payments.ManagementConsolePayments[number];
  ref: React.MutableRefObject<any>;
};

const CopyButton: React.FunctionComponent<{ value: string }> = ({ value }) => {
  const [justCopied, setJustCopied] = React.useState(false);

  const copyField = async () => {
    setJustCopied(true);
    await navigator.clipboard.writeText(value);
    setTimeout(() => {
      setJustCopied(false);
    }, 2000);
  };

  return (
    <>
      {justCopied ? (
        <Check />
      ) : (
        <a onClick={() => copyField()} title="Copy _id">
          <ContentCopy />
        </a>
      )}
    </>
  );
};

const DeleteButton = React.forwardRef(
  (
    props: { payment: RDB.Models.Payment & { created_by: { name: string } } },
    ref: any
  ) => {
    const { payment } = props;

    const onSubmit = async () => {
      const result = await API.delete(
        `/users/${payment._created_by}/payments/${payment._id}`
      );
      if (!result.json.success) {
        SessionStore.apiErr(result);
      } else {
        SessionStore.setInfo(`Deleted payment ${payment._id}`);
      }
    };

    const deletePayment = async () => {
      if (!ref) {
        return;
      }
      ref?.current?.open(
        `Delete payment`,
        [
          <p>
            This will delete <b>{payment.created_by.name}'s</b> payment of{" "}
            <b>{numeral(payment.amount).format("$0,000[.]00")}</b>{" "}
            {payment.recipient_account_number ? (
              <>
                to <b>{payment.recipient_account_name}</b> (
                {payment.recipient_account_number})
              </>
            ) : (
              <></>
            )}
            .
          </p>,

          // <p>
          //   {payment._id} {payment.type} {payment.status} {payment.pay_at}
          // </p>,
        ],
        onSubmit
      );
    };

    if (!CAN_DELETE_STATUSES.includes(payment.status)) {
      return <></>;
    }

    return (
      <a onClick={() => deletePayment()} title="Delete payment">
        <DeleteForever />
      </a>
    );
  }
);

export const PaymentRowV3 = React.forwardRef((props: PaymentRowProps, ref) => {
  const { payment } = props;

  const payer = payment.payer ?? {
    _id: "NO ID SET",
    name: "Payment Link",
    avatar_url: "https://static.dolla.nz/images/link.png",
  };
  const payeePerson =
    payment.type === "TRANSFER"
      ? { ...payer, name: payer.name + " (transfer)" }
      : payment.recipient
      ? {
          _id: payment.recipient._id,
          name: payment.recipient.name,
          avatar_url: payment.recipient.avatar_url,
        }
      : payment?.recipient_entity
      ? {
          _id: payment.recipient_entity._id,
          name: payment.recipient_entity.name,
          avatar_url: payment.recipient_entity.avatar_url,
        }
      : {
          _id: "NO ID SET",
          name: `${
            payment.contact_name
              ? `${payment.contact_name}`
              : "No contact name set"
          }${
            payment.identifier
              ? ` | ${payment.identifier}`
              : "No Identifier set"
          }`,
          avatar_url:
            payment.contact_type === "BANK_ACCOUNT"
              ? "https://static.dolla.nz/images/bank.png"
              : "https://static.dolla.nz/images/link.png",
        };

  const payee = {
    ...payeePerson,
    amount: payment.amount,
    status: payment.akahu_status ?? payment.status,
  };

  const time =
    payment.status === "CANCELLED" || payment.status === "DELETED"
      ? payment.updated_at
      : payment.order_at;

  return (
    <div className="PaymentRow">
      <div className="time" title={`${moment(time).fromNow()}`}>
        {moment(time).format("h:mma")}
      </div>
      <div className="payer">
        <div className="links">
          {payment._trigger && (
            <Inspector
              _id={payment._trigger}
              _user={payment._created_by}
              icon="trigger"
            />
          )}
        </div>
        <a
          href={
            payer._id === "NO ID SET"
              ? undefined
              : `${payer._id.startsWith("user") ? "users" : "businesses"}/${
                  payer._id
                }`
          }
        >
          <img
            className="avatar"
            src={payer.avatar_url}
            alt={`${payer.name}'s avatar`}
          />
        </a>
        {payer.name}
      </div>
      <div className="payees">
        <PayeeRow
          key={payee._id}
          payee={payee}
          request={payment.type === "REQUEST"}
          _payment={payment._id}
        />
      </div>
      <div className="actions">
        <CopyButton value={payment._id} />
        <InspectorRDB _id={payment._id} />
        <DeleteButton payment={payment} ref={ref} />
      </div>
    </div>
  );
});
