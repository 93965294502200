/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./MgmtUser.css";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import Error from "@mui/icons-material/Error";
import Pin from "@mui/icons-material/Pin";
import Lock from "@mui/icons-material/Lock";
import Fingerprint from "@mui/icons-material/Fingerprint";
import DeleteForever from "@mui/icons-material/DeleteForever";

import { managementDB } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import {
  SessionStore,
  withSessionStore,
} from "../../utils/session";
import { Loader } from "../../components/Loader";
import { DangerModal } from "../../components/DangerModal";
import { Helmet } from "react-helmet";

const MAX_NUMBER_OF_FAILED_LOGINS = 3;
const TMP_PASSWORD_MAX_AGE = { hours: 24 };

export const MgmtUser: React.FunctionComponent =
  withSessionStore((props) => {
    const urlParams = useParams();
    const _user = urlParams.id;
    const [user, setUser] = React.useState<managementDB.Models.User>();
    const [loading, setLoading] = React.useState(false);
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");

    const dangerModal = React.useRef<any>();
    const navigate = useNavigate();

    // const dangerModal = React.useRef();

    // Get this user
    const getUser = React.useCallback(async () => {
      setLoading(true);
      const result = await API.get(`/settings/users`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(
        result.json.items.find(
          (user: managementDB.Models.User) => user._id === _user
        )
      );
    }, [_user]);

    // Get the user when the page loads
    React.useEffect(() => {
      getUser();
    }, [getUser]);

    // Unlock the user
    const unlock = async () => {
      if (!user) {
        return;
      }
      const onSubmit = async () => {
        const result = await API.put(`/settings/users/${user._id}/unlock`);
        if (result.json.success) {
          await getUser();
          SessionStore.setInfo(`Reset ${user.name}'s login counter`);
        } else {
          SessionStore.apiErr(result);
        }
      };

      dangerModal.current.open(
        `Are you sure?`,
        [
          `This will reset ${user.name}'s login counter.`,
          `They will have another ${MAX_NUMBER_OF_FAILED_LOGINS} attempts to login, before being locked out again.`,
          "If they still can't figure it out, try resetting their username or password for them.",
        ],
        onSubmit
      );
    };

    // Reset the user's password
    const resetPw = async () => {
      if (!user) {
        return;
      }
      const onSubmit = async () => {
        const result = await API.put(`/settings/users/${user._id}/password`);
        if (result.json.success) {
          await getUser();
          alert(
            `Reset ${user.name}'s password, new temporary password is: ${result.json.item.password}`
          );
        } else {
          SessionStore.apiErr(result);
        }
      };

      dangerModal.current.open(
        `Are you sure?`,
        [
          `This will reset ${user.name}'s password and log out any active sessions.`,
          "You will have to give them their new temporary password before they can log in again.",
        ],
        onSubmit
      );
    };

    // Reset the user's MFA token
    const resetMfa = async () => {
      if (!user) {
        return;
      }
      const onSubmit = async () => {
        const result = await API.put(`/settings/users/${user._id}/otp`);
        if (result.json.success) {
          await getUser();
          SessionStore.setInfo(`Reset ${user.name}'s 2FA`);
        } else {
          SessionStore.apiErr(result);
        }
      };

      dangerModal.current.open(
        `Are you sure?`,
        [
          `This will reset ${user.name}'s 2FA settings.`,
          "They will have to set it back up the next time they log in.",
        ],
        onSubmit
      );
    };

    // Delete this user
    const deleteUser = async () => {
      if (!user) {
        return;
      }
      const onSubmit = async () => {
        const result = await API.delete(`/settings/users/${user._id}`);
        if (result.json.success) {
          SessionStore.setInfo(`Deleted ${user.name}`);
          navigate("/mgmt/users");
        } else {
          SessionStore.apiErr(result);
        }
      };

      dangerModal.current.open(
        `Are you REALLY sure?`,
        [
          `This will delete ${user.name} from the management console.`,
          "All login sessions will be revoked, and can't be reversed.",
          "You will look really dumb if you do this when you shouldn't",
        ],
        onSubmit
      );
    };

    // Handle the user updating their own password
    const updatePassword = async (event: React.FormEvent) => {
      event.preventDefault();
      if (!newPassword) {
        SessionStore.setError("Please enter a new password");
        return;
      }
      if (!currentPassword) {
        SessionStore.setError(
          "You must confirm your old password, in order to set a new one"
        );
        return;
      }
      setLoading(true);
      const result = await API.put("/settings/me/password", {
        current_password: currentPassword,
        password: newPassword,
      });
      setLoading(false);

      if (!result.json.success) {
        SessionStore.apiErr(result);
      } else {
        setCurrentPassword("");
        setNewPassword("");
        SessionStore.setInfo("Password updated");
        navigate("/mgmt/users");
      }
    };

    if (loading || !user) {
      return <Loader />;
    }

    const _currentUser = props.SessionStore.user?._id;

    let type = "User";
    let error;
    if (user.admin) {
      type = "Admin";
    } else {
      type = "Basic User";
    }
    if (user.dev) {
      type = "Developer";
    }
    if (user.dev && user.admin) {
      type = "Admin + Developer";
    }
    if (!user.otp_setup_at) {
      error = "Should setup 2FA";
    }
    if (user.tmp_password_set_at) {
      error = "Should set a secure password";
    }
    if (
      user.failed_login_attempts &&
      user.failed_login_attempts >= MAX_NUMBER_OF_FAILED_LOGINS
    ) {
      error = "Locked out (needs to be unlocked)";
    }
    if (
      user.tmp_password_set_at &&
      user.tmp_password_set_at <
        moment().subtract(TMP_PASSWORD_MAX_AGE).toISOString()
    ) {
      error = "Locked out (needs a password reset)";
    }

    return (
      <div className="MgmtUser">
        <Helmet>
          <title>{user.name} | Dolla Management Console</title>
        </Helmet>
        <h3>{user.name}</h3>
        {error ? (
          <div className="red">
            <Error /> {error}
          </div>
        ) : (
          <div>Joined {moment(user.created_at).fromNow()}</div>
        )}

        <div className="pretty_box">
          <span>
            <b>Username</b>
            <span>{user.username}</span>
          </span>
          <span>
            <b>Account</b>
            <span>{type}</span>
          </span>
          <span>
            <b>User ID</b>
            <span>{user._id}</span>
          </span>
        </div>
        {user._id !== _currentUser ? (
          <div className="linklist vertical">
            {user.failed_login_attempts ? (
              <a onClick={unlock}>
                <Lock className="icon" />
                Reset Failed Logins
              </a>
            ) : (
              <></>
            )}
            <a onClick={resetPw}>
              <Pin className="icon" />
              Reset Password
            </a>
            {user.otp_setup_at ? (
              <a onClick={resetMfa}>
                <Fingerprint className="icon" />
                Reset 2FA
              </a>
            ) : (
              <></>
            )}
            <a onClick={deleteUser}>
              <DeleteForever className="icon" />
              Delete User
            </a>
          </div>
        ) : (
          <div>
            {user.tmp_password_set_at ? (
              <>
                <div>
                  Your current password will expire{" "}
                  {moment(user.tmp_password_set_at)
                    .add(TMP_PASSWORD_MAX_AGE)
                    .fromNow()}
                  .
                </div>
                <p>Please set a secure password:</p>
              </>
            ) : (
              <div style={{ marginBottom: "1rem" }}>
                Update your password here:
              </div>
            )}
            <form onSubmit={updatePassword}>
              <label>
                New Password
                <input
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  onChange={(evt) => setNewPassword(evt.target.value)}
                  autoComplete="off"
                />
              </label>
              <label>
                Current Password
                <input
                  type="password"
                  name="currentPassword"
                  value={currentPassword}
                  onChange={(evt) => setCurrentPassword(evt.target.value)}
                  autoComplete="off"
                />
              </label>
              <input type="submit" value="Save" />
            </form>
          </div>
        )}
        <DangerModal openRef={dangerModal} />
      </div>
    );
  });
