/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ExpandCircleDown from "@mui/icons-material/ExpandCircleDown";
import { Document, Page, pdfjs } from "react-pdf";
import { Loader } from "../../../../components/Loader";
import { dollaUsers } from "../../../../../../../lambdas/utils-common";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type EmailAttachmentProps = {
  item: dollaUsers.Models.Email["attachments"][number] & { uri: string };
};

/** Render a pdf using pdf.js */
export const Pdf: React.FunctionComponent<EmailAttachmentProps> = (props) => {
  const [visible, setVisible] = React.useState(true);
  const [numPages, setNumPages] = React.useState(0);

  const onPdfLoad = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const pages_dom = [];
  for (var i = 0; i < numPages; i++) {
    pages_dom.push(
      <div className="page" key={`page_${i}`}>
        <Page pageNumber={i + 1}></Page>
      </div>
    );
  }

  return (
    <>
      <a
        className="attachmentName"
        onClick={() => setVisible(!visible)}
        id={`attachment_${props.item.name}`}
      >
        <AttachmentIcon /> {props.item.name}
        <span className={`expand ${visible ? "rotated" : ""}`}>
          <ExpandCircleDown />
        </span>
      </a>
      <Document
        file={props.item.uri}
        loading={<Loader />}
        onLoadSuccess={onPdfLoad}
      >
        {visible ? pages_dom : <></>}
      </Document>
    </>
  );
};
