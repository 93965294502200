/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { DollaParty } from "rdb/src/models";

import "./UserParties.css";
import moment from "moment";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Helmet } from "react-helmet";
import { Inspector } from "../../components/Inspector";

export const UserParties: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<
    dollaUsers.Models.User & { parties: Partial<DollaParty>[] }
  >();
  const [loading, setLoading] = React.useState(false);

  const getUser = React.useCallback(() => {
    async function _getUser() {
      setLoading(true);
      const result = await API.get(`/users/${_user}`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    _getUser();
  }, [_user]);

  React.useEffect(() => {
    getUser();
  }, [getUser]);

  if (loading || !user) {
    return <Loader />;
  }

  return (
    <div className="UserParties">
      <Helmet>
        <title>{user.name}'s Parties | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name}'s Parties</h3>
      {!user?.parties?.length ? (
        <p>No Parties</p>
      ) : (
        <List
          items={user?.parties?.map((party) => ({
            title: (
              <>
                {party.name || ""} {party.type || ""}{" "}
                <Inspector _user={user._id} _id={user._id} />
              </>
            ),
            subtitle: `First seen: ${moment(
              party.created_at
            ).fromNow()}, last updated ${moment(party.updated_at).fromNow()}.`,
            logo: `https://static.akahu.io/images/${party._connection}_logo.jpeg`,
            key: party._id,
          }))}
        />
      )}
    </div>
  );
};
