/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

import { RecipientFormProps } from "./NewPaymentForm";
import { EntitySelector } from "src/components/EntitySelector";
import { dollaUsers } from "utils-common";

// It would be nice if we could reference this from the model somewhere...
type EntityRecipient = {
  type: "ENTITY";
  _id?: string;
  account_number: string;
  account_name: string;
  name?: string;
  meta: { code?: string; reference?: string };
  entity: dollaUsers.Models.EntitySuggestionTo["entity"];
};

/** A form to select a business and fill in required metadata */
export const EntityRecipientForm: React.FunctionComponent<
  RecipientFormProps<EntityRecipient>
> = (props) => {
  const { prefill, onChange } = props;

  console.log("PREFILL", prefill);
  const [accountNumber, setAccountNumber] = React.useState(
    (prefill?.to?.type === "ENTITY" || prefill?.to?.type === "BANK_ACCOUNT"
      ? prefill?.to?.account_number
      : "") || ""
  );
  const [accountName, setAccountName] = React.useState<string>(
    (prefill?.to?.type === "ENTITY" || prefill?.to?.type === "BANK_ACCOUNT"
      ? prefill?.to?.account_name
      : "") || ""
  );
  const [name, setName] = React.useState<string>(
    (prefill?.to?.type === "ENTITY" || prefill?.to?.type === "BANK_ACCOUNT"
      ? prefill?.to?.account_name ?? prefill?.to?.name
      : "") || ""
  );
  const [email, setEmail] = React.useState<string>(
    (prefill?.to?.type === "ENTITY"
      ? prefill?.to?.entity?.email
      : prefill?.to?.type === "BANK_ACCOUNT"
      ? prefill.to.email
      : "") || ""
  );
  const [tradingName, setTradingName] = React.useState<string>(
    (prefill?.to?.type === "ENTITY"
      ? prefill?.to?.account_name ?? prefill?.to?.name
      : "") || ""
  );
  const [logo, setLogo] = React.useState<string>(
    (prefill?.to?.type === "ENTITY" ? prefill?.to?.entity?.avatar_url : "") || ""
  );
  const [address, setAddress] = React.useState<string>(
    (prefill?.to?.type === "ENTITY" ? prefill?.to?.entity?.address : "") || ""
  );
  const [gstNumber, setGstNumber] = React.useState<string>(
    (prefill?.to?.type === "ENTITY" ? prefill?.to?.entity?.gst_number : "") ||
      ""
  );
  const [phone, setPhone] = React.useState<string>(
    (prefill?.to?.type === "ENTITY" ? prefill?.to?.entity?.phone : "") || ""
  );
  const [website, setWebsite] = React.useState<string>(
    (prefill?.to?.type === "ENTITY" ? prefill?.to?.entity?.website : "") || ""
  );
  const [entityType, setEntityType] = React.useState<
    dollaUsers.Models.Entity["type"]
  >(
    (prefill?.to?.type === "ENTITY" ? prefill?.to?.entity?.entity_type : "") ||
      "COMPANY"
  );
  const [entityId, setEntityId] = React.useState<string>();

  const [code, setCode] = React.useState(
    (prefill?.to?.type === "ENTITY" || prefill?.to?.type === "BANK_ACCOUNT"
      ? prefill?.to?.meta?.code?.substring(0, 12).replace(/[^0-9a-z -]/gi, "")
      : "") || ""
  );
  const [reference, setReference] = React.useState(
    (prefill?.to?.type === "ENTITY" || prefill?.to?.type === "BANK_ACCOUNT"
      ? prefill?.to?.meta?.reference
          ?.substring(0, 12)
          .replace(/[^0-9a-z -]/gi, "")
      : "") || ""
  );

  /**
   * Validate the parameters to pay a bank account
   *
   * Returns null if there are no errors, otherwise an error string.
   */
  props.validationRef.current = (): string | null => {
    if (!accountNumber) {
      return "Please enter an account to pay";
    }
    if (!accountName) {
      return "Please enter a name for the recipient bank account";
    }
    if (!/\d{2}-\d{4}-\d{7}-\d{2,3}/.test(accountNumber)) {
      return "Please enter a valid bank account number";
    }

    return null;
  };

  // Whenever the parameters change, call props.onChange
  React.useEffect(() => {
    onChange({
      type: "ENTITY",
      account_number: accountNumber,
      account_name: accountName,
      name: name,
      _id: entityId,
      meta: {
        code,
        reference,
      },
      entity: {
        trading_name: tradingName,
        email: email,
        logo: logo,
        address: address,
        gst_number: gstNumber,
        phone: phone,
        website: website,
        entity_type: entityType,
      },
    });
  }, [
    onChange,
    accountName,
    accountNumber,
    email,
    code,
    reference,
    name,
    address,
    phone,
    website,
    gstNumber,
    logo,
    entityId,
    entityType,
    tradingName,
  ]);

  return (
    <>
      <label>
        <EntitySelector
          account_name={accountName}
          account_number={accountNumber}
          gst_number={gstNumber}
          email={email}
          phone={phone}
          website={website}
          address={address}
          entity_type={entityType}
          onChange={(params) => {
            setAccountNumber(params?.account_number ?? "");
            setAccountName(params?.account_name ?? "");
            setEntityId(params?._id ?? "");
            setName(params?.name ?? "");

            // Entity
            const { entity } = params;
            setName(entity?.name ?? "");
            setTradingName(entity?.trading_name ?? "");
            setLogo(entity?.avatar_url ?? "");
            setEmail(entity?.email ?? "");
            setGstNumber(entity?.gst_number ?? "");
            setLogo(entity?.avatar_url ?? "");
            setAddress(entity?.address ?? "");
            setPhone(entity?.phone ?? "");
            setWebsite(entity?.website ?? "");
            setEntityType(entity?.entity_type ?? "COMPANY");
          }}
        />
      </label>
      <label>
        <span className="text">Code</span>
        <input
          type="text"
          value={code ?? ""}
          onChange={(evt) =>
            setCode(
              evt.target.value.substring(0, 12).replace(/[^0-9a-z -]/gi, "")
            )
          }
        />
      </label>
      <label>
        <span className="text">Reference</span>
        <input
          type="text"
          value={reference ?? ""}
          onChange={(evt) =>
            setReference(
              evt.target.value.substring(0, 12).replace(/[^0-9a-z -]/gi, "")
            )
          }
        />
      </label>
    </>
  );
};
