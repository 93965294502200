import * as React from "react";
import { useNavigate } from "react-router";
import { Loader } from "src/components/Loader";
import "./NewBusiness.css";

import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { dollaUsers } from "../../../../../lambdas/utils-common";
import { BusinessEditor } from "./components/BusinessEditor";
import { Helmet } from "react-helmet";

export const NewBusiness: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const onSubmit = async (business: Partial<dollaUsers.Models.Business>) => {
    setLoading(true);
    const result = await API.post("/businesses", business);
    if (!result.json.success) {
      setLoading(false);
      SessionStore.apiErr(result);
    } else {
      // Go to the new business
      navigate(`/businesses/${result.json.item_id}`);
    }
  };

  // Only need to define nested stuff, arrays and objects
  const initialBusiness = {
    _owners: [],
    contact: {},
    categories: [],
    slugs: [],
    warnings: [],
    accounts: [],
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="NewBusiness">
      <Helmet>
        <title>New Business | Dolla Management Console</title>
      </Helmet>
      <div className="titleRow">
        <h3>New Business</h3>
      </div>
      <BusinessEditor
        business={initialBusiness}
        onSubmit={onSubmit}
        submitTitle="Create"
      />
    </div>
  );
};
