import * as React from "react";
import { Loader } from "src/components/Loader";
import { dollaUsers } from "../../../../../../../lambdas/utils-common";

type EmailHtmlProps = {
  email: dollaUsers.Models.Email & { body: { uri: string } };
};

export const EmailHtml: React.FunctionComponent<EmailHtmlProps> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [html, setHtml] = React.useState<string>();
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

  // Load the email body
  React.useEffect(() => {
    const getBody = async () => {
      setLoading(true);
      const result = await fetch(props.email.body.uri);
      const value = await result.json();
      setLoading(false);
      setHtml(value.html);
    };
    getBody();
  }, [props.email.body.uri]);

  // Update height
  const onIFrameLoad = () => {
    const frame = iframeRef.current;
    if (frame && frame.contentWindow?.document.body.scrollHeight) {
      frame.style.height =
        frame.contentWindow?.document.body.scrollHeight + 20 + "px";
    }
  };

  if (loading || !html) {
    return <Loader />;
  }

  return (
    <div className="page">
      <iframe
        srcDoc={html}
        title="Email Content"
        onLoad={onIFrameLoad}
        ref={iframeRef}
        scrolling="no"
      ></iframe>
    </div>
  );
};
