/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./SearchBase.css";
import { Loader } from "./Loader";

function debounce(callback: (...args: any[]) => void, interval: number) {
  let debounceTimeoutId: number | undefined;

  return function (...args: any[]) {
    clearTimeout(debounceTimeoutId);
    debounceTimeoutId = window.setTimeout(() => {
      callback(...args);
    }, interval);
  };
}

export type SearchProps<Result = any> = {
  /** async function to get some search results */
  query: (queryString: string) => Promise<Result[]>;
  /** function to render the results */
  renderResults?: (
    results: Result[],
    /** The result that is selected by the keyboard. -1 for none */
    selectedIndex: number
  ) => React.ReactElement;
  /** A loading indicator */
  loading?: React.ReactElement;
  /** function called when we make a keyboard selection */
  onSelect?: (item: Result) => void;
  /** The input placeholder */
  placeholder?: string;
  /** Get a reference to the input */
  forwardRef?: React.RefObject<HTMLInputElement>;
  /** Get a reference to the query function */
  queryRef?: React.MutableRefObject<(() => Promise<void>) | undefined>;
  /** How long to debounce (defaults to 200ms) */
  debounceInterval?: number;
  /** If a "clear" button should be shown (default: false) */
  showClear?: boolean;
  /** Autofocus on the input */
  autoFocus?: boolean;
  /** React element to put to the left of the search bar */
  buttons?: React.ReactElement;
  /** React element to put under the search bar */
  bottomComponent?: React.ReactElement;
  /** Only show results when the search input has focus (default: false) */
  resultsRequireFocus?: boolean;
  /** Make a search as soon as the component loads */
  initialSearch?: string;
  /** Use the first search result from an initialSearch */
  takeFirst?: boolean;
  /** Allow empty searches to be made */
  allowEmptySearches?: boolean;
  /** A reference to the clear function */
  clearRef?: React.MutableRefObject<(() => void) | undefined>;
  /** Show the amount of results returned from a search */
  showCount?: boolean;
};

/** Trying to abstract out the boring stuff around search
 *
 * This component is meant to be wrapped by other searchers, like the BigSearch
 * or SmallSearch, so it comes with no styling of it's own.
 */
export const SearchBase: React.FunctionComponent<SearchProps> = (props) => {
  const [queryString, setQueryString] = React.useState(
    props.initialSearch ?? ""
  );
  const [searching, setSearching] = React.useState(false);
  const [results, setResults] = React.useState<any[]>([]);
  const [hasFocus, setHasFocus] = React.useState(false);
  const [isKeyboardSelecting, setIsKeyboardSelecting] = React.useState(false);
  const [kbIndex, setKbIndex] = React.useState(-1);

  // This is a hack so that we can access the most up-to-date queryString from within a callback
  // Just another beautiful product of React Hooks 🙄
  const qsRef = React.useRef("");
  qsRef.current = queryString;

  React.useEffect(() => {
    if (props.initialSearch || props.initialSearch === "") {
      const seed = Math.round(Math.random() * 1000);
      if (!localStorage.getItem("seed")) {
        console.log("setting seed");
        localStorage.setItem("seed", seed.toString());
      }
      queryDebounced(queryString);
    }
    // eslint-disable-next-line
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryString(event.target.value);
    setIsKeyboardSelecting(false);
    setKbIndex(-1);
    if (event.target.value.length || props.allowEmptySearches) {
      queryDebounced(event.target.value);
    } else {
      // Reset
      setSearching(false);
      setResults([]);
    }
  };

  const query = async (qs: string) => {
    // We reference `qsRef` here instead of `queryString` because `queryString`
    // is out of date...
    if (qs !== qsRef.current) {
      // Stale search (because of debounce)
      return;
    }
    setSearching(true);
    const results = (await props.query?.(qs)) || [];
    if (qs !== qsRef.current) {
      // Stale results
      return;
    }

    if (props.initialSearch === qs && props.takeFirst) {
      console.log("TEST");
      props.onSelect?.(results[0]);
      setQueryString("");
    }

    setSearching(false);
    setResults(results);
    setKbIndex(0);
    setIsKeyboardSelecting(false);
  };
  const queryDebounced = debounce(query, props.debounceInterval || 200);

  if (props.queryRef) {
    props.queryRef.current = async (_query?: string) => {
      const q = _query === undefined ? qsRef.current : _query;
      return await query(q);
    };
  }

  const defaultRender: SearchProps["renderResults"] = (
    results,
    selectedIndex
  ) => (
    <>
      {results.map((result, i) => (
        <p key={i}>
          {selectedIndex === i && "*"}
          {JSON.stringify(result)}
        </p>
      ))}
    </>
  );
  const defaultLoading = <Loader />;

  const onFocus = () => {
    setHasFocus(true);
  };
  let mouseDownInResults = false;
  let blurOnMouseUp = false;
  const onBlur = () => {
    if (!mouseDownInResults) {
      setHasFocus(false);
      setIsKeyboardSelecting(false);
    } else {
      blurOnMouseUp = true;
    }
  };
  const onMouseDownInResults = () => {
    mouseDownInResults = true;
  };
  const onMouseUpInResults = () => {
    mouseDownInResults = false;
    if (blurOnMouseUp) {
      window.requestAnimationFrame(() => {
        setHasFocus(false);
      });
    }
  };

  const onKeyPress = (event: React.KeyboardEvent) => {
    // Our key bindings
    // There is one more special case for shift-tab too
    const UPS = ["ArrowUp"];
    const TAB = "Tab";
    const DOWNS = ["ArrowDown", TAB];
    const SELECTS = ["Enter"];
    const CANCELS = ["Escape"];

    if (!results?.length) {
      return;
    }

    const keyCode = event.key;
    if (UPS.find((x) => x === keyCode) || (keyCode === TAB && event.shiftKey)) {
      // Move up an item, looping to the top once we get there
      event.preventDefault();
      let newIdx = kbIndex - 1;
      if (newIdx < 0) {
        newIdx = results.length - 1;
      }
      setKbIndex(newIdx);
      setIsKeyboardSelecting(kbIndex !== 0);
      return;
    }
    if (DOWNS.find((x) => x === keyCode)) {
      // Move down an item, looping to the top once we get there
      event.preventDefault();
      const newIdx = (kbIndex + 1) % results.length;
      setKbIndex(newIdx);
      setIsKeyboardSelecting(true);
      return;
    }
    if (isKeyboardSelecting && SELECTS.find((x) => x === keyCode)) {
      // Select an item
      event.preventDefault();
      const item = results[kbIndex];
      setIsKeyboardSelecting(false);
      if (props.onSelect) {
        props.onSelect(item);
      }
      return;
    }
    if (!isKeyboardSelecting && SELECTS.find((x) => x === keyCode)) {
      // Query again
      queryDebounced(queryString);
      return;
    }
    if (CANCELS.find((x) => x === keyCode)) {
      // Kill the results display
      event.preventDefault();
      setIsKeyboardSelecting(false);
      setKbIndex(-1);
      setResults([]);
      setSearching(false);
      setQueryString("");
      return;
    }
  };

  const clear = () => {
    setQueryString("");
    setResults([]);
    setSearching(false);
  };
  if (props.clearRef) {
    props.clearRef.current = clear;
  }

  const showClear = props.showClear;
  const renderFunc = props.renderResults || defaultRender;
  const loader = props.loading || defaultLoading;
  return (
    <div className="SearchBase">
      <div style={{ display: "flex", gap: "1rem" }}>
        <input
          type="text"
          value={queryString}
          onChange={onChange}
          onKeyDown={onKeyPress}
          onFocus={onFocus}
          onBlur={onBlur}
          autoFocus={props.autoFocus}
          placeholder={props.placeholder || "Search"}
          ref={props.forwardRef}
        />
        {showClear && queryString && <a onClick={clear}>Clear</a>}
        {props.buttons ? props.buttons : <></>}
      </div>
      {props.bottomComponent ? (
        <div style={{ gap: "1rem", paddingBottom: "1.2rem" }}>
          {props.bottomComponent}
        </div>
      ) : (
        <></>
      )}
      {searching ? (
        loader
      ) : results.length &&
        (!props.resultsRequireFocus || hasFocus || isKeyboardSelecting) ? (
        <>
          {props.showCount && (
            <div className="resultAmount">{results.length} results</div>
          )}
          <div
            className="resultWrapper"
            onMouseDown={onMouseDownInResults}
            onMouseUp={onMouseUpInResults}
          >
            {renderFunc(results, isKeyboardSelecting ? kbIndex : -1)}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
