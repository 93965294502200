/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./XeroTenantPage.css";
import moment from "moment";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import type * as RDB from "rdb";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { Inspector } from "../../components/Inspector";
import { Helmet } from "react-helmet";
import { List } from "src/components/List";

export const XeroTenantPage: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _tenant = urlParams.id;
  const [tenant, setTenant] = React.useState<RDB.Models.XeroTenant>();
  const [users, setUsers] = React.useState<RDB.Models.User[]>([]);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  async function syncContacts() {
    const result = await API.post(`/xero/${_tenant}/sync/contacts`);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
  }

  React.useEffect(() => {
    async function getTenant() {
      setLoading(true);
      const result = await API.get(`/xero/${_tenant}`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }

      setTenant(result.json.item);
    }

    async function getUsers() {
      setLoading(true);
      const result = await API.get(`/xero/${_tenant}/users`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }

      setUsers(result.json.items);
    }
    getUsers();
    getTenant();
  }, [_tenant]);

  if (loading || !tenant) {
    return <Loader />;
  }

  return (
    <div className="TenantPage">
      <Helmet>
        <title>{tenant.name} | Dolla Management Console</title>
      </Helmet>
      <h3>
        {tenant.name}
        <Inspector _id={tenant._id} />
      </h3>
      <table>
        <tbody>
          <tr>
            <td>Connected</td>
            <td>{moment(tenant.created_at).fromNow()}</td>
          </tr>
          {/* <tr>
            <td>
              Subscribed{" "}
              <TippyExplainer>
                This tenant has an active Dolla+ subscription
              </TippyExplainer>
            </td>
            <td>
              {tenant._subscription ? (
                <span className="green">
                  <CheckCircle /> Yes
                </span>
              ) : (
                <span className="dark-grey">
                  <span className="grey">
                    <CheckCircle />
                  </span>
                  No
                </span>
              )}
            </td>
          </tr> */}
          <tr>
            <td>Type</td>
            <td>{tenant.organisation_type}</td>
          </tr>
          <tr>
            <td>GST Registered</td>
            <td>{tenant.pays_tax ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>NZBN</td>
            <td>{tenant.registration_number ?? "-"}</td>
          </tr>
          <tr>
            <td>IRD Number</td>
            <td>{tenant.tax_number ?? "-"}</td>
          </tr>
        </tbody>
      </table>
      <div className="buttonRow">
        <button onClick={() => syncContacts()}>Sync Contacts</button>
      </div>
      <div className="linklist vertical">
        <Link to={`/xero/${tenant._id}/accounts`}>
          <AccountBalanceWallet className="icon" />
          Chart of Accounts
        </Link>
        <Link to={`/xero/${tenant._id}/contacts`}>
          <PermIdentityIcon className="icon" />
          Contacts
        </Link>
      </div>
      <div>
        <h3>Users</h3>
        <List
          items={users.map((u) => ({
            title: u.name ?? "",
            logo: u.avatar_url,
            onClick: () => navigate(`/users/${u._id}`),
          }))}
        />
      </div>
    </div>
  );
};
