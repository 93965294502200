import * as React from "react";
import "./NewMgmtUser.css";
import { useNavigate } from "react-router-dom";

import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { Helmet } from "react-helmet";

export const NewMgmtUser: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [dev, setDev] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);

  const navigate = useNavigate();

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    const payload = {
      name,
      username,
      dev,
      admin,
    };
    if (!username) {
      SessionStore.setError("Please enter a username");
      return;
    }
    if (!name) {
      SessionStore.setError("Please enter a name");
      return;
    }

    setLoading(true);
    const result = await API.post("/settings/users", payload);
    if (!result.json.success) {
      setLoading(false);
      SessionStore.apiErr(result);
    } else {
      alert(
        `Created new user ${username}, temporary password is: ${result.json.item.password}`
      );
      navigate("/mgmt/users");
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="NewMgmtUser">
      <Helmet>
        <title>New Management User | Dolla Management Console</title>
      </Helmet>
      <h3>New Management User</h3>
      <form onSubmit={submit}>
        <label>
          Username
          <input
            type="text"
            value={username}
            onChange={(evt) => setUsername(evt.target.value)}
          />
        </label>
        <label>
          Name
          <input
            type="text"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </label>
        <label>
          <input
            type="checkbox"
            checked={admin}
            onChange={(evt) => setAdmin(evt.target.checked)}
            autoComplete="off"
          />
          Make user an admin
        </label>
        <label>
          <input
            type="checkbox"
            checked={dev}
            onChange={(evt) => setDev(evt.target.checked)}
            autoComplete="off"
          />
          Make user a developer
        </label>

        <input type="submit" value="Create" />
      </form>
    </div>
  );
};
