/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./UserTriggers.css";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { orderBy } from "lodash-es";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";
import { Helmet } from "react-helmet";
import moment from "moment";
import numeral from "numeral";

export const UserTriggers: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [triggers, setTriggers] = React.useState<dollaUsers.Models.Trigger[]>(
    []
  );
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingTriggers, setLoadingTriggers] = React.useState(false);

  React.useEffect(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    getUser();
  }, [_user]);

  const getTriggers = React.useCallback(() => {
    async function getTriggers() {
      setLoadingTriggers(true);
      const result = await API.get(`/users/${_user}/triggers`);
      setLoadingTriggers(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setTriggers(result.json.items);
    }
    getTriggers();
  }, [_user]);
  React.useEffect(() => {
    getTriggers();
  }, [getTriggers]);

  if (loadingUser || loadingTriggers || !user) {
    return <Loader />;
  }

  return (
    <div className="UserTriggers">
      <Helmet>
        <title>{user.name}'s Triggers | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name} Triggers</h3>
      {!triggers.length ? (
        <p>No triggers</p>
      ) : (
        <List
          items={orderBy(triggers, "created_at", "desc").map((trigger) => ({
            title: (
              <span>
                {trigger.type} Trigger:{" "}
                {numeral(trigger.amount).format("$0,000.00")}
                <Inspector _user={user._id} _id={trigger._id} />
              </span>
            ),
            subtitle:
              trigger.status === "PENDING" &&
              (trigger.type === "MIN" || trigger.type === "MAX") &&
              trigger?.eta
                ? `ETA ${moment(trigger?.eta).fromNow()}. Created ${moment(
                    trigger.created_at
                  ).fromNow(true)} ago`
                : `Completed ${moment(trigger.updated_at).fromNow(true)} ago.`,
            onClick: () =>
              navigate(`/universal/${trigger._id}?_user=${user._id}`),
            key: trigger._id,
            rightElement: <ArrowForward fontSize="inherit" />,
          }))}
        />
      )}
    </div>
  );
};
