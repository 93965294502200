/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./EntityDetailEditList.css";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import AddCircle from "@mui/icons-material/AddCircle";
import { TippyExplainer } from "../../../components/TippyExplainer";
import { Entity } from "rdb/src/models";

type EntityDetailEditListProps = {
  entity?: Partial<Entity>;
  onChange: (path: string, value: any) => void;
};
export const EntityDetailEditList: React.FunctionComponent<EntityDetailEditListProps> =
  (props) => {
    const { entity } = props;

    const onChange = async (path: string, value: any) => {
      props.onChange(path, value);
    };

    const addField = async (type: string) => {
      let temp;
      let path;

      if (type === "PREVIOUS_NAMES") {
        path = "previous_names";
        temp = [...(entity?.previous_names ?? []), ""];
      }

      if (type === "GST_NUMBERS") {
        path = "gst_numbers";
        temp = [...(entity?.gst_numbers ?? []), ""];
      }

      if (type === "EMAIL") {
        path = "email_addresses";
        temp = [...(entity?.email_addresses ?? []), ""];
      }

      if (type === "PHONE") {
        path = "phone_numbers";
        temp = [...(entity?.phone_numbers ?? []), ""];
      }

      if (type === "WEBSITE") {
        path = "websites";
        temp = [...(entity?.websites ?? []), ""];
      }

      if (type === "ADDRESS") {
        path = "addresses";
        temp = [...(entity?.addresses ?? []), ""];
      }

      if (!path) {
        return;
      }

      props.onChange(path, temp);
    };

    const removeField = (index: number, type: string) => {
      let temp;
      let path;

      if (type === "PREVIOUS_NAMES" && entity?.previous_names?.length) {
        path = "email_addresses";
        entity.previous_names.splice(index, 1);
        temp = entity.previous_names;
      }

      if (type === "GST_NUMBERS" && entity?.gst_numbers?.length) {
        path = "gst_numbers";
        entity.gst_numbers.splice(index, 1);
        temp = entity.gst_numbers;
      }

      if (type === "EMAIL" && entity?.email_addresses?.length) {
        path = "email_addresses";
        entity.email_addresses.splice(index, 1);
        temp = entity.email_addresses;
      }

      if (type === "PHONE" && entity?.phone_numbers?.length) {
        path = "phone_numbers";
        entity.phone_numbers.splice(index, 1);
        temp = entity.phone_numbers;
      }

      if (type === "WEBSITE" && entity?.websites?.length) {
        path = "websites";
        entity.websites.splice(index, 1);
        temp = entity.websites;
      }
      if (type === "ADDRESS" && entity?.addresses?.length) {
        path = "addresses";
        entity.addresses.splice(index, 1);
        temp = entity.addresses;
      }

      if (!path || !temp) {
        return;
      }

      props.onChange(path, temp);
    };

    return (
      <div className="EntityDetailEditList">
        <div className="ContactDetails">
          <span>
            Contact Details (all optional){" "}
            <TippyExplainer>
              Used to fill out a profile page for this entity
            </TippyExplainer>
          </span>
          <label>
            <span>Previous names</span>
            {entity?.previous_names && entity.previous_names?.length > 1 ? (
              <div className="AddressList">
                {entity.previous_names.map((x, i) => (
                  <div className="field">
                    <input
                      placeholder="Frodo's Burgers"
                      value={x ?? ""}
                      onChange={(evt) =>
                        onChange(`previous_names[${i}]`, evt.target.value)
                      }
                    />
                    <a onClick={() => removeField(i, "PREVIOUS_NAMES")}>
                      <RemoveCircle />
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <input
                placeholder="Frodo's Burgers"
                value={entity?.previous_names?.[0] ?? ""}
                onChange={(evt) =>
                  onChange(`previous_names[0]`, evt.target.value)
                }
              />
            )}
          </label>
          <a className="addField" onClick={() => addField("PREVIOUS_NAMES")}>
            <AddCircle /> Add previous name
          </a>

          <label>
            <span>GST Numbers</span>
            {entity?.gst_numbers && entity.gst_numbers?.length > 1 ? (
              <div className="AddressList">
                {entity.gst_numbers.map((x, i) => (
                  <div className="field">
                    <input
                      placeholder="111-111-111"
                      value={x ?? ""}
                      onChange={(evt) =>
                        onChange(`gst_numbers[${i}]`, evt.target.value)
                      }
                    />
                    <a onClick={() => removeField(i, "GST_NUMBERS")}>
                      <RemoveCircle />
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <input
                placeholder="111-111-111"
                value={entity?.gst_numbers?.[0] ?? ""}
                onChange={(evt) => onChange(`gst_numbers[0]`, evt.target.value)}
              />
            )}
          </label>
          <a className="addField" onClick={() => addField("GST_NUMBERS")}>
            <AddCircle /> Add GST number
          </a>

          <label>
            <span>Email</span>
            {entity?.email_addresses && entity.email_addresses?.length > 1 ? (
              <div className="AddressList">
                {entity.email_addresses.map((x, i) => (
                  <div className="field">
                    <input
                      type="email"
                      placeholder="frodo@frodosfish.co.nz"
                      value={x ?? ""}
                      onChange={(evt) =>
                        onChange(`email_addresses[${i}]`, evt.target.value)
                      }
                    />
                    <a onClick={() => removeField(i, "EMAIL")}>
                      <RemoveCircle />
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <input
                type="email"
                placeholder="frodo@frodosfish.co.nz"
                value={entity?.email_addresses?.[0] ?? ""}
                onChange={(evt) =>
                  onChange(`email_addresses[0]`, evt.target.value)
                }
              />
            )}
          </label>
          <a className="addField" onClick={() => addField("EMAIL")}>
            <AddCircle /> Add email
          </a>

          <label>
            <span>Phone</span>
            {entity?.phone_numbers && entity.phone_numbers?.length > 1 ? (
              <div className="AddressList">
                {entity.phone_numbers.map((x, i) => (
                  <div className="field">
                    <input
                      type="text"
                      placeholder="021 123 4567"
                      value={x ?? ""}
                      onChange={(evt) =>
                        onChange(`phone_numbers[${i}]`, evt.target.value)
                      }
                    />
                    <a onClick={() => removeField(i, "PHONE")}>
                      <RemoveCircle />
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <input
                type="text"
                placeholder="021 123 4567"
                value={entity?.phone_numbers?.[0] ?? ""}
                onChange={(evt) =>
                  onChange(`phone_numbers[0]`, evt.target.value)
                }
              />
            )}
          </label>
          <a className="addField" onClick={() => addField("PHONE")}>
            <AddCircle /> Add phone number
          </a>
          <label>
            <span>Website</span>
            {entity?.websites && entity.websites?.length > 1 ? (
              <div className="AddressList">
                {entity.websites.map((x, i) => (
                  <div className="field">
                    <input
                      type="text"
                      placeholder="www.frodosfish.co.nz"
                      value={x ?? ""}
                      onChange={(evt) =>
                        onChange(`websites[${i}]`, evt.target.value)
                      }
                    />
                    <a onClick={() => removeField(i, "WEBSITE")}>
                      <RemoveCircle />
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <input
                type="text"
                placeholder="www.frodosfish.co.nz"
                value={entity?.websites?.[0] ?? ""}
                onChange={(evt) => onChange(`websites[0]`, evt.target.value)}
              />
            )}
          </label>
          <a className="addField" onClick={() => addField("WEBSITE")}>
            <AddCircle /> Add website
          </a>
          <label>
            <span>Addresses</span>
            {entity?.addresses && entity.addresses?.length > 1 ? (
              <div className="AddressList">
                {entity.addresses.map((x, i) => (
                  <div className="field">
                    <input
                      type="text"
                      placeholder="PwC Tower, Level 38/15 Customs St W"
                      value={x ?? ""}
                      onChange={(evt) =>
                        onChange(`addresses[${i}]`, evt.target.value)
                      }
                    />
                    <a onClick={() => removeField(i, "ADDRESS")}>
                      <RemoveCircle />
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <input
                type="text"
                placeholder="PwC Tower, Level 38/15 Customs St W"
                value={entity?.addresses?.[0] ?? ""}
                onChange={(evt) => onChange("addresses[0]", evt.target.value)}
              />
            )}
          </label>
          <a className="addField" onClick={() => addField("ADDRESS")}>
            <AddCircle /> Add address
          </a>
        </div>
      </div>
    );
  };
