/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import moment from "moment";
import numeral from "numeral";
import "./PaymentRequiringVerificationRow.css";

import { dollaUsers } from "../../../../../../lambdas/utils-common";
import { Arrow } from "./Arrow";
import { Inspector } from "../../../components/Inspector";
import API from "../../../utils/api";
import { SessionStore } from "../../../utils/session";
import { DangerModal } from "../../../components/DangerModal";

const isBankAccount = (item: any): item is dollaUsers.Models.BankAccount =>
  item._id.startsWith("bank_acc_");

type Payee = {
  avatar_url: string;
  name?: string;
  amount: number;
  _id: string;
};

type PaymentRowProps = {
  payment: dollaUsers.Models.PendingPayment;
  /** A bunch of users, including those involved in this payment */
  participants: (
    | dollaUsers.Models.User
    | dollaUsers.Models.Business
    | dollaUsers.Models.BankAccount
  )[];
  /** A callback to update this payment */
  refreshPayment: (_user: string, _pmt: string) => Promise<void>;
};

export const PaymentRequiringVerificationRow: React.FunctionComponent<PaymentRowProps> =
  (props) => {
    const dangerModal = React.useRef<any>();

    const { payment, participants } = props;
    const payer = (participants.find((part) => part._id === payment._user) ?? {
      avatar_url: "https://static.dolla.nz/images/single.png",
      name: "???",
    }) as { name: string; avatar_url: string };
    const _payee =
      payment.payment_parameters.to.type === "PERSONAL"
        ? payment.payment_parameters.to._user
        : payment.payment_parameters.to.type === "BUSINESS"
        ? payment.payment_parameters.to._business
        : payment.payment_parameters.to.type === "BANK_ACCOUNT"
        ? payment.payment_parameters.to._bank_account
        : "";
    const participant = participants.find((x) => x._id === _payee);
    const payee: Payee = participant
      ? isBankAccount(participant)
        ? {
            _id: participant._id,
            avatar_url: "https://static.dolla.nz/images/bank.png",
            name: participant.name ?? participant.account_number,
            amount: payment.payment_parameters.amount,
          }
        : {
            _id: participant._id,
            avatar_url: participant.avatar_url,
            name: participant.name,
            amount: payment.payment_parameters.amount,
          }
      : {
          // If we don't know this payee, return a blank with lots of ???s
          _id: Math.random().toString(),
          avatar_url: "https://static.dolla.nz/images/single.png",
          name: "???",
          amount: payment.payment_parameters.amount,
        };
    const overdue = payment.pay_at && payment.pay_at < new Date().toISOString();

    const authorisePayment = async () => {
      const onSubmit = async () => {
        const result = await API.put(
          `/users/${payment._user}/payments/pending/${payment._id}/verify`
        );
        if (!result.json?.success) {
          SessionStore.apiErr(result);
          return;
        }
        // Get the new, updated payment
        await props.refreshPayment(payment._user, payment._id);
      };

      dangerModal.current.open(
        `Are you sure you're allowed to do this?`,
        [
          <>
            This will authorise a payment from <b>{payer.name}'s</b> account.
            You should only do this in exceptional circumstances.
          </>,
          <>
            Make sure you <b>know</b> that they want this payment to be
            processed.
          </>,
        ],
        onSubmit
      );
    };

    const cancelPayment = async () => {
      const onSubmit = async () => {
        const result = await API.put(
          `/users/${payment._user}/payments/pending/${payment._id}/cancel`
        );
        if (!result.json?.success) {
          SessionStore.apiErr(result);
          return;
        }
        // Get the new, updated payment
        await props.refreshPayment(payment._user, payment._id);
      };

      dangerModal.current.open(
        `Are you sure you want to do this?`,
        [
          <>
            This will <b>cancel {payer.name}'s payment</b>. You should only do
            this in exceptional circumstances.
          </>,
          <>
            Make sure you <b>know</b> that they don't want this payment to be
            processed before you do this.
          </>,
        ],
        onSubmit
      );
    };

    return (
      <div className="PaymentRequiringVerificationRow">
        <div className="titleRow">
          <div
            className="time"
            title={`${moment(payment.created_at).fromNow()}`}
          >
            {moment(payment.created_at).format("h:mma")}
          </div>
          <div className="payer">
            <Inspector _id={payment._id} _user={payment._user} />
            <a href={`/users/${payment._user}`}>
              <img
                className="avatar"
                src={payer.avatar_url}
                alt={`${payer.name}'s avatar`}
              />
            </a>
            {payer.name}
          </div>
          <div className="payee">
            <span className="arrow">
              <Arrow>
                <span style={{ fontSize: "80%" }}>
                  {numeral(payee.amount).format("$0,00.00")}
                </span>
              </Arrow>
            </span>
            <a
              href={`/${
                payee._id.startsWith("user")
                  ? "users"
                  : payee._id.startsWith("business")
                  ? "businesses"
                  : "universal"
              }/${payee._id}${
                payee._id.startsWith("bank_acc") ? "?_user=UNASSIGNED" : ""
              }`}
            >
              <img
                className="avatar"
                src={payee.avatar_url}
                alt={`${payee.name}'s avatar`}
              />
            </a>
            {payee.name || "???"}
            <span className={`status ${overdue ? "red" : "yellow"}`}>
              {payment.status || "???"}
            </span>
          </div>
        </div>
        <div className="subtitleRow">
          <div className="info">
            {overdue
              ? `This payment is overdue by ${moment(payment.pay_at).fromNow(
                  true
                )}. Once authorised it will be paid immediately.`
              : `Once authorised, will be paid ${
                  payment.pay_at
                    ? moment(payment.pay_at).fromNow()
                    : "immediately"
                }.`}
          </div>
          <div className="actions">
            <a onClick={() => authorisePayment()}>[ Authorise ]</a>
            <a onClick={() => cancelPayment()}>[ Cancel ]</a>
          </div>
        </div>
        <DangerModal openRef={dangerModal} />
      </div>
    );
  };
