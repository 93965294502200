/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { Inspector } from "../../components/Inspector";
import { Helmet } from "react-helmet";
import moment from "moment";
import numeral from "numeral";
import { orderBy } from "lodash";
import { List } from "src/components/List";

export const BusinessSubscriptionPlan: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _biz = urlParams.id;
  const _plan = urlParams.plan;
  const [biz, setBiz] = React.useState<dollaUsers.Models.Business>();
  const [plan, setPlan] = React.useState<dollaUsers.Models.SubscriptionPlan>();
  const [subs, setSubs] = React.useState<dollaUsers.Models.Subscription[]>([]);
  const [loadingBiz, setLoadingBiz] = React.useState(false);
  const [loadingPlan, setLoadingPlan] = React.useState(false);
  const [loadingSubs, setLoadingSubs] = React.useState(false);

  React.useEffect(() => {
    async function getBiz() {
      setLoadingBiz(true);
      const result = await API.get(`/businesses/${_biz}`);
      setLoadingBiz(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBiz(result.json.item);
    }
    getBiz();
  }, [_biz]);

  const getPlan = React.useCallback(() => {
    async function getPlan() {
      setLoadingPlan(true);
      const result = await API.get(`/users/${_biz}/subscription-plans`);
      setLoadingPlan(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }

      setPlan(result.json.items.find((x: { _id: string | undefined; }) => x._id === _plan));
    }
    getPlan();
  }, [_biz, _plan]);

  const getSubs = React.useCallback(() => {
    async function getSubs() {
      setLoadingSubs(true);
      const result = await API.get(`/users/${_biz}/subscription-plans/${_plan}/subscriptions`);
      setLoadingSubs(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setSubs(result.json.items);
    }
    getSubs();
  }, [_biz, _plan]);
  React.useEffect(() => {
    getPlan();
    getSubs();
  }, [getPlan, getSubs]);


  if (loadingBiz || loadingPlan || loadingSubs || !biz || !plan) {
    return <Loader />;
  }

  return (
    <div className="BusinessSubscriptionPlan">
      <Helmet>
        <title>{biz.name} subscription plan | Dolla Management Console</title>
      </Helmet>
      <Link to={`/businesses/${biz._id}/subscription-plans`} className="backlink">
        ← Back to {biz.name} subscription plans
      </Link>
      <h3>
        {biz.name} Subscription <Inspector _user={_biz} _id={plan._id} />{" "}
      </h3>
      <table className="prettyDetails">
        <tr>
          <td>Name</td>
          <td>
             {plan.name}
          </td>
        </tr>
        <tr>
          <td>Amount</td>
          <td>
              {numeral(plan.amount).format("$0,000.00")}
          </td>
        </tr>
        <tr>
          <td>Frequency</td>
          <td>
              {`Every ${
              plan?.period?.frequency === 1
                ? plan?.period?.interval?.slice(0, -1)
                : `${plan?.period?.frequency} ${plan?.period?.interval}`}`}
          </td>
        </tr>
        <tr>
          <td>Type</td>
          <td>
             {plan.type}
          </td>
        </tr>
        <tr>
          <td>Billing Type</td>
          <td>
             {plan.billing_type}
          </td>
        </tr>

        <tr>
          <td>Free Trial</td>
          <td>
              {plan.free_trial ? `${
              plan?.free_trial?.n === 1
                ? `1 ${plan?.free_trial?.chunk?.slice(0, -1)}`
                : `${plan?.free_trial?.n} ${plan?.free_trial?.chunk}`}` : "No"}
          </td>
        </tr>
        
        <tr>
          <td>Created</td>
          <td title={moment(plan.created_at).format()}>
            {moment(plan.created_at).fromNow()}
          </td>
        </tr>
        <tr>
          <td>Updated</td>
          <td title={moment(plan.updated_at).format()}>
            {moment(plan.updated_at).fromNow()}
          </td>
        </tr>
      </table>
      {!subs.length ? (
        <p>No Subscriptions</p>
      ) : (
        <List
          items={orderBy(subs, ["subscription_status", "next_attempt_at"], ["desc", "desc"]).map((sub) => ({
            title: (
              <span>
                Subscription: {sub._user} | {sub._id}
                <Inspector _user={sub._user} _id={sub._id} />
              </span>
            ),
            subtitle:
            sub.billing_status === "CANCELLED" || sub.cancel_at_period_end
            ? "Cancelled"
            : `${sub.amount} Next due on ${
                sub.billing_status === "PENDING_PAYMENT"
                  ? moment(sub.due_at)
                      .add(plan.period.frequency, plan.period.interval)
                      .format("ddd DD MMM YYYY")
                  : moment(sub.due_at).format("ddd DD MMM YYYY")
              }`,
              key: sub._id,
              onClick: () =>
              navigate(`/users/${sub._user}/subscriptions`),
          }))}
        />
      )}
    </div>
  );
};
