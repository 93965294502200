/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./UserPaymentLinks.css";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { orderBy } from "lodash-es";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";
import { Helmet } from "react-helmet";
import moment from "moment";
import numeral from "numeral";

export const UserPaymentLinks: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [links, setLinks] = React.useState<dollaUsers.Models.PaymentLink[]>([]);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingLinks, setLoadingLinks] = React.useState(false);

  React.useEffect(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    getUser();
  }, [_user]);

  const getLinks = React.useCallback(() => {
    async function getLinks() {
      setLoadingLinks(true);
      const result = await API.get(`/users/${_user}/payment-links`);
      setLoadingLinks(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setLinks(result.json.items);
    }
    getLinks();
  }, [_user]);
  React.useEffect(() => {
    getLinks();
  }, [getLinks]);

  if (loadingUser || loadingLinks || !user) {
    return <Loader />;
  }

  return (
    <div className="UserPaymentLinks">
      <Helmet>
        <title>{user.name}'s Payment Links | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name} Payment Links</h3>
      {!links.length ? (
        <p>No links</p>
      ) : (
        <List
          items={orderBy(links, "created_at", "desc").map((link) => ({
            title: (
              <span>
                Payment Link: {numeral(link.amount).format("$0,000.00")}
                <Inspector _user={user._id} _id={link._id} />
              </span>
            ),
            subtitle:
              link.status === "ACTIVE"
                ? `Created ${moment(
                    link.created_at
                  ).fromNow()}. Valid for ${moment(link.expires_at).fromNow(
                    true
                  )}. ${link.counter_payments} of ${
                    link.max_uses ?? "infinite"
                  } uses.`
                : link.status,
            onClick: () =>
              navigate(`/users/${user._id}/payment-links/${link._id}`),
            key: link._id,
            rightElement: <ArrowForward fontSize="inherit" />,
          }))}
        />
      )}
    </div>
  );
};
