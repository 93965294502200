import * as React from "react";
import "./ScriptViewModal.css";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { managementDB } from "../../../../../../lambdas/utils-common";

type ScriptViewModalArgs = {
  script: managementDB.Models.Script;
  user: managementDB.Models.User;
};

type ScriptViewModalProps = {
  /** A way to expose the `open` method to parents */
  openRef: React.MutableRefObject<any>;
};

export const ScriptViewModal: React.FunctionComponent<ScriptViewModalProps> = (
  props
) => {
  const [open, setOpen] = React.useState(false);
  const [args, setArgs] = React.useState<ScriptViewModalArgs>();

  const navigate = useNavigate();

  const openModal = (
    script: managementDB.Models.Script,
    user: managementDB.Models.User
  ) => {
    setArgs({ script, user });
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setArgs(undefined);
  };
  const selectScript = () => {
    if (!args) {
      return;
    }
    const { script } = args;
    window.sessionStorage.setItem(
      "management-dolla-nz-sandbox-value",
      script.code
    );
    window.sessionStorage.setItem(
      "management-dolla-nz-sandbox-script",
      JSON.stringify(script)
    );
    navigate("/sandbox");
  };

  // Pass a reference to the `openModal` to parents
  if (props.openRef) {
    props.openRef.current = { open: openModal };
  }

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      overlayClassName="ScriptViewModal modal_overlay"
      className="ScriptViewModal modal"
      ariaHideApp={false}
    >
      {!args ? (
        <></>
      ) : (
        <>
          <h4>
            {args.user.username}&nbsp;›&nbsp;
            <span className="bold">{args.script.name}</span>
          </h4>
          <span
            style={{
              color: "rgb(145, 145, 145)",
              fontSize: "80%",
            }}
          >
            Last updated {moment(args.script.updated_at).fromNow()}
          </span>
          <p>{args.script.description}</p>
          <div className="button_row">
            <button onClick={selectScript}>Select</button>
            <button onClick={closeModal}>Cancel</button>
          </div>
        </>
      )}
    </ReactModal>
  );
};
