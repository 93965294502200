import * as React from "react";
import "./ChatMessages.css";
import { orderBy } from "lodash-es";
import moment from "moment";

import ScrollToBottom from "react-scroll-to-bottom";

import { dollaUsers } from "../../../../../../lambdas/utils-common";

type ChatMessagesProps = {
  otherUser: dollaUsers.Models.User;
  messages: dollaUsers.Models.Message[];
  _actor: string;
};

export const ChatMessages: React.FunctionComponent<ChatMessagesProps> = (
  props
) => {
  return (
    <ScrollToBottom
      className="ChatMessagesRoot"
      scrollViewClassName="ChatMessages"
      followButtonClassName="Hidden"
    >
      {orderBy(props.messages, "created_at").map((message) => (
        <div
          key={message._id}
          className={`message ${
            message.created_by === props._actor ? "me" : "otherUser"
          }`}
          title={`Sent ${moment(message.created_at).fromNow()} (${moment(
            message.created_at
          ).format("DD MMM H:mm:a")})`}
        >
          {message.text}
        </div>
      ))}
    </ScrollToBottom>
  );
};
