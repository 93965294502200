/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./EmailForm.css";

import { dollaUsers } from "utils-common";
import * as RDB from "rdb";
import { AssignFormV2 } from "./AssignEmailFormV2";
import { PaymentFormV2 } from "./NewPaymentFormV2";
import { SessionStore } from "src/utils/session";
import API from "src/utils/api";
import { DangerModal } from "src/components/DangerModal";

export type EmailFormV2Props = {
  email: RDB.Models.Email;
  user: dollaUsers.Models.User;
  similarPayments?: RDB.Models.Payment[];
  checkRef: React.MutableRefObject<any | undefined>;
  reload: () => void;
};
export const EmailFormV2: React.FunctionComponent<EmailFormV2Props> = (
  props
) => {
  const { email } = props;

  const DEFAULT_PARSER = "DollaGPT";
  const suggestions = email.suggestions.filter((x) => !!x.parser);

  const [ignoreSuggestions, setIgnoreSuggestions] = React.useState(false);
  const [suggestion, setSuggestion] = React.useState(
    suggestions?.find((x) => x.parser === DEFAULT_PARSER) ?? suggestions?.[0]
  );

  const dangerModal = React.useRef<any>();

  const handleParser = React.useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      if (!suggestions?.length) {
        return;
      }

      const sug = suggestions.find((x) => x.parser === evt.target.value);

      if (!sug) {
        return;
      }

      setSuggestion(sug);
    },
    [suggestions]
  );

  const duplicateEmail = async () => {
    const result = await API.post(
      `/users/${props.user._id}/emails/${email._id}/duplicate`
    );

    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }

    const _duplicate = result.json.item_id;

    const uri = `/users/${props.user._id}/emails/${_duplicate}`;

    window.open(uri, "_blank");
  };

  if (email._user === null) {
    return <AssignFormV2 {...props} />;
  }

  if (suggestions.length && suggestion && !ignoreSuggestions) {
    /** Prefil the payment form with a suggestion and a "Clear" button */
    return (
      <div className="EmailForm">
        <button onClick={() => duplicateEmail()}>Duplicate Email</button>
        <h4>
          Suggestions:
          <select value={suggestion.parser} onChange={handleParser}>
            {suggestions?.map((x) => (
              <option value={x.parser}>{x?.parser}</option>
            ))}
          </select>
        </h4>
        <PaymentFormV2 {...props} prefill={suggestion} checkRef={dangerModal} />
        <button onClick={() => setIgnoreSuggestions(true)}>
          Clear Suggestion
        </button>
        <DangerModal openRef={dangerModal} />
      </div>
    );
  }
  return (
    <div className="EmailForm">
      <button onClick={() => duplicateEmail()}>Duplicate Email</button>
      <h4>Create Suggestion:</h4>
      <PaymentFormV2 {...props} checkRef={dangerModal} />
      <DangerModal openRef={dangerModal} />
    </div>
  );
};
