/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./UserChats.css";
import ArrowForward from "@mui/icons-material/ArrowForward";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArchiveIcon from "@mui/icons-material/Archive";
import { orderBy } from "lodash-es";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { DangerModal } from "../../components/DangerModal";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";
import { ChatAvatar } from "../../components/ChatAvatar";
import { Helmet } from "react-helmet";

const DOLLA_ID = "business_cl08jr6cd000009mu35sebxc7";
const DOLLASTORE_ID = "business_cl6egeu35000109l691uh1szy";

export const UserChats: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [chats, setChats] = React.useState<dollaUsers.Models.Chat[]>([]);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingChats, setLoadingChats] = React.useState(false);

  const dangerModal = React.useRef<any>();

  React.useEffect(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    getUser();
  }, [_user]);

  const getChats = React.useCallback(() => {
    async function getChats() {
      setLoadingChats(true);
      const result = await API.get(`/users/${_user}/chats?status=ALL`);
      setLoadingChats(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setChats(result.json.items);
    }
    getChats();
  }, [_user]);
  React.useEffect(() => {
    getChats();
  }, [getChats]);

  const handleArchive = React.useCallback(
    async (chat: dollaUsers.Models.Chat) => {
      if (!user) {
        return;
      }
      if (!chat) {
        return;
      }

      const onSubmit = async () => {
        const result = await API.put(
          `/users/${_user}/chats/${chat._id}/archive`
        );
        if (!result.json.success) {
          SessionStore.apiErr(result);
        }
        getChats();
      };

      dangerModal.current.open(
        `Archive chat`,
        [
          <>
            This will archive <b>{user.name}'s</b> chat mirror{" "}
            <b>{chat?.title}</b>.
          </>,
        ],
        onSubmit
      );
    },
    [user, _user, getChats]
  );
  const handleDelete = React.useCallback(
    async (chat: dollaUsers.Models.Chat) => {
      if (!user) {
        return;
      }
      if (!chat) {
        return;
      }

      const onSubmit = async () => {
        const result = await API.delete(
          `/users/${_user}/chats/${chat._id}/delete`
        );
        if (!result.json.success) {
          SessionStore.apiErr(result);
        }
        getChats();
      };

      dangerModal.current.open(
        `Delete chat`,
        [
          <>
            This will delete chat <b>{chat.title}</b>.
          </>,
          <>All chat mirrors and messages will be deleted.</>,
        ],
        onSubmit
      );
    },
    [user, _user, getChats]
  );

  if (loadingUser || loadingChats || !user) {
    return <Loader />;
  }

  return (
    <div className="UserChats">
      <Helmet>
        <title>{user.name}'s Chats | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name}'s Chats</h3>
      {!chats.length ? (
        <p>No chats</p>
      ) : (
        <List
          items={orderBy(chats, "last_message_at", "desc").map((chat) => {
            const hasDollaUser = chat.participants.find((p) =>
              [DOLLA_ID, DOLLASTORE_ID].includes(p._id)
            );
            return {
              title: (
                <span>
                  ${user.name} ➞ ${chat.title}{" "}
                  <Inspector _id={chat._id} _user={_user} />
                </span>
              ),
              subtitle:
                chat.status === "ACTIVE"
                  ? `${chat.message_count} message${
                      chat.message_count === 1 ? "" : "s"
                    } (${chat.unread_count} unread)`
                  : chat.status,
              logo: <ChatAvatar chat={chat} />,
              onClick: hasDollaUser
                ? () => navigate(`/chats/${chat._id}`)
                : undefined,
              key: chat._id,
              rightElementClickable: !!hasDollaUser,
              rightElement: (
                <div className="RowIcons">
                  <a onClick={() => handleArchive(chat)} title="Archive">
                    <ArchiveIcon fontSize="inherit" />
                  </a>
                  <a onClick={() => handleDelete(chat)} title="Delete">
                    <DeleteForeverIcon fontSize="inherit" />
                  </a>
                  {hasDollaUser ? (
                    <a
                      onClick={() => navigate(`/chats/${chat._id}`)}
                      title="Open chat"
                    >
                      <ArrowForward fontSize="inherit" />
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              ),
              highlight: !!hasDollaUser,
            };
          })}
        />
      )}
      <DangerModal openRef={dangerModal} />
    </div>
  );
};
