/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

import { dollaUsers } from "../../../../../../../lambdas/utils-common";
import { BusinessSelector } from "../../../../components/BusinessSelector";
import { getFormattedPhoneNumber } from "../../../../utils/phonenumbers";
import { TippyExplainer } from "src/components/TippyExplainer";
import { RecipientFormProps } from "./NewPaymentForm";

// It would be nice if we could reference this from the model somewhere...
type BusinessRecipient = {
  type: "BUSINESS";
  _business: string;
  _account: string;
  meta: {
    code?: string;
    reference?: string;
  };
};

/** A form to select a business and fill in required metadata */
export const BusinessRecipientForm: React.FunctionComponent<
  RecipientFormProps<BusinessRecipient>
> = (props) => {
  const { prefill, user, onChange } = props;
  const [business, setCurrentBusiness] = React.useState<
    dollaUsers.Models.Business | undefined
  >();
  const [_to, setTo] = React.useState(
    prefill?.to?.type === "BUSINESS" ? prefill.to._business : undefined
  );
  const [_to_account, setToAccount] = React.useState(
    prefill?.to?.type === "BUSINESS" ? prefill.to._account : undefined
  );
  const [code, setCode] = React.useState(
    (prefill?.to?.type === "BUSINESS"
      ? prefill?.to?.meta?.code?.substring(0, 12).replace(/[^0-9a-z -]/gi, "")
      : "") || ""
  );
  const [reference, setReference] = React.useState(
    (prefill?.to?.type === "BUSINESS"
      ? prefill?.to?.meta?.reference
          ?.substring(0, 12)
          .replace(/[^0-9a-z -]/gi, "")
      : "") || ""
  );
  const currentBusinessAccount = business?.accounts.find(
    (acc) => acc._id === _to_account
  );

  /**
   * Validate the parameters to pay a business
   *
   * Returns null if there are no errors, otherwise an error string.
   */
  props.validationRef.current = (): string | null => {
    if (!business || !_to) {
      return "Please pick someone to pay";
    }
    if (!_to_account) {
      return "Please pick an account to pay";
    }
    const currentBusinessAccount = business.accounts.find(
      (acc) => acc._id === _to_account
    );
    if (!currentBusinessAccount) {
      return "Invalid account to pay from";
    }
    if (currentBusinessAccount?.meta.code?.required && !code?.trim()) {
      return "Missing required 'code' field";
    }
    if (currentBusinessAccount?.meta.reference?.required && !reference.trim()) {
      return "Missing required 'reference' field";
    }
    if (
      currentBusinessAccount?.meta.code?.validation &&
      !new RegExp(currentBusinessAccount.meta.code.validation).test(code)
    ) {
      return `Invalid 'code' field (${currentBusinessAccount.meta.code.validation})`;
    }
    if (
      currentBusinessAccount?.meta.reference?.validation &&
      !new RegExp(currentBusinessAccount.meta.reference.validation).test(
        reference
      )
    ) {
      return `Invalid 'reference' field (${currentBusinessAccount.meta.reference.validation})`;
    }
    return null;
  };

  // Whenever the parameters change, call props.onChange
  React.useEffect(() => {
    onChange({
      type: "BUSINESS",
      _business: _to,
      _account: _to_account,
      meta: {
        code,
        reference,
      },
    });
  }, [onChange, _to, _to_account, code, reference]);

  // Insert the defaults when a business account is selected
  React.useEffect(() => {
    if (currentBusinessAccount && !code.length && !reference.length) {
      // Figure out the placeholders
      const NAME = user.name ?? "";
      const FNAME = user.first_name ?? "";
      const LNAME = user.last_name ?? "";
      const FINITIAL =
        user.first_name
          ?.split(/\W/)
          .map((x) => x[0].toUpperCase())
          .join(" ") ?? "";
      const LINITIAL =
        user.last_name
          ?.split(/\W/)
          .map((x) => x[0].toUpperCase())
          .join(" ") ?? "";
      const INITIALS = `${FINITIAL} ${LINITIAL}`.trim();
      const phoneFormats = getFormattedPhoneNumber(user.mobile);
      const MOBILE = phoneFormats.success ? phoneFormats.result.national : "";
      const MOBILE_INT = phoneFormats.success
        ? phoneFormats.result.international
        : "";
      const TAG = user.tag ?? "";
      const replacePlaceholders = (str: string) =>
        str
          .replace("{NAME}", NAME)
          .replace("{FNAME}", FNAME)
          .replace("{LNAME}", LNAME)
          .replace("{FINITIAL}", FINITIAL)
          .replace("{LINITIAL}", LINITIAL)
          .replace("{INITIALS}", INITIALS)
          .replace("{MOBILE}", MOBILE)
          .replace("{MOBILE_INT}", MOBILE_INT)
          .replace("{TAG}", TAG);

      const codeWithPlaceholders =
        currentBusinessAccount.meta.code?.default ?? "";
      const refWithPlaceholders =
        currentBusinessAccount.meta.reference?.default ?? "";
      setCode(replacePlaceholders(codeWithPlaceholders));
      setReference(replacePlaceholders(refWithPlaceholders));
    }
  }, [currentBusinessAccount, code, reference, user]);

  return (
    <>
      <label>
        <BusinessSelector
          _biz={_to}
          _account={_to_account}
          onChangeTo={(biz) => setTo(biz?._id)}
          onChangeAccount={(_acc) => setToAccount(_acc)}
          onBusinessLoad={(biz) => setCurrentBusiness(biz)}
        />
      </label>
      <label>
        <span className="text">
          Code{" "}
          {currentBusinessAccount?.meta?.code ? (
            <TippyExplainer>
              <b>{currentBusinessAccount.meta.code.name || "Code"}</b>
              <div className="row">
                <b>Required:</b>{" "}
                {currentBusinessAccount.meta.code.required ? "Yes" : "No"}
              </div>
              {currentBusinessAccount.meta.code.validation ? (
                <div className="row">
                  <b>Validation:</b>{" "}
                  {currentBusinessAccount.meta.code.validation}
                </div>
              ) : (
                <></>
              )}
              {currentBusinessAccount.meta.code.hint ? (
                <div className="row">
                  <b>Hint:</b> {currentBusinessAccount.meta.code.hint}
                </div>
              ) : (
                <></>
              )}
            </TippyExplainer>
          ) : (
            <></>
          )}
        </span>
        <input
          type="text"
          value={code ?? ""}
          maxLength={16}
          placeholder={currentBusinessAccount?.meta?.code?.name}
          onChange={(evt) =>
            setCode(
              evt.target.value.substring(0, 12).replace(/[^0-9a-z -]/gi, "")
            )
          }
        />
      </label>
      <label>
        <span className="text">
          Reference{" "}
          {currentBusinessAccount?.meta?.reference ? (
            <TippyExplainer>
              <b>{currentBusinessAccount.meta.reference.name || "Reference"}</b>
              <div className="row">
                <b>Required:</b>{" "}
                {currentBusinessAccount.meta.reference.required ? "Yes" : "No"}
              </div>
              {currentBusinessAccount.meta.reference.validation ? (
                <div className="row">
                  <b>Validation:</b>{" "}
                  {currentBusinessAccount.meta.reference.validation}
                </div>
              ) : (
                <></>
              )}
              {currentBusinessAccount.meta.reference.hint ? (
                <div className="row">
                  <b>Hint:</b> {currentBusinessAccount.meta.reference.hint}
                </div>
              ) : (
                <></>
              )}
            </TippyExplainer>
          ) : (
            <></>
          )}
        </span>
        <input
          type="text"
          value={reference ?? ""}
          maxLength={16}
          placeholder={currentBusinessAccount?.meta?.reference?.name}
          onChange={(evt) =>
            setReference(
              evt.target.value.substring(0, 12).replace(/[^0-9a-z -]/gi, "")
            )
          }
        />
      </label>
    </>
  );
};
