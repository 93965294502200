import * as React from "react";
import { Link, useParams } from "react-router-dom";
import "../users/UserPayments.css";
import { groupBy } from "lodash-es";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { PaymentRow } from "../users/components/PaymentRow";
import moment from "moment";

export const BusinessPayments: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _biz = urlParams.id;
  const [biz, setBusiness] = React.useState<dollaUsers.Models.Business>();
  const [payments, setPayments] = React.useState<dollaUsers.Models.Payment[]>(
    []
  );
  const [participants, setParticipants] = React.useState<
    (dollaUsers.Models.Business | dollaUsers.Models.User)[]
  >([]);
  const [loadingBusiness, setLoadingBusiness] = React.useState(false);
  const [loadingPayments, setLoadingPayments] = React.useState(false);

  React.useEffect(() => {
    async function getBusiness() {
      setLoadingBusiness(true);
      const result = await API.get(`/businesses/${_biz}`);
      setLoadingBusiness(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBusiness(result.json.item);
    }
    getBusiness();
  }, [_biz]);
  React.useEffect(() => {
    async function getPayments() {
      setLoadingPayments(true);
      const result = await API.get(`/users/${_biz}/payments`);
      setLoadingPayments(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setPayments(result.json.item.payments);
      setParticipants([
        ...result.json.item.participants,
        ...result.json.item.creators,
      ]);
    }
    getPayments();
  }, [_biz]);

  if (loadingBusiness || loadingPayments || !biz) {
    return <Loader />;
  }

  const groupedByDate = Object.entries(
    groupBy(payments, (pmt) => moment(pmt.created_at).format("Do MMM YYYY"))
  );

  return (
    <div className="UserPayments">
      <Link to={`/businesses/${biz._id}`} className="backlink">
        ← Back to {biz.name}
      </Link>
      <h3>{biz.name} Payments</h3>
      {!payments.length ? (
        <p>No payments</p>
      ) : (
        <div className="payments">
          {groupedByDate.map(([date, pmts]) => (
            <>
              <div className="date">{date}</div>
              {pmts.map((pmt) => (
                <PaymentRow
                  payment={pmt}
                  participants={[biz, ...participants]}
                />
              ))}
            </>
          ))}
        </div>
      )}
    </div>
  );
};
