import * as React from "react";
import Error from "@mui/icons-material/Error";
import Info from "@mui/icons-material/Info";
import { Alert } from "@mui/material";
import "./Alert.css";

import { SessionStore, withSessionStore } from "../../../utils/session";

export const DollaAlert: React.FunctionComponent = withSessionStore((props) => {
  const { error, info, popup } = props.SessionStore;

  const dismiss = (key: "error" | "info" | "popup") => {
    SessionStore.setState({ [key]: undefined });
  };

  return (
    <React.Fragment>
      {error ? (
        <div
          onDoubleClick={dismiss.bind(null, "error")}
          className="Alert Error"
        >
          <Error style={{ marginRight: "1rem" }} />
          {error}
        </div>
      ) : (
        <></>
      )}
      {info ? (
        <div onDoubleClick={dismiss.bind(null, "info")} className="Alert Info">
          <Info style={{ marginRight: "1rem" }} />
          {info}
        </div>
      ) : (
        <></>
      )}
      {popup ? (
        <Alert
          onDoubleClick={dismiss.bind(null, "popup")}
          className="Alert Popup"
          severity="info"
        >
          {popup}
        </Alert>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
});
