/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

import API from "../utils/api";
import { SessionStore } from "../utils/session";
import { Loader } from "./Loader";

import "./JSONObject.css";

type JSONObjectProps = {
  _id: string;
  probablyId: boolean;
  load?: boolean;
  raw: boolean;
  _tenant?: string;
  table?: string;
  /** An input object to display */
  input?: any;
};

export const JSONObjectRDB: React.FunctionComponent<JSONObjectProps> = (
  props
) => {
  const [loading, setLoading] = React.useState(false);
  const [json, setJSON] = React.useState<any>(props.input);

  const load = React.useCallback(async () => {
    setLoading(true);
    const queryParams =
      props._tenant && props.table
        ? `?_tenant=${props._tenant}&table=${props.table}`
        : "";
    const result = await API.get(`/sandbox/rdb/${props._id}` + queryParams);
    setLoading(false);
    if (!result.json || !result.json.success) {
      SessionStore.apiErr(result);
    } else {
      setJSON(result.json.item);
    }
  }, [props]);

  React.useEffect(() => {
    if (props.load) {
      load();
    }
  }, [props.load, load]);

  if (loading) {
    return (
      <div className={"load_line"}>
        {props._id}
        <div className={"mini_loader"}>
          <Loader size="small" />
        </div>
      </div>
    );
  }
  const renderJSON = (obj: any, raw: boolean, probablyId?: boolean) => {
    if (typeof obj === "string") {
      if (raw) {
        return raw ? `"${obj.toString()}",` : obj.toString();
      }
      if (probablyId) {
        return <JSONObjectRDB _id={obj} probablyId={true} raw={raw} />;
      }
      return raw ? `"${obj.toString()}",` : obj.toString();
    } else if (typeof obj === "number") {
      return raw ? `${obj.toString()},` : obj.toString();
    } else if (typeof obj === "boolean") {
      return raw ? `${obj.toString()},` : obj.toString();
    } else if (typeof obj === "object") {
      const isBuffer =
        obj && obj.type === "Buffer" && obj.data instanceof Array;
      if (obj === null) {
        return "null";
      } else if (obj === undefined) {
        return "undefined";
      } else if (obj instanceof Array) {
        return (
          <JSONGroup type="array" raw={raw}>
            {obj.map((child, i) => {
              const probablyId =
                child &&
                typeof child === "string" &&
                /^[a-z0-9_]+_[a-z0-9]{25}$/.test(child);
              return <div key={i}>{renderJSON(child, raw, probablyId)}</div>;
            })}
          </JSONGroup>
        );
      } else if (isBuffer) {
        let binary = "";
        const bytes = new Uint8Array(obj.data);
        for (var i = 0; i < bytes.length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        const base64 = window.btoa(binary);
        return <span className={"long_line"}>(Buffer) {base64}</span>;
      } else {
        const children = Object.keys(obj)
          .sort()
          .map((childKey) => {
            let probablyId =
              childKey !== "_id" &&
              obj[childKey] &&
              typeof obj[childKey] === "string" &&
              /^[a-z0-9_]+_[a-z0-9]{25}$/.test(obj[childKey]);
            return (
              <div key={childKey}>
                <span>
                  <span className={"key"}>{childKey}:&nbsp;</span>
                  {renderJSON(obj[childKey], raw, probablyId)}
                </span>
              </div>
            );
          });
        return (
          <JSONGroup type="object" raw={raw}>
            <span className={"children"}>{children}</span>
          </JSONGroup>
        );
      }
    } else {
      return "Unknown Type";
    }
  };
  if (json !== undefined) {
    return (
      <span className="JSONObject">
        <span className={"object"}>
          {renderJSON(json, props.raw, props.probablyId)}
        </span>
      </span>
    );
  }
  return (
    <span className={"object"}>
      {props.probablyId ? <a onClick={load}>{props._id}</a> : props._id}
    </span>
  );
};

type GroupProps = {
  type: "object" | "array";
  raw: boolean;
};

/** Render a list or an object */
export const JSONGroup: React.FunctionComponent<GroupProps> = (props) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const raw = props.raw;
  const openBrace = (props.type || "object") === "object" ? "{" : "[";
  const closeBrace = (props.type || "object") === "object" ? "}" : "]";

  return (
    <span className="group">
      <span>
        {!raw && <a onClick={toggle}>{collapsed ? "+" : "-"}</a>} {openBrace}
      </span>
      {collapsed && !raw ? (
        "..."
      ) : (
        <div className={"children"}>{props.children}</div>
      )}
      <span>
        {closeBrace}
        {raw ? "," : ""}
      </span>
    </span>
  );
};
