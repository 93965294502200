/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./BusinessSelector.css";

import { dollaUsers } from "../../../../lambdas/utils-common";
import { SmallBusinessSearch } from "./SmallBusinessSearch";
import { Loader } from "./Loader";
import API from "../utils/api";
import { SessionStore } from "../utils/session";
import ClearIcon from "@mui/icons-material/Clear";

type BusinessSearchResult = {
  _id: string;
  name: string;
  avatar_url: string;
  tag: string;
};

type BusinessSelectorProps = {
  onChangeTo?: (biz: BusinessSearchResult | undefined) => void;
  onChangeAccount?: (_acc: string | undefined) => void;
  onBusinessLoad?: (biz: dollaUsers.Models.Business | undefined) => void;
  _biz?: string;
  _account?: string;
};

/** A selector for a business */
export const BusinessSelector: React.FunctionComponent<BusinessSelectorProps> =
  (props) => {
    const [loading, setLoading] = React.useState(false);
    const [business, setBusiness] = React.useState<
      dollaUsers.Models.Business | undefined
    >();
    const { _biz, onBusinessLoad } = props;

    // Update the business when the value changes
    React.useEffect(() => {
      const getBusiness = async () => {
        setLoading(true);
        const result = await API.get(`/businesses/${_biz}`);
        setLoading(false);
        if (!result.json?.success) {
          SessionStore.apiErr(result);
          return;
        }
        setBusiness(result.json.item);
        onBusinessLoad?.(result.json.item);
      };

      if (_biz && _biz !== business?._id) {
        getBusiness();
      } else if (!_biz) {
        setBusiness(undefined);
        onBusinessLoad?.(undefined);
      }
    }, [_biz, business, onBusinessLoad]);

    const onSelect = (biz: BusinessSearchResult | undefined) => {
      props.onChangeTo?.(biz);
      props.onChangeAccount?.(undefined);
    };
    const onAccountChange = (_acc: string | undefined) => {
      props.onChangeAccount?.(_acc);
    };

    return (
      <div className="BusinessSelector">
        {loading ? (
          <div className="loading">
            <Loader size="small" color="#000000" />
          </div>
        ) : business ? (
          <div className="business">
            <div>
              <img className="avatar" src={business.avatar_url} alt="" />{" "}
              {business.name}{" "}
              <a onClick={() => onSelect(undefined)}>
                <ClearIcon />
              </a>
            </div>
            <div>
              <select
                value={props._account}
                onChange={(evt) => onAccountChange(evt.target.value)}
              >
                <option value={undefined}>Select an account</option>
                {business.accounts.map((acc) => (
                  <option value={acc._id} key={acc._id}>
                    {acc.name} ({acc.formatted_account})
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <SmallBusinessSearch onSelect={onSelect} />
        )}
      </div>
    );
  };
