import * as React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "./SearchBusinesses.css";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircle from "@mui/icons-material/CheckCircle";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import Warning from "@mui/icons-material/Warning";

import { BigSearch } from "../../components/BigSearch";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Helmet } from "react-helmet";

type BusinessSearchResult = {
  _id: string;
  name: string;
  avatar_url: string;
  tag: string;
  visible?: boolean;
  is_verified?: boolean;
  is_on_dolla?: boolean;
  warnings?: string[];
  // flags: ("is_verified" | "has_warnings" | "is_on_dolla")[];
};

type VISIBILITY_OPTIONS = "ALL" | "VISIBLE" | "NOT_VISIBLE";

export const SearchBusinessesPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [visibility, setVisibility] = React.useState<VISIBILITY_OPTIONS>("ALL");
  const queryRef = React.useRef<() => Promise<void>>();

  const onVisibilityChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setVisibility(evt.target.value as VISIBILITY_OPTIONS);
  };

  React.useEffect(() => {
    queryRef.current?.();
  }, [visibility]);

  const getBusinesss = async (query: string) => {
    let visibilityQuery = "";
    if (visibility === "VISIBLE") {
      visibilityQuery = "&visible=yes";
    }
    if (visibility === "NOT_VISIBLE") {
      visibilityQuery = "&visible=no";
    }
    const result = await API.get(
      `/businesses/v2/search?q=${query}${visibilityQuery}`
    );
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return [];
    }
    return result.json.items as BusinessSearchResult[];
  };

  return (
    <div className="SearchBusinessesPage">
      <Helmet>
        <title>Businesses | Dolla Management Console</title>
      </Helmet>
      <div className="titleRow">
        <h3>Businesses</h3>
        <Link to="/businesses/new">New Business</Link>
      </div>
      <BigSearch
        autoFocus
        query={getBusinesss}
        allowEmptySearches={true}
        queryRef={queryRef}
        renderItem={(result: BusinessSearchResult, highlight) => {
          // Figure out which flags are relevant
          const icons: React.ReactNode[] = [];
          if (!result.visible) {
            icons.push(
              <span
                title="Not currently visible to users"
                className="statusIcon blue"
              >
                <VisibilityOff fontSize="inherit" />
              </span>
            );
          } else {
            if (result.warnings?.length) {
              icons.push(
                <span title="Has Warnings" className="statusIcon orange">
                  <Warning fontSize="inherit" />
                </span>
              );
            }
            if (result.is_verified) {
              icons.push(
                <span title="Verified" className="statusIcon green">
                  <CheckCircle fontSize="inherit" />
                </span>
              );
            }
            if (result.is_on_dolla) {
              icons.push(
                <span title="Is on Dolla" className="statusIcon green">
                  <MonetizationOn fontSize="inherit" />
                </span>
              );
            }
          }

          return {
            title: result.name,
            subtitle: `$${result.tag}`,
            logo: result.avatar_url,
            onClick: () => {
              navigate(`/businesses/${result._id}`);
            },
            highlight,
            rightElement: <>{icons}</>,
          };
        }}
        buttons={
          <>
            <select
              className="visibilitySelector"
              value={visibility}
              onChange={onVisibilityChange}
            >
              <option value="ALL">Search All</option>
              <option value="VISIBLE">Visible</option>
              <option value="NOT_VISIBLE">Non-Visible</option>
            </select>
          </>
        }
      />
    </div>
  );
};
