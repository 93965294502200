import * as React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./UserContacts.css";
import moment from "moment";
import { orderBy } from "lodash-es";
import ArrowForward from "@mui/icons-material/ArrowForward";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";
import { Helmet } from "react-helmet";

export const UserContacts: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [contacts, setContacts] = React.useState<dollaUsers.Models.Contact[]>(
    []
  );
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingContacts, setLoadingContacts] = React.useState(false);

  React.useEffect(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    getUser();
  }, [_user]);
  React.useEffect(() => {
    async function getContacts() {
      setLoadingContacts(true);
      const result = await API.get(`/users/${_user}/contacts`);
      setLoadingContacts(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setContacts(result.json.items);
    }
    getContacts();
  }, [_user]);

  if (loadingUser || loadingContacts || !user) {
    return <Loader />;
  }

  return (
    <div className="UserContacts">
      <Helmet>
        <title>{user.name}'s Contacts | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name}'s Contacts</h3>
      {!contacts.length ? (
        <p>No contacts</p>
      ) : (
        <List
          items={orderBy(
            contacts,
            (c) => (c.last_paid_at === "NEVER" ? "0" : c.last_paid_at),
            "desc"
          ).map((contact) => ({
            title: (
              <>
                <div className="contactTitle">
                  {contact.name}
                  <span className="tag">${contact.tag}</span>
                  <Inspector _id={contact._id} _user={_user} />
                </div>
              </>
            ),
            subtitle:
              contact.last_paid_at === "NEVER"
                ? "Never paid"
                : `Last paid ${moment(contact.last_paid_at).fromNow()}`,
            logo: contact.avatar_url,
            onClick: () => navigate(`/users/${contact.unique}`),
            key: contact._id,
            rightElement: (
              <div className="ArrowForward">
                <span className="Status">{contact.status}</span>
                <ArrowForward fontSize="inherit" />
              </div>
            ),
          }))}
        />
      )}
    </div>
  );
};
