import * as React from "react";
import "./BigSearch.css";

import { SearchBase, SearchProps } from "./SearchBase";
import { List, ListItem } from "./List";

type BigSearchProps<Result = any> = SearchProps<Result> & {
  /** Turn a result into a list item, to be rendered by List */
  renderItem: (result: Result, selected: boolean) => ListItem;
};

/** A big search bar, with full-page results */
export const BigSearch: React.FunctionComponent<BigSearchProps> = (props) => {
  const renderResults: SearchProps["renderResults"] = (
    items,
    selectedIndex
  ) => {
    const listItems = items.map((item, i) =>
      props.renderItem(item, selectedIndex === i)
    );
    return <List items={listItems} />;
  };
  return (
    <div className="BigSearch">
      <SearchBase {...props} renderResults={renderResults} />
    </div>
  );
};
