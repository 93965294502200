/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./BusinessEditor.css";
import { set, uniq } from "lodash-es";

import { SessionStore } from "../../../utils/session";
import { getFormattedPhoneNumber } from "../../../utils/phonenumbers";
import { dollaUsers } from "../../../../../../lambdas/utils-common";
import { AccountEditList } from "../components/AccountEditList";
import { TippyExplainer } from "../../../components/TippyExplainer";
import { SmallUserSearch } from "../../../components/SmallUserSearch";
import { UserList } from "../../../components/UserList";

type BusinessEditorProps = {
  onSubmit: (business: Partial<dollaUsers.Models.Business>) => Promise<void>;
  business: Partial<dollaUsers.Models.Business>;
  submitTitle?: string;
};

export const BusinessEditor: React.FunctionComponent<BusinessEditorProps> = (
  props
) => {
  const [business, setBusiness] = React.useState(props.business);

  const onChange = (path: string, value: any) => {
    const newBusiness = set(business, path, value);
    setBusiness({ ...newBusiness });
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Validate
    SessionStore.setState({ error: undefined });
    if (!business.name?.length) {
      SessionStore.setError("Please give this business a name");
      return;
    }
    if (!business.tag?.length) {
      SessionStore.setError("Please give this business a tag");
      return;
    }
    if (!/^[0-9a-z_]{2,20}$/i.test(business.tag)) {
      SessionStore.setError(
        "Invalid tag. Tags must consist of 2-12 characters, 0-9 + a-z + _"
      );
      return;
    }
    if (business.contact?.phone) {
      const phoneValidation = getFormattedPhoneNumber(business.contact.phone);
      if (!phoneValidation.success) {
        SessionStore.setError(phoneValidation.message);
        return;
      }
      business.contact.phone = phoneValidation.result.international;
    }

    let accountError: string | undefined = undefined;
    business.accounts?.forEach((acc, i) => {
      const metadataFields = ["code", "reference"] as const;
      metadataFields.forEach((metaName) => {
        const meta = acc.meta[metaName];
        // Validate the regex
        if (meta?.validation?.length) {
          try {
            new RegExp(meta.validation);
          } catch (err) {
            accountError = `Invalid validation for "${metaName}" field of account ${
              i + 1
            }`;
          }
        }
        // Validate the default
        const validSubstitutions = [
          "NAME",
          "FNAME",
          "LNAME",
          "INITIALS",
          "FINITIAL",
          "LINITIAL",
          "MOBILE",
          "MOBILE_INT",
          "TAG",
        ];
        if (meta?.default?.length) {
          const reg = /\{.+\}/g;
          const matches = Array.from(meta.default.matchAll(reg));
          matches.forEach((match) => {
            // A match looks like "{FOO}". We only care about the "FOO" bit of it
            const value = match[0].slice(1, -1);
            if (!validSubstitutions.includes(value)) {
              accountError = `Invalid default for "${metaName}" field of account ${
                i + 1
              }: ${match}`;
            }
          });
        }
        // If needed, set the default keyboard
        if (meta && !meta.keyboard) {
          meta.keyboard = "default";
        }
      });
    });
    if (accountError) {
      SessionStore.setError(accountError);
      return;
    }

    // Clean up any empty items in array attrs
    business.categories =
      business.categories
        ?.join(",")
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x) ?? [];
    business.slugs =
      business.slugs
        ?.join(",")
        ?.split(",")
        .map((x) => x.trim())
        .filter((x) => x) ?? [];
    business.warnings =
      business.warnings
        ?.join(",")
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x) ?? [];

    // Submit
    props.onSubmit(business);
  };

  return (
    <form className="BusinessEditor" onSubmit={onSubmit}>
      <label>
        Name
        <input
          type="text"
          placeholder="Frodo's Fish and Chips"
          value={business.name ?? ""}
          onChange={(evt) => onChange("name", evt.target.value)}
          required
        />
      </label>
      <label>
        Legal Name
        <input
          type="text"
          placeholder="Frodo's Fish and Chips Ltd."
          value={business.legal_name ?? ""}
          onChange={(evt) => onChange("legal_name", evt.target.value)}
        />
      </label>
      <label>
        Description
        <TippyExplainer>
          A short blurb that shows up on the business profile
        </TippyExplainer>
        <textarea
          placeholder="Frodo's Fish is a restaurant that offers fresh and sustainable seafood dishes."
          value={business.description ?? ""}
          onChange={(evt) => onChange("description", evt.target.value)}
        />
      </label>
      <label>
        Akahu Merchant ID (Optional)
        <TippyExplainer>
          Akahu Merchant ID from Akahu enriched transactions
        </TippyExplainer>
        <input
          type="text"
          placeholder="merchant_123"
          value={business?._akahu_merchant ?? ""}
          onChange={(evt) => onChange("_akahu_merchant", evt.target.value)}
        />
      </label>
      <label>
        Receipt email
        <TippyExplainer>
          Where to send receipts for payments to this business
        </TippyExplainer>
        <input
          type="text"
          placeholder="(Optional)"
          value={business?.email_settings?.receipt_address ?? ""}
          onChange={(evt) =>
            onChange("email_settings.receipt_address", evt.target.value)
          }
        />
      </label>
      <label>
        Email Template
        <input
          type="text"
          placeholder="(Optional)"
          value={business?.email_settings?.email_template ?? ""}
          onChange={(evt) =>
            onChange("email_settings.email_template", evt.target.value)
          }
        />
      </label>
      <label>
        <input
          type="checkbox"
          checked={!!business.email_settings?.email_receipts}
          onChange={(evt) =>
            onChange("email_settings.email_receipts", evt.target.checked)
          }
        />{" "}
        Send email receipts to this business{" "}
        <TippyExplainer>
          If this is off, the business won't get email receipts for payments
          (even if a template is set)
        </TippyExplainer>
      </label>
      <div className="AcceptsDolla">
        <tr>
          <span>
            Accepts Dolla (all optional){" "}
            <TippyExplainer>
              Used to determine how business payments should be presented in app
            </TippyExplainer>
          </span>
          <td>
            <label>
              <input
                type="checkbox"
                checked={!!business.accepts_dolla?.in_store}
                onChange={(evt) =>
                  onChange("accepts_dolla.in_store", evt.target.checked)
                }
              />{" "}
              In Store{" "}
              <TippyExplainer>Accepts Dolla through QR payments</TippyExplainer>
            </label>
          </td>
          <td>
            <label>
              <input
                type="checkbox"
                checked={!!business.accepts_dolla?.online}
                onChange={(evt) =>
                  onChange("accepts_dolla.online", evt.target.checked)
                }
              />{" "}
              Online{" "}
              <TippyExplainer>Accepts Dolla through QR payments</TippyExplainer>
            </label>
          </td>
          <td>
            <label>
              <input
                type="checkbox"
                checked={!!business.accepts_dolla?.invoice}
                onChange={(evt) =>
                  onChange("accepts_dolla.invoice", evt.target.checked)
                }
              />{" "}
              Invoice{" "}
              <TippyExplainer>
                Accepts invoice payments - ie exposes Ref + code fields
              </TippyExplainer>
            </label>
          </td>
        </tr>
      </div>
      <label>Owners</label>
      {business._owners?.length === 0 ? (
        <i>Not owned by any Dolla Users.</i>
      ) : (
        <></>
      )}
      <UserList
        _users={business._owners}
        onRemoveUser={(user) => {
          onChange(
            "_owners",
            business._owners?.filter((_u) => _u !== user._id)
          );
        }}
        editable
      />
      <SmallUserSearch
        onSelect={(user) => {
          onChange("_owners", uniq([...(business._owners || []), user._id]));
        }}
        placeholder="Add a new owner (search)"
      />
      {!business._id ? (
        <label>
          Dolla Tag
          <input
            type="text"
            placeholder="$frodosfish"
            value={business.tag ?? ""}
            onChange={(evt) => onChange("tag", evt.target.value)}
            required
          />
        </label>
      ) : (
        <></>
      )}
      <label>
        Avatar URL
        <input
          type="text"
          placeholder="https://..."
          value={business.avatar_url ?? ""}
          onChange={(evt) => onChange("avatar_url", evt.target.value)}
          required
        />
      </label>
      <label>
        Header Image URL{" "}
        <TippyExplainer>
          This image may one day show up at the top of the business profile
          screen.
        </TippyExplainer>
        <input
          type="text"
          placeholder="https://..."
          value={business.header_url ?? ""}
          onChange={(evt) => onChange("header_url", evt.target.value)}
        />
      </label>
      <label>
        Categories (comma separated)
        <input
          type="text"
          placeholder="Food, Fast food"
          value={business.categories?.join(", ") ?? ""}
          onChange={(evt) =>
            onChange("categories", evt.target.value.split(", "))
          }
        />
      </label>
      <label>
        Slugs (comma separated){" "}
        <TippyExplainer>
          These are additional search terms that will bring up this business.
          <br />
          For example, old trading names or extra social handles can go in here.
        </TippyExplainer>
        <input
          type="text"
          placeholder="Gandalf's Gurnard, Faramir's Flounder, ..."
          value={business.slugs?.join(", ") ?? ""}
          onChange={(evt) => onChange("slugs", evt.target.value.split(", "))}
        />
      </label>
      <label>
        Warnings (comma separated){" "}
        <TippyExplainer>
          These messages show up when the user pays or views the business.
        </TippyExplainer>
        <textarea
          placeholder="These chips are hot! Some users have reported burning their mouths on them."
          value={business.warnings?.join(", ") ?? ""}
          onChange={(evt) => onChange("warnings", evt.target.value.split(", "))}
        />
      </label>

      <div className="ContactDetails">
        <span>
          Contact Details (all optional){" "}
          <TippyExplainer>
            Used to fill out a profile page for this business
          </TippyExplainer>
        </span>
        <label>
          <span>Email</span>
          <input
            type="email"
            placeholder="frodo@frodosfish.co.nz"
            value={business.contact?.email ?? ""}
            onChange={(evt) => onChange("contact.email", evt.target.value)}
          />
        </label>
        <label>
          <span>Phone</span>
          <input
            type="phone"
            placeholder="09 442 1722"
            value={business.contact?.phone ?? ""}
            onChange={(evt) => onChange("contact.phone", evt.target.value)}
          />
        </label>
        <label>
          <span>Website</span>
          <input
            type="text"
            placeholder="www.frodosfish.co.nz"
            value={business.contact?.website ?? ""}
            onChange={(evt) => onChange("contact.website", evt.target.value)}
          />
        </label>
        <label>
          <span>Instagram</span>
          <input
            type="text"
            placeholder="https://www.instagram.com/frodosfish/"
            value={business.contact?.instagram ?? ""}
            onChange={(evt) => onChange("contact.instagram", evt.target.value)}
          />
        </label>
        <label>
          <span>Facebook</span>
          <input
            type="text"
            placeholder="https://www.facebook.com/frodosfish/"
            value={business.contact?.facebook ?? ""}
            onChange={(evt) => onChange("contact.facebook", evt.target.value)}
          />
        </label>
        <label>
          <span>Twitter</span>
          <input
            type="text"
            placeholder="https://www.twitter.com/frodosfish/"
            value={business.contact?.twitter ?? ""}
            onChange={(evt) => onChange("contact.twitter", evt.target.value)}
          />
        </label>
      </div>
      <label>Bank Accounts</label>
      <label>
        <input
          type="checkbox"
          checked={!!business.hide_bank_accounts}
          onChange={(evt) => onChange("hide_bank_accounts", evt.target.checked)}
        />{" "}
        Hide bank accounts in the app{" "}
        <TippyExplainer>
          Let's us hide bank numbers for when we're doing things out of the
          ordinary (eg klickex)
        </TippyExplainer>
      </label>
      <AccountEditList
        accounts={business.accounts ?? []}
        onChange={(accounts) => onChange("accounts", accounts)}
      />

      <label>
        Reporting Email (optional)
        <TippyExplainer>
          email to send the transaction reports to eg a bookkeeper
        </TippyExplainer>
        <input
          type="text"
          placeholder="bookkeeper@example.com"
          value={business.reporting_email ?? ""}
          onChange={(evt) => onChange("reporting_email", evt.target.value)}
        />
      </label>

      <div className="ReferralDetails">
        <span>
          Referral details (optional){" "}
          <TippyExplainer>
            Who referred this merchant to us - used for revenue share
          </TippyExplainer>
        </span>
        <label>
          <span>referred at</span>
          <input
            type="date"
            value={
              business?.referral?.referred_at
                ? new Date(business.referral.referred_at)
                    .toISOString()
                    .split("T")[0]
                : ""
            }
            onChange={(evt) =>
              onChange("referral.referred_at", evt.target.value)
            }
          />
        </label>
        <label>Referred By</label>
        {!business?.referral?._user ? <i>Business was not referred</i> : <></>}
        <UserList
          _users={business?.referral?._user ? [business.referral._user] : []}
          onRemoveUser={() => {
            onChange("referral._user", "");
          }}
          editable
        />
        {business?.referral?._user === "" ? (
          <></>
        ) : (
          <SmallUserSearch
            onSelect={(user) => {
              onChange("referral._user", user._id);
            }}
            placeholder="Add the user that referred this business"
          />
        )}
      </div>

      <div className="ReferralDetails">
        <span>
          Partner Referral Details (optional){" "}
          <TippyExplainer>
            Details relating to Dolla's partnership with the business
          </TippyExplainer>
        </span>
        <label>
          <span>referral url</span>
          <input
            type="text"
            value={business?.partner_referral?.url ?? ""}
            onChange={(evt) =>
              onChange("partner_referral.url", evt.target.value)
            }
          />
        </label>
        <label>
          <span>Description</span>
          <textarea
            value={business?.partner_referral?.description ?? ""}
            onChange={(evt) =>
              onChange("partner_referral.description", evt.target.value)
            }
          />
        </label>
      </div>

      <div className="PaymentAction">
        <span>
          Payment action (optional){" "}
          <TippyExplainer>
            Used to customise the payment flow for this business
          </TippyExplainer>
        </span>
        <label>
          <span>Button Text</span>
          <input
            type="text"
            placeholder="Pay"
            value={business.pay_action?.button_text ?? ""}
            onChange={(evt) =>
              onChange("pay_action.button_text", evt.target.value)
            }
          />
        </label>
        <label>
          <span>Success Message</span>
          <input
            type="text"
            placeholder="Success!"
            value={business.pay_action?.success_message ?? ""}
            onChange={(evt) =>
              onChange("pay_action.success_message", evt.target.value)
            }
          />
        </label>
        <label>
          <span>Button Icon</span>
          <input
            type="text"
            placeholder="fish-outline"
            value={business.pay_action?.icon ?? ""}
            onChange={(evt) => onChange("pay_action.icon", evt.target.value)}
          />
        </label>
      </div>

      <label>
        <input
          type="checkbox"
          checked={!!business.is_verified}
          onChange={(evt) => onChange("is_verified", evt.target.checked)}
        />{" "}
        This business is verified{" "}
        <TippyExplainer>
          Tells users that we've verified that the account number is correct.
        </TippyExplainer>
      </label>
      <label>
        <input
          type="checkbox"
          checked={!!business.payment_link_only}
          onChange={(evt) => onChange("payment_link_only", evt.target.checked)}
        />{" "}
        This business is only available to pay via a QR code{" "}
        <TippyExplainer>
          Let's us track payments properly for reconcilliation.
        </TippyExplainer>
      </label>
      <label>
        <input
          type="checkbox"
          checked={!!business.dolla_day}
          onChange={(evt) => onChange("dolla_day", evt.target.checked)}
        />{" "}
        This business is participating in Dolla Day{" "}
        <TippyExplainer>
          Let's us track how many coffees we're shouting
        </TippyExplainer>
      </label>
      <label>
        <input
          type="checkbox"
          checked={!!business.is_visible}
          onChange={(evt) => onChange("is_visible", evt.target.checked)}
        />{" "}
        This business is visible to users{" "}
        <TippyExplainer>
          If this is off, the business won't be visible on the app.
        </TippyExplainer>
      </label>
      <input type="submit" value={props.submitTitle || "Submit"} />
    </form>
  );
};
