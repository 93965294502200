/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./BusinessPage.css";
import moment from "moment";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Chat from "@mui/icons-material/Chat";
import Payment from "@mui/icons-material/Payment";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Warning from "@mui/icons-material/Warning";
import LinkIcon from "@mui/icons-material/Link";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { getFormattedPhoneNumber } from "../../utils/phonenumbers";
import { Loader } from "../../components/Loader";
import { Inspector } from "../../components/Inspector";
import { DangerModal } from "../../components/DangerModal";
import { TippyExplainer } from "../../components/TippyExplainer";
import { UserList } from "../../components/UserList";
import { Helmet } from "react-helmet";

export const BusinessPage: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _biz = urlParams.id;
  const [biz, setBusiness] = React.useState<dollaUsers.Models.Business>();
  const [loading, setLoading] = React.useState(false);

  const dangerModal = React.useRef<any>();
  const navigate = useNavigate();

  const deleteBusiness = async () => {
    if (!biz) {
      return;
    }
    const onSubmit = async () => {
      const result = await API.delete(`/businesses/${biz._id}`);
      if (result.json.success) {
        SessionStore.setInfo(`Deleted ${biz.name}`);
        navigate("/businesses");
      } else {
        SessionStore.apiErr(result);
      }
    };

    dangerModal.current.open(
      `Are you sure?`,
      [
        <>
          This will delete <b>{biz.name}</b>.
        </>,
        `Currently, any payments, chats or contacts pointing to this business will be deleted.`,
        `This is quite destructive and cannot be undone.`,
      ],
      onSubmit
    );
  };

  React.useEffect(() => {
    async function getBusiness() {
      setLoading(true);
      const result = await API.get(`/businesses/${_biz}`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }

      // Use the formatted phone number if possible
      if (result.json.item?.contact?.phone) {
        const phoneValidation = getFormattedPhoneNumber(
          result.json.item.contact.phone
        );
        if (phoneValidation.success) {
          result.json.item.contact.phone = phoneValidation.result.national;
        }
      }

      setBusiness(result.json.item);
    }
    getBusiness();
  }, [_biz]);

  if (loading || !biz) {
    return <Loader />;
  }

  return (
    <div className="BusinessPage">
      <Helmet>
        <title>{biz.name} | Dolla Management Console</title>
      </Helmet>
      {biz.warnings?.map((warning, i) => (
        <div className="warning" key={i}>
          <Warning fontSize="inherit" />
          <span>{warning}</span>
        </div>
      ))}
      <h3>
        <img className="avatar" src={biz.avatar_url} alt="" />
        {biz.name}
        <span className="tag">${biz.tag}</span>
        <Inspector _id={biz._id} />
        <Link
          to={`/businesses/${biz._id}/edit`}
          className="titleLink"
          title="Edit"
        >
          <Edit fontSize="inherit" />
        </Link>
        <a className="titleLink" onClick={deleteBusiness} title="Delete">
          <DeleteForever fontSize="inherit" />
        </a>
      </h3>
      <table>
        <tbody>
          <tr>
            <td>Created</td>
            <td>{moment(biz.created_at).fromNow()}</td>
          </tr>
          <tr>
            <td>
              Verified{" "}
              <TippyExplainer>
                We have looked at this business and verified that they are all
                legit: They own the business they claim to, and the account
                number + contact details are correct.
              </TippyExplainer>
            </td>
            <td>
              {biz.is_verified ? (
                <span className="green">
                  <CheckCircle /> Yes
                </span>
              ) : (
                <span className="dark-grey">
                  <span className="grey">
                    <CheckCircle />
                  </span>
                  No
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>
              Visible{" "}
              <TippyExplainer>
                Can people see this business in the app?
              </TippyExplainer>
            </td>
            <td>
              {biz.is_visible ? (
                <span className="green">
                  <CheckCircle /> Yes
                </span>
              ) : (
                <span className="dark-grey">
                  <span className="grey">
                    <VisibilityOff />
                  </span>
                  No
                </span>
              )}
            </td>
          </tr>
          {biz.categories.length ? (
            <tr>
              <td>Categories</td>
              <td>{biz.categories.join(", ")}</td>
            </tr>
          ) : (
            <></>
          )}
          {Object.entries(biz.contact).map(([label, value]) => {
            // A quick hack to make them presentable
            const displayValue = {
              email: <a href={`mailto:${value}`}>{value}</a>,
              phone: <a href={`phone:${value}`}>{value.replace("+64", "0")}</a>,
              website: <a href={`https://${value}`}>{value}</a>,
              instagram: <a href={value}>{biz.name}'s Instagram Page</a>,
              facebook: <a href={value}>{biz.name}'s Facebook Page</a>,
              twitter: <a href={value}>{biz.name}'s Twitter Feed</a>,
            }[label];
            return (
              <tr key={label}>
                <td>
                  {label[0].toUpperCase()}
                  {label.slice(1)}
                </td>
                <td>{displayValue}</td>
              </tr>
            );
          })}
          {biz._owners.length ? (
            <tr>
              <td>Owned By</td>
              <td>
                <UserList _users={biz._owners} />
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
      <div className="linklist vertical">
        <Link to={`/businesses/${biz._id}/accounts`}>
          <AccountBalanceWallet className="icon" />
          Accounts
        </Link>
        <Link to={`/businesses/${biz._id}/chats`}>
          <Chat className="icon" />
          Chats
        </Link>
        <Link to={`/businesses/${biz._id}/identities`}>
          <PermIdentityIcon className="icon" />
          Identities
        </Link>
        <Link to={`/businesses/${biz._id}/payment-links`}>
          <LinkIcon className="icon" />
          Payment Links
        </Link>
        <Link to={`/businesses/${biz._id}/payments`}>
          <Payment className="icon" />
          Payments
        </Link>
        <Link to={`/businesses/${biz._id}/subscription-plans`}>
          <AutorenewIcon className="icon" />
          Subscription Plans
        </Link>
      </div>
      <DangerModal openRef={dangerModal} />
    </div>
  );
};
