/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ExpandCircleDown from "@mui/icons-material/ExpandCircleDown";
import { dollaUsers } from "../../../../../../../lambdas/utils-common";

type EmailAttachmentProps = {
  item: dollaUsers.Models.Email["attachments"][number] & { uri: string };
};

/** Render an image by just chucking it in an image tag */
export const Image: React.FunctionComponent<EmailAttachmentProps> = (props) => {
  const [visible, setVisible] = React.useState(true);

  return (
    <>
      <a
        className="attachmentName"
        onClick={() => setVisible(!visible)}
        id={`attachment_${props.item.name}`}
      >
        <AttachmentIcon /> {props.item.name}
        <span className={`expand ${visible ? "rotated" : ""}`}>
          <ExpandCircleDown />
        </span>
      </a>
      {visible ? (
        <div className="page">
          <img src={props.item.uri} alt="" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
