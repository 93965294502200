import * as React from "react";
import "./DangerModal.css";
import ReactModal from "react-modal";

import { Loader } from "./Loader";
import { SkullIcon } from "./SkullIcon";

type DangerModalArgs = {
  /** The title for this danger message */
  title: string | React.ReactElement;
  /** The body text for this danger message. Each item in the array is a seperate paragraph. */
  message: (string | React.ReactElement)[];
  /** Called when the user clicks confirm */
  onConfirm: () => Promise<void> | void;
};

type DangerModalProps = {
  /** A way to expose the `open` method to parents */
  openRef: React.MutableRefObject<any>;
};

/** A big search bar, with full-page results */
export const DangerModal: React.FunctionComponent<DangerModalProps> = (
  props
) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [args, setArgs] = React.useState<DangerModalArgs>();

  const confirm = async () => {
    setLoading(true);
    await args?.onConfirm();
    closeModal();
  };

  const openModal = (
    title: DangerModalArgs["title"],
    message: DangerModalArgs["message"],
    onConfirm: DangerModalArgs["onConfirm"]
  ) => {
    setArgs({ title, message, onConfirm });
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setLoading(false);
    setArgs(undefined);
  };

  // Pass a reference to the `openModal` to parents
  if (props.openRef) {
    props.openRef.current = { open: openModal };
  }

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      overlayClassName="DangerModal modal_overlay"
      className="DangerModal modal"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <h1 style={{ textAlign: "center" }}>
            <SkullIcon color="#d53b3b" />
            {args?.title ?? "Are you sure?"} <SkullIcon color="#d53b3b" />
          </h1>
          <div className="dangerSection">
            {args?.message.map((x, i) => (
              <div key={i}>{x}</div>
            ))}
          </div>
          <button className="wide" onClick={confirm}>
            I'm sure, do it
          </button>
        </>
      )}
    </ReactModal>
  );
};
