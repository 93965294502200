import React, { useEffect, useRef } from "react";
import _ from "lodash";

function usePrevious<T = any>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const InfiniteScrollLoader: React.FunctionComponent<{
  loading: boolean;
  next: () => Promise<void>;
  children: React.ReactChild;
  childCount?: number;
  done?: boolean;
  isInModal?: boolean;
}> = ({ loading, next, children, childCount, done, isInModal }) => {
  const ref = useRef<HTMLDivElement>(null);
  const prevLoading = usePrevious(loading);
  const prevChildCount = usePrevious(childCount);

  useEffect(() => {
    // Add the scroll listener to either the doc or the modal overlay
    const parentNode = isInModal
      ? document.querySelector(".ReactModal__Overlay")
      : document;

    const onScroll = (evt: Event) => {
      if (!ref.current || loading || done) {
        return;
      }
      // Work out if we have hit the bottom
      if (!isInModal) {
        // No idea how this works to be honest...
        if (
          ref.current.getBoundingClientRect().bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
        ) {
          next();
        }
      } else {
        const scrollingElement = evt.target as HTMLDivElement;
        if (scrollingElement) {
          const contentHeight = scrollingElement.scrollHeight; // How tall the content is
          const scroll = scrollingElement.scrollTop; // How far the top of the window has scrolled
          const scrollWindowHeight = scrollingElement.clientHeight; // How tall the (scrolling) window is
          if (scroll + scrollWindowHeight >= contentHeight) {
            next();
          }
        }
      }
    };
    const fn = _.debounce(onScroll, 150);
    if (!parentNode) {
      throw Error("Could not figure out which node is parent to the scroller");
    }
    parentNode.addEventListener("scroll", fn);
    return () => parentNode.removeEventListener("scroll", fn);
  }, [next, loading, done, isInModal]);

  useEffect(() => {
    if (
      childCount !== undefined &&
      prevLoading &&
      !loading &&
      childCount === prevChildCount
    ) {
      next();
    }
  }, [childCount, loading, prevChildCount, prevLoading, next]);

  return (
    <div ref={ref} style={{ width: "100%" }}>
      {children}
    </div>
  );
};
