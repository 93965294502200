import * as React from "react";
import "./ConstantsPage.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const ConstantsPage: React.FunctionComponent = () => {
  return (
    <div className="ConstantsPage">
      <Helmet>
        <title>Constants | Dolla Management Console</title>
      </Helmet>
      <h2>Constants</h2>
      <section>
        <h4>Profanity List</h4>
        <p>
          The profanity list is a list of very naughty words that only very
          naughtly people use. We make sure that none of our users set their
          name or handle to something containing one of these words.
        </p>
        <Link to="/constants/profanity.json">Edit Profanity List.</Link>
      </section>
      <section>
        <h4>Chat Name Generator</h4>
        <p>
          The words in this list are used to make random names for group chats.
          These are used by default when we don't have any other good names for
          the chat.
        </p>
        <Link to="/constants/chat_name_generator.json">
          Edit Chat Name Generator
        </Link>
      </section>
    </div>
  );
};
