import * as React from "react";
import "./LogoutOverlay.css";

import { Loader } from "../../../components/Loader";

export const LogoutOverlay: React.FunctionComponent = () => (
  <div className="LogoutOverlay">
    <div>
      <Loader />
    </div>
  </div>
);
