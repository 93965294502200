/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./BankAccountSelector.css";

import { dollaUsers } from "../../../../lambdas/utils-common";
import { Loader } from "./Loader";
import API from "../utils/api";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";

type BankAccountSelectorProps = {
  onChange?: (bankAcc: {
    account_name?: string;
    account_number?: string;
    email?: string;
    is_company?: boolean;
    first_name?: string;
    last_name?: string;
    company_name?: string;
  }) => void;
  onAccountLoad?: (acc: dollaUsers.Models.BankAccount | undefined) => void;
  name?: string;
  number?: string;
  email?: string;
  is_company?: boolean;
  company_name?: string;
  first_name?: string;
  last_name?: string;
};

/** A selector for a user */
export const BankAccountSelector: React.FunctionComponent<BankAccountSelectorProps> =
  (props) => {
    const [loading, setLoading] = React.useState(false);
    const [bAcc, setBAcc] = React.useState<dollaUsers.Models.BankAccount>();
    const autofilled = React.useRef(false);
    const {
      name,
      number,
      email,
      onAccountLoad,
      onChange,
      first_name,
      last_name,
      is_company,
      company_name,
    } = props;

    const clean = (str?: string) => str?.replace(/[^\d]/g, "");
    const cleanNumber = clean(number);
    const validNumber =
      cleanNumber?.length === 15 || cleanNumber?.length === 16;

    // Get the bank account when the number changes
    React.useEffect(() => {
      const getBankAccount = async () => {
        setLoading(true);
        const result = await API.get(`/bank-accounts/by-number/${number}`);
        setLoading(false);
        if (!result.json?.success) {
          return;
        }
        onAccountLoad?.(result.json?.item);
        if (result.json?.item) {
          autofilled.current = false;
          setBAcc(result.json.item);
        }
      };

      if (
        validNumber &&
        cleanNumber !== clean(bAcc?.account_number) &&
        validNumber
      ) {
        getBankAccount();
      } else if (!validNumber) {
        onAccountLoad?.(undefined);
        setBAcc(undefined);
      }
    }, [number, cleanNumber, validNumber, bAcc, onAccountLoad]);

    // Set the name if we haven't already
    React.useEffect(() => {
      if (bAcc && bAcc.name && !autofilled.current) {
        autofilled.current = true;
        if (!name || !email) {
          const newValue = {
            account_name: bAcc.name,
            account_number: number,
            email: bAcc.email,
          };
          onChange?.(newValue);
        }
      }
    }, [bAcc, name, onChange, number, email]);

    const onNumberChange = (val: string) => {
      const num = val.replace(/[^0-9]/g, "");
      const formatted = num
        ?.match(/(\d{0,2})(\d{0,4})(\d{0,7})(\d{0,3})/)
        ?.slice(1)
        ?.filter((x) => x.length)
        ?.join("-");

      const newValue = {
        account_name: name,
        account_number: formatted,
        email: email,
      };
      onChange?.(newValue);
    };
    // const onNameChange = (val: string) => {
    //   const newValue = {
    //     account_name: val,
    //     account_number: number,
    //     email: email,
    //   };
    //   onChange?.(newValue);
    // };
    const onFirstNameChange = (val: string) => {
      const newValue = {
        account_name: val + " " + last_name,
        account_number: number,
        email: email,
        first_name: val,
        last_name: last_name,
        is_company: false,
        company_name: undefined,
      };
      onChange?.(newValue);
    };
    const onLastNameChange = (val: string) => {
      const newValue = {
        account_name: first_name + " " + val,
        account_number: number,
        email: email,
        first_name: first_name,
        last_name: val,
        is_company: false,
        company_name: undefined,
      };
      onChange?.(newValue);
    };
    const onCompanyNameChange = (val: string) => {
      const newValue = {
        account_name: val,
        account_number: number,
        email: email,
        first_name: undefined,
        last_name: undefined,
        is_company: true,
        company_name: val,
      };
      onChange?.(newValue);
    };

    const onIsCompanyChange = (val: boolean) => {
      const newValue = {
        account_name: name,
        account_number: number,
        email: email,
        first_name: val ? undefined : first_name,
        last_name: undefined,
        is_company: val,
        company_name: val ? name : undefined,
      };
      onChange?.(newValue);
    };

    const onEmailChange = (val: string) => {
      const newValue = {
        account_name: name,
        account_number: number,
        email: val,
      };
      onChange?.(newValue);
    };

    return (
      <div className="BankAccountSelector">
        <label>
          <input
            type="text"
            className="accountNumber"
            value={number}
            onChange={(evt) => onNumberChange(evt.target.value)}
            placeholder="00-0000-0000000-00"
          />
          {loading ? (
            <div className="inlineLoader">
              <Loader size="small" />
            </div>
          ) : bAcc ? (
            <div className="badge" title="This bank account exists">
              <CheckIcon />
            </div>
          ) : validNumber ? (
            <div className="badge" title="This bank account will be created">
              <AddIcon />
            </div>
          ) : (
            <></>
          )}
        </label>
        <div className="checkbox-container">
          <input
            id="is-company-checkbox"
            type="checkbox"
            checked={is_company}
            onChange={(e) => onIsCompanyChange(e.target.checked)}
          />
          <label htmlFor="is-company-checkbox" className="checkbox-label">
            Is company?
          </label>
        </div>
        {/* <label>
          <input
            type="text"
            value={name}
            onChange={(evt) => onNameChange(evt.target.value)}
            placeholder="Account Name"
          />
        </label> */}
        {is_company ? (
          <label>
            <input
              type="text"
              value={company_name}
              onChange={(evt) => onCompanyNameChange(evt.target.value)}
              placeholder="Company Name"
            />
          </label>
        ) : (
          <>
            <label>
              <input
                type="text"
                value={first_name}
                onChange={(evt) => onFirstNameChange(evt.target.value)}
                placeholder="First Name"
              />
            </label>
            <label>
              <input
                type="text"
                value={last_name}
                onChange={(evt) => onLastNameChange(evt.target.value)}
                placeholder="Last Name"
              />
            </label>
          </>
        )}
        <label>
          <input
            type="text"
            value={email}
            onChange={(evt) => onEmailChange(evt.target.value)}
            placeholder="Email"
          />
          {loading ? (
            <div className="inlineLoader">
              <Loader size="small" />
            </div>
          ) : (
            <></>
          )}
        </label>
      </div>
    );
  };
