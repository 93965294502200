/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./BusinessPaymentLink.css";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { Inspector } from "../../components/Inspector";
import { UserList } from "../../components/UserList";
import { Helmet } from "react-helmet";
import moment from "moment";
import numeral from "numeral";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { set, cloneDeep } from "lodash";
import { QR } from "../sandbox/components/QR";

const renderRateLimit = (
  limit: dollaUsers.Models.PaymentLink["user_rate_limit"]
) => {
  if (!limit) {
    return "None";
  }
  const renderPeriod = (period: string, amount: number) => {
    if (amount === 1) {
      if (period.endsWith("s")) {
        return period.slice(0, -1);
      }
      return period;
    }
    if (!period.endsWith("s")) {
      return `${amount} ${period}s`;
    }
    return `${amount} ${period}`;
  };

  const amountPhrase = `${limit.n} time${limit.n === 1 ? "" : "s"}`;
  const periodPhrase = Object.entries(limit.period)
    .map(([period, amount]) => renderPeriod(period, amount))
    .join(", ");
  return `${amountPhrase} every ${periodPhrase}`;
};

type EditablePaymentLink = Omit<
  dollaUsers.Models.PaymentLink,
  "user_rate_limit"
> & {
  user_rate_limit?: {
    n: string;
    numberOfPeriods: string;
    period: string;
  };
};

const link2Editable = (
  link: dollaUsers.Models.PaymentLink
): EditablePaymentLink => {
  return {
    ...link,
    user_rate_limit: link.user_rate_limit
      ? {
          n: link.user_rate_limit.n.toString(),
          numberOfPeriods:
            Object.values(link.user_rate_limit.period)[0].toString() ?? "1",
          period:
            Object.keys(link.user_rate_limit.period)[0].toString() ?? "days",
        }
      : undefined,
  };
};
const editable2Link = (
  link: EditablePaymentLink
): dollaUsers.Models.PaymentLink => {
  return {
    ...link,
    user_rate_limit: link.user_rate_limit
      ? {
          n: Number(link.user_rate_limit.n ?? 1),
          period: {
            [link.user_rate_limit.period ?? "days"]: Number(
              link.user_rate_limit.numberOfPeriods ?? 1
            ),
          },
        }
      : undefined,
  };
};

export const BusinessPaymentLink: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _biz = urlParams.id;
  const _link = urlParams.link;
  const [biz, setBiz] = React.useState<dollaUsers.Models.Business>();
  const [link, setLink] = React.useState<dollaUsers.Models.PaymentLink>();
  const [editedLink, setEditedLink] = React.useState<EditablePaymentLink>();
  const [loadingBiz, setLoadingBiz] = React.useState(false);
  const [loadingLink, setLoadingLink] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    async function getBiz() {
      setLoadingBiz(true);
      const result = await API.get(`/businesses/${_biz}`);
      setLoadingBiz(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBiz(result.json.item);
    }
    getBiz();
  }, [_biz]);

  const getLink = React.useCallback(() => {
    async function getLink() {
      setLoadingLink(true);
      const result = await API.get(`/users/${_biz}/payment-links/${_link}`);
      setLoadingLink(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setLink(result.json.item);
    }
    getLink();
  }, [_biz, _link]);
  React.useEffect(() => {
    getLink();
  }, [getLink]);

  const deleteLink = async () => {
    if (!biz || !link) {
      return;
    }
    if (
      !window.confirm(
        "Are you sure you want to delete this link?\n\nYou can't undo this."
      )
    ) {
      return;
    }

    setLoadingLink(true);
    const result = await API.delete(
      `/users/${biz._id}/payment-links/${link._id}`
    );
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    SessionStore.setInfo("Deleted payment link");
    navigate(`/businesses/${biz._id}/payment-links`);
  };

  const toggleEdit = () => {
    if (editing) {
      setEditing(false);
      if (link) {
        setEditedLink(cloneDeep(link2Editable(link)));
      }
    } else {
      setEditing(true);
      if (!editedLink && link) {
        setEditedLink(cloneDeep(link2Editable(link)));
      }
    }
  };

  /** Handle updates to the link fields */
  const onLinkEdit = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (!editedLink) {
      return;
    }

    const field = event.target.name;
    let value = event.target.value;

    const newLink = set(editedLink, field, value);
    setEditedLink(cloneDeep(newLink));
    SessionStore.setError("");
  };

  /** Save the updated link */
  const updateLink = async () => {
    if (!editedLink) {
      return;
    }
    const normalisedLink = editable2Link(editedLink);
    // Validate
    const status = normalisedLink.status;
    const amount = Number(normalisedLink.amount);
    if (isNaN(amount)) {
      SessionStore.setError("Invalid amount");
      return;
    }
    if (!moment(normalisedLink.expires_at).isValid()) {
      SessionStore.setError("Invalid expiry date");
      return;
    }
    const expires_at = moment(normalisedLink.expires_at)
      .startOf("day")
      .toISOString();
    const max_uses = Number(normalisedLink.max_uses);
    if (isNaN(max_uses) || max_uses === 0) {
      SessionStore.setError("Invalid max uses");
      return;
    }
    if (normalisedLink.user_rate_limit) {
      if (
        isNaN(normalisedLink.user_rate_limit.n) ||
        normalisedLink.user_rate_limit.n === 0
      ) {
        SessionStore.setError("Invalid rate limit");
        return;
      }
      const periods = Object.entries(normalisedLink.user_rate_limit.period);
      for (const [, amount] of periods) {
        if (isNaN(amount) || amount === 0) {
          SessionStore.setError("Invalid rate limit");
          return;
        }
        // Don't bother validating the period type, since it's from a select
      }
    }

    // Store the sucker
    setLoadingLink(true);
    const result = await API.put(`/users/${_biz}/payment-links/${_link}`, {
      status,
      amount,
      expires_at,
      max_uses,
      user_rate_limit: normalisedLink.user_rate_limit,
    });
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    SessionStore.setInfo("Updated payment link");
    toggleEdit();
    getLink();
  };

  if (loadingBiz || loadingLink || !biz || !link) {
    return <Loader />;
  }

  const usedUp = link.max_uses ? link.counter_payments >= link.max_uses : false;

  return (
    <div className="BusinessPaymentLink">
      <Helmet>
        <title>{biz.name}'s Payment Link | Dolla Management Console</title>
      </Helmet>
      <Link to={`/businesses/${biz._id}/payment-links`} className="backlink">
        ← Back to {biz.name} payment links
      </Link>
      <h3>
        {biz.name} Payment Link <Inspector _user={_biz} _id={link._id} />{" "}
        {!usedUp ? (
          <a className="titleLink" onClick={toggleEdit} title="Edit">
            <EditIcon fontSize="inherit" />
          </a>
        ) : (
          <></>
        )}
        {!link?.counter_payments ? (
          <a className="titleLink" onClick={deleteLink} title="Delete">
            <DeleteForever fontSize="inherit" />
          </a>
        ) : (
          <></>
        )}
      </h3>
      <table className="prettyDetails">
        <tr>
          <td>Status</td>
          <td>
            {!editing ? (
              link.status
            ) : (
              <select
                value={editedLink?.status}
                onChange={onLinkEdit}
                name="status"
              >
                <option value="ACTIVE">ACTIVE</option>
                <option value="INACTIVE">INACTIVE</option>
              </select>
            )}
          </td>
        </tr>
        <tr>
          <td>Amount</td>
          <td>
            {!editing ? (
              numeral(link.amount).format("$0,000.00")
            ) : (
              <input
                type="text"
                value={editedLink?.amount}
                pattern="[\d,]*\.?\d{0,2}"
                onChange={onLinkEdit}
                name="amount"
              />
            )}
          </td>
        </tr>
        <tr>
          <td>Created</td>
          <td title={moment(link.created_at).format()}>
            {moment(link.created_at).fromNow()}
          </td>
        </tr>
        <tr>
          <td>Expires</td>
          <td title={!editing ? moment(link.expires_at).format() : undefined}>
            {!editing ? (
              moment(link.expires_at).fromNow()
            ) : (
              <input
                type="date"
                min={moment().add(1, "day").format("YYYY-MM-DD")}
                value={moment(editedLink?.expires_at).format("YYYY-MM-DD")}
                onChange={onLinkEdit}
                name="expires_at"
              />
            )}
          </td>
        </tr>
        <tr>
          <td>Used</td>
          <td>
            {link.counter_payments} of{" "}
            {!editing ? (
              link.max_uses ?? "infinite"
            ) : (
              <input
                className="inline"
                type="text"
                pattern="\d+"
                value={editedLink?.max_uses}
                onChange={onLinkEdit}
                name="max_uses"
              />
            )}{" "}
            times
          </td>
        </tr>
        <tr>
          <td>Rate Limit</td>
          <td>
            {editing ? (
              <>
                <input
                  className="inline"
                  type="text"
                  pattern="\d+"
                  value={editedLink?.user_rate_limit?.n ?? 1}
                  onChange={onLinkEdit}
                  name="user_rate_limit.n"
                />{" "}
                use{(editedLink?.user_rate_limit?.n ?? 1) === 1 ? "" : "s"}{" "}
                every{" "}
                <input
                  className="inline"
                  type="text"
                  pattern="\d+"
                  value={editedLink?.user_rate_limit?.numberOfPeriods ?? "1"}
                  onChange={onLinkEdit}
                  name={`user_rate_limit.numberOfPeriods`}
                />
                <select
                  className="inline"
                  value={editedLink?.user_rate_limit?.period ?? "days"}
                  onChange={onLinkEdit}
                  name={`user_rate_limit.period`}
                >
                  <option value="minutes">minutes</option>
                  <option value="hours">hours</option>
                  <option value="days">days</option>
                  <option value="weeks">weeks</option>
                  <option value="months">months</option>
                  <option value="years">years</option>
                </select>
              </>
            ) : (
              renderRateLimit(link.user_rate_limit)
            )}
          </td>
        </tr>
        <tr>
          <td>Created By</td>
          <td>
            {link.created_by ? (
              <UserList _users={[link.created_by]} />
            ) : (
              "Unknown"
            )}
          </td>
        </tr>
        {editing ? (
          <tr>
            <td colSpan={2}>
              <div className="buttonRow">
                <button className="button-outline" onClick={toggleEdit}>
                  Cancel
                </button>
                <button onClick={updateLink}>Save</button>
              </div>
            </td>
          </tr>
        ) : (
          <></>
        )}
      </table>
      <QR value={link.url} showDownload />
    </div>
  );
};
