/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useNavigate } from "react-router";
// import { Link } from "react-router-dom";
import "./SearchUsersPage.css";
import CheckCircle from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";

import { BigSearch } from "../../components/BigSearch";
import { SearchFilters } from "../../components/SearchFilters";
import { InfiniteScrollLoader } from "../../components/InfiniteScrollLoader";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Helmet } from "react-helmet";
import { omit, set } from "lodash";
import { useLocalStorageObject } from "src/hooks";
import { List } from "src/components/List";

type UserSearchResult = {
  _id: string;
  name: string;
  avatar_url: string;
  tag: string;
  visible?: boolean;
  status?: "ACTIVE" | "ONBOARDING" | "BLOCKED" | "DELETED";
  verified?: boolean;
  plan?: "BASIC" | "PREMIUM";
  os: "IOS" | "ANDROID";
  providers?: { provider: "MANUAL" | "AKAHU" }[];
  // flags: (
  //   | "person"
  //   | "can_request"
  //   | "can_receive"
  //   | "defaults"
  //   | "akahu"
  //   | "manual"
  //   | "ios"
  //   | "android"
  // )[];
};

type UserCounts = {
  todayCount: number;
  monthCount: number;
  activeCount: number;
  unverifiedCount: number;
};

/** CloudSearch field filters */
type flag = "yes" | "no";
export type Filters = {
  visible?: flag;
  verified?: flag;
  status?: "ACTIVE" | "ONBOARDING" | "BLOCKED" | "DELETED";
  plan?: "BASIC" | "PREMIUM";
  flags?: {
    can_request?: flag;
    can_receive?: flag;
    defaults?: flag;
    akahu?: flag;
    manual?: flag;
    ios?: flag;
    android?: flag;
  };
};

export const SearchUsersPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const openFilters =
    localStorage.getItem("openFilters") === "true" ? true : false;

  const filterFields = [
    "visible",
    "verified",
    "status",
    "plan",
    "provider",
    "flags",
  ];

  const [query, setQuery] = React.useState("");
  const [showFilters, setShowFilters] = React.useState<boolean>(openFilters);
  const [showMobile, setShowMobile] = React.useState<boolean>(false);
  const [initialSearch, setInitialSearch] = React.useState<string>("");
  const [filters, setFilters] = useLocalStorageObject({});
  const [cursor, setCursor] = React.useState(null);
  const [extraPages, setExtraPages] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [userCounts, setUserCounts] = React.useState<UserCounts | null>(null);

  const queryRef = React.useRef<() => Promise<void>>();

  const getValue = (key: string) => (obj: Record<string, any>) => obj[key];

  const loadFilters = () => {
    const localStoreFilters = {};
    filterFields.map((key) => {
      const store = localStorage.getItem(key);
      if (store) {
        if (key === "flags") {
          set(localStoreFilters, key, JSON.parse(store));
        } else {
          set(localStoreFilters, key, store);
        }
      }
    });
    console.log("loaded filters", localStoreFilters);
    return localStoreFilters as Filters;
  };

  const clearFilters = () => {
    filterFields.map((key) => {
      localStorage.removeItem(key);
    });
  };

  const handleFilters = (args: any) => {
    const newFilters = { ...args };
    console.log("newFilters", newFilters);
    setFilters(newFilters);
    queryRef?.current?.();
  };

  const search = async (qr: string) => {
    if (typeof qr !== "string") {
      qr = "";
    }
    setLoading(true);
    // Mobile search
    if (showMobile) {
      if (qr.length >= 9) {
        const result = await API.get(`/users/mobile?mobile=${qr}}`);
        if (!result.json?.success) {
          SessionStore.apiErr(result);
          return [];
        }
        const filtered = [result.json.item];
        setLoading(false);
        return filtered as UserSearchResult[];
      }
      return [];
    }
    setCursor(null);
    setExtraPages([]);
    setQuery(qr);
    // Get filters from localStorage
    const currentFilters = Object.keys(filters)?.length
      ? filters
      : loadFilters();
    setFilters(currentFilters);

    const { flags } = currentFilters;
    const validFlags: { [x: string]: any } = {};
    const validFilters: { [x: string]: any } = {};

    // remove flags with value "ALL"
    const clean = (o: any, obj: any) => {
      Object.entries(o)
        .filter(([, v]) => v !== "ALL")
        .map(([k, v]) => (obj[k] = v));
      console.log(obj);
      return obj;
    };

    console.log(
      "fn.search",
      "cleaned search params",
      JSON.stringify(
        clean(omit(currentFilters, ["flags", "size"]), validFilters)
      ),
      JSON.stringify(clean(omit(flags, "akahu"), validFlags))
    );

    const finalQuery = new URLSearchParams({
      q: qr,
      ...clean(omit(currentFilters, ["flags", "size"]), validFilters),
      ...clean(omit(flags, "akahu"), validFlags),
    }).toString();

    console.log("finalQuery", finalQuery);
    const result = await API.get(`/users/v2/search?${finalQuery}`);

    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return [];
    }

    const filtered = result.json.items.filter(
      (user: any) => user?.status === currentFilters?.status || true
    );
    setCursor(result.json.cursor);
    setLoading(false);
    return filtered as UserSearchResult[];
  };

  const getNextPage = async () => {
    if (!cursor || cursor === "null") {
      return;
    }
    setLoading(true);

    // Flags
    const currentFilters = Object.keys(filters)?.length
      ? filters
      : loadFilters();
    setFilters(currentFilters);

    const { flags } = currentFilters;
    let flagParams = "";
    if (flags) {
      Object.keys(flags).map((key, i) => {
        console.log("flags", key);
        if (flags[key] === "ALL") {
          return;
        }
        key !== "flags" && (flagParams += key);
      });
    }

    // Filter & size params
    let params = "";
    let otherParams = "";
    Object.keys(currentFilters).forEach((key, i) => {
      const value = getValue(key)(currentFilters);
      // Don't append ALL values to filter query params
      if (value === "ALL") {
        return;
      }
      // Append size to its own param string
      if (key === "size") {
        otherParams += `&${key}=${value}`;
        return;
      }
      key !== "flags" && (params += `${i === 0 ? "" : "&"}${key}=${value}`);
    });

    // Create query strings
    const searchQuery = `${query ? `?q=${query}` : ""}`;
    const flagQuery = `${params.length ? "&" : ""}flags=${flagParams}`;
    const finalQuery = !searchQuery.length
      ? `?${params}${flagQuery}${otherParams}&cursor=${cursor}`
      : `${searchQuery}&${params}&${flagQuery}${otherParams}&cursor=${cursor}`;

    const result = await API.get(`/users/v2/search${finalQuery}`);

    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }

    const filtered = result.json.items.filter(
      (user: any) => user?.status === filters?.status || true
    );
    setCursor(result.json.cursor);
    setLoading(false);
    setExtraPages([...extraPages, ...filtered]);
  };

  const getUserCounts = async () => {
    const result = await API.get("/users/counts");

    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }

    setUserCounts(result.json.item);
  };

  const toggleSearchType = () => {
    setShowMobile(!showMobile);
  };

  React.useEffect(() => {
    if (!showFilters) {
      setFilters({});
    } else {
      setInitialSearch("");
    }
  }, [showFilters]);
  React.useEffect(() => {
    const savedQuery = localStorage.getItem("query");
    if (savedQuery) {
      console.log("loading query", savedQuery);
      setQuery(savedQuery);
    }
    getUserCounts();
  }, []);

  const renderUserRow = (result: UserSearchResult, highlight: boolean) => {
    const icons: React.ReactNode[] = [];
    if (result?.verified) {
      icons.push(
        <span title="Verified" className="statusIcon green">
          <CheckCircle fontSize="inherit" />
        </span>
      );
    }
    if (result.visible !== undefined) {
      icons.push(
        <span
          title={result?.visible ? "Visible" : "Non-Visible"}
          className={`statusIcon ${result?.visible ? "green" : "red"}`}
        >
          {result?.visible ? (
            <VisibilityIcon fontSize="inherit" />
          ) : (
            <VisibilityOffIcon fontSize="inherit" />
          )}
        </span>
      );
    }
    /**
     * Flags
     */
    if (result?.providers?.length) {
      icons.push(
        <span
          title={`Defaults - ${
            result.providers?.find((p) => p.provider === "AKAHU")
              ? "AKAHU"
              : "MANUAL"
          }`}
          className="statusIcon green"
        >
          {result.providers?.find((p) => p.provider === "AKAHU") ? (
            <AccountBalanceWalletRoundedIcon fontSize="inherit" />
          ) : (
            <AccountBalanceWalletOutlinedIcon fontSize="inherit" />
          )}
        </span>
      );
    }

    if (result.os) {
      icons.push(
        <span
          title={`Device - ${result.os === "IOS" ? "iOS" : "Android"}`}
          className="statusIcon green"
        >
          {result?.os === "IOS" ? (
            <AppleIcon fontSize="inherit" />
          ) : (
            <AndroidIcon fontSize="inherit" />
          )}
        </span>
      );
    }

    if (result?.plan === "PREMIUM") {
      icons.push(
        <span title="Premium" className="statusIcon green">
          <WorkspacePremiumRoundedIcon fontSize="inherit" />
        </span>
      );
    }

    const showStatus =
      filters?.status === result?.status || result?.status === "DELETED";

    return {
      title: result.name ? result.name : "name not set",
      subtitle: result.tag ? `$${result.tag}` : "",
      logo: result.avatar_url,
      key: result._id,
      rightElement: (
        <>
          {showStatus && <span className="statusIcon">{result?.status}</span>}
          {icons}
        </>
      ),
      onClick: () => {
        navigate(`/users/${result._id}`);
      },
      highlight,
    };
  };

  return (
    <div className="SearchUsersPage">
      <Helmet>
        <title>Users | Dolla Management Console</title>
      </Helmet>
      <div className="titleRow">
        <h3>Users</h3>
      </div>
      <p className="userCounts">
        {userCounts ? (
          <>
            {userCounts.activeCount ?? 0} active &bull;{" "}
            {userCounts.unverifiedCount ?? 0} unverified &bull;{" "}
            {userCounts.todayCount ?? 0} new today &bull;{" "}
            {userCounts.monthCount ?? 0} new this month
          </>
        ) : (
          <></>
        )}
      </p>
      <InfiniteScrollLoader
        next={getNextPage}
        loading={loading}
        done={cursor === "null"}
      >
        <>
          <BigSearch
            autoFocus
            query={search}
            allowEmptySearches={true}
            initialSearch={initialSearch}
            queryRef={queryRef}
            renderItem={renderUserRow}
            buttons={
              <>
                <button
                  className={showMobile ? "button-outline" : ""}
                  style={{
                    padding: "0 2rem",
                  }}
                  onClick={toggleSearchType}
                >
                  <PhoneIcon fontSize="medium" />
                </button>
                <button
                  className={showFilters ? "button-outline" : ""}
                  onClick={() => {
                    setShowFilters(!showFilters);
                    localStorage.setItem("openFilters", String(!showFilters));
                  }}
                >
                  filters
                </button>
              </>
            }
            bottomComponent={
              showFilters ? (
                <div className="filtersWrapper">
                  <SearchFilters onChange={handleFilters} filters={filters} />
                  <div className="buttonRow">
                    <button
                      className="button-outline"
                      onClick={() => {
                        clearFilters();
                        setFilters({});
                      }}
                    >
                      Clear
                    </button>
                    <button
                      onClick={async () => {
                        queryRef.current?.();
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <span></span>
                </div>
              ) : (
                <></>
              )
            }
          />
          {extraPages?.length ? (
            <List items={extraPages.map((x: any) => renderUserRow(x, false))} />
          ) : (
            <></>
          )}
        </>
      </InfiniteScrollLoader>
    </div>
  );
};
