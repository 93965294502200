/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import "./UserSubscriptions.css";
import { orderBy } from "lodash-es";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";
import { Helmet } from "react-helmet";
import moment from "moment";

// the type returned from GET subscription end point
interface subResponse extends dollaUsers.Models.Subscription {
  plan: dollaUsers.Models.SubscriptionPlan,
  business: dollaUsers.Models.Business
}

export const UserSubscriptions: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [subs, setSubs] = React.useState<subResponse[]>([]);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingSubs, setLoadingSubs] = React.useState(false);

  React.useEffect(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    getUser();
  }, [_user]);

  const getSubs = React.useCallback(() => {
    async function getSubs() {
      setLoadingSubs(true);
      const result = await API.get(`/users/${_user}/subscriptions`);
      setLoadingSubs(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setSubs(result.json.items);
    }
    getSubs();
  }, [_user]);
  React.useEffect(() => {
    getSubs();
  }, [getSubs]);

  if (loadingUser || loadingSubs || !user) {
    return <Loader />;
  }

  return (
    <div className="UserSubscriptions">
      <Helmet>
        <title>{user.name}'s Subscriptions | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name} Subscriptions</h3>
      {!subs.length ? (
        <p>No Subscriptions</p>
      ) : (
        <List
          items={orderBy(subs, ["subscription_status", "next_attempt_at"], ["desc", "desc"]).map((sub) => ({
            title: (
              <span>
                Subscription: {sub.plan.name}
                <Inspector _user={user._id} _id={sub._id} />
              </span>
            ),
            logo: sub.business.avatar_url,
            subtitle:
            sub.billing_status === "CANCELLED" || sub.cancel_at_period_end
            ? "Cancelled"
            : `${sub.amount} Next due on ${
                sub.billing_status === "PENDING_PAYMENT"
                  ? moment(sub.due_at)
                      .add(sub?.plan.period.frequency, sub.plan.period.interval)
                      .format("ddd DD MMM YYYY")
                  : moment(sub.due_at).format("ddd DD MMM YYYY")
              }`,
          }))}
        />
      )}
    </div>
  );
};
