/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

import { UserSelector } from "../../../../components/UserSelector";
import { RecipientFormProps } from "./NewPaymentForm";

// It would be nice if we could reference this from the model somewhere...
type PersonalRecipient = {
  type: "PERSONAL";
  _user: string;
  message: string;
};

/** A form to select a business and fill in required metadata */
export const PersonalRecipientForm: React.FunctionComponent<
  RecipientFormProps<PersonalRecipient>
> = (props) => {
  const { prefill, onChange } = props;
  const [_to, setTo] = React.useState(
    prefill?.to?.type === "PERSONAL" ? prefill.to._user : undefined
  );
  const [message, setMessage] = React.useState(
    (prefill?.to?.type === "PERSONAL" ? prefill?.to?.message : "") || ""
  );

  /**
   * Validate the parameters to pay a user
   *
   * Returns null if there are no errors, otherwise an error string.
   */
  props.validationRef.current = (): string | null => {
    if (!_to) {
      return "Please pick someone to pay";
    }
    if (!message) {
      return "Please enter a message for the recipient";
    }
    return null;
  };

  // Whenever the parameters change, call props.onChange
  React.useEffect(() => {
    onChange({
      type: "PERSONAL",
      _user: _to,
      message,
    });
  }, [onChange, _to, message]);

  return (
    <>
      <label>
        <UserSelector _user={_to} onChange={(user) => setTo(user?._id)} />
      </label>
      <label>
        <span className="text">Message</span>
        <input
          type="text"
          value={message ?? ""}
          placeholder="(required)"
          onChange={(evt) => setMessage(evt.target.value)}
        />
      </label>
    </>
  );
};
