/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./BusinessPaymentLinks.css";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { orderBy } from "lodash-es";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";
import { Helmet } from "react-helmet";
import moment from "moment";
import numeral from "numeral";

export const BusinessPaymentLinks: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _biz = urlParams.id;
  const [biz, setBiz] = React.useState<dollaUsers.Models.Business>();
  const [links, setLinks] = React.useState<dollaUsers.Models.PaymentLink[]>([]);
  const [loadingBiz, setLoadingBiz] = React.useState(false);
  const [loadingLinks, setLoadingLinks] = React.useState(false);

  React.useEffect(() => {
    async function getBiz() {
      setLoadingBiz(true);
      const result = await API.get(`/businesses/${_biz}`);
      setLoadingBiz(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBiz(result.json.item);
    }
    getBiz();
  }, [_biz]);

  const getLinks = React.useCallback(() => {
    async function getLinks() {
      setLoadingLinks(true);
      const result = await API.get(`/users/${_biz}/payment-links`);
      setLoadingLinks(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setLinks(result.json.items);
    }
    getLinks();
  }, [_biz]);
  React.useEffect(() => {
    getLinks();
  }, [getLinks]);

  if (loadingBiz || loadingLinks || !biz) {
    return <Loader />;
  }

  return (
    <div className="BusinessPaymentLinks">
      <Helmet>
        <title>{biz.name}'s Payment Links | Dolla Management Console</title>
      </Helmet>
      <Link to={`/businesses/${biz._id}`} className="backlink">
        ← Back to {biz.name}
      </Link>
      <h3>{biz.name} Payment Links</h3>
      {!links.length ? (
        <p>No links</p>
      ) : (
        <List
          items={orderBy(links, "created_at", "desc").map((link) => ({
            title: (
              <span>
                Payment Link: {numeral(link.amount).format("$0,000.00")}
                <Inspector _user={biz._id} _id={link._id} />
              </span>
            ),
            subtitle:
              link.status === "ACTIVE"
                ? `Created ${moment(
                    link.created_at
                  ).fromNow()}. Valid for ${moment(link.expires_at).fromNow(
                    true
                  )}. ${link.counter_payments} of ${
                    link.max_uses ?? "infinite"
                  } uses.`
                : link.status,
            onClick: () =>
              navigate(`/businesses/${biz._id}/payment-links/${link._id}`),
            key: link._id,
            rightElement: <ArrowForward fontSize="inherit" />,
          }))}
        />
      )}
    </div>
  );
};
