import * as React from "react";
import { useNavigate } from "react-router";
import "./SearchEntities.css";

import { BigSearch } from "../../components/BigSearch";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Helmet } from "react-helmet";
import { dollaUsers } from "utils-common";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import PublicIcon from "@mui/icons-material/Public";
import { Link } from "react-router-dom";

type EntitiesSearchResult = dollaUsers.Models.Entity;

export const SearchEntitiesPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const queryRef = React.useRef<() => Promise<void>>();

  React.useEffect(() => {
    queryRef.current?.();
  }, []);

  const getEntity = async (query: string) => {
    const result = await API.get(`/entities/search?q=${query}`);

    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return [];
    }
    return result.json.items as EntitiesSearchResult[];
  };

  return (
    <div className="SearchEntitiesPage">
      <Helmet>
        <title>Entities | Dolla Management Console</title>
      </Helmet>
      <div className="titleRow">
        <h3>Entities</h3>
        <Link to="/entities/new">New Entity</Link>
      </div>
      <BigSearch
        autoFocus
        query={getEntity}
        allowEmptySearches={true}
        queryRef={queryRef}
        renderItem={(result: EntitiesSearchResult, highlight) => {
          // Figure out which flags are relevant
          const icons: React.ReactNode[] = [];
          if (result.registered_biller) {
            icons.push(
              <span
                title="This entity is a registered biller"
                className="statusIcon blue"
              >
                <MonetizationOn fontSize="inherit" />
              </span>
            );
          }
          if (result.type === "OVERSEAS") {
            icons.push(
              <span
                title="This entity is an overseas company"
                className="statusIcon blue"
              >
                <PublicIcon fontSize="inherit" />
              </span>
            );
          }

          return {
            title: result.name,
            subtitle: result.nzbn_type ?? result.type,
            logo: result.avatar_url,
            onClick: () => {
              navigate(`/entities/${result._id}`);
            },
            highlight,
            rightElement: <>{icons}</>,
          };
        }}
      />
    </div>
  );
};
