import * as React from "react";
import "./ListMgmtUsers.css";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

import { managementDB } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Helmet } from "react-helmet";

export const ListMgmtUsers: React.FunctionComponent = () => {
  const [users, setUsers] = React.useState<managementDB.Models.User[]>([]);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    async function getUsers() {
      setLoading(true);
      const result = await API.get("/settings/users");
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUsers(result.json.items);
    }
    getUsers();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const listItems = users.map((user) => {
    let subtitle = "Basic User";
    let subtitleError = false;
    if (user.admin) {
      subtitle = "Admin";
    }
    if (user.dev) {
      subtitle = "Developer";
    }
    if (user.dev && user.admin) {
      subtitle = "Admin + Developer";
    }
    if (!user.otp_setup_at) {
      subtitleError = true;
      subtitle = "Should setup 2FA";
    }
    if (user.tmp_password_set_at) {
      subtitleError = true;
      subtitle = "Should set a secure password";
    }
    const MAX_NUMBER_OF_FAILED_LOGINS = 3;
    if (
      user.failed_login_attempts &&
      user.failed_login_attempts >= MAX_NUMBER_OF_FAILED_LOGINS
    ) {
      subtitleError = true;
      subtitle = "Locked out (too many failed login attempts)";
    }
    const TMP_PASSWORD_MAX_AGE = { hours: 24 };
    if (
      user.tmp_password_set_at &&
      user.tmp_password_set_at <
        moment().subtract(TMP_PASSWORD_MAX_AGE).toISOString()
    ) {
      subtitleError = true;
      subtitle = "Locked out (temporary password expired)";
    }

    const subtitleElement = (
      <div className={`subtitle ${subtitleError ? "error" : ""}`}>
        {subtitle}
      </div>
    );

    return {
      key: user._id,
      title: user.name,
      subtitle: subtitleElement,
      onClick: () => {
        navigate(`/mgmt/users/${user._id}`);
      },
    };
  });

  return (
    <div className="ListMgmtUsers">
      <Helmet>
        <title>Management Users | Dolla Management Console</title>
      </Helmet>
      <h3>Management Users</h3>
      <Link to="/mgmt/users/new">Setup New User</Link>
      <List items={listItems} />
    </div>
  );
};
