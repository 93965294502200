import * as React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Loader } from "src/components/Loader";
import "./NewEntity.css";

import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { dollaUsers } from "utils-common";
import { Helmet } from "react-helmet";
import { EntityEditor } from "./components/EntityEditor";
import { Entity, BankAccount } from "rdb/src/models";

export const NewEntity: React.FunctionComponent = () => {
  const [queryParams] = useSearchParams();
  console.log(queryParams);

  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const onSubmit = async (params: {
    entity: Partial<Entity>;
    bank_accounts?: Partial<BankAccount>[];
  }) => {
    setLoading(true);
    console.log("Submit", params);
    let result = await API.post("/entities", params);
    if (!result.json.success) {
      setLoading(false);
      SessionStore.apiErr(result);
    } else {
      // Go to the new business
      navigate(`/entities/${result.json.item_id}`);
    }
  };

  // Only need to define nested stuff, arrays and objects
  const initialEntity: Partial<Entity> = {
    type: (queryParams?.get("type") as Entity["type"]) ?? "PERSON",
    name: queryParams?.get("name") as Entity["name"],
    gst_numbers: queryParams?.get("gst_number")
      ? ([queryParams?.get("gst_number")] as Entity["gst_numbers"])
      : [],
    email_addresses: queryParams?.get("email")
      ? ([queryParams?.get("email")] as Entity["email_addresses"])
      : [],
    websites: queryParams?.get("website")
      ? ([queryParams?.get("website")] as Entity["websites"])
      : [],
    addresses: queryParams?.get("address")
      ? ([queryParams?.get("address")] as Entity["addresses"])
      : [],
    phone_numbers: queryParams?.get("phone")
      ? ([queryParams?.get("phone")] as Entity["addresses"])
      : [],
  };

  const initialBankAccounts: dollaUsers.Models.BankAccount[] = [];

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="NewEntity">
      <Helmet>
        <title>New Entity | Dolla Management Console</title>
      </Helmet>
      <div className="titleRow">
        <h3>New Entity</h3>
      </div>
      <EntityEditor
        entity={initialEntity}
        bank_accounts={initialBankAccounts}
        onSubmit={onSubmit}
        submitTitle="Create"
      />
    </div>
  );
};
