/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import ClearIcon from "@mui/icons-material/Clear";

import { Link } from "react-router-dom";
import { NZBNEntitySearch } from "src/components/NZBNEntitySearch";
import { BankAccountSelector } from "../../../../components/BankAccountSelector";
import { RecipientFormProps } from "./NewPaymentForm";

import * as RDB from "rdb";
import api from "src/utils/api";
import { SessionStore } from "src/utils/session";

import "./BankRecipientForm.css";

// It would be nice if we could reference this from the model somewhere...
type BankRecipient = {
  type: "BANK_ACCOUNT";
  account_number: string;
  account_name: string;
  email?: string;
  meta: { code?: string; reference?: string };
  _entity?: string;
  avatar_url?: string;
  is_company?: boolean;
  company_name?: string;
  first_name?: string;
  last_name?: string;
};

/** A form to select a business and fill in required metadata */
export const BankRecipientForm: React.FunctionComponent<
  RecipientFormProps<BankRecipient>
> = (props) => {
  const { prefill, onChange } = props;
  console.log("Prefill", prefill);
  const [accountNumber, setAccountNumber] = React.useState(
    (prefill?.to?.type === "BANK_ACCOUNT" ? prefill?.to?.account_number : "") ||
      ""
  );
  const [accountName, setAccountName] = React.useState(
    (prefill?.to?.type === "BANK_ACCOUNT" ? prefill?.to?.account_name : "") ||
      ""
  );
  const [email, setEmail] = React.useState(
    (prefill?.to?.type === "BANK_ACCOUNT" ? prefill?.to?.email : "") || ""
  );
  const [code, setCode] = React.useState(
    (prefill?.to?.type === "BANK_ACCOUNT"
      ? prefill?.to?.meta?.code?.substring(0, 12).replace(/[^0-9a-z -]/gi, "")
      : "") || ""
  );
  const [reference, setReference] = React.useState(
    prefill?.to?.type === "BANK_ACCOUNT"
      ? (prefill?.to?.meta?.reference ?? prefill?.invoice_number)
          ?.substring(0, 12)
          .replace(/[^0-9a-z -]/gi, "")
      : ""
  );
  const [firstName, setFirstName] = React.useState<string | undefined>();
  const [lastName, setLastName] = React.useState<string | undefined>();
  const [companyName, setCompanyName] = React.useState<string | undefined>(
    prefill?.from_name ?? prefill?.bank_account_name ?? ""
  );
  const [isCompany, setIsCompany] = React.useState(
    prefill?.from_type !== "PERSON"
  );
  const [entity, setEntity] = React.useState<any | undefined>();
  const [entityAccounts, setEntityAccounts] = React.useState<
    RDB.Models.BankAccount[]
  >([]);
  const [entityAccount, setEntityAccount] = React.useState<
    RDB.Models.BankAccount | undefined
  >();
  /**
   * Validate the parameters to pay a bank account
   *
   * Returns null if there are no errors, otherwise an error string.
   */
  props.validationRef.current = (): string | null => {
    // if (!accountNumber) {
    //   return "Please enter an account to pay";
    // }
    // if (!accountName) {
    //   return "Please enter a name for the recipient bank account";
    // }
    if (!!accountNumber && !/\d{2}-\d{4}-\d{7}-\d{2,3}/.test(accountNumber)) {
      return "Please enter a valid bank account number";
    }

    return null;
  };

  // Whenever the parameters change, call props.onChange
  React.useEffect(() => {
    onChange({
      type: "BANK_ACCOUNT",
      account_number: accountNumber,
      account_name: accountName,
      email: email,
      meta: {
        code,
        reference,
      },
      _entity: entity?._id,
      avatar_url: entity?.avatar_url,
      is_company: isCompany,
      company_name: companyName,
      first_name: firstName,
      last_name: lastName,
    });
  }, [
    onChange,
    accountName,
    accountNumber,
    email,
    code,
    reference,
    entity,
    isCompany,
    companyName,
    firstName,
    lastName,
  ]);

  React.useEffect(() => {
    if (entityAccount) {
      setAccountNumber(entityAccount.account_number ?? "");
    }
  }, [entityAccount]);

  React.useEffect(() => {
    const getEntity = async (_id: string) => {
      const result = await api.get(`/entities?_id=${_id}`);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setEntity(result.json.item);
      getBankAccounts(result.json.item._id);
    };

    if (
      prefill?.to?.type === "ENTITY" &&
      prefill.to._id?.startsWith("entity")
    ) {
      getEntity(prefill.to._id);
    } else if (
      prefill?.to?.type === "BANK_ACCOUNT" &&
      prefill.to._entity?.startsWith("entity")
    ) {
      getEntity(prefill.to._entity);
    }
  }, [prefill?.to]);

  const getBankAccounts = async (_id?: string) => {
    if (_id) {
      const res = await api.get(`/entities/${_id}/bank-accounts`);
      const { success, items } = res.json;

      if (success) {
        setEntityAccounts(items);
        setEntityAccount(items[0]);
      }
    } else {
      setEntityAccounts([]);
    }
  };

  return (
    <>
      {entity ? (
        <>
          <div className="description">
            <div>
              <img src={entity.avatar_url} alt="" className="avatar" />
              {entity?.name}{" "}
              <a
                onClick={() => {
                  setEntity(undefined);
                  setEntityAccount(undefined);
                  setEntityAccounts([]);
                }}
              >
                <ClearIcon />
              </a>
            </div>
            {entityAccounts.length > 0 ? (
              <label>
                <span className="text">{entityAccount?.account_name}</span>
                <select
                  value={entityAccount?._id ?? ""}
                  onChange={(evt) => {
                    console.log("Entity account", evt.target.value);
                    const acc = entityAccounts.find(
                      (acc) => acc._id === evt.target.value
                    );
                    setEntityAccount(acc);
                    setAccountName(entity.name);
                    setAccountNumber(acc?.account_number ?? "");
                    if (entity?.type === "COMPANY") {
                      setIsCompany(true);
                      setCompanyName(entity.trading_name ?? entity.name);
                      setFirstName(undefined);
                      setLastName(undefined);
                    } else {
                      setIsCompany(false);
                      setCompanyName(undefined);
                      setFirstName(entity.first_name);
                      setLastName(entity.last_name);
                    }
                  }}
                >
                  {entityAccounts.map((acc) => (
                    <option key={acc._id} value={acc._id}>
                      {acc.account_number === entity.biller_account
                        ? "⭐️ "
                        : ""}
                      {acc.account_number}
                    </option>
                  ))}
                </select>
              </label>
            ) : (
              <label>No accounts</label>
            )}
          </div>
        </>
      ) : (
        <>
          <label>
            <NZBNEntitySearch
              existing_only
              onSelect={(e) => {
                setEntity(e);
                setAccountName((e as any)?.name);
                if ((e as any)?.type === "COMPANY") {
                  setIsCompany(true);
                  setCompanyName((e as any)?.trading_name ?? (e as any)?.name);
                  setFirstName(undefined);
                  setLastName(undefined);
                } else {
                  setIsCompany(false);
                  setCompanyName(undefined);
                  setFirstName((e as any)?.first_name);
                  setLastName((e as any)?.last_name);
                }

                void getBankAccounts(e._id);
              }}
            />
          </label>
          <label>
            <BankAccountSelector
              name={accountName}
              number={accountNumber}
              email={email}
              is_company={isCompany}
              first_name={firstName}
              last_name={lastName}
              company_name={companyName}
              onChange={({
                account_name,
                account_number,
                email,
                is_company,
                first_name,
                last_name,
                company_name,
              }) => {
                setAccountNumber(account_number ?? "");
                setAccountName(account_name ?? "");
                setEmail(email ?? "");
                setEntity(undefined);
                setIsCompany(is_company ?? true);
                setFirstName(first_name);
                setLastName(last_name);
                setCompanyName(company_name);
              }}
            />
          </label>
          {
            <label>
              <Link
                to={`/entities/new?name=${prefill?.from_name}&type=${prefill?.type}&gst_number=${prefill?.gst_number}&email=${email}&website=${prefill?.from_website}&address=${prefill?.from_address}&phone=${prefill?.from_phone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>Generate Entity</button>
              </Link>
            </label>
          }
        </>
      )}

      <div>
        <span className="text">Code</span>
        <input
          type="text"
          value={code ?? ""}
          onChange={(evt) =>
            setCode(
              evt.target.value.substring(0, 12).replace(/[^0-9a-z -]/gi, "")
            )
          }
        />
      </div>
      <label>
        <span className="text">Reference</span>
        <input
          type="text"
          value={reference ?? ""}
          onChange={(evt) =>
            setReference(
              evt.target.value.substring(0, 12).replace(/[^0-9a-z -]/gi, "")
            )
          }
        />
      </label>
    </>
  );
};
