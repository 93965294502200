/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./UserXero.css";
import { orderBy } from "lodash-es";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Helmet } from "react-helmet";
import * as RDB from "rdb";

export const UserXero: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [tenants, setTenants] = React.useState<RDB.Models.XeroTenant[]>([]);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingTenants, setLoadingTenants] = React.useState(false);

  async function syncTenants() {
    const result = await API.post(`/users/${_user}/xero`);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
  }

  React.useEffect(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }

    async function getTenants() {
      setLoadingTenants(true);
      const result = await API.get(`/users/${_user}/xero`);
      setLoadingTenants(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setTenants(result.json.items);
    }
    getUser();
    getTenants();
  }, [_user]);

  if (loadingUser || loadingTenants || !user) {
    return <Loader />;
  }

  return (
    <div className="UserSubscriptions">
      <Helmet>
        <title>{user.name}'s Xero Orgs | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name} Xero Orgs</h3>
      <div className="buttonRow">
        <button onClick={() => syncTenants()}>Sync Xero</button>
      </div>
      {!tenants.length ? (
        <p>No Xero Orgs</p>
      ) : (
        <List
          items={orderBy(tenants, ["name"], ["asc"]).map((tenant) => ({
            title: tenant.name,

            onClick: () => navigate(`/xero/${tenant._id}`),
          }))}
        />
      )}
    </div>
  );
};
