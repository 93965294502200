/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./UserList.css";
import { Link } from "react-router-dom";
import RemoveCircle from "@mui/icons-material/RemoveCircle";

import { dollaUsers } from "../../../../lambdas/utils-common";
import API from "../utils/api";
import { SessionStore } from "../utils/session";
import { Loader } from "./Loader";

type UserListProps =
  | {
      _users?: string[];
      users?: dollaUsers.Models.User[];
      editable?: false;
    }
  | {
      _users?: string[];
      users?: dollaUsers.Models.User[];
      editable: true;
      onRemoveUser: (user: dollaUsers.Models.User) => void;
    };

const userCache: { [_id: string]: dollaUsers.Models.User } = {};

/** A simple list of users, that will fetch them if it needs to */
export const UserList: React.FunctionComponent<UserListProps> = (props) => {
  const [users, setUsers] = React.useState(props.users ?? []);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    async function getUser(_user: string) {
      if (userCache[_user]) {
        return userCache[_user];
      }
      const result = _user.startsWith("user")
        ? await API.get(`/users/${_user}`)
        : await API.get(`/businesses/${_user}`);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      userCache[_user] = result.json.item;
      return result.json.item as dollaUsers.Models.User;
    }
    async function getUsers() {
      setLoading(true);
      const results = await Promise.all(
        props._users?.map((_user) => getUser(_user)) || []
      );
      setLoading(false);
      setUsers(results.filter((x): x is dollaUsers.Models.User => !!x));
    }
    getUsers();
  }, [props._users]);

  if (loading) {
    return <Loader size="small" />;
  }
  return (
    <div className="UserList">
      {users.map((user) => {
        if (props.editable) {
          return (
            <div className="user" key={user._id}>
              <img className="avatar" alt="" src={user.avatar_url} />
              <span className="name">{user.name}</span>
              <span className="tag">${user.tag}</span>
              <a
                className="removeButton"
                onClick={() => props.onRemoveUser(user)}
                title="Remove this user"
              >
                <RemoveCircle fontSize="inherit" />
              </a>
            </div>
          );
        } else {
          return (
            <Link className="user" key={user._id} to={`/users/${user._id}`}>
              <img className="avatar" alt="" src={user.avatar_url} />
              <span className="name">{user.name}</span>
              <span className="tag">${user.tag}</span>
            </Link>
          );
        }
      })}
    </div>
  );
};
