import * as React from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import * as RDB from "rdb";
import { SessionStore } from "src/utils/session";
import API from "src/utils/api";

type EmailAttachmentTableProps = {
  email: RDB.Models.Email;
  onChange: (email: RDB.Models.Email) => void;
};

export const EmailAttachmentSelector: React.FunctionComponent<EmailAttachmentTableProps> =
  (props) => {
    const { email } = props;

    const onAttachmentSelect = async (i: number) => {
      if (!email) {
        return;
      }

      const newAttachments = email.attachments;
      newAttachments[i].selected = !email.attachments[i].selected;

      const result = await API.put(
        `/users/${email._user}/emails/${email._id}/attachments`,
        {
          attachments: newAttachments,
        }
      );

      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      props.onChange({
        ...email,
        attachments: newAttachments,
      });
    };

    if (!email.attachments.length) {
      return <></>;
    }

    return (
      <table>
        <thead>
          <tr>
            <td>Attachment</td>
            <td>Size</td>
            <td>Selected</td>
          </tr>
        </thead>
        <tbody>
          {props.email.attachments.map((attch, i) => (
            <tr>
              <td>
                <a
                  className="attachment"
                  href={`#attachment_${attch.name}`}
                  key={attch.name}
                >
                  <AttachmentIcon /> {attch.name}
                </a>
              </td>
              <td>{Number(attch.size) / 100} KB</td>
              <td>
                <input
                  style={{ margin: "0px" }}
                  type="checkbox"
                  value={attch.key}
                  checked={!!attch.selected}
                  onChange={(e) => onAttachmentSelect(i)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
