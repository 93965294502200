import * as React from "react";
import { useNavigate } from "react-router";
import "./SearchBankAccounts.css";

import { BigSearch } from "../../components/BigSearch";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Helmet } from "react-helmet";
import { dollaUsers } from "utils-common";
import VisibilityOnIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type BankAccountsSearchResult = dollaUsers.Models.BankAccount;

export const SearchBankAccountsPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const queryRef = React.useRef<() => Promise<void>>();

  React.useEffect(() => {
    queryRef.current?.();
  }, []);

  const getBankAccount = async (q: string) => {
    if (!q) {
      return [];
    }

    const result = await API.get(`/bank-accounts/by-number/${q}`);

    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return [];
    }
    return [result.json.item] as BankAccountsSearchResult[];
  };

  return (
    <div className="SearchBankAccountsPage">
      <Helmet>
        <title>Bank Accounts | Dolla Management Console</title>
      </Helmet>
      <div className="titleRow">
        <h3>Bank Accounts</h3>
      </div>
      <BigSearch
        autoFocus
        query={getBankAccount}
        allowEmptySearches={true}
        queryRef={queryRef}
        placeholder="Search account number"
        renderItem={(result: BankAccountsSearchResult, highlight) => {
          // Figure out which flags are relevant
          const icons: React.ReactNode[] = [];
          if (result.is_public) {
            icons.push(
              <span
                title="This bank account is public"
                className="statusIcon blue"
              >
                <VisibilityOnIcon fontSize="inherit" />
              </span>
            );
          } else {
            icons.push(
              <span
                title="This bank account is not public"
                className="statusIcon blue"
              >
                <VisibilityOffIcon fontSize="inherit" />
              </span>
            );
          }

          return {
            title: result.account_number,
            subtitle: result.account_name ?? result.name,
            logo: result.avatar_url,
            onClick: () => {
              navigate(`/bank_accounts/${result.account_number}`);
            },
            highlight,
            rightElement: <>{icons}</>,
          };
        }}
      />
    </div>
  );
};
