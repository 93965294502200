/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { dollaUsers } from "../../../../../../../lambdas/utils-common";
import { Image } from "./Image";
import { Download } from "./Download";
import { Pdf } from "./Pdf";

type EmailAttachmentProps = {
  item: dollaUsers.Models.Email["attachments"][number] & { uri: string };
};

/** Display an attachment of an arbritrary type */
export const EmailAttachment: React.FunctionComponent<EmailAttachmentProps> = (
  props
) => {
  const type = props.item.type;

  // A PDF
  if (type === "application/pdf") {
    return <Pdf item={props.item} />;
  }
  // An image
  if (type.startsWith("image/")) {
    return <Image item={props.item} />;
  }

  // Something else...
  return <Download item={props.item} />;
};
