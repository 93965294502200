/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import "./UserDevices.css";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Helmet } from "react-helmet";

// User related log groups
const LOG_GROUPS = {
  // API
  "app info": { api: "/aws/lambda/api-dolla-nz-app-info" },
  accounts: {
    api: "/aws/lambda/api-dolla-nz-accounts",
    streams: "/aws/lambda/dolla-streams-accounts",
  },
  auth: { api: "/aws/lambda/api-dolla-nz-auth" },
  akahu: {
    api: "/aws/lambda/api-dolla-nz-akahu",
    webhooks: "/aws/lambda/dolla-akahu-webhooks",
  },
  chats: {
    api: "/aws/lambda/api-dolla-nz-chats",
    streams: "/aws/lambda/dolla-streams-chats",
  },
  contacts: {
    api: "/aws/lambda/api-dolla-nz-contacts",
    streams: "/aws/lambda/dolla-streams-contacts",
  },
  payments: {
    api: "/aws/lambda/api-dolla-nz-payments",
    streams: "/aws/lambda/dolla-streams-payments",
  },
  addressbook: { api: "/aws/lambda/api-dolla-nz-upload-addressbook" },
  users: {
    api: "/aws/lambda/api-dolla-nz-users",
    streams: "/aws/lambda/dolla-streams-users",
  },
  messages: { streams: "/aws/lambda/dolla-streams-messages" },
  "pending payments": {
    streams: "/aws/lambda/dolla-streams-pending-payments",
  },
  router: { streams: "/aws/lambda/dolla-streams-router" },
  settlements: { streams: "/aws/lambda/dolla-streams-settlements" },
};

export const UserLogs: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _user = urlParams.id;
  const startTime = "1800000";
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [loading, setLoading] = React.useState(false);

  const getUser = React.useCallback(() => {
    async function _getUser() {
      setLoading(true);
      const result = await API.get(`/users/${_user}`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    _getUser();
  }, [_user]);

  React.useEffect(() => {
    getUser();
  }, [getUser]);

  const constructStream = (value: string) => value.replace(/\//g, "$252F");

  if (loading || !user) {
    return <Loader />;
  }

  return (
    <div className="UserDevices">
      <Helmet>
        <title>{user.name}'s Logs | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name}'s Log Groups</h3>
      {user.devices.length === 0 ? (
        <p>No Log Groups</p>
      ) : (
        <List
          items={Object.entries(LOG_GROUPS).map(([name, groups]) => ({
            title: <>{name || ""}</>,
            logo: <></>,
            rightElement: (
              <div>
                {Object.entries(groups).map(([k, v]) => {
                  const stream = constructStream(v);
                  const url = `https://ap-southeast-2.console.aws.amazon.com/cloudwatch/home?region=ap-southeast-2#logsV2:log-groups/log-group/${stream}/log-events$3FfilterPattern$3D$2522${_user}$2522$26start$3D-${startTime}`;
                  return (
                    <span style={{ padding: "1rem" }}>
                      <a href={url} target="_blank" rel="noreferrer">
                        {k.toLocaleUpperCase()}
                      </a>
                    </span>
                  );
                })}
              </div>
            ),
          }))}
        />
      )}
    </div>
  );
};
