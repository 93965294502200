/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import "./UserPage.css";
import Tippy from "@tippyjs/react";
import Notes from "@mui/icons-material/Notes";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import Chat from "@mui/icons-material/Chat";
import Contacts from "@mui/icons-material/Contacts";
import Payment from "@mui/icons-material/Payment";
import Devices from "@mui/icons-material/Devices";
import Email from "@mui/icons-material/Email";
import BarChart from "@mui/icons-material/BarChart";
import DeleteForever from "@mui/icons-material/DeleteForever";
import GoogleIcon from "@mui/icons-material/Google";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import Autorenew from "@mui/icons-material/Autorenew";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BalanceIcon from "@mui/icons-material/Balance";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import FormatColorResetIcon from "@mui/icons-material/FormatColorReset";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { DangerModal } from "../../components/DangerModal";
import { StatusModal } from "./components/StatusModal";
import { AccountModal } from "./components/AccountModal";
import { VerifyModal } from "./components/VerifyModal";
import { DeleteUserModal } from "./components/DeleteUserModal";
import { UserDetails } from "./components/UserDetails";
import { InspectorRDB } from "src/components/InspectorRDB";
import { Helmet } from "react-helmet";

export const UserPage: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [loading, setLoading] = React.useState(false);
  const [verified, setVerified] = React.useState(false);
  const [blocked, setBlocked] = React.useState(false);
  const [accounts, setAccounts] = React.useState<dollaUsers.Models.Account[]>(
    []
  );
  const [editing, setEditing] = React.useState<boolean>(false);

  const dangerModal = React.useRef<any>();
  const deleteModal = React.useRef<any>();
  const statusModal = React.useRef<any>();
  const accountModal = React.useRef<any>();
  const verifyModal = React.useRef<any>();

  /** Buttons */
  const changeStatus = async (newStatus: "ACTIVE" | "BLOCKED") => {
    if (!user) {
      alert("No user");
      return;
    }

    if (user.status === newStatus) {
      alert(`same status: ${newStatus}`);
      return;
    }
    const onSubmit = async () => {
      const result = await API.put(`/users/${user._id}/status`, {
        status: newStatus,
      });
      if (!result.json.success) {
        SessionStore.apiErr(result);
      }
      setBlocked(newStatus === "BLOCKED");
    };

    let text;

    switch (newStatus) {
      case "ACTIVE":
        text = "This verifies the user and removes the $100 limit.";
        break;
      case "BLOCKED":
        text =
          "This will lock their account and prevent them from using the app completely.";
        break;
      default:
        text = "something";
        break;
    }

    dangerModal.current.open(
      `Set user status`,
      [
        <>
          This will set <b>{user.name}'s</b> status to <b>{newStatus}</b>.
        </>,
        text,
      ],
      onSubmit
    );
  };

  const verifyUser = async () => {
    if (!user) {
      alert("No user");
      return;
    }

    if (!user?.defaults) {
      alert(`User has no default accounts`);
      return;
    }

    if (!accounts) {
      alert(`User has no accounts`);
      return;
    }

    const onSubmit = async () => {
      if (verified) {
        return;
      }
      const result = await API.put(`/users/${user._id}/verify`, {
        verify: true,
      });
      if (!result.json.success) {
        SessionStore.apiErr(result);
      }
      setVerified(true);
      getUser();
    };
    getAccounts();
    verifyModal.current.open(user, onSubmit);
  };

  const resetPIN = async () => {
    if (!user) {
      return;
    }

    const onSubmit = async () => {
      const result = await API.delete(`/users/${user._id}/pin`);
      if (!result.json.success) {
        SessionStore.apiErr(result);
      }
      getUser();
    };

    dangerModal.current.open(
      `Are you sure?`,
      [
        <>
          This will reset <b>{user.name}'s</b> PIN.
        </>,
        `The current PIN & API tokens will be deleted forcing a logout on all devices.`,
      ],
      onSubmit
    );
  };

  const resetAvatar = async () => {
    if (!user) {
      return;
    }

    const onSubmit = async () => {
      const payload = {
        avatar_url: "https://static.dolla.nz/images/single.png",
      };
      const result = await API.put(`/users/${user._id}/edit`, payload);
      if (!result.json.success) {
        SessionStore.apiErr(result);
      }
      getUser();
    };

    dangerModal.current.open(
      `Are you sure?`,
      [
        <>
          This will reset <b>{user.name}'s</b> avatar to the default image.
        </>,
      ],
      onSubmit
    );
  };

  const refreshCounters = async () => {
    if (!user) {
      return;
    }
    const result = await API.post(`/users/${user._id}/counters`);
    if (!result.json.success) {
      SessionStore.apiErr(result);
    }
    getUser();
  };

  const createDollaStoreChat = async () => {
    if (!user) {
      return;
    }
    setLoading(true);
    const result = await API.post(`/users/${user._id}/chats/dollastore`);
    if (!result.json.success) {
      SessionStore.apiErr(result);
    }
    getUser();
  };

  const markOnboarded = async () => {
    if (!user) {
      return;
    }
    const _device = user?.devices[0]?._id;
    if (!_device) {
      return;
    }
    const result = await API.put(`/users/${user._id}/onboard`);
    if (!result.json.success) {
      SessionStore.apiErr(result);
    }
    getUser();
  };

  const deleteUser = async () => {
    if (!user) {
      return;
    }

    deleteModal.current.open();
  };

  /** get Items */
  const getUser = React.useCallback(() => {
    async function getUser() {
      setLoading(true);
      const result = await API.get(`/users/${_user}`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBlocked(result.json.item.status === "BLOCKED");
      // Verified if the has the appropriate props and defaults set up
      setVerified(
        result.json.item?.name_verified_at !== undefined &&
          result.json.item.status === "ACTIVE"
      );
      if (result.json.item) {
        setUser(result.json.item);
      } else {
        SessionStore.setError("This user doesn't exist");
        return;
      }
    }
    getUser();
  }, [_user, blocked, verified]);
  React.useEffect(() => {
    getUser();
  }, [getUser]);

  const getAccounts = React.useCallback(() => {
    async function getAccounts() {
      const result = await API.get(`/users/${_user}/accounts`);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setAccounts(result.json.items);
    }
    getAccounts();
  }, [user]);
  React.useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  if (loading || !user || !accounts) {
    return <Loader />;
  }

  return (
    <div className="UserPage">
      <Helmet>
        <title>{user?.name ?? "Someone"} | Dolla Management Console</title>
      </Helmet>
      {/* Header */}
      <h3>
        <img className="avatar" src={user.avatar_url} alt="" />
        {`${user.first_name} ${user.last_name} ${
          user.preferred_name !== user.first_name && user.preferred_name
            ? `(${user.preferred_name})`
            : ""
        }`}
        <span className="tag">${user.tag}</span>

        <Tippy content={"Inspect this item"}>
          <InspectorRDB _id={user._id} />
        </Tippy>
        <Tippy content={`Google ${user.name}`}>
          <a
            className="titleLink"
            href={`https://www.google.com/search?q=${encodeURIComponent(
              user.name + " nz"
            )}`}
            title="Google"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GoogleIcon fontSize="inherit" />
          </a>
        </Tippy>
        <Tippy content={"Edit"}>
          <a
            className="titleLink"
            onClick={() => setEditing(!editing)}
            title="Edit"
          >
            <EditIcon fontSize="inherit" />
          </a>
        </Tippy>
        <Tippy content={"Delete user"}>
          <a className="titleLink" onClick={deleteUser} title="Delete">
            <DeleteForever fontSize="inherit" />
          </a>
        </Tippy>
      </h3>
      {/* Table */}
      <UserDetails
        user={user}
        refreshUser={getUser}
        statusModal={statusModal}
        editing={editing}
        toggleEdit={() => setEditing(!editing)}
      />
      {/* Buttons */}
      <div className="buttonRow">
        <button onClick={() => verifyUser()}>
          {verified ? "Parties" : "Verify"}
        </button>
        <button onClick={() => changeStatus(blocked ? "ACTIVE" : "BLOCKED")}>
          {blocked ? "Set to active" : "Block user"}
        </button>
        <button onClick={resetPIN}>Reset PIN</button>
        <button onClick={resetAvatar}>Reset Avatar</button>
        <button onClick={refreshCounters}>Refresh Counters</button>
        <button onClick={createDollaStoreChat}>
          Create chat with DollaStore
        </button>
        {user.status === "ONBOARDING" && (
          <button onClick={markOnboarded}>Mark onboarded</button>
        )}
      </div>
      {/* Links */}
      <div className="linklist vertical">
        <Link to={`/users/${user._id}/logs`}>
          <Notes className="icon" />
          Logs
        </Link>
        <Link to={`/users/${user._id}/accounts`}>
          <AccountBalanceWallet className="icon" />
          Accounts
        </Link>
        <Link to={`/users/${user._id}/parties`}>
          <PermIdentityIcon className="icon" />
          Parties
        </Link>
        <Link to={`/users/${user._id}/chats`}>
          <Chat className="icon" />
          Chats
        </Link>
        <Link to={`/users/${user._id}/contacts`}>
          <Contacts className="icon" />
          Contacts
        </Link>
        <Link to={`/users/${user._id}/devices`}>
          <Devices className="icon" />
          Devices
        </Link>
        <Link to={`/users/${user._id}/emails`}>
          <Email className="icon" />
          Emails
        </Link>
        <Link to={`/users/${user._id}/payments-v3`}>
          <Payment className="icon" />
          Payments V3
        </Link>
        <Link to={`/users/${user._id}/payments`}>
          <Payment className="icon" />
          Payments
        </Link>
        <Link to={`/users/${user._id}/payment-links`}>
          <LinkIcon className="icon" />
          Payment Links
        </Link>
        <Link to={`/users/${user._id}/subscriptions`}>
          <Autorenew className="icon" />
          Subscriptions
        </Link>
        <Link to={`/users/${user._id}/transactions`}>
          <AccountBalanceIcon className="icon" />
          Transactions
        </Link>
        <Link to={`/users/${user._id}/triggers`}>
          <BalanceIcon className="icon" />
          Triggers
        </Link>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://mixpanel.com/project/2798409/view/3333065/app/profile#distinct_id=${user._id}`}
        >
          <BarChart className="icon" />
          Mixpanel
        </a>
        <Link to={`/users/${user._id}/xero`}>
          <FormatColorResetIcon className="icon" />
          Xero
        </Link>
      </div>
      <DangerModal openRef={dangerModal} />
      <DeleteUserModal
        openRef={deleteModal}
        user={user}
        refreshUser={getUser}
      />
      <StatusModal openRef={statusModal} />
      <AccountModal openRef={accountModal} reloadAccounts={getAccounts} />
      <VerifyModal openRef={verifyModal} />
    </div>
  );
};
