import * as React from "react";

import { withSessionStore } from "../utils/session";

export const OnlyDev: React.FunctionComponent<{
  children: React.ReactChild[] | React.ReactChild;
}> = withSessionStore((props) =>
  props.SessionStore.user?.dev ? props.children : <></>
);
export const NotDev: React.FunctionComponent<{
  children: React.ReactChild[] | React.ReactChild;
}> = withSessionStore((props) =>
  props.SessionStore.user?.dev ? <></> : props.children
);
