import * as React from "react";
import QRCode from "react-qr-code";
import "./Login.css";

import { SessionStore } from "src/utils/session";
import API from "../../utils/api";
import { Loader } from "../../components/Loader";
import { Helmet } from "react-helmet";

export const LoginPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);
  const [otpUri, setOtpUri] = React.useState<string | null>(null);
  const [enterOtp, setEnterOtp] = React.useState(false);
  const [setupOtp, setSetupOtp] = React.useState(false);

  // Submit a login
  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setOtpUri(null);
    setEnterOtp(false);
    setSetupOtp(false);
    setOtp("");
    setError(null);

    const result = await API.post("/auth", {
      username,
      password,
      otp,
    });
    if (result.json?.success) {
      // If we need to change our password, redirect to there once we're logged in
      if (result.json.item.change_password) {
        window.sessionStorage.setItem(
          "dolla_management_login_location",
          `/mgmt/users/${result.json.item.user._id}`
        );
      }
      SessionStore.setState({
        user: result.json.item.user,
        _token: result.json.item._token,
        token_expiry: result.json.item.expires_at,
        change_password: result.json.item.change_password,
      });
      window.location.reload();
    } else {
      setLoading(false);

      if (result.json?.message === "invalid_credentials") {
        setError("Incorrect credentials");
      } else if (result.json?.message === "missing_otp") {
        setError(null);
        setEnterOtp(true);
      } else if (result.json?.message === "otp_setup") {
        setError(otp ? "Incorrect code, try again" : null);
        setSetupOtp(true);
        setOtpUri(result.json.item.uri);
      } else if (result.json?.message === "locked_out") {
        setError("You have been locked out");
      } else if (result.json?.message === "invalid_username") {
        setError("Invalid Username");
      } else {
        setError(`API error (${result.status})`);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="Login">
      <Helmet>
        <title>Log In | Dolla Management Console</title>
      </Helmet>
      {error ? <p className="error">{error}</p> : <></>}
      {enterOtp ? (
        <form onSubmit={submit}>
          <h3>Enter 2FA</h3>
          <input
            type="text"
            name="otp"
            placeholder="2FA Code"
            value={otp}
            onChange={(event) =>
              setOtp(event.target.value.replace(/[^0-9]/g, ""))
            }
            autoComplete="off"
            autoFocus
          />
          <input type="submit" value="Continue" />
        </form>
      ) : setupOtp && otpUri ? (
        <form onSubmit={submit}>
          <h3>Set Up 2FA</h3>
          <p>Scan the code below and enter a generated code to continue</p>
          <QRCode value={otpUri} size={200} />
          <input
            type="text"
            name="otp"
            placeholder="Code"
            value={otp}
            onChange={(event) =>
              setOtp(event.target.value.replace(/[^0-9]/g, ""))
            }
            autoComplete="off"
            autoFocus
          />
          <input type="submit" value="Continue" />
        </form>
      ) : (
        <form onSubmit={submit}>
          <h3>Sign In</h3>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            autoComplete="off"
            autoFocus
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            autoComplete="off"
          />
          <input type="submit" value="Log In" />
        </form>
      )}
    </div>
  );
};
