import * as React from "react";

import { withSessionStore } from "../utils/session";

export const OnlyAdmin: React.FunctionComponent<{
  children: React.ReactChild[] | React.ReactChild;
}> = withSessionStore((props) =>
  props.SessionStore.user?.admin ? props.children : <></>
);
export const NotAdmin: React.FunctionComponent<{
  children: React.ReactChild[] | React.ReactChild;
}> = withSessionStore((props) =>
  props.SessionStore.user?.admin ? <></> : props.children
);
