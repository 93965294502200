/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import { InfiniteScrollLoader } from "../../components/InfiniteScrollLoader";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Helmet } from "react-helmet";
import { List } from "src/components/List";
import * as RDB from "rdb";
import { Link, useNavigate, useParams } from "react-router-dom";

export const XeroContactsPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _tenant = urlParams.id;
  const [cursor, setCursor] = React.useState(null);
  const [extraPages, setExtraPages] = React.useState<RDB.Models.XeroContact[]>(
    []
  );
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log("XeroContactsPage useEffect", _tenant);
    getNextPage();
  }, [_tenant]);

  const getNextPage = async () => {
    if (cursor === "null" || !_tenant) {
      return;
    }
    setLoading(true);
    const finalQuery = cursor ? `?cursor=${cursor}` : "";

    const result = await API.get(`/xero/${_tenant}/contacts${finalQuery}`);

    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }

    const newResults: RDB.Models.XeroContact[] = result.json.items;
    setCursor(result.json.cursor);
    setLoading(false);
    setExtraPages([...extraPages, ...newResults]);
  };

  return (
    <div className="SearchUsersPage">
      <Helmet>
        <title>Xero Contacts | Dolla Management Console</title>
      </Helmet>
      <Link to={`/xero/${_tenant}`} className="backlink">
        ← Back to Xero org
      </Link>
      <div className="titleRow">
        <h3>Xero Contacts</h3>
      </div>
      <InfiniteScrollLoader
        next={getNextPage}
        loading={loading}
        done={cursor === "null"}
      >
        <>
          {extraPages?.length ? (
            <List
              items={extraPages.map((c) => ({
                title: c.name,
                subtitle: `${c._id} ${c._entity ? `| ${c._entity}` : ""}`,
                onClick: () =>
                  navigate(
                    `/universal/rdb/${c._id}?_tenant=${_tenant}&table=xero_contacts`
                  ),
              }))}
            />
          ) : (
            <></>
          )}
        </>
      </InfiniteScrollLoader>
    </div>
  );
};
