/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import "./UserTransactions.css";
import { orderBy } from "lodash-es";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Helmet } from "react-helmet";
import moment from "moment";
import numeral from "numeral";
import type { EnrichedTransaction } from "akahu";
import SupportIcon from "@mui/icons-material/Support";
import { TransactionSupportModal } from "./components/TransactionSupportModal";

export const UserTransactions: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [transactions, setTransactions] = React.useState<EnrichedTransaction[]>(
    []
  );
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingTransactions, setLoadingTransactions] = React.useState(false);
  const TransactionModal = React.useRef<any>(null);

  const excludedTypes = ["TRANSFER", "ATM", "TAX", "FEE"];
  const stats = {
    total: transactions.length,
    debits: transactions.filter((t) => t.amount < 0).length,
    potential: transactions.filter(
      (t) => t.amount < 0 && !excludedTypes.includes(t.type)
    ).length,
    enriched: transactions.filter((t) => t.merchant).length,
    enrichedPercent: (
      (transactions.filter((t) => t.merchant).length /
        transactions.filter(
          (t) => t.amount < 0 && !excludedTypes.includes(t.type)
        ).length) *
      100
    ).toFixed(1),
  };

  React.useEffect(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    getUser();
  }, [_user]);

  const getSubs = React.useCallback(() => {
    async function getSubs() {
      setLoadingTransactions(true);
      const result = await API.get(`/users/${_user}/transactions`);
      setLoadingTransactions(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setTransactions(result.json.items);
    }
    getSubs();
  }, [_user]);
  React.useEffect(() => {
    getSubs();
  }, [getSubs]);

  if (loadingUser || loadingTransactions || !user) {
    return <Loader />;
  }

  return (
    <div className="UserTransactions">
      <Helmet>
        <title>{user.name}'s Transactions | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name} Transactions</h3>
      {transactions.length && (
        <p className="transactionStats">
          <>
            {stats.total} total &bull; {stats.debits} debits &bull;{" "}
            {stats.potential} potentially enrichable &bull; {stats.enriched}{" "}
            enriched &bull; {stats.enrichedPercent}% enriched
          </>
        </p>
      )}
      {!transactions.length ? (
        <p>No Transactions</p>
      ) : (
        <List
          items={orderBy(transactions, ["date"], ["desc"]).map(
            (transaction) => ({
              title: (
                <span>
                  {`${transaction.description}${
                    transaction.merchant?.name
                      ? " | " + transaction.merchant.name
                      : ""
                  }${
                    transaction.meta?.other_account
                      ? " | " + transaction.meta.other_account
                      : ""
                  } | ${transaction._id}`}
                  <a
                    className="support"
                    onClick={() => {
                      TransactionModal.current?.open(transaction);
                    }}
                    title="Transaction Support"
                  >
                    <SupportIcon fontSize="inherit" />
                  </a>
                </span>
              ),
              rightElement: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  <div>{moment(transaction.date).format("DD/MM/YYYY")}</div>

                  <div>{`${numeral(transaction.amount).format(
                    "$0,00[.]00"
                  )}`}</div>
                </div>
              ),
              logo:
                transaction?.meta?.logo ??
                "http://static.dolla.nz/images/piggy-bank.png",
              subtitle: `${transaction.type} ${
                transaction.category ? `| ${transaction.category.name}` : ""
              }`,
            })
          )}
        />
      )}
      <TransactionSupportModal openRef={TransactionModal} user={user} />
    </div>
  );
};
