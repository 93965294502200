/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";

import { Loader } from "../../../components/Loader";
import API from "../../../utils/api";
import { SessionStore } from "../../../utils/session";

import { managementDB } from "../../../../../../lambdas/utils-common/src";

type ScriptListProps = {
  onSelect: (script: managementDB.Models.Script) => void;
  reloadRef: React.MutableRefObject<any>;
};

export const ScriptList: React.FunctionComponent<ScriptListProps> = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [scripts, setScripts] = React.useState<managementDB.Models.Script[]>(
    []
  );

  React.useEffect(() => {
    const load = async () => {
      setLoading(true);
      const results = await API.get("/scripts");
      setLoading(false);
      if (results.json.success) {
        setScripts(results.json.items);
      } else {
        SessionStore.apiErr(results);
      }
    };

    if (props.reloadRef) {
      props.reloadRef.current = {
        reload: load,
      };
    }

    load();
  }, [props.reloadRef]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader size="medium" />
      ) : (
        scripts.map((script) => (
          <a onClick={props.onSelect.bind(null, script)} key={script._id}>
            {script.name}
          </a>
        ))
      )}
    </React.Fragment>
  );
};
