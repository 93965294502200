/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./SearchFilters.css";
import { set, unset } from "lodash-es";
import { TippyExplainer } from "./TippyExplainer";

export type SearchFiltersProps = {
  onChange: any;
  filters?: any;
};

/**
 * A search filter component
 */
export const SearchFilters: React.FunctionComponent<SearchFiltersProps> = (
  props
) => {
  const { filters } = props;

  const onChange = (path: any, value: any) => {
    if (value === "") {
      unset(filters, path);
      console.log("unset", path, filters);
      props.onChange(filters);
      return;
    }
    set(filters, path, value);
    props.onChange(filters);
  };

  return (
    <div className="filterList">
      <table>
        <tbody>
          <tr>
            <td>
              <b>Filters:</b>
            </td>
          </tr>
          <tr>
            <td>
              <div className="inner">
                <span>
                  Status
                  <TippyExplainer>
                    The user status:
                    <br />
                    All - All statuses (default)
                    <br />
                    ACTIVE - Active users, users that have completed the
                    onboarding process.
                    <br />
                    ONBOARDED - Users still in the onboarding process.
                    <br />
                    BLOCKED - Users that are barred from Dolla.
                  </TippyExplainer>{" "}
                  <select
                    value={filters?.status ?? ""}
                    onChange={(evt) => onChange("status", evt.target.value)}
                  >
                    <option value="ALL">All</option>
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="ONBOARDING">ONBOARDING</option>
                    <option value="BLOCKED">BLOCKED</option>
                    <option value="DELETED">DELETED</option>
                  </select>
                </span>
              </div>
            </td>
            <td>
              <div className="inner">
                <span>
                  Plan
                  <TippyExplainer>
                    The user's subscription plan
                    <br />
                    All - All subscription plans (default)
                    <br />
                    BASIC - todo.
                    <br />
                    PREMIUM - todo.
                  </TippyExplainer>{" "}
                  <select
                    value={filters?.plan ?? ""}
                    onChange={(evt) => onChange("plan", evt.target.value)}
                  >
                    <option value="ALL">All</option>
                    <option value="BASIC">BASIC</option>
                    <option value="PREMIUM">PREMIUM</option>
                  </select>
                </span>
              </div>
            </td>
            <td>
              <div className="inner">
                <span>
                  Visible
                  <TippyExplainer>
                    Is visible in CloudSearch.
                  </TippyExplainer>{" "}
                  <select
                    value={filters?.visible ?? ""}
                    onChange={(evt) => onChange("visible", evt.target.value)}
                  >
                    <option value="ALL">All</option>
                    <option value="yes">Visible</option>
                    <option value="no">Non-visible</option>
                  </select>
                </span>
              </div>
            </td>
            <td>
              <div className="inner">
                <span>
                  OS<TippyExplainer>iOS or Android.</TippyExplainer>{" "}
                  <select
                    value={filters?.flags?.os ?? ""}
                    onChange={(evt) => onChange("flags.os", evt.target.value)}
                  >
                    <option value="ALL">All</option>
                    <option value="IOS">iOS</option>
                    <option value="ANDROID">android</option>
                  </select>
                </span>
              </div>
            </td>
          </tr>
          <tr className="checkbox">
            <td>
              <div className="inner">
                <span>
                  Verified
                  <TippyExplainer>
                    Has been verified by the Dolla team.
                  </TippyExplainer>{" "}
                  <select
                    value={filters?.verified ?? ""}
                    onChange={(evt) => onChange("verified", evt.target.value)}
                  >
                    <option value="ALL">All</option>
                    <option value="yes">yes</option>
                    <option value="no">no</option>
                  </select>
                </span>
              </div>
            </td>
            <td>
              <div className="inner">
                <span>
                  Defaults
                  <TippyExplainer>
                    Has set a default account.
                  </TippyExplainer>{" "}
                  <select
                    value={filters?.flags?.defaults ?? ""}
                    onChange={(evt) =>
                      onChange("flags.defaults", evt.target.value)
                    }
                  >
                    <option value="ALL">All</option>
                    <option value="yes">yes</option>
                    <option value="no">no</option>
                  </select>
                </span>
              </div>
            </td>
            <td>
              <div className="inner">
                <span>
                  Provider
                  <TippyExplainer>
                    Which provider does this user have.
                  </TippyExplainer>{" "}
                  <select
                    value={filters?.flags?.provider ?? ""}
                    onChange={(evt) =>
                      onChange("flags.provider", evt.target.value)
                    }
                  >
                    <option value="ALL">All</option>
                    <option value="AKAHU">Akahu</option>
                    <option value="MANUAL">Manual</option>
                  </select>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
