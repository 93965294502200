/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./UserDetails.css";

import API from "../../../utils/api";
import { SessionStore } from "../../../utils/session";
import { Loader } from "../../../components/Loader";
import { UserList } from "../../../components/UserList";

import { dollaUsers } from "../../../../../../lambdas/utils-common";
import { TippyExplainer } from "src/components/TippyExplainer";
import CheckCircle from "@mui/icons-material/CheckCircle";
import moment from "moment";
import { WebhookStatus } from "./WebhookStatus";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import AddCircle from "@mui/icons-material/AddCircle";

type UserDetailsProps = {
  user?: dollaUsers.Models.User;
  refreshUser: () => void;
  statusModal: React.MutableRefObject<any>;
  editing: boolean;
  toggleEdit: () => void;
};

export const UserDetails: React.FunctionComponent<UserDetailsProps> = (
  props
) => {
  const { user, refreshUser, statusModal, editing, toggleEdit } = props;
  const device = user?.devices
    ?.sort((a, b) => Date.parse(a.last_seen_at) - Date.parse(b.last_seen_at))
    ?.reverse()?.[0];
  const [loading, setLoading] = React.useState(false);
  /** Editable fields */
  const [firstname, setFirstname] = React.useState("");
  const [middlename, setMiddlename] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [preferredname, setPreferredname] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [planType, setPlanType] = React.useState("");
  const [featureFlags, setFeatureFlags] = React.useState<string[]>([]);

  // If we're starting to edit, reset all fields
  React.useEffect(() => {
    if (editing && user) {
      const {
        first_name,
        middle_name,
        last_name,
        preferred_name,
        tag,
        email,
        mobile,
        plan,
        feature_flags,
      } = user;
      setFirstname(first_name ?? "");
      setMiddlename(middle_name ?? "");
      setLastname(last_name ?? "");
      setPreferredname(preferred_name ?? "");
      setTag(tag ?? "");
      setEmail(email ?? "");
      setMobile(mobile ?? "");
      setPlanType(plan.type ?? "");
      setFeatureFlags(feature_flags ?? []);
    }
  }, [editing, user]);

  /** Save the updates to this user */
  const updateUser = async () => {
    if (!user) {
      return;
    }

    // Form new `name` if firstname or lastname has changed.
    const name = `${preferredname} ${lastname}`;
    // Put together the payload we want to send
    const editPayload = {
      // Optional fields
      ...(middlename !== user.middle_name && { middle_name: middlename }),
      // Everything else
      ...(preferredname &&
        preferredname !== user.preferred_name && {
          preferred_name: preferredname,
        }),
      ...(firstname &&
        firstname !== user.first_name && { first_name: firstname }),
      ...(lastname && lastname !== user.last_name && { last_name: lastname }),
      ...(tag &&
        tag !== user.tag && {
          tag: tag,
        }),
      ...(name && name !== user.name && { name }),
      ...(email && email !== user.email && { email }),
      ...(mobile && mobile !== user.mobile && { mobile }),
      ...(planType !== user.plan.type && { plan: { type: planType } }),
      feature_flags: featureFlags.filter((f) => !!f).map((f) => f.trim()),
    } as Record<string, any>;

    if (!Object.keys(editPayload).length) {
      toggleEdit();
      return;
    }

    const onSubmit = async () => {
      setLoading(true);
      console.log("EditPayload", editPayload);
      const result = await API.put(`/users/${user._id}/edit`, editPayload);
      setLoading(false);
      if (!result.json.success) {
        SessionStore.apiErr(result);
        return;
      }
      refreshUser();
      toggleEdit();
    };

    const flat = (obj: any, out: any) => {
      Object.keys(obj).forEach((key) => {
        if (Array.isArray(obj[key])) {
          out[key] = obj[key].join();
          console.log(out[key]);
        } else if (typeof obj[key] == "object") {
          out = flat(obj[key], out);
        } else {
          out[key] = obj[key];
        }
      });
      return out;
    };

    const indexableUser = user as Record<string, any>;

    statusModal.current.open(
      `Update User`,
      `This will update the following properties for ${user.name}'s account:`,
      [
        Object.keys(flat(editPayload, {})).map((key: string) => (
          <p>
            <b>{key === "type" ? "plan" : key}</b>:{" "}
            <span style={{ fontSize: "14px" }}>
              {key === "type"
                ? planType
                : Array.isArray(editPayload[key])
                ? editPayload[key].join(", ")
                : editPayload[key]}{" "}
              {indexableUser[key] ? <i>({indexableUser[key]})</i> : ""}
            </span>
          </p>
        )),
      ],
      onSubmit
    );
  };

  if (loading || !user) {
    return <Loader />;
  }

  const status = {
    ACTIVE: "Active",
    BLOCKED: "Blocked",
    ONBOARDING: "Onboarding",
    DELETED: "Deleted",
  }[user.status];

  const addFeature = () => setFeatureFlags([...featureFlags, ""]);

  const removeFeature = (index: number) =>
    setFeatureFlags(featureFlags.filter((_, i) => i !== index));

  const updateFeature = (index: number, value: string) => {
    const newFeatureFlags = [...featureFlags];
    newFeatureFlags[index] = value.toUpperCase().replaceAll(" ", "_");
    setFeatureFlags(newFeatureFlags);
  };

  return (
    <table className="UserDetails">
      <tbody>
        {editing ? (
          <>
            <tr>
              <td>First name</td>
              <td>
                <input
                  type="text"
                  value={firstname}
                  onChange={(evt) => setFirstname(evt.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Middle name</td>
              <td>
                <input
                  type="text"
                  value={middlename}
                  onChange={(evt) => setMiddlename(evt.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Last name</td>
              <td>
                <input
                  type="text"
                  value={lastname}
                  onChange={(evt) => setLastname(evt.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Preferred name</td>
              <td>
                <input
                  type="text"
                  value={preferredname}
                  onChange={(evt) => setPreferredname(evt.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Tag</td>
              <td>
                <input
                  type="text"
                  value={tag}
                  onChange={(evt) => setTag(evt.target.value)}
                  maxLength={12}
                />
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <td>Name</td>
              <td>
                {user?.first_name} {user?.middle_name} {user?.last_name}
              </td>
            </tr>
            <tr>
              <td>Preferred name</td>
              <td>{user?.preferred_name}</td>
            </tr>
            <tr>
              <td>Tag</td>
              <td>${user?.tag}</td>
            </tr>
          </>
        )}
        <tr>
          <td>Created</td>
          <td>{moment(user.created_at).fromNow()}</td>
        </tr>
        <tr>
          <td>Last seen</td>
          <td>
            {device ? (
              moment(device?.last_seen_at).fromNow()
            ) : (
              <i>No devices</i>
            )}
          </td>
        </tr>
        <tr>
          <td>User Agent</td>
          <td>{device ? device?.user_agent : <i>Unknown</i>}</td>
        </tr>
        <tr>
          <td>
            <TippyExplainer>
              We have looked at this user and verified that they are legit: The
              account number + contact details are correct.
            </TippyExplainer>{" "}
            Verified
          </td>
          <td>
            {user.name_verified_at ? (
              <span className="green">
                <CheckCircle /> {moment(user.name_verified_at).fromNow()}
              </span>
            ) : (
              <span className="dark-grey">
                <span className="grey">
                  <CheckCircle />
                </span>
                No
              </span>
            )}
          </td>
        </tr>
        <tr>
          <td>Status</td>
          <td>
            <b>
              <span
                className={
                  ["BLOCKED", "DELETED"].includes(user.status)
                    ? "red"
                    : "dark-grey"
                }
              >
                {status ?? user.status}
              </span>
            </b>
          </td>
        </tr>
        <tr>
          <td>Plan</td>
          <td>
            {editing ? (
              <select
                value={planType}
                onChange={(evt) => setPlanType(evt.target.value)}
              >
                <option value="BASIC">BASIC</option>
                <option value="PREMIUM">PREMIUM</option>
              </select>
            ) : (
              user?.plan?.type
            )}
          </td>
        </tr>
        <tr>
          <td>Email</td>
          <td>
            {editing ? (
              <input
                type="text"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
            ) : (
              <a href={`mailto:${user?.email}`}>{user?.email}</a>
            )}
          </td>
        </tr>
        <tr>
          <td>Mobile</td>
          {editing ? (
            <td>
              <input
                type="text"
                value={mobile}
                onChange={(evt) => setMobile(evt.target.value)}
              />
            </td>
          ) : (
            <td>{user?.mobile}</td>
          )}
        </tr>
        <tr>
          <td>
            <TippyExplainer>
              Has this user connected their bank accounts to Dolla?
            </TippyExplainer>{" "}
            Has defaults?
          </td>
          <td>
            {user?.defaults && Object.keys(user?.defaults)?.length ? (
              <span className="green">
                Yes <CheckCircle />
              </span>
            ) : (
              <span className="dark-grey">
                No
                <span className="grey">
                  <CheckCircle />
                </span>
              </span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <TippyExplainer>
              Which providers has this user connected to?
            </TippyExplainer>{" "}
            Providers?
          </td>
          <td>
            {user.providers.map((p) => (
              <span>{p.provider}</span>
            ))}
          </td>
        </tr>
        <tr>
          <td>Counters</td>
          <td>
            {Object.entries(user.counters).map(([k, v]) => (
              <span>
                {k.replace("_", " ")}: <b>{v}</b>
              </span>
            ))}
          </td>
        </tr>
        <tr>
          <td>Features</td>
          <td>
            {editing ? (
              <>
                {featureFlags.map((f, i) => (
                  <span>
                    <input
                      type="text"
                      value={f}
                      onChange={(evt) => {
                        updateFeature(i, evt.target.value);
                      }}
                    />
                    <a onClick={() => removeFeature(i)}>
                      <RemoveCircle />
                    </a>
                  </span>
                ))}
                <a onClick={() => addFeature()}>
                  <AddCircle />
                </a>
              </>
            ) : (
              (user?.feature_flags || []).map((f) => <span>{f}</span>)
            )}
          </td>
        </tr>
        {user.providers.find((p) => p.provider === "AKAHU") && (
          <tr>
            <td>Webhooks</td>
            <td>
              <WebhookStatus _user={user._id} />
            </td>
          </tr>
        )}

        {user.invited_by.length ? (
          <tr>
            <td>Invited By</td>
            <td>
              <UserList _users={user.invited_by} />
            </td>
          </tr>
        ) : (
          <></>
        )}
        {editing ? (
          <tr>
            <td colSpan={2}>
              <div className="buttonRow">
                <button className="button-outline" onClick={() => toggleEdit()}>
                  cancel
                </button>
                <button onClick={updateUser}>Update</button>
              </div>
            </td>
          </tr>
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
};
