/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./DeleteUserModal.css";
import ReactModal from "react-modal";

import API from "../../../utils/api";
import { Loader } from "../../../components/Loader";
import { SkullIcon } from "../../../components/SkullIcon";
import { dollaUsers } from "../../../../../../lambdas/utils-common";
import { useNavigate } from "react-router-dom";

type DeleteUserModalProps = {
  /** A way to expose the `open` method to parents */
  openRef: React.MutableRefObject<any>;
  /** The user ID */
  user: dollaUsers.Models.User;
  /** A callback to refresh the user */
  refreshUser: () => void;
};

/** A big search bar, with full-page results */
export const DeleteUserModal: React.FunctionComponent<DeleteUserModalProps> = (
  props
) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const nav = useNavigate();
  const { user, refreshUser } = props;

  const deleteUser = async () => {
    if (!user._id) {
      return;
    }
    setLoading(true);
    const result = await API.delete(`/users/${user._id}`);
    if (!result.json?.success) {
      setError(result.json?.message ?? `API error (${result.status})`);
      return;
    }
    refreshUser();
    closeModal();
  };

  const purgeUser = async () => {
    if (!user._id) {
      return;
    }
    setLoading(true);
    const result = await API.delete(`/users/${user._id}/purge`);
    if (!result.json?.success) {
      setError(result.json?.message ?? `API error (${result.status})`);
      return;
    }
    nav(-1);
    closeModal();
  };

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setLoading(false);
  };

  // Pass a reference to the `openModal` to parents
  if (props.openRef) {
    props.openRef.current = { open: openModal };
  }

  const content = loading ? (
    <Loader />
  ) : (
    <>
      {user.status !== "DELETED" ? (
        <div className="dangerSection">
          <b>Soft-Delete {user.preferred_name}</b>
          <span>
            This will put the user in a deleted state. Their tag will still be
            taken, and they will automatically leave any chats. All Akahu access
            will be revoked. This is suitable for a user who wants to leave
            Dolla.
          </span>
          <button onClick={() => deleteUser()}>Soft Delete {user.name}</button>
        </div>
      ) : (
        <></>
      )}
      <div className="dangerSection">
        <b>Purge {user.preferred_name}</b>
        <span>
          This will totally destroy all of this user's data, including payments
          and chats (including mirrors). It is extremely destructive. Suitable
          for deleting a test user created for development purposes.
        </span>
        <button onClick={() => purgeUser()}>Purge {user.name}</button>
      </div>
    </>
  );

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      overlayClassName="DeleteUserModal modal_overlay"
      className="DeleteUserModal modal"
    >
      <>
        <h1 style={{ textAlign: "center" }}>
          <SkullIcon color="#d53b3b" /> Delete {user.name}{" "}
          <SkullIcon color="#d53b3b" />
        </h1>
        {error ? <p className="red">{error}</p> : <></>}
        {content}
      </>
    </ReactModal>
  );
};
