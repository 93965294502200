/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./SmallSearch.css";

import { SearchBase, SearchProps } from "./SearchBase";
import { Loader } from "./Loader";

export type SmallSearchProps<Result = any> = Omit<
  SearchProps<Result>,
  "renderResults"
> & {
  /** Turn a result into a rendered item */
  renderItem: (result: Result, selected: boolean) => React.ReactNode;
  /** Clear the input when we select an item */
  clearOnSelect?: boolean;
};

/** A little search bar with results that hover beneath it */
export const SmallSearch: React.FunctionComponent<SmallSearchProps> = (
  props
) => {
  const clearRef = React.useRef<() => void>();

  const onSelect = (item: any) => {
    if (props.onSelect) {
      props.onSelect(item);
    }
    if (props.clearOnSelect && clearRef.current) {
      clearRef.current();
    }
  };
  const renderResults: SearchProps["renderResults"] = (
    items,
    selectedIndex
  ) => {
    return (
      <div className="results">
        {items.map((item, i) => (
          <a
            onClick={() => onSelect(item)}
            key={i}
            className={selectedIndex === i ? "selected" : ""}
          >
            {props.renderItem(item, selectedIndex === i)}
          </a>
        ))}
      </div>
    );
  };
  const loading = (
    <div className="inlineLoader">
      <Loader size="small" />
    </div>
  );
  return (
    <div className="SmallSearch">
      <SearchBase
        {...props}
        renderResults={renderResults}
        loading={loading}
        clearRef={clearRef}
        resultsRequireFocus
      />
    </div>
  );
};
