import storeWrapper from "./storeWrapper";
import { omit } from "lodash-es";

// The build system doesn't support aliases, so we have to use this big ugly import
import { managementDB } from "../../../../lambdas/utils-common";

import { CustomResponse } from "./api";

const DEFAULTS = {
  wide: false,
};

type SessionState = {
  /** The currently logged in user */
  user?: managementDB.Models.User;
  /** The user's api token */
  _token?: string;
  /** Timestamp when the token expires */
  token_expiry?: string;
  /** An info alert to show */
  info?: string;
  /** An error alert to show */
  error?: string;
  /** A popup alert to show */
  popup?: string;
  /** A flag saying that the user needs to update their password */
  change_password?: boolean;
  /** Should the page be full-width */
  wide?: boolean;
};

class _SessionStore extends EventTarget {
  state: SessionState = { ...DEFAULTS };

  // Change stuff
  emitChange() {
    this.dispatchEvent(new Event("change"));
  }
  addChangeListener(cb: () => void) {
    this.addEventListener("change", cb);
  }
  removeChangeListener(cb: () => void) {
    this.removeEventListener("change", cb);
  }

  // LocalStorage stuff
  storeLocal() {
    try {
      window.localStorage.setItem(
        "management-dolla-nz-session",
        JSON.stringify(omit(this.state, ["error", "info"]))
      );
    } catch (error) {
      console.log("No localstorage available, not persisting state");
    }
  }
  retreiveLocal() {
    try {
      const sessionStr = window.localStorage.getItem(
        "management-dolla-nz-session"
      );
      if (!sessionStr) {
        throw Error("No session");
      }
      const state = JSON.parse(sessionStr);
      if (state !== undefined) {
        this.state = state;
      }
    } catch (error) {
      console.log("Can't find existing session");
    }
    return this.state;
  }

  // Store stuff
  getState() {
    return this.state;
  }
  setState(newState: Partial<SessionState>) {
    Object.entries(newState).forEach(([key, value]) => {
      (this.state as any)[key] = value;
    });
    this.emitChange();
    this.storeLocal();
  }
  clear() {
    try {
      window.localStorage.removeItem("management-dolla-nz-session");
    } catch (error) {
      console.log("No localstorage available, not persisting state");
    }
    this.state = {};
    this.emitChange();
  }
  delete(key: string) {
    delete (this.state as any)[key];
    this.storeLocal();
    this.emitChange();
  }
  apiErr(response: CustomResponse) {
    this.setState({
      error: response.json?.message
        ? response.json.message
        : `API error (${response.status})`,
    });
  }
  setInfo(info: string) {
    this.setState({
      info,
    });
  }
  setError(error: string) {
    this.setState({
      error,
    });
  }
  setPopup(popup: string) {
    this.setState({
      popup,
    });
  }
}

export const SessionStore = new _SessionStore();
SessionStore.retreiveLocal();

export type SessionStoreProps = { SessionStore: SessionState };

export const withSessionStore = storeWrapper<SessionStoreProps>(
  SessionStore,
  "SessionStore"
);

// Keep session (in memory) in sync with localStorage.
// This keeps separate tabs in sync.
window.addEventListener("storage", () => {
  SessionStore.retreiveLocal();
});

// Remove info + error when page changes
window.addEventListener("popstate", () => {
  SessionStore.delete("info");
  SessionStore.delete("error");
});
window.addEventListener("_pushState", () => {
  SessionStore.delete("info");
  SessionStore.delete("error");
});
window.addEventListener("_replaceState", () => {
  SessionStore.delete("info");
  SessionStore.delete("error");
});
