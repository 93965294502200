/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./VerifyModal.css";
import ReactModal from "react-modal";
// import ContentCopy from "@mui/icons-material/ContentCopy";
// import Check from "@mui/icons-material/Check";
import API from "../../../utils/api";
import { SessionStore } from "../../../utils/session";
import { Loader } from "../../../components/Loader";
import { dollaUsers } from "utils-common";
import CheckCircle from "@mui/icons-material/CheckCircle";
import _ from "lodash-es";

type PartiesTableProps = {
  /** Akahu Parties property to iterate over*/
  item: any;
  /** Consdensed td */
  hideTags?: Boolean;
  /** A component used for hyperlinks */
  rightComponent?: ({ item }: any) => JSX.Element;
};

export const PartiesTable: React.FunctionComponent<PartiesTableProps> = (
  props
) => {
  const { item, hideTags = false, rightComponent } = props;

  if (!item || !item?.length) {
    return (
      <table className="propertyTable">
        <tr>
          <td>
            <i>No Data</i>
            {/* Add tippy */}
          </td>
        </tr>
      </table>
    );
  }

  return (
    <table className="propertyTable">
      {item.map((property: any) => (
        <tr>
          <td>
            {property?.formatted ?? property?.value ?? <i>No Data</i>}{" "}
            {property?.verified && <CheckCircle fontSize="medium" />}
          </td>
          {!hideTags && (
            <td>
              <div className="attributeList">
                {property?.subtype?.map((type: string) => (
                  <span>{type}</span>
                ))}
              </div>
            </td>
          )}
          {rightComponent && <td>{rightComponent({ item: property })}</td>}
        </tr>
      ))}
    </table>
  );
};

type VerifyModalArgs = {
  /** The User object (dolla) */
  user: dollaUsers.Models.User;
  /** Called when the user clicks confirm */
  onConfirm: () => Promise<void> | void;
};

type VerifyModalProps = {
  /** A way to expose the `open` method to parents */
  openRef: React.MutableRefObject<any>;
};

/** A big search bar, with full-page results */
export const VerifyModal: React.FunctionComponent<VerifyModalProps> = (
  props
) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [args, setArgs] = React.useState<VerifyModalArgs>();
  const [parties, setParties] = React.useState<any>(null);

  const confirm = async () => {
    setLoading(true);
    await args?.onConfirm();
    closeModal();
  };

  const openModal = (
    user: VerifyModalArgs["user"],
    onConfirm: VerifyModalArgs["onConfirm"]
  ) => {
    setArgs({ user, onConfirm });
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setLoading(false);
    setArgs(undefined);
  };

  const getParties = React.useCallback(() => {
    const user = args?.user;
    async function getParties() {
      const result = await API.get(`/users/${user?._id}/accounts/parties`);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setParties(result.json.item);
    }
    if (user && user?.defaults) {
      getParties();
    }
  }, [args]);
  React.useEffect(() => {
    getParties();
  }, [getParties]);

  // Filter for unique item and append subtypes
  const uniqueItem = (item: any, sort: string = "value") =>
    _.uniqBy(
      item?.map((i: any) => {
        const types = Array.from(
          new Set(
            item
              .filter((o: any) =>
                o?.formatted
                  ? o?.formatted === i?.formatted
                  : o?.value === i?.value
              )
              .map((o: any) => o?.subtype)
          )
        );
        return {
          ...i,
          subtype: types, // replace with array of subtypes
        };
      }),
      sort
    );
  // Remove duplicate Parties data
  const removeDuplicates = (v: any, i: number, a: any) =>
    a.findIndex((o: any) =>
      o?.formatted ? o?.formatted === v?.formatted : o?.value === v?.value
    ) === i;

  // Pass a reference to the `openModal` to parents
  if (props.openRef) {
    props.openRef.current = { open: openModal };
  }

  const content =
    loading || !parties ? (
      <Loader />
    ) : (
      <>
        <p>
          See essential info about <b>{args?.user?.name}</b>
        </p>
        <table className="infoTable">
          <tbody>
            <tr>
              <td>Name:</td>
              <td>
                <PartiesTable item={parties.map((x: any) => x?.name)} />
              </td>
            </tr>

            <tr>
              <td>DOB:</td>
              <td>
                <PartiesTable item={parties.map((x: any) => x?.dob)} />
              </td>
            </tr>

            <tr>
              <td>Addresses:</td>
              <td>
                <PartiesTable
                  item={parties
                    .map((x: any) => uniqueItem(x?.addresses, "formatted")?.[0])
                    .filter(removeDuplicates)}
                />
              </td>
            </tr>

            <tr>
              <td>Phone:</td>
              <td>
                <PartiesTable
                  item={parties
                    .map(
                      (x: any) => uniqueItem(x?.phone_numbers, "formatted")?.[0]
                    )
                    .filter(removeDuplicates)}
                  hideTags
                />
              </td>
            </tr>

            <tr>
              <td>Email:</td>
              <td>
                <PartiesTable
                  item={parties
                    .map(
                      (x: any) =>
                        uniqueItem(x?.email_addresses, "formatted")?.[0]
                    )
                    .filter(removeDuplicates)}
                  hideTags
                />
              </td>
            </tr>

            <tr>
              <td>Tax number:</td>
              <td>
                <PartiesTable item={parties.map((x: any) => x?.tax_number)} />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="buttonRow">
          <button className="wide blue" onClick={confirm}>
            {args?.user?.name_verified_at ? "Close" : "Approve Account"}
          </button>
        </div>
      </>
    );

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      overlayClassName="AccountModal modal_overlay"
      className="AccountModal modal"
    >
      <>
        <h3>{args?.user?.name_verified_at ? "Parties" : "Verify User"}</h3>
        {content}
      </>
    </ReactModal>
  );
};
