/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import * as RDB from "rdb";
import numeral from "numeral";
import moment from "moment-timezone";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SessionStore } from "src/utils/session";
import { isNil } from "lodash";
import { Link } from "react-router-dom";

type PaymentHistoryTableProps = {
  payments: RDB.Models.Payment[] | undefined;
  onChange: (email: RDB.Models.Email) => void;
};

export const PaymentHistoryTable: React.FunctionComponent<
  PaymentHistoryTableProps
> = (props) => {
  const { payments } = props;

  //@ts-ignore
  const handleSelectValue = async (
    value: string | undefined | null,
    name?: string
  ) => {
    if (isNil(value)) {
      return;
    }
    SessionStore.setPopup(`Copied ${name} "${value}"`);
  };

  if (!payments || !payments.length) {
    return <></>;
  }

  return (
    <table>
      <thead style={{ position: "sticky" }}>
        <tr>
          <td>Payment</td>
          <td>Amount</td>
          <td>Code</td>
          <td>Reference</td>
          <td>Status</td>
          <td>Date</td>
          <td></td>
        </tr>
      </thead>
      <tbody style={{ maxHeight: "20rem", overflow: "auto" }}>
        {payments.map((pmt, i) => (
          <tr>
            <td>
              <Link
                className="attachment"
                to={`/universal/rdb/${pmt._id}`}
                key={pmt._id}
                target="_blank"
              >
                {pmt._id}
              </Link>
            </td>
            <td
            // onClick={() =>
            //   handleSelectValue(
            //     numeral(pmt.amount).format("$0,000[.]00"),
            //     "amount"
            //   )
            // }
            >
              {numeral(pmt.amount).format("$0,000[.]00")}
            </td>
            <td
            // onClick={() => handleSelectValue(pmt?.code, "code")}
            >
              {pmt.code}
            </td>
            <td
            // onClick={() => handleSelectValue(pmt.reference, "reference")}
            >
              {pmt.reference}
            </td>
            <td>{pmt.status}</td>
            <td>
              {moment(pmt?.paid_at ?? pmt.pay_at ?? pmt.created_at).fromNow()}
            </td>
            <td>
              <ContentCopyIcon />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
