import * as React from "react";
import "./PaymentsV3Page.css";
import { groupBy, orderBy } from "lodash-es";

import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { PaymentRowV3 } from "../users/components/PaymentV3Row";
import moment from "moment";
import { Helmet } from "react-helmet";
import * as RDB from "rdb";
import { DangerModal } from "src/components/DangerModal";

// Used for type discrimination

export const PaymentsV3Page: React.FunctionComponent = () => {
  const [payments, setPayments] =
    React.useState<RDB.payments.ManagementConsolePayments>([]);
  const [loadingPayments, setLoadingPayments] = React.useState(false);

  const dangerModal = React.useRef<any>();

  React.useEffect(() => {
    async function getPayments() {
      setLoadingPayments(true);
      const paymentResult = await API.get(`/payments/v3`);

      setLoadingPayments(false);
      if (!paymentResult.json?.success) {
        SessionStore.apiErr(paymentResult);
        return;
      }

      setPayments([...paymentResult.json.items]);
    }
    getPayments();
  }, []);

  if (loadingPayments) {
    return <Loader />;
  }

  /** Given a payment, figure out what sort key to use.
   *
   * We use special sort keys for payments that we want to put in special spots.
   */
  const getSortDate = (pmt: RDB.Models.Payment) =>
    pmt.status === "CANCELLED" ? pmt.updated_at : pmt.order_at;
  const getDateTitle = (pmt: RDB.Models.Payment) => {
    let date = pmt.order_at;
    if (pmt.status === "SCHEDULED" && pmt.pay_at) {
      date = pmt.pay_at;
    }
    if (pmt.status === "CANCELLED" && pmt.updated_at) {
      date = pmt.updated_at;
    }
    return moment(date).format("Do MMM YYYY");
  };

  let groupedByDate: [string, RDB.payments.ManagementConsolePayments][] =
    Object.entries(
      groupBy(orderBy(payments, getSortDate, "desc"), getDateTitle)
    );
  const yesterdayTitle = moment().subtract(1, "day").format("Do MMM YYYY");
  const todayTitle = moment().format("Do MMM YYYY");
  const tomorrowTitle = moment().add(1, "day").format("Do MMM YYYY");
  // Insert an entry for today if one doesn't exist
  if (!groupedByDate.find(([date]) => date === todayTitle)) {
    groupedByDate.push([todayTitle, []]);
  }
  groupedByDate = orderBy(
    groupedByDate,
    ([title, [pmt]]) =>
      pmt ? getSortDate(pmt) : moment(title, "Do MMM YYYY").toISOString(),
    "desc"
  );

  return (
    <div className="PaymentsPage">
      <Helmet>
        <title>Dolla Payments V3 | Dolla Management Console</title>
      </Helmet>
      <h3>Dolla Payments</h3>
      <div className="linkRow">
        <a href="#today">Jump to today</a>
      </div>
      {!payments.length ? (
        <p>No payments</p>
      ) : (
        <div className="payments">
          {groupedByDate.map(([date, pmts]) => (
            <>
              <div className="date">
                {date === yesterdayTitle ? (
                  <>
                    {date}
                    <span className="subtitle">Yesterday</span>
                  </>
                ) : date === todayTitle ? (
                  <>
                    {date}
                    <span className="subtitle" id="today">
                      Today
                    </span>
                  </>
                ) : date === tomorrowTitle ? (
                  <>
                    {date}
                    <span className="subtitle">Tomorrow</span>
                  </>
                ) : (
                  date
                )}
              </div>
              {pmts.map((pmt: any) => {
                return <PaymentRowV3 payment={pmt} ref={dangerModal} />;
              })}
            </>
          ))}
        </div>
      )}
      <DangerModal openRef={dangerModal} />
    </div>
  );
};
