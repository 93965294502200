import * as React from "react";
import "./ChatAvatar.css";

import { dollaUsers } from "../../../../lambdas/utils-common";

type ChatAvatarProps = {
  chat: dollaUsers.Models.Chat;
};

export const ChatAvatar: React.FunctionComponent<ChatAvatarProps> = (props) => {
  if (props.chat.avatar_url) {
    return (
      <img src={props.chat.avatar_url} className="ChatAvatarImage" alt="" />
    );
  }
  const avatars = props.chat.participants.map((x) => x.avatar_url).slice(0, 3);
  const extraPeople = Math.max(props.chat.participants.length - 3, 0);
  return (
    <span className="ChatAvatarStack">
      {avatars.map((avatar_url) => (
        <img src={avatar_url} className="ChatAvatarImage" alt="" />
      ))}
      {extraPeople ? (
        <span className="extraPeople">+{extraPeople}</span>
      ) : (
        <></>
      )}
    </span>
  );
};
