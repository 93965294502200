/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./AccountEditList.css";
import { set } from "lodash-es";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import AddCircle from "@mui/icons-material/AddCircle";
import { TippyExplainer } from "../../../components/TippyExplainer";

import { dollaUsers } from "../../../../../../lambdas/utils-common";

type AccountEditListProps = {
  accounts: Partial<dollaUsers.Models.BusinessAccount>[];
  onChange: (accounts: Partial<dollaUsers.Models.BusinessAccount>[]) => void;
};
export const AccountEditList: React.FunctionComponent<AccountEditListProps> = (
  props
) => {
  const { accounts } = props;

  const onChange = (index: number, path: string, value: any) => {
    const account = accounts[index];
    if (account) {
      accounts[index] = set(account, path, value);
    }
    props.onChange([...accounts]);
  };

  const onChangeMeta = (
    metadataIndex: number,
    accountIndex: number,
    path: string,
    value: any
  ) => {
    const account = accounts[accountIndex];
    if (account) {
      const additional = account.meta?.additional ?? [];
      additional[metadataIndex] = set(additional[metadataIndex], path, value);
      onChange(accountIndex, "meta.additional", additional);
    }
  };

  const removeMetadata = (metadataIndex: number, accountIndex: number) => {
    const account = accounts[accountIndex];
    if (account) {
      const additional = account.meta?.additional ?? [];
      const newAdditional = additional.filter((_, i) => i !== metadataIndex);
      onChange(accountIndex, "meta.additional", newAdditional);
    }
  };

  const addAccount = () => {
    const newAccounts = [
      ...accounts,
      { meta: {}, provider: "MANUAL" as const },
    ];
    props.onChange(newAccounts);
  };
  const removeAccount = (index: number) => {
    const newAccounts = accounts.filter((_acc, i) => i !== index);
    props.onChange(newAccounts);
  };

  const addMetadata = (accountIndex: number) => {
    const account = accounts[accountIndex];
    if (account) {
      const additional = account.meta?.additional ?? [];
      additional.push({
        name: "",
        hint: "",
        validation: "",
        keyboard: "default",
        required: false,
        persistant: false,
        key_string: "",
      });
      onChange(accountIndex, "meta.additional", additional);
    }
  };

  return (
    <div className="AccountEditList">
      {accounts.map((acc, i) => {
        const disabled = acc.provider === "AKAHU";
        return (
          <div className="Account" key={i}>
            {disabled ? (
              <span>⚠️ This account comes from Akahu</span>
            ) : (
              <a className="RemoveAccount" onClick={() => removeAccount(i)}>
                <RemoveCircle fontSize="large" />
              </a>
            )}
            <table>
              <tbody>
                <tr>
                  <td>
                    Name{" "}
                    <TippyExplainer>
                      An optional name to show users when they pay this account.
                      <br />
                      For example, Vodafone might have a "Vodafone Broadband"
                      account and a "Vodafone Mobile" account.
                    </TippyExplainer>
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="(Optional)"
                      value={acc.name ?? ""}
                      onChange={(evt) => onChange(i, "name", evt.target.value)}
                    />
                  </td>
                  <td>
                    <div className="inner">
                      Account Number
                      <input
                        type="text"
                        placeholder="(Required)"
                        value={acc.formatted_account ?? ""}
                        onChange={(evt) =>
                          onChange(i, "formatted_account", evt.target.value)
                        }
                        required
                        disabled={disabled}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    Description{" "}
                    <TippyExplainer>
                      An optional description to show users when they pay this
                      account.
                    </TippyExplainer>
                  </td>
                  <td colSpan={2}>
                    <input
                      type="text"
                      placeholder="(Optional)"
                      value={acc.description ?? ""}
                      onChange={(evt) =>
                        onChange(i, "description", evt.target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Fee Description{" "}
                    <TippyExplainer>
                      An optional description of the fees when paying this
                      account to show users when they pay this account.
                    </TippyExplainer>
                  </td>
                  <td colSpan={2}>
                    <input
                      type="text"
                      placeholder="(Optional)"
                      value={acc.meta?.fee_description ?? ""}
                      onChange={(evt) =>
                        onChange(i, "meta.fee_description", evt.target.value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Minimum{" "}
                    <TippyExplainer>
                      An optional minimum amount when paying this account
                      <br />
                      For example, Digicell has a minimum topup of $10
                    </TippyExplainer>
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="(Optional)"
                      value={acc?.meta?.min ?? ""}
                      onChange={(evt) =>
                        onChange(i, "meta.min", evt.target.value)
                      }
                    />
                  </td>
                  <td>
                    <div className="inner">
                      Maximum{" "}
                      <TippyExplainer>
                        An optional maximum amount when paying this account
                        <br />
                        For example, Digicell has a maximum topup of $30
                      </TippyExplainer>
                      <input
                        type="number"
                        placeholder="(Optional)"
                        value={acc?.meta?.max ?? ""}
                        onChange={(evt) =>
                          onChange(i, "meta.max", evt.target.value)
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    Email template{" "}
                    <TippyExplainer>
                      Override the default email template when a user pays this
                      account
                      <br />
                    </TippyExplainer>
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="(Optional)"
                      value={acc?.email_template ?? ""}
                      onChange={(evt) =>
                        onChange(i, "email_template", evt.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!acc.meta?.KYC}
                      onChange={(evt) =>
                        onChange(i, "meta.KYC", evt.target.checked)
                      }
                    />{" "}
                    Send KYC info with payments
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!acc.default}
                      onChange={(evt) =>
                        onChange(i, "default", evt.target.checked)
                      }
                    />{" "}
                    Default Account
                  </td>
                </tr>
                <tr className="borderTop">
                  <td>Code</td>
                  <td>
                    <div className="inner">
                      Label
                      <input
                        type="text"
                        placeholder="What do you put in this field?"
                        value={acc.meta?.code?.name ?? ""}
                        onChange={(evt) =>
                          onChange(i, "meta.code.name", evt.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inner">
                      Hint
                      <input
                        type="text"
                        placeholder="Where do you find this information?"
                        value={acc.meta?.code?.hint ?? ""}
                        onChange={(evt) =>
                          onChange(i, "meta.code.hint", evt.target.value)
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>
                    <div className="inner">
                      Validation{" "}
                      <TippyExplainer>
                        This uses Regular Expressions. If you need a hand with
                        it, feel free to ask Olly!
                      </TippyExplainer>
                      <input
                        type="text"
                        placeholder="^[0-9]{6}$"
                        value={acc.meta?.code?.validation ?? ""}
                        onChange={(evt) =>
                          onChange(i, "meta.code.validation", evt.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inner">
                      Keyboard Type{" "}
                      <TippyExplainer>
                        What sort of keyboard we should show the user when they
                        go to enter this field:
                        <br />
                        Default - Normal, alphanumeric keyboard.
                        <br />
                        Numeric - Digits 0-9 only.
                        <br />
                        Decimal - Digits 0-9 and ".".
                      </TippyExplainer>
                      <select
                        value={acc.meta?.code?.keyboard ?? "default"}
                        onChange={(evt) =>
                          onChange(i, "meta.code.keyboard", evt.target.value)
                        }
                      >
                        <option value="default">Default</option>
                        <option value="numeric">Numeric</option>
                        <option value="decimal">Decimal</option>
                        <option value="email-address">Email</option>
                        <option value="phone-pad">Phone</option>
                        <option value="url">URL</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>
                    <div className="inner">
                      Default{" "}
                      <TippyExplainer>
                        <p>The default value for this field.</p>
                        <p>
                          As well as static data, this field allows several
                          placeholders:
                        </p>
                        <ul>
                          <li>
                            <b>{"{NAME}"}</b> The user's first + last names,
                            separated by a space
                          </li>
                          <li>
                            <b>{"{FNAME}"}</b> The user's first name
                          </li>
                          <li>
                            <b>{"{LNAME}"}</b> The user's last name
                          </li>
                          <li>
                            <b>{"{INITIALS}"}</b> The user's first + last
                            initials, separated by a space
                          </li>
                          <li>
                            <b>{"{FINITIAL}"}</b> The initial of the user's
                            first name
                          </li>
                          <li>
                            <b>{"{LINITIAL}"}</b> The initial of the user's last
                            name
                          </li>
                          <li>
                            <b>{"{MOBILE}"}</b> The user's mobile number, in
                            national format
                          </li>
                          <li>
                            <b>{"{MOBILE_INT}"}</b> The user's mobile number, in
                            international format, but without the leading "+"
                          </li>
                          <li>
                            <b>{"{TAG}"}</b> The user's dolla tag
                          </li>
                        </ul>
                      </TippyExplainer>
                      <input
                        type="text"
                        placeholder="{MOBILE}"
                        value={acc.meta?.code?.default ?? ""}
                        onChange={(evt) =>
                          onChange(i, "meta.code.default", evt.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!acc.meta?.code?.required}
                      onChange={(evt) =>
                        onChange(i, "meta.code.required", evt.target.checked)
                      }
                    />{" "}
                    This field is required
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!acc.meta?.code?.persistant}
                      onChange={(evt) =>
                        onChange(i, "meta.code.persistant", evt.target.checked)
                      }
                    />{" "}
                    This field is persistant across payments
                  </td>
                </tr>
                <tr className="borderTop">
                  <td>Reference</td>
                  <td>
                    <div className="inner">
                      Label
                      <input
                        type="text"
                        placeholder="What do you put in this field?"
                        value={acc.meta?.reference?.name ?? ""}
                        onChange={(evt) =>
                          onChange(i, "meta.reference.name", evt.target.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inner">
                      Hint
                      <input
                        type="text"
                        placeholder="Where do you find this information?"
                        value={acc.meta?.reference?.hint ?? ""}
                        onChange={(evt) =>
                          onChange(i, "meta.reference.hint", evt.target.value)
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>
                    <div className="inner">
                      Validation{" "}
                      <TippyExplainer>
                        This uses Regular Expressions. If you need a hand with
                        it, feel free to ask Olly!
                      </TippyExplainer>
                      <input
                        type="text"
                        placeholder="^[0-9]{6}$"
                        value={acc.meta?.reference?.validation ?? ""}
                        onChange={(evt) =>
                          onChange(
                            i,
                            "meta.reference.validation",
                            evt.target.value
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="inner">
                      Keyboard Type{" "}
                      <TippyExplainer>
                        What sort of keyboard we should show the user when they
                        go to enter this field:
                        <br />
                        Default - Normal, alphanumeric keyboard.
                        <br />
                        Numeric - Digits 0-9 only.
                        <br />
                        Decimal - Digits 0-9 and ".".
                      </TippyExplainer>
                      <select
                        value={acc.meta?.reference?.keyboard ?? "default"}
                        onChange={(evt) =>
                          onChange(
                            i,
                            "meta.reference.keyboard",
                            evt.target.value
                          )
                        }
                      >
                        <option value="default">Default</option>
                        <option value="numeric">Numeric</option>
                        <option value="decimal">Decimal</option>
                        <option value="email-address">Email</option>
                        <option value="phone-pad">Phone</option>
                        <option value="url">URL</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>
                    <div className="inner">
                      Default{" "}
                      <TippyExplainer>
                        <p>The default value for this field.</p>
                        <p>
                          As well as static data, this field allows several
                          placeholders:
                        </p>
                        <ul>
                          <li>
                            <b>{"{NAME}"}</b> The user's first + last names,
                            separated by a space
                          </li>
                          <li>
                            <b>{"{FNAME}"}</b> The user's first name
                          </li>
                          <li>
                            <b>{"{LNAME}"}</b> The user's last name
                          </li>
                          <li>
                            <b>{"{INITIALS}"}</b> The user's first + last
                            initials, separated by a space
                          </li>
                          <li>
                            <b>{"{FINITIAL}"}</b> The initial of the user's
                            first name
                          </li>
                          <li>
                            <b>{"{LINITIAL}"}</b> The initial of the user's last
                            name
                          </li>
                          <li>
                            <b>{"{MOBILE}"}</b> The user's mobile number, in
                            national format
                          </li>
                          <li>
                            <b>{"{MOBILE_INT}"}</b> The user's mobile number, in
                            international format, but without the leading "+"
                          </li>
                          <li>
                            <b>{"{TAG}"}</b> The user's dolla tag
                          </li>
                        </ul>
                      </TippyExplainer>
                      <input
                        type="text"
                        placeholder="{LNAME}"
                        value={acc.meta?.reference?.default ?? ""}
                        onChange={(evt) =>
                          onChange(
                            i,
                            "meta.reference.default",
                            evt.target.value
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!acc.meta?.reference?.required}
                      onChange={(evt) =>
                        onChange(
                          i,
                          "meta.reference.required",
                          evt.target.checked
                        )
                      }
                    />{" "}
                    This field is required
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!acc.meta?.reference?.persistant}
                      onChange={(evt) =>
                        onChange(
                          i,
                          "meta.reference.persistant",
                          evt.target.checked
                        )
                      }
                    />{" "}
                    This field is persistant across payments
                  </td>
                </tr>
                {acc.meta?.additional?.map((add, j) => {
                  return (
                    <>
                      <tr className="borderTop">
                        <td></td>
                        <td>
                          <div className="inner">
                            Name
                            <input
                              type="text"
                              placeholder="What do you put in this field?"
                              value={add.name ?? ""}
                              onChange={(evt) =>
                                onChangeMeta(j, i, "name", evt.target.value)
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="inner">
                            Hint
                            <input
                              type="text"
                              placeholder="Where do you find this information?"
                              value={add.hint ?? ""}
                              onChange={(evt) =>
                                onChangeMeta(j, i, "hint", evt.target.value)
                              }
                            />
                          </div>
                        </td>
                        <a onClick={() => removeMetadata(j, i)}>
                          <RemoveCircle />
                        </a>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>
                          <div className="inner">
                            Validation{" "}
                            <TippyExplainer>
                              This uses Regular Expressions. If you need a hand
                              with it, feel free to ask Olly!
                            </TippyExplainer>
                            <input
                              type="text"
                              placeholder="^[0-9]{6}$"
                              value={add.validation ?? ""}
                              onChange={(evt) =>
                                onChangeMeta(
                                  j,
                                  i,
                                  "validation",
                                  evt.target.value
                                )
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="inner">
                            Keyboard Type{" "}
                            <TippyExplainer>
                              What sort of keyboard we should show the user when
                              they go to enter this field:
                              <br />
                              Default - Normal, alphanumeric keyboard.
                              <br />
                              Numeric - Digits 0-9 only.
                              <br />
                              Decimal - Digits 0-9 and ".".
                            </TippyExplainer>
                            <select
                              value={add.keyboard ?? "default"}
                              onChange={(evt) =>
                                onChangeMeta(j, i, "keyboard", evt.target.value)
                              }
                            >
                              <option value="default">Default</option>
                              <option value="numeric">Numeric</option>
                              <option value="decimal">Decimal</option>
                              <option value="email-address">Email</option>
                              <option value="phone-pad">Phone</option>
                              <option value="url">URL</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>
                          <div className="inner">
                            Value{" "}
                            <TippyExplainer>
                              <p>The default value for this field.</p>
                              <p>
                                As well as static data, this field allows
                                several placeholders:
                              </p>
                              <ul>
                                <li>
                                  <b>{"{NAME}"}</b> The user's first + last
                                  names, separated by a space
                                </li>
                                <li>
                                  <b>{"{FNAME}"}</b> The user's first name
                                </li>
                                <li>
                                  <b>{"{LNAME}"}</b> The user's last name
                                </li>
                                <li>
                                  <b>{"{INITIALS}"}</b> The user's first + last
                                  initials, separated by a space
                                </li>
                                <li>
                                  <b>{"{FINITIAL}"}</b> The initial of the
                                  user's first name
                                </li>
                                <li>
                                  <b>{"{LINITIAL}"}</b> The initial of the
                                  user's last name
                                </li>
                                <li>
                                  <b>{"{MOBILE}"}</b> The user's mobile number,
                                  in national format
                                </li>
                                <li>
                                  <b>{"{MOBILE_INT}"}</b> The user's mobile
                                  number, in international format, but without
                                  the leading "+"
                                </li>
                                <li>
                                  <b>{"{TAG}"}</b> The user's dolla tag
                                </li>
                              </ul>
                            </TippyExplainer>
                            <input
                              type="text"
                              placeholder="{LNAME}"
                              value={add.default ?? ""}
                              onChange={(evt) =>
                                onChangeMeta(j, i, "default", evt.target.value)
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={!!add.required}
                            onChange={(evt) =>
                              onChange(
                                i,
                                "meta.reference.required",
                                evt.target.checked
                              )
                            }
                          />{" "}
                          This field is required
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>
                          <div className="inner">
                            Key String{" "}
                            <TippyExplainer>
                              <p>
                                The string used for the key in the json object
                              </p>
                              <p>
                                if not supplied the value of the name field will
                                be used
                              </p>
                            </TippyExplainer>
                            <input
                              type="text"
                              placeholder="key"
                              value={add.key_string ?? ""}
                              onChange={(evt) =>
                                onChangeMeta(
                                  j,
                                  i,
                                  "key_string",
                                  evt.target.value
                                )
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={!!add.persistant}
                            onChange={(evt) =>
                              onChangeMeta(
                                j,
                                i,
                                "persistant",
                                evt.target.checked
                              )
                            }
                          />{" "}
                          This field is persistant across payments
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={!!add.server_side}
                            onChange={(evt) =>
                              onChangeMeta(
                                j,
                                i,
                                "server_side",
                                evt.target.checked
                              )
                            }
                          />{" "}
                          This field is added serverside
                        </td>
                      </tr>
                    </>
                  );
                })}
                <a onClick={() => addMetadata(i)}>
                  <AddCircle /> Add Metadata
                </a>
              </tbody>
            </table>
          </div>
        );
      })}
      <a onClick={addAccount}>
        <AddCircle /> Add Account
      </a>
    </div>
  );
};
