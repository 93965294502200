/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./UserIdentities.css";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { orderBy } from "lodash-es";

import { dollaUsers } from "utils-common";
import { uniqBy } from "lodash-es";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Inspector } from "../../components/Inspector";
import { Helmet } from "react-helmet";
import moment from "moment";

export const UserIdentities: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [businesses, setBusinesses] = React.useState<
    dollaUsers.Models.Business[]
  >([]);
  const [identities, setIdentities] = React.useState<
    dollaUsers.Models.Identity[]
  >([]);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingIdentities, setLoadingIdentities] = React.useState(false);
  const [loadingBusinesses, setLoadingBusinesses] = React.useState(false);

  React.useEffect(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    getUser();
  }, [_user]);

  const getIdentities = React.useCallback(() => {
    async function getIdentities() {
      setLoadingIdentities(true);
      const result = await API.get(`/users/${_user}/identities`);
      setLoadingIdentities(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setIdentities(result.json.items);
    }
    getIdentities();
  }, [_user]);
  React.useEffect(() => {
    getIdentities();
  }, [getIdentities]);

  const getBusinesses = React.useCallback(() => {
    async function getBusinesses() {
      if (!identities.length) {
        return;
      }
      setLoadingBusinesses(true);
      const filteredBiz = uniqBy(identities, "_user").map((x) => x._user);
      const results = await Promise.all(
        filteredBiz.map(async (_biz) => {
          const res = await API.get(`/businesses/${_biz}`);
          console.log(_biz, res.json);
          if (!res.json.success) {
            return;
          }
          return res.json.item;
        })
      );
      setLoadingBusinesses(false);
      const filteredResults = results.filter((x) => x);
      if (!filteredResults.length) {
        return;
      }
      setBusinesses(filteredResults);
    }
    getBusinesses();
  }, [identities]);
  React.useEffect(() => {
    getBusinesses();
  }, [getBusinesses]);

  if (loadingUser || loadingIdentities || loadingBusinesses || !user) {
    return <Loader />;
  }

  return (
    <div className="UserIdentities">
      <Helmet>
        <title>{user.name}'s Identities | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <h3>{user.name} Identities</h3>
      {!identities.length ? (
        <p>No identities</p>
      ) : (
        <List
          items={orderBy(identities, "created_at", "desc").map((identity) => {
            const biz = businesses?.find((x) => x._id === identity._user);
            return {
              logo: biz?.avatar_url,
              title: (
                <span>
                  {biz?.name ?? identity._user} identity
                  <Inspector _user={identity._user} _id={identity._id} />
                </span>
              ),
              subtitle: `Created ${moment(identity.created_at).fromNow(
                true
              )} ago.`,
              onClick: () =>
                navigate(`/universal/${identity._id}?_user=${identity._user}`),
              key: identity._id,
              rightElement: <ArrowForward fontSize="inherit" />,
            };
          })}
        />
      )}
    </div>
  );
};
