/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import "./BusinessAccounts.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { isEmpty } from "lodash-es";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { Helmet } from "react-helmet";

export const BusinessAccounts: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _biz = urlParams.id;
  const [biz, setBiz] = React.useState<dollaUsers.Models.Business>();

  const [loading, setLoading] = React.useState(false);

  const getBiz = React.useCallback(() => {
    async function getBiz() {
      setLoading(true);
      const result = await API.get(`/businesses/${_biz}`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setBiz(result.json.item);
    }
    getBiz();
  }, [_biz]);
  React.useEffect(() => {
    getBiz();
  }, [getBiz]);

  if (loading || !biz) {
    return <Loader />;
  }

  const accounts = biz.accounts;

  return (
    <div className="BusinessAccounts">
      <Helmet>
        <title>{biz.name}'s Accounts | Dolla Management Console</title>
      </Helmet>
      <Link to={`/businesses/${biz._id}`} className="backlink">
        ← Back to {biz.name}
      </Link>
      <div className="titleRow">
        <h3>{biz.name} Accounts</h3>
      </div>
      {!accounts.length ? (
        <p>
          {biz.name} hasn't got any accounts. This means people will be unable
          to pay them.
        </p>
      ) : (
        accounts.map((acc) => (
          <div className="account" key={acc._id}>
            <div className="title">
              <img className="avatar" src={biz.avatar_url} alt="" />
              {acc.name ? (
                <>
                  {acc.description ? (
                    <Tippy content={acc.description} className="BusinessTippy">
                      <u>{acc.name}</u>
                    </Tippy>
                  ) : (
                    <span>{acc.name}</span>
                  )}
                  <span className="subtitle">({acc.formatted_account})</span>
                </>
              ) : acc.description ? (
                <Tippy content={acc.description} className="BusinessTippy">
                  <u>{acc.formatted_account}</u>
                </Tippy>
              ) : (
                acc.formatted_account
              )}
              <div className="spacer"></div>
              {acc.provider === "AKAHU" ? (
                <Tippy
                  content="This account is linked to Akahu"
                  className="BusinessTippy"
                >
                  <img
                    className="badge akahu"
                    src="https://static.akahu.io/images/logo-small.png"
                    alt="This account is provided by Akahu"
                  />
                </Tippy>
              ) : (
                <Tippy
                  content="This account was entered manually"
                  className="BusinessTippy"
                >
                  <div className="badge manual">M</div>
                </Tippy>
              )}
            </div>
            <div className="meta">
              <div>
                <b>Code:</b>{" "}
                {isEmpty(acc.meta.code) ? (
                  <span className="subtitle">Unused</span>
                ) : acc.meta.code?.hint ? (
                  <Tippy
                    content={acc.meta.code?.hint}
                    className="BusinessTippy"
                  >
                    <u>{acc.meta.code?.name}</u>
                  </Tippy>
                ) : (
                  acc.meta.code?.name
                )}
              </div>
              <div className="smallRow">
                <span>
                  {acc.meta.code?.required ? "Required" : "Not required"}
                </span>
                &bull;
                <span>
                  {acc.meta.code?.validation ? (
                    <Tippy
                      content={<pre>/{acc.meta.code?.validation}/</pre>}
                      className="BusinessTippy"
                    >
                      <u>Validated</u>
                    </Tippy>
                  ) : (
                    "Not validated"
                  )}
                </span>
              </div>
            </div>
            <div className="meta">
              <div>
                <b>Reference:</b>{" "}
                {isEmpty(acc.meta.reference) ? (
                  <span className="subtitle">Unused</span>
                ) : acc.meta.reference?.hint ? (
                  <Tippy
                    content={acc.meta.reference?.hint}
                    className="BusinessTippy"
                  >
                    <u>{acc.meta.reference?.name}</u>
                  </Tippy>
                ) : (
                  acc.meta.reference?.name
                )}
              </div>
              <div className="smallRow">
                <span>
                  {acc.meta.reference?.required ? "Required" : "Not required"}
                </span>
                &bull;
                <span>
                  {acc.meta.reference?.validation ? (
                    <Tippy
                      content={<pre>/{acc.meta.reference?.validation}/</pre>}
                      className="BusinessTippy"
                    >
                      <u>Validated</u>
                    </Tippy>
                  ) : (
                    "Not validated"
                  )}
                </span>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};
