import * as React from "react";
import { Navigate } from "react-router-dom";

export const IndexPage: React.FunctionComponent = () => {
  const sesstore = window.sessionStorage;
  // By default, redirect to the users page
  const [location, setLocation] = React.useState<string | null>(null);

  React.useEffect(() => {
    // If we tried to load another page, go to that instead
    if (sesstore.getItem("dolla_management_login_location")) {
      const redirect = sesstore.getItem("dolla_management_login_location");
      sesstore.removeItem("dolla_management_login_location");
      setLocation(redirect);
    } else {
      setLocation("/users");
    }
  }, [sesstore]);

  if (location) {
    return <Navigate to={location} />;
  }
  return <></>;
};
