/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./UserSelector.css";

import { dollaUsers } from "../../../../lambdas/utils-common";
import { SmallUserSearch } from "./SmallUserSearch";
import { Loader } from "./Loader";
import API from "../utils/api";
import { SessionStore } from "../utils/session";
import ClearIcon from "@mui/icons-material/Clear";

type UserSearchResult = {
  _id: string;
  name: string;
  avatar_url: string;
  tag: string;
};

type UserSelectorProps = {
  onChange?: (user: UserSearchResult | undefined) => void;
  onUserLoad?: (biz: dollaUsers.Models.User | undefined) => void;
  _user?: string;
};

/** A selector for a user */
export const UserSelector: React.FunctionComponent<UserSelectorProps> = (
  props
) => {
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState<dollaUsers.Models.User | undefined>();
  const { _user, onUserLoad } = props;

  // Update the user when the value changes
  React.useEffect(() => {
    const getUser = async () => {
      setLoading(true);
      const result = await API.get(`/users/${_user}`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
      onUserLoad?.(result.json.item);
    };

    if (_user && _user !== user?._id) {
      getUser();
    } else if (!_user) {
      setUser(undefined);
      onUserLoad?.(undefined);
    }
  }, [_user, user, onUserLoad]);

  const onSelect = (user: UserSearchResult | undefined) => {
    props.onChange?.(user);
  };

  return (
    <div className="UserSelector">
      {loading ? (
        <Loader size="small" color="#000000" />
      ) : user ? (
        <div className="user">
          <div>
            <img className="avatar" src={user.avatar_url} alt="" /> {user.name}{" "}
            <a onClick={() => onSelect(undefined)}>
              <ClearIcon />
            </a>
          </div>
        </div>
      ) : (
        <SmallUserSearch onSelect={onSelect} />
      )}
    </div>
  );
};
