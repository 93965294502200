/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./EmailList.css";

import { dollaUsers } from "utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { orderBy } from "lodash-es";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Helmet } from "react-helmet";
import * as RDB from "rdb";
import { InspectorRDB } from "src/components/InspectorRDB";

export const EmailListV2: React.FunctionComponent = () => {
  const [emails, setEmails] = React.useState<RDB.Models.Email[]>([]);
  const [recipients, setRecipients] = React.useState<
    (dollaUsers.Models.User | dollaUsers.Models.Business)[]
  >([]);
  const [loading, setLoading] = React.useState(false);

  const [processed, setProcessed] = React.useState(false);
  const nav = useNavigate();

  React.useEffect(() => {
    async function getEmails() {
      setLoading(true);
      const result = await API.get(`/emails/v2?processed=${processed}`);
      setLoading(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }

      setEmails(result.json.item.emails);
      setRecipients(result.json.item.recipients);
    }
    getEmails();
  }, [processed]);

  const onStatusChange = () => {
    setProcessed(!processed);
  };

  const toggleEmailStatus = async (email: RDB.Models.Email) => {
    // Optimistically remove from the list
    setEmails(emails.filter((e) => email._id !== e._id));
    const result = await API.put(
      `/users/${email._user === null ? "UNASSIGNED" : email._user}/emails/${
        email._id
      }/process`,
      { processing_result: "NO_INVOICE" }
    );
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      // Put this email back in...
      setEmails([...emails, email]);
      return;
    }
  };

  if (loading) {
    return <Loader />;
  }

  const currentView = processed ? "Processed" : "Unprocessed";

  return (
    <div className="EmailList">
      <Helmet>
        <title>Emails - {currentView} | Dolla Management Console</title>
      </Helmet>
      <div className="header">
        <h3>Emails - {currentView}</h3>
        <a className="toggleButton" onClick={onStatusChange}>
          {processed ? "Show Unprocessed" : "Show Processed"}
        </a>
      </div>
      <div className="linkRow">
        <Link to="/emails/templates">Email Templates</Link>
      </div>
      {!emails.length ? (
        <p>No emails.</p>
      ) : (
        <List
          items={orderBy(emails, "created_at", "desc").map((email) => {
            let recipient: { name?: string; avatar_url: string } | undefined =
              recipients.find((r) => email._user === r._id);
            if (!recipient && email._user === null) {
              // Unassigned user
              recipient = {
                name: "Unassigned",
                avatar_url: "https://static.dolla.nz/images/single.png",
              };
            } else if (!recipient) {
              // Unknown user...
              recipient = {
                name: "???",
                avatar_url: "https://static.dolla.nz/images/single.png",
              };
            }
            return {
              title: `${recipient.name ?? "Unnamed user"}: ${email.subject}`,
              subtitle: `From ${email.from} ${moment(
                email.created_at
              ).fromNow()}. ${
                email.dolla_approved_at
                  ? `Approved by Dolla ${moment(
                      email.dolla_approved_at
                    ).fromNow()}`
                  : email.dolla_processed_at
                  ? `Processed by Dolla ${moment(
                      email.dolla_processed_at
                    ).fromNow()}.`
                  : `${email.suggestions.length} suggestion${
                      email._payments.length === 1 ? "" : "s"
                    }.`
              }`,
              logo: recipient.avatar_url,
              onClick: () =>
                nav(
                  `/users/${
                    email._user === null ? "UNASSIGNED" : email._user
                  }/emails/${email._id}`
                ),
              rightElement: !email.dolla_processed_at ? (
                <>
                  <a
                    className="toggleButton"
                    onClick={() => toggleEmailStatus(email)}
                    title={"Mark as processed"}
                  >
                    <ArchiveIcon fontSize="inherit" />
                  </a>
                  <InspectorRDB _id={email._id} />
                </>
              ) : (
                <InspectorRDB _id={email._id} />
              ),
              rightElementClickable: true,
            };
          })}
        />
      )}
    </div>
  );
};
