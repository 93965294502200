/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { dollaUsers } from "../../../../../../../lambdas/utils-common";

type EmailAttachmentProps = {
  item: dollaUsers.Models.Email["attachments"][number] & { uri: string };
};

/** A download link for another type of attachemnt */
export const Download: React.FunctionComponent<EmailAttachmentProps> = (
  props
) => {
  return (
    <a
      className="downloadLink"
      href={props.item.uri}
      title={`Download ${props.item.name}`}
      download={props.item.name}
      target="_blank"
      rel="noreferrer"
    >
      <AttachmentIcon />
      {props.item.name} (Download)
    </a>
  );
};
