import * as React from "react";
import { withSessionStore } from "./utils/session";
import initWindowState from "./utils/windowState";
import { stage } from "./utils/helpers";

import { LoginPage } from "./pages/auth/Login";
import { AppRouter } from "./pages/Router";

export const App: React.FunctionComponent = withSessionStore((props) => {
  initWindowState();

  // If we are not in the staging environment, update the favicon!
  React.useEffect(() => {
    if (stage !== "prod") {
      const iconPath = `https://static.${stage}.dolla.nz/images/icon.png`;
      const favIconLink: HTMLLinkElement | null = document.querySelector(
        "head>link[rel='icon']"
      );
      const aplIconLink: HTMLLinkElement | null = document.querySelector(
        "head>link[rel='apple-touch-icon']"
      );
      if (favIconLink) {
        favIconLink.href = iconPath;
      }
      if (aplIconLink) {
        aplIconLink.href = iconPath;
      }
    }
  }, []);

  if (props.SessionStore._token) {
    return <AppRouter />;
  }
  return <LoginPage />;
});
