import { EventEmitter } from "events";
import { io } from "socket.io-client";
import { stage } from "./helpers";

/** A websocket connection to the backend
 *
 * Open it with `socket.open();`
 * Close it with `socket.close();`
 *
 * After it's open, you can go:
 * ```
 * socket.on("message", payload => {
 *    // Do something here
 * });
 * ```
 */
class DollaSocket extends EventEmitter {
  // Used to match up reconnecting sessions
  deviceId;

  // The underlying socket
  socket;

  // Used to indicate that we are intentionally shutting down the socket, as
  // opposed to a network interuption
  shouldDisconnect = false;

  constructor(deviceId) {
    super();
    this.deviceId = deviceId;
  }

  /** Open the socket connection */
  async open(_user, _token) {
    // If already open, just return
    if (this.isOpen()) {
      console.log(`[WS] Already connected.`);
      return;
    }

    // Idiot check
    if (!_user || !_token) {
      console.log(`[WS] Cannot open web socket. Missing user or token`);
      return;
    }

    const socketUrl = {
      prod: "https://ws.dolla.nz",
      staging: "https://ws.staging.dolla.nz",
    }[stage];

    // Create the socket
    this.socket = io(socketUrl, {
      path: "/public/socket",
      auth: { _api_token: _token, _user },
    });

    // Wait for the connection to be made
    await new Promise((res) => this.socket.once("connect", () => res(true)));

    // Initialise the WS session on the server
    const initialised = await new Promise((res) =>
      this.socket.emit("init", { id: this.deviceId }, (result) => {
        res(result);
      })
    );
    if (!initialised) {
      console.log(`[WS] Cannot open web socket. Server said no...`);
      return;
    }

    // The socket is now active, and will begin to receive events
    console.log(`[WS] Web socket opened`);
    this.shouldDisconnect = false;

    // Set up a listener for network interuption
    this.socket.on("disconnect", () => {
      if (!this.shouldDisconnect) {
        // This disconnect was probably caused by a network interruption
        // TODO: Check the network status every few seconds and try to reconnect
        console.log(
          `[WS] Disconnected. Probably due to network dropout. Reconnection is not yet implemented.`
        );
      }
      console.log(`[WS] Closed`);
    });

    // What we're all here for, set up a listener for events!
    this.socket.on("backendEvent", this._onMessage.bind(this));
  }

  /** Close the socket connection */
  close() {
    if (this.isOpen()) {
      this.shouldDisconnect = true;
      this.socket?.disconnect();
      console.log(`----------CLOSED SOCKET CONNECTION --------`);
    }
  }

  /** See if the socket is open */
  isOpen() {
    return this.socket?.connected;
  }

  /** Called when a new message comes in from the backend */
  _onMessage(payload) {
    let message = payload;
    try {
      message = JSON.parse(payload);
    } catch (error) {
      console.log(`[WS] Received a non-JSON message`, message);
    }
    this.emit("message", message);
  }
}

// Come up with a random ID for this session.
// It only has to be unique within this user's devices, so we don't need a
// proper cuid.
const id = `${new Date().getTime()}-${Math.random().toString().slice(2)}`;

// Create the socket for this session
const socket = new DollaSocket(id);

// Set up a default listener
// We might want to get rid of this eventually, if it gets too verbose.
// socket.on("message", (message) => {
// console.debug(`[WS] Received message`, message);
// });

export default socket;
