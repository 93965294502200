/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams } from "react-router-dom";
// import "./UserSubscriptions.css";
import { orderBy } from "lodash-es";

import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import { Helmet } from "react-helmet";

// the type returned from GET subscription end point
interface accountsResponse {
  accountID: string;
  tenantID: string;
  code: string;
  name: string;
}

export const XeroAccountsPage: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _tenant = urlParams.id;
  const [accounts, setAccounts] = React.useState<accountsResponse[]>([]);
  const [loadingAccounts, setLoadingAccounts] = React.useState(false);

  React.useEffect(() => {
    async function getSubs() {
      setLoadingAccounts(true);
      const result = await API.get(`/xero/${_tenant}/accounts`);
      setLoadingAccounts(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setAccounts(result.json.items);
    }
    getSubs();
  }, [_tenant]);

  if (loadingAccounts) {
    return <Loader />;
  }

  return (
    <div className="XeroAccounts">
      <Helmet>
        <title>Xero Accounts | Dolla Management Console</title>
      </Helmet>
      <Link to={`/xero/${_tenant}`} className="backlink">
        ← Back to Xero org
      </Link>
      <h3>Xero Accounts</h3>
      {!accounts.length ? (
        <p>No Accounts</p>
      ) : (
        <List
          items={orderBy(accounts, ["code", "name"], ["desc", "desc"]).map(
            (acc) => ({
              title: (
                <span>
                  {acc.code} - {acc.name}
                </span>
              ),
              subtitle: acc.accountID,
            })
          )}
        />
      )}
    </div>
  );
};
