/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./List.css";

export type ListItem = {
  /** A bold title */
  title?: string | React.ReactElement;
  /** A lighter subtitle */
  subtitle?: string | React.ReactElement;
  /** Display a logo on the left */
  logo?: string | React.ReactElement;
  /** Display something on the right */
  rightElement?: React.ReactElement;
  /** Seperate the right element from the rest of the row */
  rightElementClickable?: boolean;
  /** Make the whole row clickable */
  onClick?: () => void;
  /** Highlight this row */
  highlight?: boolean;
  /** A key for this. If undefined default to the index */
  key?: string;
};

export const List: React.FunctionComponent<{ items: ListItem[] }> = (props) => (
  <div className="List">
    {props.items.map((item, i) => {
      const content = (
        <>
          {item.logo ? (
            typeof item.logo === "string" ? (
              <img src={item.logo} alt="" />
            ) : (
              item.logo
            )
          ) : (
            <></>
          )}
          <div className="content">
            <div className="title">{item.title}</div>
            <div className="subtitle">{item.subtitle}</div>
          </div>
          {item.rightElement && !item.rightElementClickable ? (
            item.rightElement
          ) : (
            <></>
          )}
        </>
      );
      const rowClass = item.highlight ? "row highlight" : "row";

      if (item.onClick) {
        return (
          <div className={rowClass} key={item.key || i}>
            <a
              className="rowWrapper"
              key={item.key || i}
              onClick={item.onClick}
            >
              {content}
            </a>
            {item.rightElement && item.rightElementClickable ? (
              item.rightElement
            ) : (
              <></>
            )}
          </div>
        );
      }
      return (
        <div className={rowClass} key={item.key || i}>
          <div className="rowWrapper">{content}</div>
          {item.rightElement && item.rightElementClickable ? (
            item.rightElement
          ) : (
            <></>
          )}
        </div>
      );
    })}
  </div>
);
