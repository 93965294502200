/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./UserEmailList.css";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { List } from "../../components/List";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { orderBy } from "lodash-es";
import ArchiveIcon from "@mui/icons-material/Archive";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Helmet } from "react-helmet";
import * as RDB from "rdb";
import { InspectorRDB } from "src/components/InspectorRDB";

type FETCH_STATUSES = dollaUsers.Models.Email["status"];

export const UserEmailList: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  const [emails, setEmails] = React.useState<RDB.Models.Email[]>([]);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingEmails, setLoadingEmails] = React.useState(false);
  const [fetchStatus, setFetchStatus] =
    React.useState<FETCH_STATUSES>("ARRIVED");
  const nav = useNavigate();

  React.useEffect(() => {
    async function getEmails() {
      setLoadingEmails(true);
      const result = await API.get(
        `/users/${_user}/emails?status=${fetchStatus}`
      );
      setLoadingEmails(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }

      setEmails(result.json.items);
    }
    getEmails();
  }, [fetchStatus, _user]);
  React.useEffect(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }

      setUser(result.json.item);
    }
    getUser();
  }, [_user]);

  const onStatusChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const val = evt.target.value as FETCH_STATUSES;
    setFetchStatus(val);
  };

  const toggleEmailStatus = async (email: RDB.Models.Email) => {
    // Optimistically remove from the list
    setEmails(emails.filter((e) => email._id !== e._id));
    const newStatus = {
      ARRIVED: "ARCHIVED",
      ARCHIVED: "ARRIVED",
    }[email.status];
    const result = await API.put(
      `/users/${email._user}/emails/${email._id}/status`,
      { status: newStatus }
    );
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      // Put this email back in...
      setEmails([...emails, email]);
      return;
    }
  };

  if (loadingEmails || loadingUser || !user) {
    return <Loader />;
  }

  const currentView = {
    ARRIVED: "Inbox",
    ARCHIVED: "Archived",
  }[fetchStatus];

  return (
    <div className="UserEmailList">
      <Helmet>
        <title>{user.name}'s Emails | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <div className="header">
        <h3>
          {user.name}'s Emails - {currentView}
        </h3>
        <select
          className="statusSelector"
          value={fetchStatus}
          onChange={onStatusChange}
        >
          <option value="ARRIVED">Inbox</option>
          <option value="ARCHIVED">Archived</option>
        </select>
      </div>
      {!emails.length ? (
        <p>No emails.</p>
      ) : (
        <List
          items={orderBy(emails, "created_at", "desc").map((email) => {
            return {
              title: `${user.name ?? "Unnamed user"}: ${email.subject}`,
              subtitle: `From ${email.from} ${moment(
                email.created_at
              ).fromNow()}.`,
              logo: user.avatar_url,
              onClick: () => nav(`/users/${email._user}/emails/${email._id}`),
              rightElement: (
                <>
                  <a
                    className="toggleButton"
                    onClick={() => toggleEmailStatus(email)}
                    title={
                      email.status === "ARCHIVED" ? "Move to inbox" : "Archive"
                    }
                  >
                    {email.status === "ARCHIVED" ? (
                      <InboxIcon fontSize="inherit" />
                    ) : (
                      <ArchiveIcon fontSize="inherit" />
                    )}
                  </a>
                  <InspectorRDB _id={email._id} />
                </>
              ),
              rightElementClickable: true,
            };
          })}
        />
      )}
    </div>
  );
};
