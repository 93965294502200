/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import "./UserAccounts.css";
import "tippy.js/dist/tippy.css";

import { dollaUsers } from "../../../../../lambdas/utils-common";
import API from "../../utils/api";
import { SessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { AccountModal } from "./components/AccountModal";
import { AccountList } from "./components/AccountList";
import { Helmet } from "react-helmet";
import type * as RDB from "../../../../../lambdas/rdb";

export const UserAccounts: React.FunctionComponent = () => {
  const urlParams = useParams();
  const _user = urlParams.id;
  const [user, setUser] = React.useState<dollaUsers.Models.User>();
  // const [accounts, setAccounts] = React.useState<dollaUsers.Models.Account[]>(
  //   []
  // );
  const [rdbAccounts, setRdbAccounts] = React.useState<RDB.Models.Account[]>(
    []
  );
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingAccounts, setLoadingAccounts] = React.useState(false);
  const accountModal = React.useRef<any>();

  const getUser = React.useCallback(() => {
    async function getUser() {
      setLoadingUser(true);
      const result = await API.get(`/users/${_user}`);
      setLoadingUser(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setUser(result.json.item);
    }
    getUser();
  }, [_user]);
  React.useEffect(() => {
    getUser();
  }, [getUser]);

  const getRDBAccounts = React.useCallback(() => {
    async function getAccounts() {
      setLoadingAccounts(true);
      const result = await API.get(`/users/${_user}/accounts/v2`);
      setLoadingAccounts(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setRdbAccounts(result.json.items);
    }
    getAccounts();
  }, [_user]);

  React.useEffect(() => {
    getRDBAccounts();
  }, [getRDBAccounts]);

  const syncAccounts = async () => {
    setLoadingAccounts(true);
    const result = await API.put(`/users/${_user}/accounts/akahu`);
    if (!result.json?.success) {
      setLoadingAccounts(false);
      SessionStore.apiErr(result);
      return;
    }
    getRDBAccounts();
  };
  const refreshAccounts = async () => {
    setLoadingAccounts(true);
    const result = await API.post(`/users/${_user}/accounts/refresh`);
    if (!result.json?.success) {
      setLoadingAccounts(false);
      SessionStore.apiErr(result);
      return;
    }
    getRDBAccounts();
  };

  if (loadingUser || loadingAccounts || !user) {
    return <Loader />;
  }

  return (
    <div className="UserAccounts">
      <Helmet>
        <title>{user.name}'s Accounts | Dolla Management Console</title>
      </Helmet>
      <Link to={`/users/${user._id}`} className="backlink">
        ← Back to {user.name}
      </Link>
      <div className="titleRow">
        <h3>{user.name}'s Accounts</h3>
      </div>
      <div className="buttonRow">
        {user.type === "PERSONAL" &&
        user.providers.find((x) => x.provider === "AKAHU") ? (
          <>
            {rdbAccounts.length ? (
              <button
                onClick={refreshAccounts}
                title="Trigger Akahu to go to the banks and fetch the latest data"
              >
                Trigger Akahu Refresh
              </button>
            ) : (
              <></>
            )}
            <button
              onClick={syncAccounts}
              title="Fetch the latest accounts from Akahu and create/delete any new/deleted accounts"
            >
              Sync with Akahu
            </button>
          </>
        ) : (
          <></>
        )}
      </div>

      {!rdbAccounts.length ? (
        <p>No Accounts</p>
      ) : (
        <AccountList
          user={user}
          accounts={rdbAccounts}
          accountModal={accountModal}
        />
      )}

      <AccountModal
        openRef={accountModal}
        reloadAccounts={getRDBAccounts}
        accounts={rdbAccounts}
      />
    </div>
  );
};
