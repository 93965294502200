// import { dollaUsers } from "utils-common";
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import API from "../../../utils/api";
import type { Webhook } from "akahu";
import CircleIcon from "@mui/icons-material/Circle";
import SyncIcon from "@mui/icons-material/Sync";
import Tippy from "@tippyjs/react";
import { SessionStore } from "src/utils/session";
import "./WebhookStatus.css";

type WebhookStatusProps = {
  _user: string;
};

export const WebhookStatus: React.FunctionComponent<WebhookStatusProps> = ({
  _user,
}) => {
  const [webhooks, setWebhooks] = React.useState<Webhook[]>([]);
  const [loadingWebhooks, setLoadingWebhooks] = React.useState(false);
  const [resubscribing, setResubscribing] = React.useState(false);

  const allHooks = [
    "ACCOUNT" as const,
    "IDENTITY" as const,
    "PAYMENT" as const,
    "TOKEN" as const,
    "TRANSFER" as const,
    "TRANSACTION" as const,
  ];

  React.useEffect(() => {
    const getWebhooks = async () => {
      setLoadingWebhooks(true);
      const result = await API.get(`/users/${_user}/webhooks`);
      setLoadingWebhooks(false);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
        return;
      }
      setWebhooks(result.json.items);
    };
    getWebhooks();
  }, [_user]);

  const onResubscribe = async () => {
    setResubscribing(true);
    const result = await API.put(`/users/${_user}/webhooks`);
    setResubscribing(false);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return;
    }
    setWebhooks(result.json.items);
  };

  return (
    <div className="WebhookStatus">
      {allHooks.map((h) => (
        <CircleIcon
          className={
            loadingWebhooks
              ? "loading"
              : webhooks.find((wh) => wh.type === h && wh.state === _user)
              ? "active"
              : "inactive"
          }
          titleAccess={`${h} webhook is ${
            loadingWebhooks
              ? "loading"
              : webhooks.find((wh) => wh.type === h)
              ? "active"
              : "inactive"
          }`}
        />
      ))}
      <Tippy content={"Resubscribe to all webhooks"}>
        <a onClick={onResubscribe} className="refresh">
          <SyncIcon
            className={resubscribing || loadingWebhooks ? "spin" : undefined}
          />
        </a>
      </Tippy>
    </div>
  );
};
