import * as React from "react";

type ArrowProps = {
  reverse?: boolean;
};

export const Arrow: React.FunctionComponent<ArrowProps> = (props) => {
  const { reverse } = props;

  const FRONT = reverse ? "–" : "→";
  const BACK = reverse ? "←" : "–";
  return (
    <React.Fragment>
      {props.children && (
        <span
          style={{
            fontSize: "120%",
            marginLeft: "1rem",
            marginRight: "0.25rem",
          }}
        >
          {BACK}
        </span>
      )}
      {props.children}
      <span
        style={{
          fontSize: "120%",
          marginLeft: "0.25rem",
          marginRight: "1rem",
          marginBottom: "2px",
        }}
      >
        {FRONT}
      </span>
    </React.Fragment>
  );
};
