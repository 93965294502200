/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./SmallUserSearch.css";

import { SmallSearch, SmallSearchProps } from "./SmallSearch";
import API from "../utils/api";
import { SessionStore } from "../utils/session";

type UserSearchResult = {
  _id: string;
  name: string;
  avatar_url: string;
  tag: string;
};

type SmallUserSearchProps = Omit<
  SmallSearchProps<UserSearchResult>,
  "query" | "renderItem"
>;

/** A small user search bar */
export const SmallUserSearch: React.FunctionComponent<SmallUserSearchProps> = (
  props
) => {
  const queryUsers = async (query: string) => {
    const result = await API.get(`/users/v2/search?q=${query}`);
    if (!result.json?.success) {
      SessionStore.apiErr(result);
      return [];
    }
    return result.json.items as UserSearchResult[];
  };

  const renderUser: SmallSearchProps["renderItem"] = (user) => {
    return (
      <>
        <img className="avatar" src={user.avatar_url} alt="" />
        {user.name}
      </>
    );
  };
  return (
    <div className="SmallUserSearch">
      <SmallSearch
        {...props}
        renderItem={renderUser}
        query={queryUsers}
        placeholder={props.placeholder || "Search for a user"}
        clearOnSelect={props.clearOnSelect || true}
      />
    </div>
  );
};
