import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { hideAll as hideTippy } from "tippy.js";
import Person from "@mui/icons-material/Person";
import "./Wrapper.css";

import { DollaAlert } from "./components/Alert";
import { LogoutOverlay } from "./components/LogoutOverlay";
import { Spacer } from "./components/Spacer";
import { NavLink } from "./components/NavLink";
import { OnlyDev } from "../../components/OnlyDev";
import { OnlyAdmin, NotAdmin } from "../../components/OnlyAdmin";
import { withSessionStore, SessionStore } from "../../utils/session";
import API from "../../utils/api";
import { stage } from "../../utils/helpers";

export const NavWrapper: React.FunctionComponent<React.PropsWithChildren<{}>> =
  withSessionStore((props) => {
    const [loggingOut, setLoggingOut] = React.useState(false);
    const onFocus = React.useRef<any>();
    const isOnSandboxPage = useLocation().pathname === "/sandbox";

    React.useEffect(() => {
      window.addEventListener("focus", onFocus.current);
      return () => {
        window.removeEventListener("focus", onFocus.current);
      };
    });

    const logout = async () => {
      setLoggingOut(true);
      hideTippy();
      await API.delete("/auth");
      SessionStore.setState({
        _token: undefined,
        token_expiry: undefined,
        user: undefined,
        info: undefined,
        error: undefined,
      });
    };

    API.setLogoutFunction(logout);
    onFocus.current = () => {
      const newState = SessionStore.retreiveLocal();
      if (
        newState.token_expiry &&
        newState.token_expiry < new Date().toISOString()
      ) {
        logout();
      }
    };

    const logoUrl =
      stage === "prod"
        ? "https://static.dolla.nz/images/icon.png"
        : `https://static.${stage}.dolla.nz/images/icon.png`;

    return (
      <div className="Wrapper">
        {loggingOut ? <LogoutOverlay /> : <></>}
        <header>
          <nav>
            <Link to="/">
              <img className="logo" src={logoUrl} alt="Dolla Logo" />
            </Link>
            <div className="links">
              <NavLink to="/users">Users</NavLink>
              <NavLink to="/businesses">Businesses</NavLink>
              <NavLink to="/entities">Entities</NavLink>
              <NavLink to="/bank_accounts">Bank Accounts</NavLink>
              <NavLink to="/payments">Payments</NavLink>
              <NavLink to="/paymentsv3">Payments V3</NavLink>
              {/* <NavLink to="/emails">Emails</NavLink> */}
              <NavLink to="/emails">Emails</NavLink>

              <Spacer />
              <OnlyDev>
                <NavLink to="/sandbox" button>
                  Sandbox
                </NavLink>
              </OnlyDev>

              <Tippy
                content={
                  <>
                    <OnlyAdmin>
                      <Link
                        to="/mgmt/users"
                        className="tippyLink"
                        onClick={() => hideTippy()}
                      >
                        Management Users
                      </Link>
                    </OnlyAdmin>
                    <NotAdmin>
                      <Link
                        to={`/mgmt/users/${props.SessionStore.user?._id}`}
                        className="tippyLink"
                        onClick={() => hideTippy()}
                      >
                        Settings
                      </Link>
                    </NotAdmin>
                    <Link
                      to={`/constants`}
                      className="tippyLink"
                      onClick={() => hideTippy()}
                    >
                      Edit Constants
                    </Link>
                    <Link
                      to="/qr"
                      className="tippyLink"
                      onClick={() => hideTippy()}
                    >
                      Generate QR Code
                    </Link>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={logout} className="tippyLink">
                      Log out
                    </a>
                  </>
                }
                interactive={true}
                trigger="click"
                className="WrapperTippy"
              >
                <button className="roundIcon">
                  <Person />
                </button>
              </Tippy>
            </div>
          </nav>
        </header>

        <section>
          <div
            className={`content ${
              props.SessionStore.wide && isOnSandboxPage ? "wide" : ""
            }`}
          >
            <DollaAlert />
            {props.children}
          </div>
        </section>
        <footer>
          <span>© 2022 Dolla Ltd.</span>
        </footer>
      </div>
    );
  });
