/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { hideAll as hideTippy } from "tippy.js";
import Editor, {
  OnChange as MonacoChangeType,
  BeforeMount as MonacoBeforeMount,
  OnMount as MonacoOnMount,
  Monaco,
} from "@monaco-editor/react";
import type * as monaco from "monaco-editor";
import { Link } from "react-router-dom";
import "./Sandbox.css";
import ColumnIcon from "@mui/icons-material/ViewColumn";
import RowIcon from "@mui/icons-material/CalendarViewDay";

import API from "../../utils/api";
import { SessionStore, withSessionStore } from "../../utils/session";
import { Loader } from "../../components/Loader";
import { ScriptList } from "./components/ScriptList";
import { SaveScriptModal } from "./components/SaveScriptModal";
import extLibs from "./externalLibs.json";
import colors from "../../utils/colors.json";

import { managementDB } from "../../../../../lambdas/utils-common";
import { Helmet } from "react-helmet";

const sesstore = window.sessionStorage;
const localStore = window.localStorage;

const DEFAULT_SCRIPT = `import { dollaUsers } from "utils-common";
import * as RDB from "rdb";

const _user = "";
const _id = "";

RDB.open();
const item = await dollaUsers.get(_user, _id);

Log(item);
`;

type DollaScript = any;

export const SandboxPage: React.FunctionComponent = withSessionStore(
  (props) => {
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(DEFAULT_SCRIPT);
    const [compiled, setCompiled] = React.useState<null | string>(null);
    const [result, setResult] = React.useState<null | string>(null);
    const [error, setError] = React.useState<null | string>(null);
    const [scriptMeta, setScriptMeta] = React.useState<DollaScript>({});
    const [layout, setLayout] = React.useState<"COLUMN" | "ROW">("COLUMN");

    const monacoInstance = React.useRef<Monaco>();
    const editorInstance = React.useRef<monaco.editor.IStandaloneCodeEditor>();
    const saveModal = React.useRef<any>(); // TODO: type
    const scriptList = React.useRef<any>(); // TODO: type

    // Restore state from the session store
    React.useEffect(() => {
      const prev_value = sesstore.getItem("management-dolla-nz-sandbox-value");
      const prev_script = sesstore.getItem(
        "management-dolla-nz-sandbox-script"
      );
      if (prev_value) {
        setValue(prev_value);
      }
      if (prev_script) {
        setScriptMeta(JSON.parse(prev_script));
      }
    }, []);

    React.useEffect(() => {
      const oldLayout = localStore.getItem(
        "management-dolla-nz-sandbox-layout"
      );
      if (oldLayout === "COLUMN" || oldLayout === "ROW") {
        setLayout(oldLayout);
        SessionStore.setState({ wide: oldLayout === "ROW" });
      }
    }, []);
    const changeLayout = () => {
      const newLayout = {
        COLUMN: "ROW" as const,
        ROW: "COLUMN" as const,
      }[layout];
      setLayout(newLayout);
      localStore.setItem("management-dolla-nz-sandbox-layout", newLayout);
      SessionStore.setState({ wide: newLayout === "ROW" });
    };

    const compileTS = async () => {
      // Try to get the compiled value
      if (!editorInstance.current || !monacoInstance.current) {
        return;
      }
      setCompiled(null);
      const tsWorker =
        await monacoInstance.current.languages.typescript.getTypeScriptWorker();
      const model = editorInstance.current.getModel();
      if (!model) {
        return;
      }
      const serviceProxy = await tsWorker(model.uri);
      const outputFiles = await serviceProxy.getEmitOutput(
        model.uri.toString()
      );
      const output = outputFiles.outputFiles[0].text;
      setCompiled(output);
      console.log("OUT:\n", output);
    };

    React.useEffect(() => {
      compileTS();
    }, [value]);

    const onChange: MonacoChangeType = async (value) => {
      if (value) {
        setValue(value);
        window.sessionStorage.setItem(
          "management-dolla-nz-sandbox-value",
          value
        );
      }
    };

    const beforeEditorMount: MonacoBeforeMount = (monaco) => {
      // Make the output compatible with node14
      monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        ...monaco.languages.typescript.typescriptDefaults.getCompilerOptions(),
        lib: ["es2016"],
        target: monaco.languages.typescript.ScriptTarget.ES2020,
        module: monaco.languages.typescript.ModuleKind.CommonJS,
        esModuleInterop: true,
        allowSyntheticDefaultImports: true,
        strict: true,
      });

      monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
        ...monaco.languages.typescript.typescriptDefaults.getDiagnosticsOptions(),
        diagnosticCodesToIgnore: [
          2307, // Ignore import errors (because there's no easy way to package up all the lambda's installed packages)
          1378, // Allow top-level await
        ],
      });
      // Include our libs
      extLibs.forEach(({ filename, code }) => {
        const libUri = `ts:filename/${filename}`;
        monaco.languages.typescript.typescriptDefaults.addExtraLib(
          code,
          libUri
        );
        try {
          monaco.editor.createModel(
            code,
            "typescript",
            monaco.Uri.parse(libUri)
          );
        } catch (err) {
          console.log("LIB ALREADY LOADED", libUri);
        }
        console.log("LOAD LIB", libUri);
      });
    };

    const onMount: MonacoOnMount = async (editor, monaco) => {
      editorInstance.current = editor;
      monacoInstance.current = monaco;
      compileTS();
    };

    const execute = async () => {
      if (!compiled) {
        return;
      }
      setLoading(true);

      // Tidy up the compiled code
      const cleanCompiled = compiled
        .split("\n")
        .filter((line) => {
          // Remove the exports definition
          if (line.trim().startsWith("Object.defineProperty(exports")) {
            return false;
          }
          // Remove the strict mode
          if (line.trim() === '"use-strict"') {
            return false;
          }
          return true;
        })
        .join("\n");

      const result = await API.post("/sandbox", {
        payload: cleanCompiled,
      });
      setLoading(false);
      if (!result.json || !result.json.success) {
        setResult(null);
        setError(`Could not execute script (${result.status})`);
      } else {
        setResult(result.json.item.result);
        setError(result.json.item.error);
      }
    };

    const onScriptSelected = (script: DollaScript) => {
      hideTippy();
      setScriptMeta(script);
      setValue(script.code);
      window.sessionStorage.setItem(
        "management-dolla-nz-sandbox-value",
        script.code
      );
      window.sessionStorage.setItem(
        "management-dolla-nz-sandbox-script",
        JSON.stringify(script)
      );
    };

    const saveScriptAs = () => {
      hideTippy();
      saveModal.current?.open(scriptMeta || {}, value, () => {
        console.log("SAVED");
        scriptList.current?.reload();
      });
    };

    const saveScript = async () => {
      if (!scriptMeta._id) {
        return;
      }
      const result = await API.put(`/scripts/${scriptMeta._id}`, {
        code: value,
      });
      if (!result.json?.success) {
        SessionStore.apiErr(result);
      } else {
        scriptList.current?.reload();
      }
    };

    const onNewScript = async (newScript: managementDB.Models.Script) => {
      onScriptSelected(newScript);
      scriptList.current?.reload();
    };

    const deleteScript = async () => {
      if (!scriptMeta._id) {
        return;
      }
      const result = await API.delete(`/scripts/${scriptMeta._id}`);
      if (!result.json?.success) {
        SessionStore.apiErr(result);
      } else {
        setScriptMeta({});
        setValue(DEFAULT_SCRIPT);
        window.sessionStorage.setItem(
          "management-dolla-nz-sandbox-value",
          DEFAULT_SCRIPT
        );
        window.sessionStorage.setItem(
          "management-dolla-nz-sandbox-script",
          JSON.stringify({})
        );
        scriptList.current?.reload();
      }
    };

    const owner = scriptMeta.owner || props.SessionStore.user?.username;
    return (
      <div className="Sandbox">
        <Helmet>
          <title>Sandbox | Dolla Management Console</title>
        </Helmet>
        <div className="linkRow">
          <Tippy
            content={
              <>
                <a className="tippyLink" onClick={saveScriptAs}>
                  Create Script
                </a>
                <Link className="tippyLink" to="/sandbox/browse">
                  Browse All Scripts
                </Link>
                <ScriptList
                  onSelect={onScriptSelected}
                  reloadRef={scriptList}
                />
              </>
            }
            interactive={true}
            trigger="click"
            className="SandboxTippy"
          >
            <a>My Scripts</a>
          </Tippy>

          <div className="layoutSwitch">
            <a onClick={() => changeLayout()} title="Change layout">
              {layout === "COLUMN" ? (
                <ColumnIcon fontSize="inherit" />
              ) : (
                <RowIcon fontSize="inherit" />
              )}
            </a>
          </div>
        </div>
        {scriptMeta?._id ? (
          <div className="row">
            <h4>
              {owner}&nbsp;›&nbsp;
              <span className="bold">{scriptMeta.name}</span>
            </h4>
            <button onClick={saveScript}>Save</button>
            <button onClick={saveScriptAs}>Save As</button>
            <button onClick={deleteScript}>Delete</button>
          </div>
        ) : (
          <></>
        )}
        <div className={`layout_${layout}`}>
          <div>
            <div className="editor">
              <Editor
                height={layout === "ROW" ? "90vh" : "500px"}
                defaultLanguage="typescript"
                language="typescript"
                value={value}
                onChange={onChange}
                beforeMount={beforeEditorMount}
                onMount={onMount}
                theme="light"
                loading={<Loader />}
              />
            </div>
            <button
              className="flexButton"
              disabled={!compiled}
              onClick={loading ? undefined : execute}
            >
              {loading ? (
                <Loader size="small" color={colors.white} />
              ) : !compiled ? (
                "Compiling..."
              ) : (
                "Execute"
              )}
            </button>
          </div>

          <div>
            {result ? <div className="infoResult">{result}</div> : <></>}
            {error ? <div className="errorResult">{error}</div> : <></>}
          </div>
        </div>

        <SaveScriptModal openRef={saveModal} onDone={onNewScript} />
      </div>
    );
  }
);
