import * as React from "react";
import "./StatusModal.css";
import ReactModal from "react-modal";

import { Loader } from "../../../components/Loader";

type StatusModalArgs = {
  /** The title for this message */
  title: string | React.ReactElement;
  /** The subtitle for this message */
  subtext: string | React.ReactElement;
  /** The body text for this message. Each item in the array is a seperate paragraph. */
  message: (string | React.ReactElement)[];
  /** Called when the user clicks confirm */
  onConfirm: () => Promise<void> | void;
};

type StatusModalProps = {
  /** A way to expose the `open` method to parents */
  openRef: React.MutableRefObject<any>;
};

/** A big search bar, with full-page results */
export const StatusModal: React.FunctionComponent<StatusModalProps> = (
  props
) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [args, setArgs] = React.useState<StatusModalArgs>();

  const confirm = async () => {
    setLoading(true);
    await args?.onConfirm();
    closeModal();
  };

  const openModal = (
    title: StatusModalArgs["title"],
    subtitle: StatusModalArgs["subtext"],
    message: StatusModalArgs["message"],
    onConfirm: StatusModalArgs["onConfirm"]
  ) => {
    setArgs({ title, subtext: subtitle, message, onConfirm });
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setLoading(false);
    setArgs(undefined);
  };

  // Pass a reference to the `openModal` to parents
  if (props.openRef) {
    props.openRef.current = { open: openModal };
  }

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      overlayClassName="StatusModal modal_overlay"
      className="StatusModal modal"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <h2 style={{ textAlign: "center" }}>{args?.title}</h2>
          <div>
            <p>{args?.subtext}</p>
          </div>
          <div className="dangerSection">
            {args?.message.map((x, i) => (
              <div key={i}>{x}</div>
            ))}
          </div>
          <button className="wide" onClick={confirm}>
            I'm sure, do it
          </button>
        </>
      )}
    </ReactModal>
  );
};
