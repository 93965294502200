import * as React from "react";
import "./SaveScriptModal.css";
import ReactModal from "react-modal";

import { Loader } from "../../../components/Loader";
import API from "../../../utils/api";
import { managementDB } from "../../../../../../lambdas/utils-common";

type SaveScriptModalProps = {
  /** A way to expose the `open` method to parents */
  openRef: React.MutableRefObject<any>;
  onDone: (newScript: managementDB.Models.Script) => void;
};

/** A big search bar, with full-page results */
export const SaveScriptModal: React.FunctionComponent<SaveScriptModalProps> = (
  props
) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [meta, setMeta] = React.useState<any>();
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");

  const openModal = (meta: any, value: string, onDone: () => {}) => {
    setMeta(meta);
    setValue(value);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setMeta(undefined);
    setValue("");
    setError("");
    setLoading(false);
  };

  // Pass a reference to the `openModal` to parents
  if (props.openRef) {
    props.openRef.current = { open: openModal };
  }

  const save = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!open || loading) {
      return;
    }
    setLoading(true);
    const result = await API.post(`/scripts`, {
      name: meta.name,
      description: meta.description,
      code: value,
    });
    if (!result.json?.success) {
      setError(result.json?.message ?? `API error (${result.status})`);
      setLoading(false);
      return;
    }
    setLoading(false);
    props.onDone(result.json.item);
    closeModal();
  };

  const onInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMeta({
      ...meta,
      [event.target.name]: event.target.value,
    });
  };

  if (loading) {
    return <Loader />;
  }

  const isNew = !meta?._id;
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      overlayClassName="SaveScriptModal modal_overlay"
      className="SaveScriptModal modal"
      ariaHideApp={false}
    >
      {error ? <p className="error">{error}</p> : <></>}
      <form onSubmit={save}>
        <h4>{isNew ? "Create Script" : "Save Script As"}</h4>
        <label>
          Name
          <br />
          <input
            type="text"
            name="name"
            onChange={onInput}
            value={meta?.name || ""}
          />
        </label>
        <label>
          Description
          <br />
          <textarea
            name="description"
            placeholder="Describe what this script does..."
            onChange={onInput}
            value={meta?.description || ""}
          />
        </label>
        <div className="buttonRow">
          <input type="submit" value={isNew ? "Create" : "Save"} />
          <button onClick={closeModal}>Cancel</button>
        </div>
      </form>
    </ReactModal>
  );
};
