/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import "./AccountModal.css";
import ReactModal from "react-modal";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Check from "@mui/icons-material/Check";

import API from "../../../utils/api";
import { Loader } from "../../../components/Loader";
import * as RDB from "../../../../../../lambdas/rdb";
import moment from "moment-timezone";
import numeral from "numeral";

type AccountModalProps = {
  /** A way to expose the `open` method to parents */
  openRef: React.MutableRefObject<any>;
  /** Called when we want to reload all the accounts */
  reloadAccounts: () => void;
  /** Other accounts to use for min max */
  accounts?: RDB.Models.Account[];
};

/** A big search bar, with full-page results */
export const AccountModal: React.FunctionComponent<AccountModalProps> = (
  props
) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [account, setAccount] = React.useState<RDB.Models.Account>();
  const [minAccount, setMinAccount] = React.useState<RDB.Models.Account>();
  const [maxAccount, setMaxAccount] = React.useState<RDB.Models.Account>();
  const [justCopied, setJustCopied] = React.useState<false | string>(false);

  React.useEffect(() => {
    console.log(account, props.accounts);
    if (account) {
      if (account.balance?.min) {
        const minAccount = props.accounts?.find(
          (x) => x._id === account.balance?.min?._acc
        );
        setMinAccount(minAccount);
      }
      if (account.balance?.max) {
        const maxAccount = props.accounts?.find(
          (x) => x._id === account.balance?.max?._acc
        );
        setMaxAccount(maxAccount);
      }
    }
  }, [account, props.accounts]);

  const deleteAccount = async () => {
    if (!account) {
      return;
    }
    setLoading(true);
    const result = await API.delete(
      `/users/${account._user}/accounts/${account._id}`
    );
    if (!result.json?.success) {
      setError(result.json?.message ?? `API error (${result.status})`);
      return;
    }
    props.reloadAccounts();
    closeModal();
  };

  const retryMinMax = async (type: string) => {
    if (!account) {
      return;
    }
    if (!["MIN", "MAX"].includes(type)) {
      return;
    }
    setLoading(true);
    const result = await API.post(
      `/users/${account._user}/accounts/${account._id}/minmax`,
      { type }
    );
    if (!result.json?.success) {
      setError(result.json?.message ?? `API error (${result.status})`);
      return;
    }
    props.reloadAccounts();
    closeModal();
  };

  const updateTrigger = async (type: string) => {
    if (!account) {
      return;
    }
    const trigger =
      type === "MIN" ? account?.balance?.min : account?.balance?.max;
    if (!trigger) {
      return;
    }
    trigger.status = trigger.status === "ACTIVE" ? "PAUSED" : "ACTIVE";
    setLoading(true);
    const result = await API.put(
      `/users/${account._user}/accounts/${account._id}/minmax`,
      {
        [type.toLowerCase()]: {
          status: trigger.status,
        },
      }
    );
    if (!result.json?.success) {
      setError(result.json?.message ?? `API error (${result.status})`);
      return;
    }
    props.reloadAccounts();
    closeModal();
  };

  const copyField = async (field: "formatted_account" | "_id") => {
    if (account) {
      setJustCopied(field);
      await navigator.clipboard.writeText(account[field] ?? "");
      setTimeout(() => {
        setJustCopied(false);
      }, 2000);
    }
  };

  const openModal = (
    account: RDB.Models.Account,
    accounts: RDB.Models.Account[]
  ) => {
    setAccount(account);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setLoading(false);
    setAccount(undefined);
  };

  // Pass a reference to the `openModal` to parents
  if (props.openRef) {
    props.openRef.current = { open: openModal };
  }

  const content =
    loading || !account ? (
      <Loader />
    ) : (
      <>
        <p>
          Linked {moment(account.created_at).fromNow()}, last refreshed{" "}
          {moment(account.refreshed?.balance).fromNow()}.
        </p>
        <table>
          <tbody>
            <tr>
              <td>_id:</td>
              <td>
                {account._id}{" "}
                {justCopied === "_id" ? (
                  <Check />
                ) : (
                  <a onClick={() => copyField("_id")} title="Copy">
                    <ContentCopy />
                  </a>
                )}
              </td>
              <td></td>
            </tr>

            <tr>
              <td>status:</td>
              <td>{account.status}</td>
              <td></td>
            </tr>
            <tr>
              <td>Attributes:</td>
              <td>
                <div className="attributeList">
                  {account.attributes?.sort().map((attr) => (
                    <span>{attr}</span>
                  ))}
                </div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Number:</td>
              <td>
                {account.formatted_account}{" "}
                {justCopied === "formatted_account" ? (
                  <Check />
                ) : (
                  <a
                    onClick={() => copyField("formatted_account")}
                    title="Copy"
                  >
                    <ContentCopy />
                  </a>
                )}
              </td>
              <td></td>
            </tr>
            {account.meta?.holder ? (
              <tr>
                <td>Holder:</td>
                <td>{account.meta.holder}</td>
                <td></td>
              </tr>
            ) : (
              <></>
            )}
            <tr>
              <td>Balance:</td>
              <td>
                <b>{numeral(account.balance?.current).format("$0,00.00")}</b> ({" "}
                {numeral(account.balance?.available).format("$0,00.00")}{" "}
                available )
              </td>
              <td></td>
            </tr>
            {/* Min max */}
            {minAccount && (
              <tr>
                <td>Minimum:</td>
                <td>
                  <b>
                    {numeral(account.balance?.min?.amount).format("$0,00.00")}
                  </b>{" "}
                  ( {numeral(minAccount.balance?.available).format("$0,00.00")}{" "}
                  {minAccount?.name ?? minAccount?.formatted_account} ){" "}
                  {account.balance?.min?.status ? (
                    <>( {account.balance?.min?.status} ) </>
                  ) : (
                    <> </>
                  )}
                  {account.balance?.min?.last_run_at ? (
                    <>
                      - Last run{" "}
                      {moment(account.balance?.min?.last_run_at).fromNow()}
                    </>
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  {account.balance?.min && (
                    <div className="buttonRow">
                      <button
                        className="blue"
                        onClick={() => updateTrigger("MIN")}
                      >
                        {account.balance?.min.status === "ACTIVE"
                          ? "Pause"
                          : "Enable"}
                      </button>
                      <button
                        className="blue"
                        onClick={() => retryMinMax("MIN")}
                      >
                        Run Min
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            )}
            {!!account.balance?.min?.buffer && (
              <tr>
                <td>Buffer:</td>
                <td>
                  <b>
                    {numeral(account.balance?.min?.buffer).format("$0,00.00")}
                  </b>
                </td>
                <td></td>
              </tr>
            )}
            {maxAccount && (
              <tr>
                <td>Maximum:</td>
                <td>
                  <b>
                    {numeral(account.balance?.max?.amount).format("$0,00.00")}
                  </b>{" "}
                  ( {numeral(maxAccount.balance?.available).format("$0,00.00")}{" "}
                  {maxAccount?.name ?? maxAccount?.formatted_account} ){" "}
                  {account.balance?.max?.status ? (
                    <>( {account.balance?.max?.status} ) </>
                  ) : (
                    <> </>
                  )}
                  {account.balance?.max?.last_run_at ? (
                    <>
                      - Last run{" "}
                      {moment(account.balance?.max?.last_run_at).fromNow()}
                    </>
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  {account.balance?.max && (
                    <div className="buttonRow">
                      <button
                        className="blue"
                        onClick={() => updateTrigger("MAX")}
                      >
                        {account.balance?.max.status === "ACTIVE"
                          ? "Pause"
                          : "Enable"}
                      </button>
                      <button
                        className="blue"
                        onClick={() => retryMinMax("MAX")}
                      >
                        Run Max
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            )}
            <tr>
              <td>Provider:</td>
              <td>{account.provider}</td>
              <td></td>
            </tr>
            {account.provider === "AKAHU" ? (
              <tr>
                <td>Akahu ID:</td>
                <td>{account._pid}</td>
                <td></td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        </table>
        <div className="buttonRow">
          <button className="wide red" onClick={deleteAccount}>
            Delete Account
          </button>
        </div>
      </>
    );

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      overlayClassName="AccountModal modal_overlay"
      className="AccountModal modal compact"
    >
      <>
        <h3>{account?.name ?? "Account"}</h3>
        {error ? <p className="red">{error}</p> : <></>}
        {content}
      </>
    </ReactModal>
  );
};
