import bankValidator from "nz-bank-validator";

export function sleep(ms: number) {
  return new Promise((res) => {
    setTimeout(res, ms);
  });
}

type Stage = "prod" | "staging";
/**
 * What stage this app is running in.
 *
 * This is easier than reading the env var on it's own since the environment
 * variable is undefined when running locally.
 */
export const stage = (process.env.REACT_APP_DOLLA_STAGE ?? "staging") as Stage;

/** Formats an NZBN address object */
export const getFullAddress = (
  addressList:
    | {
        uniqueIdentifier: string;
        startDate: string;
        endDate: string;
        careOf: string;
        address1: string;
        address2: string;
        address3: string;
        address4: string;
        postCode: string;
        countryCode: string;
        addressType: "POSTAL";
        pafId: string;
      }
    | undefined
) => {
  if (!addressList) {
    return "";
  }

  const {
    address1: ad1,
    address2: ad2,
    address3: ad3,
    address4: ad4,
    postCode,
  } = addressList;

  return [ad1, ad2, ad3, ad4].filter((x) => x).join(", ") + " " + postCode;
};

/**
 * Validate and format an NZ bank account number
 */
export function validateBankAccount(number: string, long?: boolean) {
  if (typeof number !== "string") {
    return false;
  }
  // Format for the validator
  const parts = number
    .replace(/[^0-9]/g, "")
    .replace(/(\d{2})(\d{4})(\d{7})(\d{2,3})/, "$1-$2-$3-$4")
    .split("-");
  if (!long) {
    if (parts[parts.length - 1].length === 3) {
      parts[parts.length - 1] = parts[parts.length - 1].slice(1);
    }
  } else if (parts[parts.length - 1].length === 2) {
    parts[parts.length - 1] = `0${parts[parts.length - 1]}`;
  }
  const formatted = parts.join("-");
  if (!bankValidator.validate(formatted)) {
    return false;
  }
  return formatted;
}
